import * as Elements from './index';

/**
 * Contains all available elements for HRF.
 */
const MAPPING = {
  tasks: Elements.materialAndFinancialScheduleTasks,
  hrf_element_summary: Elements.hrfElementSummary,
  product_indicators: Elements.indicators,
  result_indicators: Elements.indicators,
};

/**
 * Gets element from mapping or if not exists, returns empty function.
 *
 * @param {string} name - element name
 * @returns {Node | string} - element from mapping or empty function
 */
// eslint-disable-next-line func-names
export const getDocxElement = (name) => MAPPING[name] ?? function () {};
