import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  TextField,
} from '@mui/material';
import {
  Controller,
  useForm,
  FormProvider,
} from 'react-hook-form';
import { request } from '../../_services';
import {
  API_ROUTE,
  CHARS_LIMIT,
} from '../../_constants';
import CharsCounter from '../../Features/CharsCounter';
import { SaveCancelButtons } from '../../Features/SaveCancelButtons';
import Validations from '../../Features/ApplicationTemplate/Edit/Validations/Validations';
import { TitledContainer } from '../../Features/TitledContainer';
import { DynamicFieldForm } from './ElementField';

/**
 * Field edit form component.
 *
 * @param {object} props - root props
 * @param {object} props.fieldData - current field data
 * @param {Function} props.backHandler - back button handler
 * @param {Function} props.submitHandler - submit form handler (optional)
 * @param {string} props.templateElementId - application template ID
 * @returns {FieldEditForm}
 */
export function FieldEditForm({
  fieldData,
  backHandler,
  submitHandler,
  templateElementId,
}) {
  const form = useForm({
    defaultValues: {
      label: fieldData?.label || '',
      help: fieldData?.help || '',
      choices: fieldData?.choices || [],
      maxLength: fieldData?.maxLength || '',
      minRowNumber: fieldData?.minRowNumber || 0,
      maxRowNumber: fieldData?.maxRowNumber || null,
      minItems: fieldData?.minItems || 0,
      maxItems: fieldData?.maxItems || 10,
      acceptedFileExtensions: fieldData?.acceptedFileExtensions || [],
      minAttachments: fieldData?.minAttachments || 0,
      maxAttachments: fieldData?.maxAttachments || 0,
      modules: fieldData?.modules?.map((module) => module['@id']) || [],
      maxFilesize: fieldData?.maxFilesize || 0,
      uuid: fieldData?.uuid || '',
      ...(fieldData?.rules ? { rules: fieldData?.rules } : {}),
    },
    mode: 'all',
  });

  const {
    control, handleSubmit, getValues,
  } = form;

  /**
   * Build API PUT URL.
   *
   * @returns {string} - request url
   */
  const makeUpdateUrl = () => API_ROUTE.editElementField
    .replace(':templateElementId', templateElementId)
    .replace(':fieldUuid', fieldData.uuid);

  /**
   * Handle form submit.
   *
   * @param {object} values - data form
   */
  const onSubmit = async (values) => {
    const payload = {
      properties: {
        ...values,
        minRowNumber: values.minRowNumber,
        maxRowNumber: values.maxRowNumber,
        minItems: Number(values.minItems),
        maxItems: Number(values.maxItems),
        maxLength: Number(values.maxLength),
      },
    };

    if (submitHandler !== null) {
      submitHandler(payload, values.uuid);

      return;
    }

    const {
      statusSuccess,
    } = await request.put(
      makeUpdateUrl(),
      payload
    );

    if (statusSuccess) {
      backHandler();
    }
  };
  const isCollectionField = fieldData.type === 'collection';

  return (
    <FormProvider {...form}>
      <TitledContainer title={isCollectionField ? 'Edycja kolekcji' : 'Edycja pola'} variant="slim">
        <FormControl fullWidth margin="normal">
          <Controller
            rules={{
              required: {
                value: true,
                message: `Proszę uzupełnić pole "Zmiana etykiety ${isCollectionField ? 'kolekcji' : 'pola'}"`,
              },
            }}
            name="label"
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                error={!!error}
                helperText={error?.message}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                label={`Zmiana etykiety ${isCollectionField ? 'kolekcji' : 'pola'}`}
                required
                onBlur={onBlur}
                InputProps={{
                  endAdornment: (
                    <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.LONG} onOutline />
                  ),
                }}
                inputProps={{ maxLength: CHARS_LIMIT.LONG }}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <Controller
            name="help"
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                error={!!error}
                helperText={error?.message}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                label="Pomoc kontekstowa"
                required
                onBlur={onBlur}
                InputProps={{
                  endAdornment: (
                    <CharsCounter valueLength={value.length} maxLength={CHARS_LIMIT.STORY} onOutline />
                  ),
                }}
                inputProps={{ maxLength: CHARS_LIMIT.STORY }}
              />
            )}
          />
        </FormControl>
        <DynamicFieldForm
          fieldData={fieldData}
          onChangeHandler={control}
          formData={getValues()}
        />
      </TitledContainer>
      <Validations rules={fieldData?.rules || []} type={fieldData?.type} />
      <Box mt={3} display="flex" justifyContent="center">
        <SaveCancelButtons
          saveHandler={handleSubmit(onSubmit)}
          saveButtonId="07hCU6Gm8l91Y1B"
          cancelHandler={backHandler}
          cancelButtonId="cl0TuxTwCqghAB7"
        />
      </Box>
    </FormProvider>
  );
}

FieldEditForm.propTypes = {
  fieldData: PropTypes.instanceOf(Object).isRequired,
  backHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func,
  templateElementId: PropTypes.string.isRequired,
};

FieldEditForm.defaultProps = {
  submitHandler: null,
};
