import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { request } from '../../_services';
import {
  API_ROUTE,
  PANEL_TYPES,
} from '../../_constants';
import { useGlobalContext } from '../Global.context';
import { useReloadListener } from '../ReloadListener.context';
import {
  evaluationSheetHelper,
  iriToId,
} from '../../_helpers';
import { useAuth } from '../../_security';
import { EvaluationSheetLoader } from '../../Features/Expert/EvaluationSheets/EvaluationSheetReusableComponents';

/**
 * Evaluation Sheets context wrapper.
 * Fetch expert panel and evaluation sheet data from API
 *
 * @type {React.Context}
 */
export const EvaluationSheetsContext = createContext({});

export const useEvaluationSheetsContext = () => useContext(EvaluationSheetsContext);

/**
 * Assessment sheet provider.
 *
 * @param {object} props - root props
 * @param {Node} props.children - children elements
 * @param {boolean} props.isReadonly - is read only
 * @returns {EvaluationSheetsProvider}
 */
export function EvaluationSheetsProvider({
  children, isReadonly,
}) {
  const [store, setStore] = useState({});
  const [isLoaded, setLoaded] = useState(false);
  const {
    evaluationSheetId, expertPanelId,
  } = useParams();
  const {
    id: loggedUserId, isAdmin,
  } = useAuth();
  const navigate = useNavigate();
  const { notify } = useGlobalContext();
  const errorMessage = 'Wystąpił błąd podczas wczytywania arkusza ocen. Spróbuj ponownie.';
  const {
    reload, watch,
  } = useReloadListener();
  const watcherName = 'evaluation-sheet';

  const fetchEvaluationSheet = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}`);

    if (statusSuccess) {
      const {
        expertPanel, transformedData,
      } = await fetchExpertPanel();

      let expertsJustifications = [];
      let expertCompletions = [];
      let isApplicationLeadingExpert = false;

      if (transformedData.hasAcceptedAtLeastOneModule || transformedData.isEmployeeOrAdmin) {
        expertsJustifications = await fetchExpertsJustifications();
        expertCompletions = await fetchExpertCompletions();
      }

      if (expertPanel.criteriaSet.panelType === PANEL_TYPES.moduleFirstStage) {
        isApplicationLeadingExpert = await fetchApplicationLeadingExpert();
      }

      setStore({
        expertPanel,
        transformedData: {
          ...transformedData,
          isApplicationLeadingExpert,
        },
        evaluationSheet: payload,
        expertsJustifications,
        expertCompletions,
        reloadData: () => {
          setLoaded(false);
          reload(watcherName);
        },
        isReadonly,
      });
      setLoaded(true);

      return;
    }

    navigate(-1);
    setTimeout(() => {
      notify(errorMessage, 'error');
    }, 100);
  };

  const fetchExpertPanel = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.expertPanels}/${expertPanelId}`);

    if (statusSuccess) {
      const evaluationSheet = payload.evaluationSheets.find(({ id }) => id === evaluationSheetId);
      const chairmanId = iriToId(payload.chairman.user['@id'] || '');
      const viceChairmanId = iriToId(payload.viceChairman?.user?.['@id'] || '');
      const isChairmanOrViceChairman = chairmanId === loggedUserId || viceChairmanId === loggedUserId;
      const isEmployeeOrAdmin = (isChairmanOrViceChairman || isAdmin);
      const hasAcceptedAtLeastOneModule = isEmployeeOrAdmin
        || evaluationSheetHelper.hasAcceptedMinOneModule(payload.modules, loggedUserId);

      return {
        expertPanel: payload,
        transformedData: {
          evaluationSheet,
          isChairmanOrViceChairman,
          isEmployeeOrAdmin,
          hasAcceptedAtLeastOneModule,
          expertList: evaluationSheetHelper.getExpertListFromModules(payload.modules),
        },
      };
    }

    navigate(-1);
    setTimeout(() => {
      notify(errorMessage, 'error');
    }, 100);

    return null;
  };

  const fetchExpertsJustifications = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}/expert-justifications`);

    if (statusSuccess) {
      return payload;
    }

    return [];
  };

  const fetchApplicationLeadingExpert = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(API_ROUTE.expertPanelLeadingExperts.replace(':id', expertPanelId));

    if (!statusSuccess) {
      navigate(-1);
      setTimeout(() => {
        notify('Wystąpił błąd podczas wczytywania arkusza ocen. Spróbuj ponownie.', 'error');
      }, 100);

      return false;
    }

    return evaluationSheetHelper.isExpert(
      loggedUserId,
      payload?.leadingExpert || {}
    );
  };

  const fetchExpertCompletions = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}/expert-panel-completions`);

    if (statusSuccess) {
      return payload;
    }

    return null;
  };

  useEffect(() => {
    fetchEvaluationSheet();
  }, [watch(watcherName)]);

  return (
    <EvaluationSheetsContext.Provider value={store}>
      {!isLoaded && (
        <EvaluationSheetLoader />
      )}
      {isLoaded && children}
    </EvaluationSheetsContext.Provider>
  );
}
EvaluationSheetsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isReadonly: PropTypes.bool,
};

EvaluationSheetsProvider.defaultProps = {
  isReadonly: true,
};
