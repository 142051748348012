import { useNavigate } from 'react-router-dom';
import {
  addHours,
  format,
  getUnixTime,
  parseISO,
} from 'date-fns';
import {
  Box,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from 'react';
import { theme } from '../../styles/theme';

/**
 * Session info bar.
 *
 * @param {object} props - root props
 * @param {string} props.sessionStartDate - session start date
 * @returns {SessionInfoBar}
 */
function SessionInfoBar({ sessionStartDate }) {
  const navigate = useNavigate();
  const endDate = getUnixTime(addHours(parseISO(sessionStartDate), 1));

  const [secondsToSessionEnd, setSecondsToSessionEnd] = useState(endDate - getUnixTime(new Date()));

  useEffect(() => {
    const timer = secondsToSessionEnd > 0
      && setInterval(() => setSecondsToSessionEnd(endDate - getUnixTime(new Date())), 1000);

    if (secondsToSessionEnd <= 0) {
      navigate(-1);
    }

    return () => clearInterval(timer);
  }, [secondsToSessionEnd]);

  if (!sessionStartDate) {
    return '';
  }

  return (
    <Box mb={3}>
      <Box
        border={1}
        borderColor={theme.palette.error.main}
        p={2}
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          textAlign="center"
          color={theme.palette.error.main}
        >
          {`Do końca sesji pozostało ${format(new Date(secondsToSessionEnd * 1000), 'mm:ss')}.
        Arkusz oceny może być uzupełniany jednocześnie przez jednego eksperta.
        Przed końcem sesji naciśnij Zapisz. Po tym czasie wprowadzone zmiany, które nie są zapisane zostaną usunięte.`}
        </Typography>
      </Box>
    </Box>
  );
}

export default SessionInfoBar;

SessionInfoBar.propTypes = {
  sessionStartDate: PropTypes.string.isRequired,
};
