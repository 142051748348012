import { AlignmentType } from 'docx';
import {
  printoutToDocxTable,
  printoutToDocxTableCell,
  printoutToDocxTableRow,
  printoutToDocxText,
} from '../PrintoutToDocxComponents';

/**
 * Hrf element summary
 *
 * @param {object} props - root props
 * @param {object} props.subElementData - sub element data
 * @returns {hrfElementSummary}
 */
export const hrfElementSummary = ({ subElementData }) => {
  const {
    label, elementData,
  } = subElementData;

  return [
    printoutToDocxText({
      text: label,
      size: 16,
      align: AlignmentType.LEFT,
      bold: true,
      marginTop: 500,
      marginBottom: 500,
    }),
    printoutToDocxTable({
      columns: 6,
      rows: [
        printoutToDocxTableRow({
          children: [
            printoutToDocxTableCell({
              text: 'Grupa kategorii',
              bgColor: 'gray',
              numberOfColumns: 6,
              colSpan: 1,
            }),
            printoutToDocxTableCell({
              text: 'Kategoria Kosztu',
              bgColor: 'gray',
              numberOfColumns: 6,
              colSpan: 1,
            }),
            printoutToDocxTableCell({
              text: 'Wydatki ogółem',
              bgColor: 'gray',
              numberOfColumns: 6,
              colSpan: 1,
            }),
            printoutToDocxTableCell({
              text: 'Wydatki kwalifikowane',
              bgColor: 'gray',
              numberOfColumns: 6,
              colSpan: 1,
            }),
            printoutToDocxTableCell({
              text: 'Dofinansowanie',
              bgColor: 'gray',
              numberOfColumns: 6,
              colSpan: 1,
            }),
            printoutToDocxTableCell({
              text: '% dofinansowania',
              bgColor: 'gray',
              numberOfColumns: 6,
              colSpan: 1,
            }),
          ],
        }),
        ...subElementData.elementData.items.flatMap((group) => [
          ...group.items.flatMap((expense, expenseIndex) => printoutToDocxTableRow({
            children: [
              printoutToDocxTableCell({
                text: expenseIndex === 0 ? group.name : '',
                numberOfColumns: 6,
                colSpan: 1,
              }),
              printoutToDocxTableCell({
                text: expense.name,
                numberOfColumns: 6,
                colSpan: 1,
              }),
              printoutToDocxTableCell({
                text: expense.total_expenses,
                numberOfColumns: 6,
                colSpan: 1,
              }),
              printoutToDocxTableCell({
                text: expense.eligible_expenses,
                numberOfColumns: 6,
                colSpan: 1,
              }),
              printoutToDocxTableCell({
                text: expense.co_financing,
                numberOfColumns: 6,
                colSpan: 1,
              }),
              printoutToDocxTableCell({
                text: expense.co_financing_percent,
                numberOfColumns: 6,
                colSpan: 1,
              }),
            ],
          })),
          printoutToDocxTableRow({
            children: [
              printoutToDocxTableCell({
                text: 'Suma ( dla każdej grupy )',
                bgColor: 'gray',
                numberOfColumns: 6,
                colSpan: 2,
              }),
              printoutToDocxTableCell({
                text: group.total_expenses,
                numberOfColumns: 6,
                colSpan: 1,
              }),
              printoutToDocxTableCell({
                text: group.eligible_expenses,
                numberOfColumns: 6,
                colSpan: 1,
              }),
              printoutToDocxTableCell({
                text: group.co_financing,
                numberOfColumns: 6,
                colSpan: 1,
              }),
              printoutToDocxTableCell({
                text: group.co_financing_percent,
                numberOfColumns: 6,
                colSpan: 1,
              }),
            ],
          }),
        ]),
        printoutToDocxTableRow({
          children: [
            printoutToDocxTableCell({
              text: 'Suma wydatków',
              bgColor: 'gray',
              numberOfColumns: 6,
              colSpan: 2,
            }),
            printoutToDocxTableCell({
              text: elementData.total_expenses,
              numberOfColumns: 6,
              colSpan: 1,
            }),
            printoutToDocxTableCell({
              text: elementData.eligible_expenses,
              numberOfColumns: 6,
              colSpan: 1,
            }),
            printoutToDocxTableCell({
              text: elementData.co_financing,
              numberOfColumns: 6,
              colSpan: 1,
            }),
            printoutToDocxTableCell({
              text: elementData.co_financing_percent,
              numberOfColumns: 6,
              colSpan: 1,
            }),
          ],
        }),
      ],
    }),
  ];
};
