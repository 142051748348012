import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useEvaluationSheetsContext } from './EvaluationSheets.context';

/**
 * Evaluation sheets action context wrapper.
 * Other features of evaluation sheet
 *
 * @type {React.Context}
 */
export const EvaluationSheetsActionContext = createContext({});

export const useEvaluationSheetsActionContext = () => useContext(EvaluationSheetsActionContext);

/**
 * Evaluation sheets action provider.
 *
 * @param {object} props - root props
 * @param {Node} props.children - children elements
 * @returns {EvaluationSheetsActionProvider}
 */
export function EvaluationSheetsActionProvider({
  children,
}) {
  const [showFilteredModulesByExpert, setShowFilteredModulesByExpert] = useState(true);
  const [isExpandedAllEvaluationItems, setExpandedAllEvaluationItems] = useState(false);
  const [activeModule, setActiveModule] = useState(null);
  const [isFormDirty, setFormDirty] = useState(false);
  const {
    isReadonly, transformedData: { isEmployeeOrAdmin },
  } = useEvaluationSheetsContext();

  const handleShowFilteredModulesByExpert = (value = null) => {
    setShowFilteredModulesByExpert((prevState) => {
      if (typeof value === 'boolean') {
        return value;
      }

      return !prevState;
    });
  };

  const handleExpandAllEvaluationItems = () => {
    setExpandedAllEvaluationItems((prevState) => !prevState);
  };

  useEffect(() => {
    if (isEmployeeOrAdmin || isReadonly) {
      setShowFilteredModulesByExpert(false);
    }
  }, [isEmployeeOrAdmin]);

  const actionStore = useMemo(() => ({
    showFilteredModulesByExpert,
    handleShowFilteredModulesByExpert,
    isExpandedAllEvaluationItems,
    handleExpandAllEvaluationItems,
    activeModule,
    handleActiveModule: setActiveModule,
    isFormDirty,
    handleFormDirty: setFormDirty,
  }), [isExpandedAllEvaluationItems, activeModule, isFormDirty, showFilteredModulesByExpert]);

  return (
    <EvaluationSheetsActionContext.Provider value={actionStore}>
      {children}
    </EvaluationSheetsActionContext.Provider>
  );
}
EvaluationSheetsActionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
