import uniqid from 'uniqid';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import {
  useEvaluationSheetsContext,
  useGlobalDialog,
} from '../../../../../Context';
import EvaluationSheetRejectDialog from './EvaluationSheetReject.dialog';

/**
 * EvaluationSheetRejectButton element.
 *
 * @param {object} props - root props
 * @param {object} props.requestPayload - request payload
 * @param {string} props.expertByUsersId - expert by users id
 * @returns {EvaluationSheetRejectButton}
 */
function EvaluationSheetRejectButton({
  requestPayload, expertByUsersId,
}) {
  const { isReadonly } = useEvaluationSheetsContext();
  const { render } = useGlobalDialog();

  return (
    <Button
      type="button"
      id={uniqid()}
      variant="outlined"
      color="primary"
      onClick={() => render(
        <EvaluationSheetRejectDialog
          expertByUsersId={expertByUsersId}
          requestPayload={requestPayload}
          isReadonly={isReadonly}
        />,
        'Odrzuenie wniosku',
      )}
      disabled={isReadonly}
    >
      Odrzuć wniosek
    </Button>
  );
}

export default EvaluationSheetRejectButton;

EvaluationSheetRejectButton.propTypes = {
  requestPayload: PropTypes.objectOf(Object).isRequired,
  expertByUsersId: PropTypes.string.isRequired,
};
