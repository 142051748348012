import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../_constants';

/**
 * InnovationDescription element renderer.
 *
 * @returns {InnovationDescription}
 */
export function InnovationDescription() {
  const { isReadonly } = useElementContext();

  const { get } = useDictionaryLoader(
    DICTIONARIES.productInnovationImprovement,
  );

  return (
    <FieldSorter>
      {/* Czy projekt dotyczy innowacji produktowej (produktu ) na poziomie kraju? */}
      <FormField name="innovation_description_product_innovation_at_national_level">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Czy projekt dotyczy innowacji produktowej na poziomie powyżej kraju? */}
      <FormField name="innovation_description_product_innovation_above_national_level">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Czy innowacja produktowa dotyczy wyrobu? */}
      <FormField name="innovation_description_product_innovation_involves_choice">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Czy innowacja produktowa dotyczy usługi? */}
      <FormField name="innovation_description_product_innovation_involves_service">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Innowacja produktowa uwzględnia znaczące udoskonalenia w zakresie cech i zastosowań
      co najmniej pod względem */}
      <FormField name="product_innovation_features_and_applications_improvements">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.productInnovationImprovement.name))}
            multiple
            renderSelectAllButton={false}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {/* Nazwa produktu */}
      <FormField name="innovation_description_product_name">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis innowacji produktowej wnioskodawcy */}
      <FormField name="innovation_description_applicant_product_innovation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Oferta konkurencji */}
      <FormField name="innovation_description_competitor_offerings">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Nowe lub znacząco udoskonalone cechy i funkcjonalności produktu przesądzające o innowacyjności produktu */}
      <FormField name="innovation_description_innovative_product_features">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Badanie innowacji produktowej (jeśli dotyczy) */}
      <FormField name="innovation_description_product_innovation_research">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Nowe cechy i funkcjonalności produktu decydujące o przydatności użytkowej produktu dla odbiorcy */}
      <FormField name="innovation_description_new_product_features">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
