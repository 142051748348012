import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { FormField } from '../../Application/Application/FormField';
import {
  DICTIONARIES,
  BOOLEAN_VALUES,
  API_ROUTE,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import {
  iriToId,
  formatPkdCodesFieldValue,
} from '../../../_helpers';
import { FieldSorter } from './FieldSorter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';
import CharsCounter from '../../CharsCounter';
import { Alert } from '../../Alert';
import { request } from '../../../_services';

/**
 * ProjectClassification element renderer.
 *
 * @returns {ProjectClassification}
 */
export function ProjectClassification() {
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const {
    watch, getValues, setValue, reset,
  } = useFormContext();

  const loadKisDomain = async (kisDomainArea) => {
    const { payload } = await request.get(
      `${API_ROUTE.elementDictionaries.kisDomains}?areas.id=${iriToId(kisDomainArea)}`
    );

    setValue('project_classification_kis_domain', payload[0]?.name ?? '');
  };

  useEffect(() => {
    const kisDomainArea = getValues('project_classification_kis_domain_area');
    kisDomainArea && typeof kisDomainArea === 'string'
      ? loadKisDomain(kisDomainArea)
      : setValue('project_classification_kis_domain', '');
  }, [watch('project_classification_kis_domain_area')]);

  const isProjectSubjectFallsWithinRisScope = watch('project_classification_project_subject_falls_within_ris_scope');
  const pkdCodesChoices = fieldsConfig?.project_classification_pkd?.choices || [];

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.interventionScopes,
    DICTIONARIES.activityTypes,
    DICTIONARIES.risAreas,
    DICTIONARIES.kisDomainAreas,
    DICTIONARIES.sumpCompatibilities,
  );

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Zakres interwencji */}
      <FormField name="project_classification_intervention_scope">
        {({
          name, onChange, value, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={filterOptions(get(DICTIONARIES.interventionScopes.name))
              .map(({
                cstCode, '@id': id, name: interventionScopeName,
              }) => ({
                id,
                name: `${cstCode} - ${interventionScopeName}`,
              }))}
            optionsMapKeys={['id', 'name']}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Rodzaj działalności gospodarczej */}
      <FormField name="project_classification_activity_type">
        {({
          name, onChange, value, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={filterOptions(get(DICTIONARIES.activityTypes.name))}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Kod i nazwa PKD działalności, której dotyczy projekt */}
      <FormField name="project_classification_pkd">
        {({
          name, onChange, value, onBlur, error,
        }) => (
          <ApiAutocomplete
            id={name}
            filterBy="nameOrCode"
            initialValue={iriToId(value)}
            initialValueFilterBy="id"
            changeHandler={({ id }) => onChange(id)}
            optionParser={formatPkdCodesFieldValue}
            textFieldProps={{ onBlur }}
            baseUrl={API_ROUTE.elementDictionaries.pkdCodes}
            disabled={isReadonly}
            customFilter={pkdCodesChoices.length === 0
              ? () => true
              : (payloadObject) => pkdCodesChoices.includes(payloadObject.id)}
            error={error}
          />
        )}
      </FormField>
      {/* Opis rodzaju działalności */}
      <FormField name="project_classification_activity_type_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Przedmiot projektu wpisuje się w zakres Regionalnych Inteligentnych Specjalizacji (RIS) wspólnych dla  */}
      <FormField name="project_classification_project_subject_falls_within_ris_scope">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                reset((formValues) => ({
                  ...formValues,
                  project_classification_ris_area: FIELD_TYPES_MAP.limited_choice,
                  project_classification_justification_selected_ris_area: FIELD_TYPES_MAP.text,
                }));
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {isProjectSubjectFallsWithinRisScope === BOOLEAN_VALUES.TRUE && (
        <>
          {/* Obszar RIS (wspólny dla co najmniej dwóch województw Polski Wschodniej), w który wpisuje się projekt. */}
          <FormField name="project_classification_ris_area">
            {({
              name, onChange, value, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                options={filterOptions(get(DICTIONARIES.risAreas.name))}
                initialValue={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isReadonly}
                error={error}
                multiple
                renderSelectAllButton={false}
              />
            )}
          </FormField>
          {/* Uzasadnienie wybranego obszaru RIS, w który wpisuje się projekt */}
          <FormField name="project_classification_justification_selected_ris_area">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                multiline
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </>
      )}
      {/* Obszar KIS */}
      <FormField name="project_classification_kis_domain_area">
        {({
          onChange, value, name, onBlur, error, filterOptions,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.kisDomainAreas.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* KIS */}
      <FormField name="project_classification_kis_domain">
        {({ value }) => (<Alert severity="status">{value}</Alert>)}
      </FormField>
      {/* Uzasadnienie wybranego obszaru KIS */}
      <FormField name="project_classification_kis_explanation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Projekt jest ujęty w Regionalnym Planie Transportowym województwa z makroregionu Polski Wschodniej */}
      <FormField name="project_classification_project_is_included_in_regional_transportation_plan">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Projekt jest spójny z odpowiednimi planami zrównoważonej mobilności miejskiej lub innymi dokumentami
      planowania mobilności miejskiej (dotyczy inwestycji realizowanych w miejskich obszarach funkcjonalnych) */}
      <FormField name="project_classification_project_is_consistent_with_urban_mobility_plans">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      {/* Projekt jest ujęty w instrumencie terytorialnym */}
      <FormField name="project_classification_project_is_included_in_territorial_instrument">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zgodność Planu Zrównoważonej Mobilności Miejskiej (z ang. SUMP) z Komunikatem Komisji Europejskiej
      do Parlamentu Europejskiego, Rady Europejskiego Komitetu Ekonomiczno-Społecznego i Komitetu Regionów
      z 17.12.2013 r. „Wspólne dążenie do osiągnięcia konkurencyjnej i zasobooszczędnej mobilności”
      COM (2013) 913 final */}
      <FormField name="project_classification_sustainable_urban_mobility_plan_compatibility">
        {({
          onChange, value, name, onBlur, error, filterOptions,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.sumpCompatibilities.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Zgodność projektu z dokumentami z zakresu planowania mobilności miejskiej */}
      <FormField name="project_classification_urban_mobility_planning_documents_compatibility">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zgodność projektu z właściwym Programem ochrony powietrza oraz Planem na rzecz zrównoważonej energii
       i klimatu (z ang. SECAP) */}
      <FormField name="project_classification_relevant_air_protection_program_compliance">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Projekt partnerski */}
      <FormField name="project_classification_partner_project">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Projekt dotyczy pomocy publicznej */}
      <FormField name="project_classification_project_concerns_public_aid">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Spójność projektu ze strategiami rozwoju województw Polski Wschodniej */}
      <FormField
        name="project_classification_project_consistency_with_eastern_poland_voivodeships_development_strategies"
      >
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
