import { Typography } from '@mui/material';
import { useEvaluationSheetsContext } from '../../../../Context';

/**
 * Evaluation sheet header.
 *
 * @returns {EvaluationSheetHeader}
 */
export function EvaluationSheetHeader() {
  const { evaluationSheet: { application: { applicationNumber } } } = useEvaluationSheetsContext();

  return (
    <Typography variant="pageHeading" textAlign="center">
      {`Numer wniosku: ${applicationNumber}`}
    </Typography>
  );
}
