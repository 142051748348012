import {
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  useEvaluationSheetsContext,
  useGlobalContext,
} from '../../../../../Context';
import { request } from '../../../../../_services';
import {
  API_ROUTE,
  EVALUATION_SHEET_STATUS,
  URL,
} from '../../../../../_constants';

/**
 * Evaluation sheet unlock for experts button.
 *
 * @returns {EvaluationSheetUnlockForExpertsButton}
 */
export function EvaluationSheetUnlockForExpertsButton() {
  const {
    evaluationSheetId, expertPanelId,
  } = useParams();
  const navigate = useNavigate();
  const { formState: { isSubmitting } } = useFormContext();
  const {
    transformedData: { isEmployeeOrAdmin }, expertPanel: {
      number: panelNumber, recruitmentId,
    },
  } = useEvaluationSheetsContext();
  const { notify } = useGlobalContext();

  const unlockEvaluationSheetForExperts = async () => {
    const {
      statusSuccess,
    } = await request.put(`${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}`, {
      status: EVALUATION_SHEET_STATUS.edition,
    });

    navigate(
      generatePath(URL.recruitment.expertPanelApplicationsList, {
        id: recruitmentId,
        expertPanelId,
        panelNumber,
      }),
    );
    setTimeout(() => {
      notify(
        statusSuccess
          ? 'Arkusz został odblokowany dla ekspertów.'
          : 'Nie udało się odblokować arkusza dla ekspertów. Spróbuj ponownie.',
        statusSuccess ? 'success' : 'error'
      );
      window.scroll(0, 0);
    }, 100);
  };

  if (!isEmployeeOrAdmin) {
    return null;
  }

  return (
    <Button
      type="button"
      color="secondary"
      variant="outlined"
      id="2h4Vi78zcCr7d9I"
      onClick={unlockEvaluationSheetForExperts}
      disabled={isSubmitting}
    >
      Odblokuj dla ekspertów
    </Button>
  );
}
