import {
  Box,
  Button,
} from '@mui/material';
import { useEvaluationSheetsActionContext } from '../../../../Context';

/**
 * EvaluationSheetExpandButton element.
 *
 * @returns {EvaluationSheetExpandButton}
 */
export function EvaluationSheetExpandButton() {
  const {
    isExpandedAllEvaluationItems, handleExpandAllEvaluationItems,
  } = useEvaluationSheetsActionContext();

  return (
    <Box display="flex" justifyContent="flex-end" width="100%" mb={2}>
      <Button
        type="button"
        id="aUyw3NEWNKeE3yu"
        color="primary"
        variant="outlined"
        onClick={handleExpandAllEvaluationItems}
      >
        {isExpandedAllEvaluationItems ? 'Zwiń wszystkie' : 'Rozwiń wszystkie'}
      </Button>
    </Box>
  );
}
