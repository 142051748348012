import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { mapPkdCodes } from '../../../../_helpers';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import { useApplicationToPdfContext } from '../../../../Context';

/**
 * ProjectSmeAssumptions Pdf element.
 *
 * @returns {ProjectSmeAssumptionsPdf}
 */
function ProjectSmeAssumptionsPdf() {
  const { getDictionary } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="project_sme_assumptions_description" isSingleRow />
        <RowField name="project_sme_assumptions_pkd_codes" externalDictionary={mapPkdCodes(getDictionary)} />
        <RowField name="project_sme_assumptions_project_sme_goal" isSingleRow />
        <RowField name="project_sme_assumptions_investment_component" isSingleRow />
        <RowField name="project_sme_assumptions_consulting_services_component" isSingleRow />
        <RowField name="project_sme_assumptions_training_component" isSingleRow />
        <RowField name="project_sme_assumptions_product_name" isSingleRow />
        <RowField name="project_sme_assumptions_digital_transformation" isSingleRow />
        <RowField name="project_sme_assumptions_green_transformation" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ProjectSmeAssumptionsPdf;
