import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import SupplementCallsList from './SupplementCalls.list';
import {
  useAssessmentSheetContext,
  useGlobalDialog,
} from '../../../../Context';

/**
 * Supplement calls dialog.
 *
 * @returns {SupplementCallsDialog}
 */
function SupplementCallsDialog() {
  const {
    store: {
      application: {
        applicationNumber, applicationId,
      },
    },
  } = useAssessmentSheetContext();
  const { render } = useGlobalDialog();
  const { id: evaluationSheetId } = useParams();

  return (
    <Button
      variant="contained"
      color="secondary"
      id="LfKPWP098Eu8f2J"
      onClick={() => render(
        <SupplementCallsList
          applicationNumber={applicationNumber}
          applicationId={applicationId}
          evaluationSheetId={evaluationSheetId}
        />,
        `Lista wezwań do uzupełnień wniosku ${applicationNumber}`
      )}
    >
      Uzupełnienia do wniosku
    </Button>
  );
}

export default SupplementCallsDialog;
