import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';
import { getClusterMembersAndApplicantNameForPdf } from '../../../../_helpers/getClusterMembersAndApplicantName';
import { useApplicationToPdfContext } from '../../../../Context';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ClusterInternationalization pdf element.
 *
 * @returns {ClusterInternationalizationPdf}
 */
function ClusterInternationalizationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    previousInitialFormData,
    id,
  } = useApplicationToPdfContext();
  const applicantName = initialFormData?.applicant_name || '';
  const clusterMembers = initialFormData?.cluster_members || [];
  const previousApplicantName = previousInitialFormData?.applicant_name || '';
  const previousClusterMembers = previousInitialFormData?.cluster_members || [];
  const combinedClusterMembersParticipatingInEventDictionary = [
    ...getClusterMembersAndApplicantNameForPdf(applicantName, clusterMembers, id),
    ...getClusterMembersAndApplicantNameForPdf(previousApplicantName, previousClusterMembers, id),
  ];

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Tabela: Członkowie klastra biorący udział w procesie internacjonalizacji */}
        <CollectionWrapper name="cluster_members">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  {/* Nazwa członka klastra */}
                  <RowField
                    name={`${collectionName}.${index}.name`}
                    value={item.name}
                    oldValue={previousRowData?.name}
                  />
                  {/* NIP */}
                  <RowField
                    name={`${collectionName}.${index}.nip`}
                    value={item.nip}
                    oldValue={previousRowData?.nip}
                  />
                  {/* Wielkość przedsiębiorstwa */}
                  <RowField
                    name={`${collectionName}.${index}.enterprise_size`}
                    value={item.enterprise_size}
                    oldValue={previousRowData?.enterprise_size}
                    dictionaryName={DICTIONARIES.companySize.name}
                  />
                  {/* Dotychczasowe rynki zagraniczne sprzedaży produktów przeznaczonych do internacjonalizacji */}
                  <RowField
                    name={`${collectionName}.${index}.existing_markets`}
                    value={item.existing_markets}
                    oldValue={previousRowData?.existing_markets}
                    dictionaryName={DICTIONARIES.countries.name}
                  />
                  {/* Rynki, których dotyczą działania z zakresu internacjonalizacji */}
                  <RowField
                    name={`${collectionName}.${index}.markets_affected_by_internationalization_activities`}
                    value={item.markets_affected_by_internationalization_activities}
                    oldValue={previousRowData?.markets_affected_by_internationalization_activities}
                    dictionaryName={DICTIONARIES.countries.name}
                  />
                  {/* Produkty podlegające internacjonalizacji */}
                  <RowField
                    name={`${collectionName}.${index}.internationalized_products`}
                    value={item.internationalized_products}
                    oldValue={previousRowData?.internationalized_products}
                    isSingleRow
                  />
                  {/* Potencjał organizacyjny i finansowy przedsiębiorcy do internacjonalizacji */}
                  <RowField
                    name={`${collectionName}.${index}.organizational_and_financial_potential`}
                    value={item.organizational_and_financial_potential}
                    oldValue={previousRowData?.organizational_and_financial_potential}
                    isSingleRow
                  />
                  {/* Opis i uzasadnienie zaplanowanych usług w zakresie internacjonalizacji na rzecz przedsiębiorcy */}
                  <RowField
                    name={`${collectionName}.${index}.planned_services_description_and_justification`}
                    value={item.planned_services_description_and_justification}
                    oldValue={previousRowData?.planned_services_description_and_justification}
                    isSingleRow
                  />
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        {/* Tabela: Wydarzenia/ usługi z zakresu internacjonalizacji */}
        <CollectionWrapper name="internationalisation_events_services">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                {/* Nazwa wydarzenia/ usługi */}
                <View style={printStyle.table}>
                  <RowField
                    name={`${collectionName}.${index}.event_name`}
                    value={item.event_name}
                    oldValue={previousRowData?.event_name}
                    isSingleRow
                  />
                  {/* Rodzaj wydarzenia/usługi */}
                  <RowField
                    name={`${collectionName}.${index}.event_service_types`}
                    value={item.event_service_types}
                    oldValue={previousRowData?.event_service_types}
                    dictionaryName={DICTIONARIES.eventServiceTypes.name}
                  />
                  {/* Planowana data rozpoczęcia */}
                  <RowField
                    name={`${collectionName}.${index}.start_date`}
                    value={item.start_date}
                    oldValue={previousRowData?.start_date}
                  />
                  {/* Planowana data zakończenia */}
                  <RowField
                    name={`${collectionName}.${index}.end_date`}
                    value={item.end_date}
                    oldValue={previousRowData?.end_date}
                  />
                  {/* Lokalizacja (państwo) */}
                  <RowField
                    name={`${collectionName}.${index}.country`}
                    value={item.country}
                    oldValue={previousRowData?.country}
                    dictionaryName={DICTIONARIES.countries.name}
                  />
                  {/* Członkowie klastra biorący udział w wydarzeniu */}
                  <RowField
                    name={`${collectionName}.${index}.cluster_members_participating_in_event`}
                    value={item.cluster_members_participating_in_event}
                    oldValue={previousRowData?.cluster_members_participating_in_event}
                    externalDictionary={combinedClusterMembersParticipatingInEventDictionary}
                  />
                  {/* Opis sposobu organizacji, w tym szczegółowego zakresu usługi, wraz z uzasadnieniem */}
                  <RowField
                    name={`${collectionName}.${index}.description_how_to_organize`}
                    value={item.description_how_to_organize}
                    oldValue={previousRowData?.description_how_to_organize}
                    isSingleRow
                  />
                  {/* Cel wydarzenia/ usługi */}
                  <RowField
                    name={`${collectionName}.${index}.event_purpose`}
                    value={item.event_purpose}
                    oldValue={previousRowData?.event_purpose}
                    dictionaryName={DICTIONARIES.clusterInternationalizationEventPurpose.name}
                  />
                  {/* Rekrutacja do udziału w wydarzeniu  */}
                  <RowField
                    name={`${collectionName}.${index}.event_recruitment_to_participate`}
                    value={item.event_recruitment_to_participate}
                    oldValue={previousRowData?.event_recruitment_to_participate}
                    isSingleRow
                  />
                  {/* Rodzaj wydarzenia/ usługi (KKK) */}
                  <RowField
                    name={`${collectionName}.${index}.kkk_event_types`}
                    value={item.kkk_event_types}
                    oldValue={previousRowData?.kkk_event_types}
                    dictionaryName={DICTIONARIES.clusterInternationalizationKkkEventType.name}
                  />
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        {/* Badanie potrzeb członków klastra w zakresie internacjonalizacji */}
        <RowField name="cluster_internationalization_researching_the_needs_of_cluster_members" isSingleRow />
        {/* Projekt dotyczy rozwoju lub świadczenia usług z zakresu internacjonalizacji oferty
      klastra lub internacjonalizacji modeli biznesowych członków klastra  */}
        <RowField name="cluster_internationalization_project_concerns_the_development" />
        {/* Data publikacji/ rozesłania zaproszenia do udziału w ankiecie potrzeb */}
        <RowField name="cluster_internationalization_invitation_publication_date" />
        {/* Data publikacji podsumowania wyników ankiety */}
        <RowField name="cluster_internationalization_survey_results_summary_publication_date" />
        {/* Link do publikacji podsumowującej wyniki ankiety */}
        <RowField
          name="cluster_internationalization_link_to_the_publication_summarizing_the_survey_results"
          isSingleRow
        />
        {/*  Opis wyników ankiety i uzasadnienie rekomendacji w zakresie internacjonalizacji oferty klastra oraz
      internacjonalizacji modeli biznesowych członków klastra, ze wskazaniem pozostałcyh źródeł danych branych
      pod uwagę w analizie */}
        <RowField name="cluster_internationalization_survey_results_description" isSingleRow />
        {/* Zidentyfikowane potrzeby rozwojowe w zakrsie uczestnictwa klastra w międzynarodowych
      partnerstwach (sieciach współpracy)  */}
        <RowField name="cluster_internationalization_identified_development_needs" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ClusterInternationalizationPdf;
