import { AlignmentType } from 'docx';
import { printoutToDocxText } from './printoutToDocxText';
import { getDocxElement } from '../Elements/mappingRegistry.docx';

/**
 * Printout to docx element
 *
 * @param {object} elementData - single element data
 * @param {Array} dictionaries - dictionaries list
 * @returns {printoutToDocxElement}
 */
export const printoutToDocxElement = (elementData, dictionaries) => [
  printoutToDocxText({
    text: elementData.label,
    size: 16,
    align: AlignmentType.LEFT,
    bold: true,
    marginTop: 700,
    marginBottom: 700,
  }),
  ...elementData.fields
    .flatMap((subElementData) => getDocxElement(subElementData.name)({
      subElementData,
      dictionaries,
      parentElementData: elementData,
    })),
];
