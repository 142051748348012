import {
  Box,
  Button,
} from '@mui/material';
import { theme } from '../../../../styles/theme';
import { EXPERT_PANEL_TYPES } from '../../../../_constants';
import SupplementCallsDialog from '../../AssessmentSheet/SupplementCalls/SupplementCalls.dialog';
import {
  useEvaluationSheetsActionContext,
  useEvaluationSheetsContext,
} from '../../../../Context';
import {
  EvaluationSheetChairmanRemarksButton,
} from './EvaluationSheetChairmanRemarks/EvaluationSheetChairmanRemarksButton';

/**
 * EvaluationSheetActionBar element.
 *
 * @returns {EvaluationSheetActionBar}
 */
export function EvaluationSheetActionBar() {
  const {
    transformedData: { isEmployeeOrAdmin }, expertPanel: { criteriaSet: { panelType } },
  } = useEvaluationSheetsContext();
  const {
    handleShowFilteredModulesByExpert, handleActiveModule,
  } = useEvaluationSheetsActionContext();

  return (
    <Box
      p={2}
      bgcolor={theme.palette.brandGray10.main}
      justifyContent="flex-start"
      display="flex"
      flexWrap="wrap"
      mb={3}
    >
      <Box gap={1} display="flex">
        {!isEmployeeOrAdmin && (
          <Button
            type="button"
            id="O6Ru89ug3QJR502"
            color="secondary"
            variant="contained"
            onClick={() => {
              handleShowFilteredModulesByExpert(true);
              handleActiveModule(null);
            }}
          >
            Mój arkusz oceny
          </Button>
        )}
        <EvaluationSheetChairmanRemarksButton />
      </Box>
      {panelType !== EXPERT_PANEL_TYPES.moduleFirstStage && (
        <SupplementCallsDialog />
      )}
    </Box>
  );
}
