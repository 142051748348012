import { printoutToDocxTableCell } from './printoutToDocxTableCell';
import {
  DATE,
  FIELD_TYPES,
} from '../../../_constants';
import {
  formatDateString,
  iriToId,
} from '../../../_helpers';

/**
 * Printout to docx cell value by type
 *
 * @param {object} props - root props
 * @param {Array} props.templateFields - template fields
 * @param {string} props.fieldName - field name
 * @param {string} props.bgColor - background color
 * @param {number} props.colSpan - column merge
 * @param {number} props.numberOfColumns - number of columns
 * @param {string | number} props.value - field value
 * @param {Array} props.dictionaries - dictionaries
 * @returns {printoutToDocxCellValueByType}
 */
export const printoutToDocxCellValueByType = ({
  templateFields,
  fieldName,
  numberOfColumns = 6,
  bgColor = 'white',
  colSpan,
  value,
  dictionaries = [],
}) => {
  const fieldConfig = templateFields?.find(({ name }) => name === fieldName);

  const getFieldValue = () => {
    if (fieldConfig?.type === FIELD_TYPES.date && value) {
      return formatDateString(value, DATE.defaultFormatWithoutTime);
    }

    if (fieldConfig?.type === FIELD_TYPES.choice || fieldConfig?.type === FIELD_TYPES.limitedChoice) {
      return dictionaries.find(({ id }) => id === iriToId(value))?.name || value;
    }

    return value;
  };

  return printoutToDocxTableCell({
    text: getFieldValue(),
    bgColor,
    numberOfColumns,
    colSpan,
  });
};
