import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { FieldSorter } from './FieldSorter';
import { FormField } from '../../Application/Application/FormField';
import { useElementContext } from '../../../Context';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
  getImplementers,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import CharsCounter from '../../CharsCounter';
import {
  MonetaryField,
  parsePastedText,
} from '../../MonetaryField';

/**
 * Regional investment aid infrastructure application element.
 *
 * @returns {RegionalInvestmentAidInfrastructure}
 */
function RegionalInvestmentAidInfrastructure() {
  const {
    control, watch, getValues, setValue, resetField, reset,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const {
    get, isLoaded,
  } = useDictionaryLoader(DICTIONARIES.initialInvestmentTypes);

  const collectionFieldName = 'regional_investment_aid_rd_infrastructure_investment_aid';

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });

  const { initialInvestmentTypes } = ELEMENTS_DICTIONARIES_MAP;

  const isRegionalValue = watch('regional_investment_aid_rd_infrastructure_is_regional');

  const regionalInvestmentAidRdInfrastructureInvestmentAidConfig = fieldsConfig[collectionFieldName];
  const subfields = regionalInvestmentAidRdInfrastructureInvestmentAidConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = regionalInvestmentAidRdInfrastructureInvestmentAidConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0 && isRegionalValue === BOOLEAN_VALUES.TRUE) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber, isRegionalValue]);

  const additionalFieldsByIds = [
    initialInvestmentTypes.investmentInTangibleFixedAssets.id,
    initialInvestmentTypes.investmentInTangibleFixedAssetsAndValues.id,
  ];

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Czy moduł dotyczy regionalnej pomocy inwestycyjnej (art. 14 rozporządzenia Komisji (UE) nr 651/2014) */}
      <FormField name="regional_investment_aid_rd_infrastructure_is_regional">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(collectionFieldName, { defaultValue: FIELD_TYPES_MAP.collection });
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Tabela: Rodzaj inwestycji początkowej */}
      <LabeledFieldRow
        hide={isRegionalValue === BOOLEAN_VALUES.FALSE}
        fieldConfig={regionalInvestmentAidRdInfrastructureInvestmentAidConfig}
      >
        {fields.map((field, index) => (
          <CollectionFieldContainer
            key={field.id}
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionFieldName,
              index,
            )}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            isIndexed
          >
            {/* Nazwa wnioskodawcy/ konsorcjanta */}
            <FormField name={`${collectionFieldName}.${index}.applicant_name`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={getImplementers(getValues)}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Rodzaj inwestycji początkowej */}
            <FormField name={`${collectionFieldName}.${index}.initial_investment_type`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.initialInvestmentTypes.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Uzasadnienie */}
            <FormField name={`${collectionFieldName}.${index}.reason`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {additionalFieldsByIds.includes(watch(`${collectionFieldName}.${index}.initial_investment_type`)) && (
              <>
                {/* Ponownie wykorzystane aktywa */}
                <FormField name={`${collectionFieldName}.${index}.reused_assets`}>
                  {({
                    onChange, value, name, maxLength, onBlur, error,
                  }) => (
                    <TextField
                      multiline
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={value}
                      variant="outlined"
                      InputProps={{
                        endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} />,
                      }}
                      inputProps={{ maxLength }}
                      disabled={isReadonly}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </FormField>
                {/* Wartość księgowa ponownie wykorzystywanych aktywów, odnotowana w roku obrotowym */}
                <FormField name={`${collectionFieldName}.${index}.reused_assets_book_value`}>
                  {({
                    onChange, value, name, onBlur, error,
                  }) => (
                    <TextField
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={value}
                      variant="outlined"
                      disabled={isReadonly}
                      InputProps={{
                        inputComponent: MonetaryField,
                      }}
                      inputProps={{
                        allowNegative: false,
                        allowedDecimalSeparators: ['.', ','],
                      }}
                      onPaste={(event) => parsePastedText(event, onChange)}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </FormField>
              </>
            )}
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="p30u3qEZs2gC1RH"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Rodzaj inwestycji początkowej */}
      <FormField name="regional_investment_aid_rd_infrastructure_initial_investment_type">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== initialInvestmentTypes.investmentInTangibleFixedAssets.id) {
                reset((formValues) => ({
                  ...formValues,
                  regional_investment_aid_rd_infrastructure_reused_assets: FIELD_TYPES_MAP.text,
                  regional_investment_aid_rd_infrastructure_reused_assets_book_value: FIELD_TYPES_MAP.text,
                }));
              }
            }}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.initialInvestmentTypes.name))}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {additionalFieldsByIds.includes(watch('regional_investment_aid_rd_infrastructure_initial_investment_type'))
          && (
            <>
              {/* Ponownie wykorzystane aktywa */}
              <FormField name="regional_investment_aid_rd_infrastructure_reused_assets">
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Wartość księgowa ponownie wykorzystywanych aktywów, odnotowana w roku obrotowym */}
              <FormField name="regional_investment_aid_rd_infrastructure_reused_assets_book_value">
                {({
                  onChange, value, name, onBlur, error,
                }) => (
                  <TextField
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    disabled={isReadonly}
                    InputProps={{
                      inputComponent: MonetaryField,
                    }}
                    inputProps={{
                      allowNegative: false,
                      allowedDecimalSeparators: ['.', ','],
                    }}
                    onPaste={(event) => parsePastedText(event, onChange)}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
            </>
          )}
      {/* Uzasadnienie */}
      <FormField name="regional_investment_aid_rd_infrastructure_reason">
        {({
          name, onChange, value, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}

export default RegionalInvestmentAidInfrastructure;
