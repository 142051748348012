import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import RowField from '../../Components/RowField/RowField';

/**
 * Person representing competition participant pdf element renderer.
 *
 * @returns {PersonRepresentingCompetitionParticipantPdf}
 */
function PersonRepresentingCompetitionParticipantPdf() {
  return (
    <CollectionWrapper name="person_representing_competition_participant">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.first_name`}
                value={item.first_name}
                oldValue={previousRowData?.first_name}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.last_name`}
                value={item.last_name}
                oldValue={previousRowData?.last_name}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.position`}
                value={item.position}
                oldValue={previousRowData?.position}
                isSingleRow
              />
              <RowField
                name={`${collectionName}.${index}.phone_number`}
                value={item.phone_number}
                oldValue={previousRowData?.phone_number}
              />
              <RowField
                name={`${collectionName}.${index}.email`}
                value={item.email}
                oldValue={previousRowData?.email}
                isSingleRow
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default PersonRepresentingCompetitionParticipantPdf;
