import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import { useApplicationToPdfContext } from '../../../../Context';
import { BOOLEAN_VALUES } from '../../../../_constants';

/**
 * ProjectEligibility Pdf element.
 *
 * @returns {ProjectEligibilityPdf}
 */
function ProjectEligibilityPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Data akredytacji Ośrodka Innowacji */}
        <RowField name="project_eligibility_accreditation_date" />
        {/* Zakres akredytacji Ośrodka Innowacji */}
        <RowField name="project_eligibility_accreditation_scope" />
        {/* Dotychczasowa oferta usługowa  */}
        <RowField name="project_eligibility_service_offering" isSingleRow />
        {/* Tabela "Usługi rozwijane w ramach projektu" */}
        <CollectionWrapper name="project_eligibility_developed_services">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    {/* Nazwa usługi */}
                    <RowField
                      name={`${collectionName}.${index}.service_name`}
                      value={item.service_name}
                      oldValue={previousRowData?.service_name}
                    />
                    {/* Średnia wartość pomocy de minimis dla przedsiębiorcy w związku ze świadczeniem usługi */}
                    <RowField
                      name={`${collectionName}.${index}.average_de_minimis_aid`}
                      value={item.average_de_minimis_aid}
                      oldValue={previousRowData?.average_de_minimis_aid}
                    />
                    {/* Planowana liczba zrealizowanych usług */}
                    <RowField
                      name={`${collectionName}.${index}.services_provided_number`}
                      value={item.services_provided_number}
                      oldValue={previousRowData?.services_provided_number}
                    />
                    {/* Opis usługi, sposobu jej świadczenia, zasobów, mierzalnych celów i sposobu wyceny */}
                    <RowField
                      name={`${collectionName}.${index}.service_description`}
                      value={item.service_description}
                      oldValue={previousRowData?.service_description}
                      isSingleRow
                    />
                    {/* Czy sposób świadczenia usługi opiera się o rozwiązanie cyfrowe */}
                    <RowField
                      name={`${collectionName}.${index}.is_service_delivery_based_on_digital_solutions`}
                      value={item.is_service_delivery_based_on_digital_solutions}
                      oldValue={previousRowData?.is_service_delivery_based_on_digital_solutions}
                    />
                    {/* Charakterystyka wykorzystywanych narzędzi informatycznych (inne niż poczta elektroniczna) */}
                    <RowField
                      name={`${collectionName}.${index}.tools_characteristics`}
                      value={item.tools_characteristics}
                      oldValue={previousRowData?.tools_characteristics}
                      isSingleRow
                    />
                    {/* Uzasadnienie rozwoju usługi z perspektywy potrzeb rynku, rozwoju potencjału kadrowego lub
                    kompetencyjnego lub organizacyjnego lub infrastrukturalnego OI */}
                    <RowField
                      name={`${collectionName}.${index}.service_development_justification`}
                      value={item.service_development_justification}
                      oldValue={previousRowData?.service_development_justification}
                      isSingleRow
                    />
                    {/* Akredytacja usługi */}
                    <RowField
                      name={`${collectionName}.${index}.service_accreditation`}
                      value={item.service_accreditation}
                      oldValue={previousRowData?.service_accreditation}
                    />
                    {/* Czy usługi, których celem jest rozwój kompetencji kadr przedsiębiorstw w branżach,
                    w których funkcjonuje Sektorowa Rada ds.kompetencji,lub Grupa robocza KIS Wnioskodawca
                    wykorzystuje dostępne rekomendacje Rady */}
                    <RowField
                      name={`${collectionName}.${index}.services_available_from_group_recommendations`}
                      value={item.services_available_from_group_recommendations}
                      oldValue={previousRowData?.services_available_from_group_recommendations}
                    />
                    {initialFormData?.project_eligibility_developed_services[index]
                      .services_available_from_group_recommendations === BOOLEAN_VALUES.TRUE && (
                      <RowField
                        name={`${collectionName}.${index}.group_used_description`}
                        value={item.group_used_description}
                        oldValue={previousRowData?.group_used_description}
                        isSingleRow
                      />
                    )}
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
      </FieldSorter>
    </View>
  );
}

export default ProjectEligibilityPdf;
