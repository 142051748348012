import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import PrintoutApplication from '../../PrintToPdf/PrintApplication/PrintoutApplication';
import {
  useGlobalContext,
  useGlobalDialog,
} from '../../../Context';
import { request } from '../../../_services';
import { API_ROUTE } from '../../../_constants';

/**
 * PrintoutSupplementCallContent element.
 *
 * @param {object} props - root props
 * @param {string} props.externalApplicationId - external application id
 * @param {string} props.supplementCallId - supplement call id
 * @returns {PrintoutSupplementCallContent}
 */
function PrintoutSupplementCallContent({
  supplementCallId, externalApplicationId,
}) {
  const [isLoading, setLoading] = useState(true);
  const { notify } = useGlobalContext();
  const [applicationDataIds, setApplicationDataIds] = useState({
    previousApplicationId: null,
    currentApplicationId: null,
  });
  const { closeAll } = useGlobalDialog();
  const getApplicationDataIds = async () => {
    const {
      statusSuccess, payload,
    } = await request.get(API_ROUTE.supplementCallArchivalPoints
      .replace(':id', supplementCallId), 'application/json');

    setLoading(false);

    if (!statusSuccess) {
      notify('Nie udało się pobrać wymaganych danych do porównania.', 'error');

      closeAll();

      return;
    }

    setApplicationDataIds({
      previousApplicationId: payload.before?.id || null,
      currentApplicationId: payload.after?.id || null,
    });
  };

  useEffect(() => {
    getApplicationDataIds();

    return () => {
      setLoading(true);
    };
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        <Typography mb={2}>
          Trwa przygotowywanie wymaganych danych do wygenerowania porównania wniosku.
        </Typography>
        <ContainerLoader />
      </Box>
    );
  }

  if (!applicationDataIds.currentApplicationId && !applicationDataIds.previousApplicationId) {
    return (
      <Typography textAlign="center">
        Brakuje wymaganych danych do wygenerowania porównania.
      </Typography>
    );
  }

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      <Typography mb={2} width="100%" textAlign="center">
        Wymagane dane zostały przygotowane. Kliknij przycisk poniżej w celu wygenerowania porówniania wniosku.
      </Typography>
      <Box display="flex" flexWrap="wrap" width="100%" justifyContent="center" gap={3}>
        <PrintoutApplication
          isReadonly
          externalApplicationDataId={applicationDataIds.currentApplicationId}
          previousApplicationDataId={applicationDataIds.previousApplicationId}
          externalApplicationId={externalApplicationId}
          withChanges
          buttonLabel="Porównaj PDF"
          isApplicationData
        />
        <Button
          id="8330C2evpS96UWJ"
          color="error"
          variant="outlined"
          onClick={closeAll}
        >
          Zamknij
        </Button>
      </Box>
    </Box>
  );
}

export default PrintoutSupplementCallContent;

PrintoutSupplementCallContent.propTypes = {
  supplementCallId: PropTypes.string.isRequired,
  externalApplicationId: PropTypes.string.isRequired,
};
