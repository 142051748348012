const usersApi = process.env.REACT_APP_USERS_API_URL;
const recruitmentsApi = process.env.REACT_APP_RECRUITMENTS_API_URL;
const pdfPrinterApi = process.env.REACT_APP_PDF_PRINTER_API_URL;
const cstApi = process.env.REACT_APP_CST_API_URL;

export const API_ROUTE = {
  ping: `${usersApi}/api/ping`,
  versionAuth: `${usersApi}/api/version`,
  versionRecruitments: `${recruitmentsApi}/api/public/version`,
  token: `${usersApi}/authentication_token`,
  register: `${usersApi}/api/public/users`,
  logout: `${usersApi}/api/logout`,
  users: `${usersApi}/api/users`,
  getUsersForProtestReviews: `${usersApi}/api/get-users-for-protest-reviews`,
  userAnonymization: `${usersApi}/api/user-anonymizations`,
  usersMe: `${usersApi}/api/me`,
  remindLogin: `${usersApi}/api/public/login-remind`,
  switchProfile: `${usersApi}/api/switch-profile`,
  ownPasswordChange: `${usersApi}/api/password-change`,
  publicIssues: `${usersApi}/api/public/issues`,
  issues: `${usersApi}/api/issues`,
  statements: `${usersApi}/api/statements`,
  recruitments: `${recruitmentsApi}/api/recruitments`,
  ongoingRecruitments: `${recruitmentsApi}/api/ongoing-recruitments`,
  implementationLevels: `${recruitmentsApi}/api/implementation-levels`,
  implementerTypes: `${recruitmentsApi}/api/implementer-types`,
  passwordRecovery: `${usersApi}/api/public/password-recovery`,
  applicationTemplates: `${recruitmentsApi}/api/application-templates`,
  templateLogotypes: `${recruitmentsApi}/api/template-logotypes`,
  departamentyMerytoryczne: `${recruitmentsApi}/api/departamenty-merytoryczne`,
  typyWnioskodawcy: `${recruitmentsApi}/api/typy-wnioskodawcy`,
  wielkosciPrzedsiebiorstwa: `${recruitmentsApi}/api/wielkosci-przedsiebiorstwa`,
  remindPassword: `${usersApi}/api/public/password-recovery-demand`,
  templateElementDefinitions: `${recruitmentsApi}/api/template-element-definitions`,
  addTemplateElement: `${recruitmentsApi}/api/application-templates/:templateId/template-elements/:elementName`,
  templateElement: `${recruitmentsApi}/api/template-elements`,
  editElementField: `${recruitmentsApi}/api/template-elements/:templateElementId/fields/:fieldUuid`,
  templateElements: `${recruitmentsApi}/api/template-elements`,
  applicationPrints: `${recruitmentsApi}/api/application-prints`,
  elementDictionaries: {
    general: `${recruitmentsApi}/api/element-dictionaries`,
    main: `${recruitmentsApi}/api/template-elements/:templateElementId`,
    reorder: `${recruitmentsApi}/api/element-dictionaries/:templateElementId/order/:resourceName`,
    tasks: `${recruitmentsApi}/api/predefined-tasks`,
    costCategoryGroups: `${recruitmentsApi}/api/cost-category-groups`,
    costCategories: `${recruitmentsApi}/api/cost-categories`,
    taskTypes: `${recruitmentsApi}/api/task-types`,
    riskTypes: `${recruitmentsApi}/api/risk-types`,
    technicalResourcesAndIntangibleAssets: `${recruitmentsApi}/api/technical-resource-and-intangible-asset-types`,
    initialInvestmentTypes: `${recruitmentsApi}/api/initial-investment-types`,
    roleInProjects: `${recruitmentsApi}/api/role-in-projects`,
    cooperationStatuses: `${recruitmentsApi}/api/cooperation-statuses`,
    logotypes: `${recruitmentsApi}/api/logotypes`,
    pkdCodes: `${recruitmentsApi}/api/pkd-codes`,
    solutionInnovationLevels: `${recruitmentsApi}/api/solution-innovation-levels`,
    compentenceModuleEssenceScope: `${recruitmentsApi}/api/competences-module-essence-scopes`,
    compentenceModuleEssenceType: `${recruitmentsApi}/api/competences-module-essence-support-types`,
    compentenceModuleEssenceTargetGroup: `${recruitmentsApi}/api/competences-module-essence-target-groups`,
    resultImplementationMethods: `${recruitmentsApi}/api/rd-result-implementation-methods`,
    technicalExaminationPerformers: `${recruitmentsApi}/api/rd-technical-examination-performers`,
    investmentTypes: `${recruitmentsApi}/api/investment-types`,
    environmentalAidType: `${recruitmentsApi}/api/environmental-aid-types`,
    workTypes: `${recruitmentsApi}/api/work-types`,
    innovationTypes: `${recruitmentsApi}/api/innovation-types`,
    implementationScopes: `${recruitmentsApi}/api/implementation-scopes`,
    legalEntityTypes: `${recruitmentsApi}/api/legal-entity-types`,
    lumpSumTypes: `${recruitmentsApi}/api/lump-sum-types`,
    internationalizationModuleEssenceScopes:
    `${recruitmentsApi}/api/internationalization-module-essence-scopes`,
    internationalizationModuleEssenceEventTypes:
    `${recruitmentsApi}/api/internationalization-module-essence-event-types`,
    internationalizationModuleEssenceTicketCategories:
    `${recruitmentsApi}/api/internationalization-module-essence-ticket-categories`,
    internationalizationModuleEssenceTicketModes:
    `${recruitmentsApi}/api/internationalization-module-essence-ticket-modes`,
    internationalizationModuleEssenceLawCategories:
    `${recruitmentsApi}/api/internationalization-module-essence-law-categories`,
    internationalizationModuleEssenceModeOfActions:
    `${recruitmentsApi}/api/internationalization-module-essence-mode-of-actions`,
    internationalizationModuleEssenceActionSubjects:
    `${recruitmentsApi}/api/internationalization-module-essence-action-subjects`,
    innovationResultingFromImplementationOfResearchAreaTypes:
    `${recruitmentsApi}/api/innovation-resulting-from-implementation-of-research-area-types`,
    noveltyOfResultsOfResearchAreas: `${recruitmentsApi}/api/novelty-of-results-of-research-areas`,
    rdInfrastructureAttachments: `${recruitmentsApi}/api/application/:id/rd-infrastructure-attachments`,
    humanResourcesPotentialRdInfrastructureHumanResourcesRoleInProject:
    `${recruitmentsApi}/api/human-resources-potential-rd-infrastructure-human-role-in-project`,
    investmentTypeGreenings: `${recruitmentsApi}/api/investment-type-greenings`,
    education: `${recruitmentsApi}/api/education`,
    innovationImplementationModuleEssenceWorkTypes:
    `${recruitmentsApi}/api/innovation-implementation-module-essence-work-types`,
    moduleImplementationPotentialDocumentTypes: `${recruitmentsApi}/api/module-implementation-potential-document-types`,
    cstCostCategories: `${recruitmentsApi}/api/cst-cost-categories`,
    cstLimitCategories: `${recruitmentsApi}/api/cst-limit-categories`,
    ueHorizontalPoliciesProjectComplianceLegalActs:
    `${recruitmentsApi}/api/ue-horizontal-policies-project-compliance-legal-acts`,
    ueHorizontalPoliciesProjectComplianceIsR6Rules:
    `${recruitmentsApi}/api/ue-horizontal-policies-project-compliance-is-r6-rules`,
    ueHorizontalPoliciesProjectComplianceImpacts:
    `${recruitmentsApi}/api/ue-horizontal-policies-project-compliance-impacts`,
    ueHorizontalPoliciesProjectComplianceR6Rules:
    `${recruitmentsApi}/api/ue-horizontal-policies-project-compliance-r6-rules`,
    projectCollaborationTypes: `${recruitmentsApi}/api/project-collaboration-types`,
    countries: `${recruitmentsApi}/api/countries`,
    interventionScopes: `${recruitmentsApi}/api/intervention-scopes?active=true`,
    activityTypes: `${recruitmentsApi}/api/activity-types?active=true`,
    measureUnits: `${recruitmentsApi}/api/measure-units`,
    lumpSumCostNames: `${recruitmentsApi}/api/lump-sum-cost-names`,
    edihSevicesTypes: `${recruitmentsApi}/api/edih-service-types`,
    digitalCostCategories: `${recruitmentsApi}/api/digital-cost-categories`,
    supportForms: `${recruitmentsApi}/api/support-forms`,
    linkedStrategies: `${recruitmentsApi}/api/strategies`,
    instrumentsImplementations: `${recruitmentsApi}/api/instruments-implementations`,
    genderEqualityDimensions: `${recruitmentsApi}/api/gender-equality-dimensions`,
    projectTypes: `${recruitmentsApi}/api/project-types`,
    impact: `${recruitmentsApi}/api/impact-on-project-compatibility-with-equality-principle-women-and-men`,
    projectCompatibility: `${recruitmentsApi}/api/project-compatibility-with-sustainable-development-principles`,
    ecosystemPartnerType: `${recruitmentsApi}/api/ecosystem-partner-types`,
    innovationCenterType: `${recruitmentsApi}/api/innovation-center-types`,
    risAreas: `${recruitmentsApi}/api/ris-areas`,
    ecoDesignStages: `${recruitmentsApi}/api/eco-design-stages`,
    greenSustainableEconomies: `${recruitmentsApi}/api/project-takes-into-account-promotion-green-and-sustainable-economies`,
    kisDomains: `${recruitmentsApi}/api/kis-domains`,
    kisDomainAreas: `${recruitmentsApi}/api/kis-domain-areas`,
    clusterTypes: `${recruitmentsApi}/api/cluster-types`,
    eventServiceTypes: `${recruitmentsApi}/api/event-service-types`,
    projectInnovations: `${recruitmentsApi}/api/project-innovations`,
    strategicAreas: `${recruitmentsApi}/api/strategic-areas`,
    serviceImplementationBenefits: `${recruitmentsApi}/api/service-implementation-benefits`,
    accelerationProgramConceptPath: `${recruitmentsApi}/api/acceleration-program-concept-paths`,
    projectFinancingSources: `${recruitmentsApi}/api/project-financing-sources`,
    responsibilityAreas: `${recruitmentsApi}/api/responsibility-areas`,
    mainParametersAndIndicators: `${recruitmentsApi}/api/main-parameters-and-indicators`,
    supportScopes: `${recruitmentsApi}/api/support-scopes`,
    projectComplexities: `${recruitmentsApi}/api/project-complexities`,
    compatibilityWithIdeaNebInitiative: `${recruitmentsApi}/api/compatibility-with-idea-neb-initiative`,
    takeIntoAccountDoesNotTakeIntoAccount: `${recruitmentsApi}/api/taking-into-account-transport-services-in-mof-area`,
    projectImplementationInAreaWithSeparatedPaidParkingZone: `${recruitmentsApi}/api/project-implementation-in-area-with-separated-paid-parking-zones`,
    sumpCompatibilities: `${recruitmentsApi}/api/sump-compatibilities`,
    projectImplementationRegions: `${recruitmentsApi}/api/project-implementation-regions`,
    innovationScope: `${recruitmentsApi}/api/innovation-scopes`,
    confirmationDocuments: `${recruitmentsApi}/api/confirmation-documents`,
    launchPlatforms: `${recruitmentsApi}/api/launch-platforms`,
    productInnovationImprovement: `${recruitmentsApi}/api/product-innovation-improvements`,
    ueHorizontalPoliciesProjectSolutions: `${recruitmentsApi}/api/ue-horizontal-policies-project-project-solutions`,
    operationScopeGozTransformationSubjects: `${recruitmentsApi}/api/operation-scope-goz-transformation-subjects`,
    promotionalActivitiesEconomicSector: `${recruitmentsApi}/api/promotional-activities-economic-sectors`,
    promotionalActivitiesType: `${recruitmentsApi}/api/promotional-activities-types`,
    promotionalActivitiesEconomicSectorsEvent: `${recruitmentsApi}/api/promotional-activities-economic-sectors-events?itemsPerPage=300`,
    teamImplementingProgramConceptResponsibilityAreas: `${recruitmentsApi}/api/team-implementing-program-concept-responsibility-areas`,
    applicantGeneralInformationEntityTypes: `${recruitmentsApi}/api/applicant-general-information-entity-types`,
    reduceTurnoverCalculationType: `${recruitmentsApi}/api/reduce-turnover-calculation-types`,
    productPlannedTypes: `${recruitmentsApi}/api/product-planned-types`,
    innovationLevels: `${recruitmentsApi}/api/innovation-levels`,
    startingPlatforms: `${recruitmentsApi}/api/starting-platforms`,
    ueProgramTypes: `${recruitmentsApi}/api/ue-program-types`,
    applicantUeProgramRoles: `${recruitmentsApi}/api/applicant-ue-program-roles`,
    applicantPreparedSolutionIndustryAffiliation: `${recruitmentsApi}/api/applicant-prepared-solution-industry-affiliations`,
    applicantPreparedSolutionReadinessLevels: `${recruitmentsApi}/api/applicant-prepared-solution-readiness-levels`,
    applicantPreparedSolutionSubjects: `${recruitmentsApi}/api/applicant-prepared-solution-subjects`,
    applicantPreparedSolutionInnovationLevel: `${recruitmentsApi}/api/applicant-prepared-solution-innovation-levels`,
    startupApplicantInformationActivityFiscalYearsNumber: `${recruitmentsApi}/api/startup-applicant-information-activity-fiscal-years-numbers`,
    startupApplicantInformationCompetitionLevel: `${recruitmentsApi}/api/startup-applicant-information-competition-levels`,
    startupApplicantInformationHonorableMentionObject: `${recruitmentsApi}/api/startup-applicant-information-honorable-mention-objects`,
    ventureFeasibilityEconomicOrEnvironmentalEffect: `${recruitmentsApi}/api/venture-feasibility-economic-or-environmental-effects`,
    gozTransformationImplementationActionPath: `${recruitmentsApi}/api/goz-transformation-implementation-action-paths`,
    gozTransformationImplementationProductProcess: `${recruitmentsApi}/api/goz-transformation-implementation-product-processes`,
    projectImplementationJustificationLevelInnovationImplementedSolution: `${recruitmentsApi}/api/project-implementation-justification-level-innovation-implemented-solutions`,
    scopeOfInnovation: `${recruitmentsApi}/api/project-implementation-justification-scope-of-innovations`,
    productAndWorkDescriptionProductInnovationScale: `${recruitmentsApi}/api/product-and-work-description-product-innovation-scales`,
    productAndWorkDescriptionProductInnovationLevel: `${recruitmentsApi}/api/product-and-work-description-product-innovation-levels`,
    projectGeneralInformationCompetitionCategory: `${recruitmentsApi}/api/project-general-information-competition-categories`,
    clusterInternationalizationEventPurpose: `${recruitmentsApi}/api/cluster-internationalization-event-purposes`,
    productAndWorkDescriptionProductDevelopmentStage: `${recruitmentsApi}/api/product-and-work-description-product-development-stages`,
    productAndWorkDescriptionProductRight: `${recruitmentsApi}/api/product-and-work-description-product-rights`,
    ueHorizontalPoliciesProjectIncludesActivity: `${recruitmentsApi}/api/ue-horizontal-policies-project-includes-activities`,
    clusterInternationalizationKkkEventType: `${recruitmentsApi}/api/cluster-internationalization-kkk-event-types`,
  },
  modules: `${recruitmentsApi}/api/modules`,
  operators: `${recruitmentsApi}/api/operators`,
  operatorsSelections: `${recruitmentsApi}/api/operators-selections`,
  modulesWithNaOption: `${recruitmentsApi}/api/modules/get-with-na-option`,
  applicantAuthorizationParticipateExpertPanel: `${recruitmentsApi}/api/applicant-authorization-participate-expert-panel`,
  applications: `${recruitmentsApi}/api/applications`,
  applicationsByOperator: `${recruitmentsApi}/api/applications-by-operator`,
  applicationApproval: `${recruitmentsApi}/api/mass/application-approval`,
  applicationsCorrection: `${recruitmentsApi}/api/applications-correction`,
  applicationsCorrectionEnd: `${recruitmentsApi}/api/applications/:id/correction/:parpCorrectionId/actions/parp-correction-end`,
  applicationAttachments: `${recruitmentsApi}/api/applications/:id/attachments`,
  applicationData: `${recruitmentsApi}/api/application-data`,
  rounds: `${recruitmentsApi}/api/rounds`,
  allocations: `${recruitmentsApi}/api/allocations`,
  menuTabsDictionary: `${recruitmentsApi}/api/template-menu-tabs`,
  menuTabs: `${recruitmentsApi}/api/menu-tabs`,
  modulesSelections: `${recruitmentsApi}/api/modules-selections`,
  rssFeed: `${usersApi}/api/rss-feed`,
  rssFeedGet: `${usersApi}/api/public/rss-feed`,
  recruitmentsConfig: `${recruitmentsApi}/api/application-config`,
  validations: `${usersApi}/api/public/validations`,
  attachmentsRecruitments: `${recruitmentsApi}/api/attachments`,
  attachmentsAuth: `${usersApi}/api/attachments`,
  issuesGet: `${usersApi}/api/redmine/issues`,
  systemMessages: `${usersApi}/api/messages`,
  messagesByProfile: `${usersApi}/api/messages-by-profile`,
  userMessages: `${usersApi}/api/user-messages`,
  accountActivation: `${usersApi}/api/public/account-activation/:activationCode`,
  intl: {
    countries: `${recruitmentsApi}/api/dictionaries/countries`,
    citizenship: `${recruitmentsApi}/api/dictionaries/citizenships`,
  },
  expertPersonalData: `${usersApi}/api/expert-personal-data`,
  expertAddress: `${usersApi}/api/expert-addresses`,
  expertEducations: `${usersApi}/api/expert-educations`,
  expertCareers: `${usersApi}/api/expert-careers`,
  propertyForms: `${recruitmentsApi}/api/property-forms`,
  vatRecoveryPossibilities: `${recruitmentsApi}/api/vat-recovery-possibilities`,
  dziedzinyProjektu: `${recruitmentsApi}/api/dziedziny-projektu`,
  projectCard: `${recruitmentsApi}/api/project-card`,
  excludedEntitiesRegistry: `${usersApi}/api/excluded-entities-registry`,
  applicationUnlocks: `${recruitmentsApi}/api/application-unlocks`,
  applicationWithdrawals: `${recruitmentsApi}/api/application-withdrawals`,
  applicationWithdrawalDecide: `${recruitmentsApi}/api/application-withdrawal-decide`,
  applicationHasActiveWithdrawal: `${recruitmentsApi}/api/applications/:id/has-active-withdrawal`,
  applicationWithdrawalsAttachments: `${recruitmentsApi}/api/application-withdrawals/:id/attachments`,
  applicationOwnerSwitchings: `${recruitmentsApi}/api/application-owner-switchings`,
  applicationStatusHistories: `${recruitmentsApi}/api/application-status-histories`,
  refreshToken: `${usersApi}/token/refresh`,
  userPermissions: `${usersApi}/api/users/:id/user-roles`,
  roles: `${usersApi}/api/roles`,
  userRoles: `${usersApi}/api/user-roles`,
  otherDomains: `${usersApi}/api/other-domains`,
  financialDomains: `${usersApi}/api/financial-domains`,
  financialJustifications: `${usersApi}/api/financial-justifications`,
  kisDomains: `${usersApi}/api/kis-domains`,
  kisDomainAreas: `${usersApi}/api/kis-domain-areas`,
  revokePermissions: `${usersApi}/api/user-roles/revoke`,
  oecdScienceDomains: `${usersApi}/api/oecd-science-domains`,
  naceScienceDomains: `${usersApi}/api/nace-science-domains`,
  grantPermissions: `${usersApi}/api/user-roles/grant`,
  additionalActivity: `${usersApi}/api/expert-additional-activities`,
  applicationForms: `${usersApi}/api/application-forms`,
  applicationFormCorrections: `${usersApi}/api/application-form-corrections`,
  supplementCalls: `${usersApi}/api/supplement-calls`,
  supplementCallsByEmployee: `${usersApi}/api/supplement-calls-by-employee`,
  supplementCallArchivalPoints: `${usersApi}/api/supplement-calls/:id/archival-points`,
  evaluationStages: `${usersApi}/api/evaluation-stages`,
  domainJustifications: `${usersApi}/api/domain-justifications`,
  applicationDomainJustifications: `${usersApi}/api/application-forms/:applicationFormId/domain-justifications`,
  otherDomainJustifications: `${usersApi}/api/other-domain-justifications`,
  applicationOtherDomainJustifications: `${usersApi}/api/application-forms/:applicationFormId/other-domain-justifications`,
  predefinedTask: `${recruitmentsApi}/api/predefined-tasks`,
  taskType: `${recruitmentsApi}/api/task-types`,
  applicationTasks: `${recruitmentsApi}/api/applications/:applicationId/elements/:elementId/tasks`,
  organizationAddress: `${recruitmentsApi}/api/organization-addresses`,
  helpCenter: `${usersApi}/api/help-center-items`,
  publicHelpCenter: `${usersApi}/api/public/help-center-items`,
  privateHelpCenter: `${usersApi}/api/private/help-center-items`,
  helpCenterAttachment: `${usersApi}/api/help-center-items/:id/attachments`,
  applicationExpenses: `${recruitmentsApi}/api/applications/:applicationId/elements/:elementId`,
  expertList: `${usersApi}/api/expert-users`,
  expertPanels: `${usersApi}/api/expert-panels`,
  expertPanelsList: `${usersApi}/api/expert-panels-list`,
  applicationInExpertPanel: `${usersApi}/api/application-in-expert-panel`,
  expertPanelList: `${usersApi}/api/expert-panel-list`,
  exchangeDataWithCst: `${usersApi}/api/exchange-data-with-cst`,
  applicationFormEvaluations: `${usersApi}/api/application-form-evaluations`,
  indicators: `${recruitmentsApi}/api/indicators`,
  indicatorsForElement: `${recruitmentsApi}/api/indicators-for-element/:elementId`,
  elementIndicators: `${recruitmentsApi}/api/element-indicators`,
  statementsApplication: `${pdfPrinterApi}/api/templates/expert-application-form-statements`,
  termsOfUse: `${usersApi}/api/terms-of-uses/current`,
  cst: {
    dictionaries: `${cstApi}/api/dictionaries`,
  },
  expertContracts: `${usersApi}/api/expert-contracts`,
  expertContract: `${usersApi}/api/expert-contract`,
  expertContractByUsers: `${usersApi}/api/expert-contract-by-users`,
  expertCriteriaSets: `${usersApi}/api/criteria-sets`,
  expertCriteriaItems: `${usersApi}/api/criteria-items`,
  evaluationCommitteeRoles: `${usersApi}/api/evaluation-committee-roles`,
  evaluationCommittees: `${usersApi}/api/evaluation-committees`,
  evaluationCommitteeExpertDetails: `${usersApi}/api/evaluation-committee-expert-details/:id`,
  departments: `${usersApi}/api/departments`,
  informationClauses: `${recruitmentsApi}/api/information-clauses`,
  geoDictionaries: {
    countries: `${recruitmentsApi}/api/cst-countries`,
    voivodeships: `${recruitmentsApi}/api/cst-voivodeships`,
    districts: `${recruitmentsApi}/api/cst-districts`,
    communes: `${recruitmentsApi}/api/cst-communes`,
    towns: `${recruitmentsApi}/api/cst-towns`,
    streets: `${recruitmentsApi}/api/cst-streets`,
    nutsMacroRegions: `${recruitmentsApi}/api/cst-nuts-macroregions`,
  },
  rules: {
    rulesList: `${recruitmentsApi}/api/rules-checker/rules-list`,
    rules: `${recruitmentsApi}/api/rules-checker/rules`,
    rulesChecker: `${recruitmentsApi}/api/rules-checker/check/:id`,
  },
  applicationProjectBudget: `${recruitmentsApi}/api/applications/:id/element_data/project_budget`,
  hrfElementSummary: `${recruitmentsApi}/api/applications/:applicationId/element_data/hrf_element_summary/:elementId`,
  cstRecruitmentMap: {
    get: `${recruitmentsApi}/api/recruitments/:recruitmentId/cst-recruitment-mapping`,
    putOrPost: `${recruitmentsApi}/api/cst-recruitment-mappings`,
  },
  newReports: `${usersApi}/api/reports`,
  expertPanelModuleByExperts: `${usersApi}/api/expert-panel-module-by-experts`,
  expertPanelModules: `${usersApi}/api/expert-panel-modules`,
  evaluationCommitteeByParticipant: `${usersApi}/api/evaluation-committee-by-participant`,
  evaluationStatements: `${usersApi}/api/evaluation-statements`,
  removeRedundantFields: `${recruitmentsApi}/api/commands/remove-redundant-fields`,
  cstElementOrdinalNumbers: `${recruitmentsApi}/api/applications/:id/cst-element-ordinal-numbers`,
  expertPanelByExperts: `${usersApi}/api/expert-panel-by-experts`,
  expertEvaluationSheets: `${usersApi}/api/evaluation-sheets`,
  evaluationSheetsWithExpertPanelDates: `${usersApi}/api/evaluation-sheets/expert-panel-date`,
  expertEvaluationItems: `${usersApi}/api/evaluation-items`,
  evaluationSessions: `${usersApi}/api/evaluation-sessions`,
  expertPanelExpertByUsers: `${usersApi}/api/expert-panel-expert-by-users`,
  chairmanRemarks: `${usersApi}/api/chairman-remarks`,
  evaluationQuestions: `${usersApi}/api/evaluation-questions`,
  supplementCallsByApplicant: `${usersApi}/api/supplement-calls-by-applicant`,
  evaluationAnswers: `${usersApi}/api/evaluation-answers`,
  statementAcceptances: `${usersApi}/api/statement-acceptances`,
  expertPanelLeadingExperts: `${usersApi}/api/expert-panels/:id/leading-expert`,
  expertPanelApplications: `${usersApi}/api/expert-panel-applications`,
  applicationDatas: `${recruitmentsApi}/api/application-datas`,
  applicationParpCorrections: `${recruitmentsApi}/api/application-parp-corrections`,
  protests: `${usersApi}/api/protests`,
  protestReviews: `${usersApi}/api/protest-reviews`,
  protestEvaluations: `${usersApi}/api/protest-evaluations`,
  protestResults: `${usersApi}/api/protest-results`,
  protestReviewRollbacks: `${usersApi}/api/protest-review-rollbacks`,
  protestCreateQuestion: `${usersApi}/api/create-by-protest-patron`,
  bankTransferDetails: `${usersApi}/api/bank-transfer-details`,
  orderAcceptanceProtocols: `${usersApi}/api/order-acceptance-protocols`,
  protestByApplication: `${usersApi}/api/protest-by-application/application/:id`,
  protestReviewsByOwners: `${usersApi}/api/protest-reviews-by-owners`,
  reviewItemQuestions: `${usersApi}/api/review-item-questions`,
  protestReviewItems: `${usersApi}/api/protest-review-items`,
  expertWorkEvaluations: `${usersApi}/api/expert-work-evaluations`,
  expertWorkEvaluationsByExpert: `${usersApi}/api/expert-work-evaluation-by-experts`,
  expertWorkEvaluationResults: `${usersApi}/api/expert-work-evaluation-results`,
  criterionTypes: `${usersApi}/api/criterion-types`,
  aspectTypes: `${usersApi}/api/aspect-types`,
  expertTaxReturns: `${usersApi}/api/expert-tax-returns`,
  expertPanelPreliminaryRemarks: `${usersApi}/api/expert-panel-preliminary-remarks`,
  paymentRecommendations: `${recruitmentsApi}/api/payment-recommendations`,
  paymentRecommendationStatuses: `${recruitmentsApi}/api/payment-recommendation-statuses`,
  beneficiarySectors: `${recruitmentsApi}/api/beneficiary-sectors`,
  activities: `${recruitmentsApi}/api/activities`,
  financingSources: `${recruitmentsApi}/api/financing-sources`,
  paragraphs: `${recruitmentsApi}/api/paragraphs`,
  expertPanelPreliminaryRemarksByApplicant: `${usersApi}/api/my-expert-panel-preliminary-remark`,
  evaluationCommitteeExternalExpertsForRecruitment:
    `${usersApi}/api/evaluation-committee-external-experts-for-recruitment`,
  evaluationSheetsCheckAccessToPdf: `${usersApi}/api/evaluation-sheets/check-access-to-pdf`,
  evaluationSheetsShareAccessToPdf: `${usersApi}/api/evaluation-sheets/:id/access-pdf`,
  evaluationSheetsList: `${usersApi}/api/evaluation-sheets/:applicationId/list-for-application`,
  projectBudgetLimits: `${recruitmentsApi}/api/application-templates/:applicationTemplateId/project-budget-limits`,
  evaluationSheetExpertJustifications: `${usersApi}/api/expert-justifications`,
  evaluationSheetExpertCompletions: `${usersApi}/api/expert-panel-completions`,
  expertPanelExperts: `${usersApi}/api/expert-panel-experts`,
  removeExpertWorkEvaluations: `${usersApi}/api/expert-work-evaluations/:workEvaluationId/remove-evaluation`,
};
