import {
  TextField,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { FieldSorter } from './FieldSorter';
import FieldConfig from '../../Application/Application/FieldConfig/FieldConfig';
import {
  DICTIONARIES,
  FIELD_TYPES_MAP,
  ELEMENTS_DICTIONARIES_MAP,
} from '../../../_constants';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import {
  UeHorizontalPoliciesProjectComplianceProducts,
  UeHorizontalPoliciesProjectComplianceEnvironmentalProtection,
  UeHorizontalPoliciesProjectCompliance6rRuleCompatibility,
  UeHorizontalPoliciesProjectComplianceOther,
} from './UeHorizontalPoliciesProjectComplianceTables';

/**
 * UeHorizontalPoliciesProjectCompliance element renderer.
 *
 * @returns {UeHorizontalPoliciesProjectCompliance}
 */
export function UeHorizontalPoliciesProjectCompliance() {
  const {
    watch, resetField,
  } = useFormContext();

  const {
    ueHorizontalPoliciesProjectComplianceIsR6Rules: {
      project6R,
      projectPositiveInfluence,
      atLeastOneRuleFrom6RAndPositiveImpactOnOtherEnvironmentalAspects,
      withAtLeastOneRuleFrom6R,
      withAtLeastTwoRulesFrom6R,
      positiveImpactOnOtherEnvironmentalAspectsOfProject,
    },
  } = ELEMENTS_DICTIONARIES_MAP;

  const showTableUeHorizontalPoliciesProjectCompliance6rRuleCompatibilityById = [
    project6R.id,
    atLeastOneRuleFrom6RAndPositiveImpactOnOtherEnvironmentalAspects.id,
    withAtLeastTwoRulesFrom6R.id,
    withAtLeastOneRuleFrom6R.id,
  ];

  const showTableUeHorizontalPoliciesProjectComplianceOtherById = [
    projectPositiveInfluence.id,
    positiveImpactOnOtherEnvironmentalAspectsOfProject.id,
    atLeastOneRuleFrom6RAndPositiveImpactOnOtherEnvironmentalAspects.id,
  ];

  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const productsConfig = fieldsConfig.ue_horizontal_policies_project_compliance_products;
  const environmentalProtectionConfig = fieldsConfig.ue_horizontal_policies_project_compliance_environment_protection;
  const compliance6rRuleCompatibilityConfig = fieldsConfig
    .ue_horizontal_policies_project_compliance_6r_rule_compatibility;
  const complianceOtherConfig = fieldsConfig.ue_horizontal_policies_project_compliance_other;

  const ueHorizontalPoliciesProject = watch('ue_horizontal_policies_project_compliance_is_6r_rule');

  const { get } = useDictionaryLoader(
    DICTIONARIES.ueHorizontalPoliciesProjectComplianceIsR6Rules,
    DICTIONARIES.impact,
    DICTIONARIES.projectCompatibility,
    DICTIONARIES.greenSustainableEconomies,
    DICTIONARIES.ueHorizontalPoliciesProjectSolutions,
    DICTIONARIES.ueHorizontalPoliciesProjectIncludesActivity,
  );

  return (
    <FieldSorter>
      {/* Równościowe zasady horyzontalne */}
      <FieldConfig name="ue_horizontal_policies_project_compliance_rules">
        {({ label }) => (
          <Typography fontWeight="bold">
            {label}
          </Typography>
        )}
      </FieldConfig>
      {/* Pozytywny wpływ projektu na realizację zasady równości szans i niedyskryminacji, w tym dostępności dla */}
      <FormField name="ue_horizontal_policies_project_compliance_equality_rule">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela "Produkty/ usługi w projekcie" */}
      <LabeledFieldRow fieldConfig={productsConfig} contextHelpLabel fullWidth>
        <UeHorizontalPoliciesProjectComplianceProducts />
      </LabeledFieldRow>
      {/* Zgodność projektu z zasadą równości kobiet i mężczyzn */}
      <FormField name="ue_horizontal_policies_project_compliance_sex_equality">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Zasady zrównoważonego rozwoju */}
      <FieldConfig name="ue_horizontal_policies_project_compliance_development_principles">
        {({ label }) => (
          <Typography fontWeight="bold">
            {label}
          </Typography>
        )}
      </FieldConfig>
      {/* Tabela "Produkty/ usługi w projekcie" */}
      <LabeledFieldRow fieldConfig={environmentalProtectionConfig} contextHelpLabel fullWidth>
        <UeHorizontalPoliciesProjectComplianceEnvironmentalProtection />
      </LabeledFieldRow>
      {/* Zgodność z zasadami 6R lub innymi aspektami środowiskowymi */}
      <FormField name="ue_horizontal_policies_project_compliance_is_6r_rule">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (!event.target.value?.includes(project6R.id)) {
                resetField(
                  'ue_horizontal_policies_project_compliance_6r_rule_compatibility',
                  { defaultValue: FIELD_TYPES_MAP.collection }
                );
              }
              if (!event.target.value?.includes(projectPositiveInfluence.id)) {
                resetField(
                  'ue_horizontal_policies_project_compliance_other',
                  { defaultValue: FIELD_TYPES_MAP.collection }
                );
              }
            }}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.ueHorizontalPoliciesProjectComplianceIsR6Rules.name))}
            multiple
            renderSelectAllButton={false}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Tabela "Projekt będzie realizowany zgodnie z zasadami 6R" */}
      {ueHorizontalPoliciesProject
        ?.some((value) => showTableUeHorizontalPoliciesProjectCompliance6rRuleCompatibilityById.includes(value))
        && (
          <LabeledFieldRow
            fieldConfig={compliance6rRuleCompatibilityConfig}
            contextHelpLabel
            fullWidth
          >
            <UeHorizontalPoliciesProjectCompliance6rRuleCompatibility />
          </LabeledFieldRow>
        )}
      {/* Tabela "Projekt będzie miał pozytywny wpływ na inne aspekty środowiskowe (nie objęte zasadami 6R)" */}
      {ueHorizontalPoliciesProject
        ?.some((value) => showTableUeHorizontalPoliciesProjectComplianceOtherById.includes(value))
        && (
          <LabeledFieldRow
            fieldConfig={complianceOtherConfig}
            contextHelpLabel
            fullWidth
          >
            <UeHorizontalPoliciesProjectComplianceOther />
          </LabeledFieldRow>
        )}

      {/* Etykieta: Pozytywny wpływ projektu na realizację zasady równości szans i niedyskryminacji,
      w tym dostępności dla osób z niepełnosprawnościami, względem niepodejmowania dyskryminujących aktów prawnych   */}
      <FormField name="ue_horizontal_policies_project_compliance_project_impact" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Etykieta: Wnioskodawca - jednostka samorządu terytorialnego */}
      <FormField name="ue_horizontal_policies_project_compliance_local_government" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Oświadczam, że na terenie jednostki samorządu terytorialnego nie obowiązują dyskryminujące akty prawne  */}
      <FormField name="ue_horizontal_policies_project_compliance_statement_1">
        {({
          onChange, value, name, onBlur, error, allChoices,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Etykieta: Wnioskodawca: podmiot kontrolowany przez jednostkę samorządu terytorialnego lub
      podmiot zależny od jednostki samorządu terytorialnego   */}
      <FormField
        name="ue_horizontal_policies_project_compliance_entity_controlled_by_local_government_unit"
        contextHelpLabel
      >
        {() => null}
      </FormField>
      {/* Oświadczam, że na terenie na którym posiadam swoją siedzibę nie obowiązują dyskryminujące akty prawne  */}
      <FormField name="ue_horizontal_policies_project_compliance_statement_2">
        {({
          onChange, value, name, onBlur, error, allChoices,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Zgodność projektu z Kartą Praw Podstawowych UE i Konwencją o prawach osób niepełnosprawnych   */}
      <FormField name="ue_horizontal_policies_project_project_compliance_with_fundamental_rights_charter">
        {({
          onChange, value, name, onBlur, error, allChoices,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Wpływ   */}
      <FormField name="ue_horizontal_policies_project_impact">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={get(DICTIONARIES.impact.name)}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Etykieta: Zasady środowiskowe */}
      <FormField name="ue_horizontal_policies_project_environmental_principles" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Zgodność projektu z zasadą zrównoważonego rozwoju, w tym z zasadą „nie czyń poważnych szkód   */}
      <FormField name="ue_horizontal_policies_project_project_compatibility">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={get(DICTIONARIES.projectCompatibility.name)}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name="ue_horizontal_policies_project_justifications">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Projekt uwzględnia promocję zielonej i zrównoważonej gospodarki  */}
      <FormField name="ue_horizontal_policies_project_takes_into_account_promotion">
        {({
          onChange, value, name, onBlur, error, filterOptions,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            multiple
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.greenSustainableEconomies.name))}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name="ue_horizontal_policies_project_project_takes_into_account_promotion_justifications">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zgodność z Kartą Praw Podstawowych Unii Europejskiej z dnia 26 października 2012 r. */}
      <FormField name="ue_horizontal_policies_project_compliance_fundamental_rights_charter">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zgodność z Konwencją o Prawach Osób Niepełnosprawnych z dnia 13 grudnia 2006 r. */}
      <FormField name="ue_horizontal_policies_project_compliance_convention_on_rights_persons_with_disabilities">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      {/* Etykieta: Pozytywny wpływ projektu na realizację zasady równości szans i niedyskryminacji, w tym dostępności
      dla osób z niepełnosprawnościami */}
      <FormField name="ue_horizontal_policies_project_project_positive_influence" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Etykieta: Wpływ realizacji projektu na promocję zielonej i zrównoważonej gospodarki */}
      <FormField name="ue_horizontal_policies_project_project_implementation_impact" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Projekt uwzględnia co najmniej jedno z rozwiązań dla promocji zielonej i zrównoważonej gospodarki  */}
      <FormField name="ue_horizontal_policies_project_project_solutions">
        {({
          onChange, value, name, onBlur, error, filterOptions,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            multiple
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.ueHorizontalPoliciesProjectSolutions.name))}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {/* Projekt uwzględnia co najmniej jedno z rozwiązań dla promocji zielonej i zrównoważonej gospodarki -
      uzasadniene */}
      <FormField name="ue_horizontal_policies_project_project_solutions_justifications">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      {/* Etykieta:Cele środowiskowe UE */}
      <FormField name="ue_horizontal_policies_project_compliance_environmental_goals" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Etykieta:Łagodzenie zmian klimatu */}
      <FormField name="ue_horizontal_policies_project_compliance_mitigating_climate_change" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name="ue_horizontal_policies_project_compliance_mitigating_climate_change_justifications">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta:Adaptacja do zmian klimatu */}
      <FormField name="ue_horizontal_policies_project_compliance_climate_change_adaptation" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name="ue_horizontal_policies_project_compliance_climate_change_adaptation_justifications">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta:Odpowiednie użytkowanie i ochrona zasobów wodnych i morskich */}
      <FormField name="ue_horizontal_policies_project_compliance_water_resources_protection" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name="ue_horizontal_policies_project_compliance_water_resources_protection_justifications">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta:Gospodarka o obiegu zamkniętym, w tym zapobieganie powstawaniu odpadów i recykling */}
      <FormField name="ue_horizontal_policies_project_compliance_circular_economy" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name="ue_horizontal_policies_project_compliance_circular_economy_justifications">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta:Zapobieganie i kontrola zanieczyszczeń powietrza, wody lub ziemi */}
      <FormField name="ue_horizontal_policies_project_compliance_air_pollution_control" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name="ue_horizontal_policies_project_compliance_air_pollution_control_justifications">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta:Ochrona i odtwarzanie bioróżnorodności i ekosystemów */}
      <FormField name="ue_horizontal_policies_project_compliance_ecosystem_protection" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name="ue_horizontal_policies_project_compliance_ecosystem_protection_justifications">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      {/* Etykieta: Podejmowane działania związane z zasadą zrównoważonego rozwoju */}
      <FormField
        name="ue_horizontal_policies_project_compliance_actions_taken_related_principle_of_sustainable_development"
        contextHelpLabel
      >
        {() => null}
      </FormField>
      {/* Etykieta:Obszary */}
      <FormField name="ue_horizontal_policies_project_compliance_areas" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Racjonalne gospodarowanie zasobami */}
      <FormField name="ue_horizontal_policies_project_compliance_rational_management_of_resources">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Ograniczenie presji na środowisko */}
      <FormField name="ue_horizontal_policies_project_compliance_reducing_pressure_on_environment">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Uwzględnianie efektów środowiskowych w zarządzaniu */}
      <FormField
        name="ue_horizontal_policies_project_compliance_taking_into_account_environmental_effects_in_management"
      >
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Podnoszenie świadomości ekologicznej społeczeństwa */}
      <FormField
        name="ue_horizontal_policies_project_compliance_raising_ecological_awareness_of_society"
      >
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta:Zgodność przedsięwzięcia z zasadą „niewyrządzania znaczącej szkody środowisku ( DNSH)” */}
      <FormField
        name="ue_horizontal_policies_project_compliance_compliance_of_project_with_principle_dnsh"
        contextHelpLabel
      >
        {() => null}
      </FormField>
      {/* Uzasadnienie pozytywnego wpływu na zasadę DNSH */}
      <FormField
        name="ue_horizontal_policies_project_compliance_justification_for_positive_impact_on_dnsh_rule"
      >
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* etykieta: "Zgodność z zasadą długotrwałego wpływu przedsięwzięcia na wydajność i odporność
      gospodarki polskiej" */}
      <FormField name="ue_horizontal_policies_project_compliance_compliance_with_principles" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Uzasadnienie zapewnienia efektów długoterminowych */}
      <FormField
        name="ue_horizontal_policies_project_compliance_justification_for_ensuring_long_term_effects"
      >
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      <FormField name="ue_horizontal_policies_project_includes_activities">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={filterOptions(get(DICTIONARIES.ueHorizontalPoliciesProjectIncludesActivity.name))}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
            multiple
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
