import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';
import { useGlobalDialog } from '../../../../../Context';

/**
 * Evaluation sheet history of chairman remarks list.
 *
 * @param {object} props - root props
 * @param {Array} props.remarksList - list of remarks
 * @returns {EvaluationSheetHistoryOfChairmanRemarksList}
 */
export function EvaluationSheetHistoryOfChairmanRemarksList({ remarksList }) {
  const { closeAll } = useGlobalDialog();

  return (
    <>
      {remarksList.map(({
        remark, id,
      }) => (
        <Fragment key={id}>
          <Typography
            fontFamily={'"Montserrat-Light", sans-serif'}
            fontSize="1.2rem"
            textAlign="left"
            mb={1}
          >
            {remark}
          </Typography>
          <Box my={2}>
            <Divider />
          </Box>
        </Fragment>
      ))}
      <Box display="flex" justifyContent="center" mt={5}>
        <Button
          id="8jo676l2zs5nkG1"
          variant="outlined"
          color="primary"
          onClick={closeAll}
        >
          Zamknij
        </Button>
      </Box>
    </>
  );
}

EvaluationSheetHistoryOfChairmanRemarksList.propTypes = {
  remarksList: PropTypes.arrayOf(Object).isRequired,
};
