import {
  useMemo,
  useState,
} from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  Backdrop,
  Button,
  MenuItem,
  Paper,
  Portal,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { request } from '../../../_services';
import {
  API_ROUTE,
  PRINT_METHODS,
} from '../../../_constants';
import {
  applicationTemplateDataStore,
  MAPPING_TYPE,
} from '../../../_helpers/applicationTemplateData.store';
import { DictionaryProvider } from '../../DictionaryProvider/DictionaryProvider';
import PrintoutApplicationDictionaries from './PrintoutApplicationDictionaries';
import { useGlobalContext } from '../../../Context';
import { usePrintoutStyles } from '../../../styles/printout';

const BUTTON_TYPES = {
  button: 'button',
  menuItem: 'menuItem',
};

/**
 * Printout application component
 *
 * @param {object} props - root props
 * @param {boolean} props.isReadonly - is readonly
 * @param {string} props.externalApplicationId - external application id
 * @param {boolean} props.withChanges - show differences between old and new data
 * @param {string} props.previousApplicationDataId - previous application data id
 * @param {string} props.externalApplicationDataId - external application data id
 * @param {string} props.buttonType - button type
 * @param {string} props.buttonLabel - button label
 * @param {boolean} props.isApplicationData - is application data
 * @returns {PrintoutApplication}
 */
function PrintoutApplication({
  isReadonly,
  externalApplicationDataId,
  withChanges,
  previousApplicationDataId,
  buttonType,
  buttonLabel,
  isApplicationData,
  externalApplicationId,
}) {
  const { notify } = useGlobalContext();
  const { id: appId } = useParams();
  const [applicationData, setApplicationData] = useState({});
  const [previousApplicationData, setPreviousApplicationData] = useState({});
  const [applicationTemplateData, setApplicationTemplateData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const applicationId = externalApplicationId || externalApplicationDataId || appId;

  const sendPrintOrderToServer = async () => {
    setLoading(true);
    const { statusSuccess } = await request.post(`${API_ROUTE.applications}/${applicationId}/print`);

    setLoading(false);
    if (statusSuccess) {
      notify(
        `Wydruk wniosku o dofinansowanie o numerze ID ${applicationId} jest w trakcie przygotowania, po zakończeniu będzie dostępny w zakładce "Wydruki"`,
        'success',
      );
      window.scroll(0, 0);

      return;
    }
    notify(
      `Wystąpił błąd podczas przygotowania wysdruku wniosku o dofinansowanie o numerze ID ${applicationId}`,
      'error',
    );
  };

  const getApplicationTemplate = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.applications}/${applicationId}/template`);

    if (statusSuccess) {
      return payload;
    }

    if (!statusSuccess) {
      notify(
        'Nie udało się wygenerować wydruku PDF. Spróbuj ponownie.',
        'error'
      );

      navigate(-1);
    }

    return {};
  };

  const getApplicationData = async () => {
    setLoading(true);
    const applicationTemplate = await getApplicationTemplate();

    if (withChanges) {
      const {
        payload, statusSuccess,
      } = await getPreviousApplicationData();

      if (!statusSuccess) {
        notify(
          'Nie udało się wygenerować wydruku PDF. Spróbuj ponownie.',
          'error'
        );

        return;
      }

      setPreviousApplicationData(payload.data);
    }

    const externalApplicationData = isApplicationData
      ? await request.get(`${API_ROUTE.applicationDatas}/${externalApplicationDataId}`) : {};

    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.applications}/${applicationId}`);

    if (statusSuccess) {
      if (payload.defaultPrinter === PRINT_METHODS.server) {
        sendPrintOrderToServer();

        return;
      }
      setApplicationTemplateData(applicationTemplate);
      setApplicationData(!isApplicationData ? payload : {
        ...payload,
        applicationData: { data: externalApplicationData?.payload?.data },
      });
    }

    if (!statusSuccess) {
      notify(
        'Nie udało się wygenerować wydruku PDF. Spróbuj ponownie.',
        'error'
      );
    }

    setLoading(false);
  };

  const getPreviousApplicationData = async () => request.get(`${API_ROUTE.applicationDatas}/${previousApplicationDataId}`);

  /**
   * Application template managed by service.
   */
  const managedApplicationTemplate = useMemo(
    () => applicationTemplateDataStore(
      applicationData?.applicationData?.data || {},
      applicationTemplateData || {},
      applicationId,
      MAPPING_TYPE.printToPdf,
      applicationData?.informationClause?.contents,
    ),
    [applicationData?.applicationData?.data, applicationTemplateData]
  );

  const getBackdropComponent = (open) => (
    <Portal>
      <Backdrop open={open} sx={usePrintoutStyles.backdrop}>
        <Paper elevation={3} sx={usePrintoutStyles.paper}>
          <Typography>
            Proszę czekać... Trwa generowanie wydruku wniosku.
          </Typography>
        </Paper>
      </Backdrop>
    </Portal>
  );

  const getButtonByType = (loading) => {
    if (buttonType === BUTTON_TYPES.button) {
      return (
        <Button
          type="button"
          color="secondary"
          variant="contained"
          onClick={getApplicationData}
          disabled={loading}
          id="Odr0RrrCHCibyVk"
        >
          {loading ? 'Generowanie PDF...' : buttonLabel}
        </Button>
      );
    }

    return (
      <MenuItem
        key={uniqid()}
        variant="right-border"
        id={uniqid()}
        onClick={getApplicationData}
        disabled={loading}
      >
        {loading ? 'Generowanie PDF...' : buttonLabel}
      </MenuItem>
    );
  };

  return (
    isLoading
      ? (
        <>
          {getButtonByType(isLoading)}
          {getBackdropComponent(isLoading)}
        </>
      )
      : (
        <>
          {getButtonByType(isLoading)}
          {getBackdropComponent(isLoading)}
          {Object.values(applicationData).length !== 0 && (
            <DictionaryProvider>
              {getBackdropComponent(false)}
              <PrintoutApplicationDictionaries
                backdrop={getBackdropComponent}
                getButtonByType={getButtonByType}
                application={applicationData}
                managedApplicationTemplate={{
                  ...managedApplicationTemplate,
                  pdfPrintTitle: applicationData?.recruitment?.pdfPrintTitle || 'Wniosek o dofinansowanie projektu',
                }}
                previousInitialFormData={previousApplicationData}
                applicationId={applicationId}
                setApplicationData={setApplicationData}
                isReadonly={isReadonly}
                withChanges={withChanges}
              />
            </DictionaryProvider>
          )}
        </>
      )
  );
}

export default PrintoutApplication;

PrintoutApplication.propTypes = {
  isReadonly: PropTypes.bool.isRequired,
  externalApplicationDataId: PropTypes.string,
  withChanges: PropTypes.bool,
  previousApplicationDataId: PropTypes.string,
  buttonType: PropTypes.oneOf([BUTTON_TYPES.button, BUTTON_TYPES.menuItem]),
  buttonLabel: PropTypes.string,
  isApplicationData: PropTypes.bool,
  externalApplicationId: PropTypes.string,
};

PrintoutApplication.defaultProps = {
  externalApplicationId: null,
  externalApplicationDataId: null,
  withChanges: false,
  previousApplicationDataId: null,
  buttonType: BUTTON_TYPES.button,
  buttonLabel: 'Wydruk PDF',
  isApplicationData: false,
};
