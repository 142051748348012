import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
  useMemo,
} from 'react';
import { useEvaluationSheetsContext } from './EvaluationSheets.context';
import { evaluationSheetHelper } from '../../_helpers';
import { useAuth } from '../../_security';

/**
 * Evaluation sheets with modules context wrapper.
 * Models data from API
 *
 * @type {React.Context}
 */
export const EvaluationSheetWithStagesContext = createContext({});

export const useEvaluationSheetWithStagesContext = () => useContext(EvaluationSheetWithStagesContext);

/**
 * Evaluation sheets with modules provider.
 *
 * @param {object} props - root props
 * @param {Node} props.children - children elements
 * @returns {EvaluationSheetWithStagesProvider}
 */
export function EvaluationSheetWithStagesProvider({
  children,
}) {
  const { id: loggedUserId } = useAuth();
  const {
    expertPanel, evaluationSheet, transformedData, expertsJustifications, expertCompletions,
  } = useEvaluationSheetsContext();

  const evaluationSheetWithStagesStore = useMemo(() => ({
    ...transformedData,
    evaluationItems: evaluationSheetHelper.mappedEvaluationItems(evaluationSheet.items),
    application: evaluationSheet.application,
    modules: evaluationSheetHelper.mappedModules(
      expertPanel.modules,
      loggedUserId,
      transformedData.isEmployeeOrAdmin,
      transformedData.isApplicationLeadingExpert,
    ),
    panelNumber: expertPanel.number,
    recruitmentId: expertPanel.recruitmentId,
    expertsJustifications,
    expertCompletions,
  }), [expertPanel, transformedData, evaluationSheet]);

  return (
    <EvaluationSheetWithStagesContext.Provider value={evaluationSheetWithStagesStore}>
      {children}
    </EvaluationSheetWithStagesContext.Provider>
  );
}
EvaluationSheetWithStagesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
