import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { request } from '../../../../../_services';
import { API_ROUTE } from '../../../../../_constants';
import {
  useEvaluationSheetsContext,
  useEvaluationSheetWithStagesContext,
} from '../../../../../Context';
import { useCustomSnackbar } from '../../../../../_hooks';
import {
  evaluationSheetHelper,
  iriToId,
} from '../../../../../_helpers';
import { useAuth } from '../../../../../_security';

/**
 * Evaluation sheet save button.
 *
 * @returns {EvaluationSheetSaveButton}
 */
export function EvaluationSheetSaveButton() {
  const {
    getValues, reset, formState: { isSubmitting },
  } = useFormContext();
  const { reloadData } = useEvaluationSheetsContext();
  const {
    evaluationItems, modules, isEmployeeOrAdmin,
  } = useEvaluationSheetWithStagesContext();
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const { id: loggedUserId } = useAuth();

  const saveExpertJustification = async (filteredEvaluationItems) => {
    const expertsJustifications = filteredEvaluationItems
      .map((evaluationItemId) => getValues(`${evaluationItemId}.justifications`)).flat();

    const filteredExpertJustificationsByExpert = expertsJustifications
      .filter(({
        createdBy, justification,
      }) => (iriToId(createdBy) === loggedUserId && justification));

    if (!filteredExpertJustificationsByExpert.length === 0) {
      return;
    }

    const expertJustificationsToResolve = filteredExpertJustificationsByExpert.map((expertJustification) => {
      const expertJustificationId = expertJustification?.id;

      return expertJustificationId
        ? request.put(`${API_ROUTE.evaluationSheetExpertJustifications}/${expertJustificationId}`, expertJustification)
        : request.post(`${API_ROUTE.evaluationSheetExpertJustifications}`, expertJustification);
    });

    const responses = await Promise.all(expertJustificationsToResolve);

    if (responses.some(({ statusSuccess }) => !statusSuccess)) {
      errorNotification('Nie udało się zapisać propozycji uzasadnienia. Spróbuj ponownie.');

      return;
    }

    successNotification('Propozycja uzasadnienia została zapisana.');
  };

  const saveCompleteWorkOnEvaluationSheet = async (expertsCompleteWork) => {
    const completeWorkByUser = expertsCompleteWork.find(({ userId }) => userId === loggedUserId);

    if (!completeWorkByUser) {
      return;
    }

    const { statusSuccess } = completeWorkByUser.expertCompletionId
      ? await request.put(`${API_ROUTE.evaluationSheetExpertCompletions}/${completeWorkByUser.expertCompletionId}`, {
        completed: completeWorkByUser.completed === 'true',
      })
      : await request.post(API_ROUTE.evaluationSheetExpertCompletions, {
        completed: completeWorkByUser.completed === 'true',
        evaluationSheet: `/lsi/auth/api/evaluation-sheets/${completeWorkByUser.evaluationSheetId}`,
      });

    if (statusSuccess) {
      successNotification('Informacja o zakończeniu pracy na formularzu została zapisana.');

      return;
    }

    errorNotification('Nie udało się zapisać informacji o zakończeniu pracy na formularzu. Spróbuj ponownie.');
  };

  const onSubmit = async () => {
    const values = getValues();
    const filteredEvaluationItems = Object.keys(values)
      .filter((key) => (
        isEmployeeOrAdmin || evaluationSheetHelper
          .filterEvaluationItemsByAccess(evaluationItems, modules).includes(key)) && key !== 'expertsCompleteWork');

    if (!isEmployeeOrAdmin) {
      await saveExpertJustification(filteredEvaluationItems);
      await saveCompleteWorkOnEvaluationSheet(values.expertsCompleteWork);
    }

    const evaluationItemsToResolve = filteredEvaluationItems
      .map((key) => request.put(`${API_ROUTE.expertEvaluationItems}/${key}`, {
        ...values[key],
        points: values[key]?.points?.toString() || null,
      }));

    await Promise.all(evaluationItemsToResolve);

    reloadData();
    successNotification('Formularz oceny został zapisany. ');
    reset({}, { keepValues: true });
  };

  return (
    <Button
      type="button"
      color="secondary"
      variant="contained"
      id="a1Ewx1n6WyV1n8t"
      disabled={isSubmitting}
      onClick={onSubmit}
    >
      Zapisz
    </Button>
  );
}
