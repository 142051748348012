import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { DICTIONARIES } from '../../../../_constants';
import { mapPkdCodes } from '../../../../_helpers';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import { useApplicationToPdfContext } from '../../../../Context';

/**
 * ProductAndWorkDescription pdf element.
 *
 * @returns {ProductAndWorkDescriptionPdf}
 */
function ProductAndWorkDescriptionPdf() {
  const { getDictionary } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Opis produktu  */}
        <RowField name="product_and_work_description_product_description" isSingleRow />
        {/* PKD produktu */}
        <RowField name="product_and_work_description_product_pkd" externalDictionary={mapPkdCodes(getDictionary)} />
        {/* Etap zaawansowania prac nad produktem */}
        <RowField
          name="product_and_work_description_product_development_stage"
          dictionaryName={DICTIONARIES.productAndWorkDescriptionProductDevelopmentStage.name}
        />
        {/* Przebieg prac badawczych i rozwojowych */}
        <RowField name="product_and_work_description_research_and_development_work_progress" isSingleRow />
        {/* Przebieg prac wdrożeniowych  */}
        <RowField name="product_and_work_description_implementation_work_progress" isSingleRow />
        {/* Źródła finansowania prac badawczych, rozwojowych oraz wdrożeniowych produktu  */}
        <RowField name="product_and_work_description_works_financing_sources" isSingleRow />
        {/* Skala innowacyjności produktu */}
        <RowField
          name="product_and_work_description_product_innovation_scale"
          dictionaryName={DICTIONARIES.productAndWorkDescriptionProductInnovationScale.name}
        />
        {/* Poziom innowacyjności produktu */}
        <RowField
          name="product_and_work_description_product_innovation_level"
          dictionaryName={DICTIONARIES.productAndWorkDescriptionProductInnovationLevel.name}
        />
        {/* Uzasadnienie dot. wybranego poziomu innowacyjności produktu */}
        <RowField
          name="product_and_work_description_justification_of_selected_level_of_product_innovation"
          isSingleRow
        />
        {/* Istniejące rozwiązania/ produkty konkurencyjne oraz porównanie cech przez nie oferowanych względem
       zgłaszanego produktu */}
        <RowField name="product_and_work_description_existing_competitive_products" isSingleRow />
        {/* Korzyści dla odbiorcy produktu */}
        <RowField name="product_and_work_description_product_recipient_benefits" isSingleRow />
        {/* Opis potencjału rynkowego produktu */}
        <RowField name="product_and_work_description_product_market_potential_description" isSingleRow />
        {/* Szacunkowa wielkość rynku docelowego i jego tendencje rozwojowe */}
        <RowField name="product_and_work_description_estimated_target_market_size" isSingleRow />
        {/* Strategia wprowadzenia produktu na rynek  */}
        <RowField name="product_and_work_description_product_launch_strategy" isSingleRow />
        {/* Opis podejmowanych działań zmierzających do wprowadzenia produktu na rynki międzynarodowe  */}
        <RowField name="product_and_work_description_product_launch_on_international_markets_description" isSingleRow />
        {/* 16.Deklaracja udziału w EXPO 2025 w Osace */}
        <RowField name="product_and_work_description_participation_in_expo_2025" />
        {/* 17.Opis strategii zmierzającej do wprowadzenia produktu na rynek japoński (jeśli dotyczy) */}
        <RowField name="product_and_work_description_product_launch_on_japanese_markets_description" isSingleRow />
        {/* 18.Opis modelu biznesowego */}
        <RowField name="product_and_work_description_business_model_description" isSingleRow />
        {/* 19.Opis wpływu produktu na rozwój branży */}
        <RowField name="product_and_work_description_product_impact_description" isSingleRow />
        {/* 20.Opis problemów społecznych jakie rozwiązuje produkt */}
        <RowField name="product_and_work_description_social_problems_description" isSingleRow />
        {/* 21.Opis wpływu produktu lub sposobu jego wytwarzania na środowisko */}
        <RowField name="product_and_work_description_product_impact_on_the_environment_description" isSingleRow />
        {/* 22.Patenty, zgłoszenia patentowe związane z produktem */}
        <RowField name="product_and_work_description_patents_given_to_product" isSingleRow />
        {/* 23.Nagrody i wyróżnienia przyznane produktowi */}
        <RowField name="product_and_work_description_awards_given_to_product" isSingleRow />
        {/* 24. Zdjęcia i/lub filmy prezentujące produkt */}
        <RowField name="product_and_work_description_photos_presenting_product" isSingleRow />
        {/* 25.Autorzy produktu */}
        <RowField name="product_and_work_description_product_authors" isSingleRow />
        {/* Numer konta, na który zostanie wypłacona nagroda lub wyróżnienie */}
        <RowField name="product_and_work_description_bank_account_number" isSingleRow />
        {/* Przysługujące prawa do produktu */}
        <RowField
          name="product_and_work_description_product_rights"
          dictionaryName={DICTIONARIES.productAndWorkDescriptionProductRight.name}
        />
        {/* Uzasadnienie */}
        <RowField name="product_and_work_description_product_rights_justification" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ProductAndWorkDescriptionPdf;
