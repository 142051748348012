import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { useGlobalDialog } from '../../../../../Context';
import { EvaluationSheetSingleChairmanRemark } from './EvaluationSheetSingleChairmanRemark';

/**
 * Evaluation sheet chairman remarks list.
 *
 * @param {object} props - root props
 * @param {Array} props.evaluationItems - list of evaluation items
 * @param {Array} props.modulesList - list of modules
 * @returns {EvaluationSheetChairmanRemarksList}
 */
export function EvaluationSheetChairmanRemarksList({
  evaluationItems, modulesList,
}) {
  const { closeAll } = useGlobalDialog();

  const remarksList = sortBy(
    evaluationItems.map(({
      criteriaItem, chairmanRemarks,
    }) => ({
      id: criteriaItem.id,
      moduleName: modulesList.find(({ moduleId: id }) => criteriaItem.moduleId === id)?.moduleName || '',
      evaluationItemName: criteriaItem.name,
      remark: chairmanRemarks.find(({ active }) => active)?.remark || null,
    })).filter(({ remark }) => remark),
    'moduleName',
  );

  return (
    <>
      {remarksList.length === 0 && (
        <Typography
          fontFamily={'"Montserrat-Light", sans-serif'}
          fontSize="1.2rem"
          fontWeight="bold"
          textAlign="center"
        >
          Lista uwag przewodniczącego jest pusta.
        </Typography>
      )}
      {remarksList.map(({
        evaluationItemName, remark, id, moduleName,
      }) => (
        <EvaluationSheetSingleChairmanRemark key={id} remark={remark} label={`${moduleName ? `${moduleName}:` : ''} ${evaluationItemName}`} />
      ))}
      <Box mt={5} display="flex" justifyContent="center" alignItems="center">
        <Button
          id="c4R5S4pv1qcm969"
          type="button"
          color="primary"
          variant="outlined"
          onClick={closeAll}
        >
          Zamknij
        </Button>
      </Box>
    </>
  );
}

EvaluationSheetChairmanRemarksList.propTypes = {
  evaluationItems: PropTypes.arrayOf(Object).isRequired,
  modulesList: PropTypes.arrayOf(Object).isRequired,
};
