import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ADDITIONAL_MODULE_IDS } from '../../../../_constants';
import { formatDateString } from '../../../../_helpers';

/**
 * Evaluation sheet module refused.
 *
 * @param {object} props - root props
 * @param {string} props.moduleId - module id
 * @param {string} props.refusalDate - refusal date
 * @param {string} props.refusalRemarks - refusal remarks
 * @returns {EvaluationSheetModuleRefused}
 */
export function EvaluationSheetModuleRefused({
  refusalRemarks, refusalDate, moduleId,
}) {
  return (
    <>
      {moduleId !== ADDITIONAL_MODULE_IDS.modulowaFinansowa && (
        <>
          <Typography variant="h4" textAlign="center">
            {`Moduł został odrzucony dnia: ${formatDateString(refusalDate)}`}
          </Typography>
          <Typography variant="h4" textAlign="center" mt={2}>
            {`Powód odrzucenia: ${refusalRemarks}`}
          </Typography>
        </>
      )}
      {moduleId === ADDITIONAL_MODULE_IDS.modulowaFinansowa && (
        <Typography variant="h4" textAlign="center">
          &quot;Ocena finansowa&quot; została odrzucona.
        </Typography>
      )}
    </>
  );
}

EvaluationSheetModuleRefused.propTypes = {
  moduleId: PropTypes.string.isRequired,
  refusalDate: PropTypes.string.isRequired,
  refusalRemarks: PropTypes.string.isRequired,
};
