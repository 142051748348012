import {
  AlignmentType,
  Document,
  PageOrientation,
} from 'docx';
import {
  printoutToDocxElement,
  printoutToDocxFooter,
  printoutToDocxHeader,
  printoutToDocxText,
} from '../PrintoutToDocxComponents';

/**
 * Printout to docx indicators template
 *
 * @param {object} managedApplicationData - managed application data
 * @returns {printoutToDocxIndicatorsTemplate}
 */
export const printoutToDocxIndicatorsTemplate = (managedApplicationData) => new Document({
  sections: [
    {
      headers: {
        default: printoutToDocxHeader(),
      },
      footers: {
        default: printoutToDocxFooter(managedApplicationData.applicationNumber),
      },
      properties: {
        page: {
          size: {
            orientation: PageOrientation.LANDSCAPE,
          },
        },
      },
      children: [
        printoutToDocxText({
          text: 'Wskaźniki',
          size: 20,
          align: AlignmentType.CENTER,
          bold: true,
          marginBottom: 500,
        }),
        ...managedApplicationData.mappedIndicatorsElements
          .flatMap((elementData) => printoutToDocxElement(elementData, [])),
      ],
    },
  ],
});
