import {
  AlignmentType,
  Footer,
  PageNumber,
  Paragraph,
  TextRun,
} from 'docx';

/**
 * Printout to docx footer.
 *
 * @param {string} applicationNumber - application number
 * @returns {printoutToDocxFooter}
 */
export const printoutToDocxFooter = (applicationNumber) => new Footer({
  children: [
    new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          text: `Numer wniosku: ${applicationNumber}`,
          font: 'Arial',
          size: 20,
        }),
      ],
    }),
    new Paragraph({
      alignment: AlignmentType.RIGHT,
      children: [
        new TextRun({
          children: ['Strona: ', PageNumber.CURRENT, '/', PageNumber.TOTAL_PAGES],
          font: 'Arial',
          size: 20,
        }),
      ],
    }),
  ],
});
