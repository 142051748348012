import {
  Controller,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  Divider,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';
import { booleanValuesOptions } from '../../../../_optionsCustomAutocomplete';
import { useAuth } from '../../../../_security';
import { useEvaluationSheetsContext } from '../../../../Context';

/**
 * Evaluation sheet complete work form.
 *
 * @returns {EvaluationSheetCompleteWorkForm}
 */
export function EvaluationSheetCompleteWorkForm() {
  const { isReadonly } = useEvaluationSheetsContext();
  const { control } = useFormContext();
  const collectionName = 'expertsCompleteWork';
  const { id: loggedUserId } = useAuth();

  const { fields } = useFieldArray({
    control,
    name: collectionName,
  });

  if (fields.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={3} mb={5}>
      <Grid item xs={12}>
        <Divider />
        <Typography align="left" fontWeight="bold" mt={2}>
          Praca na formularzu oceny została zakończona
        </Typography>
      </Grid>
      {fields.map((item, index) => (
        <Fragment key={item.id}>
          <Grid item xs={12} md={3} justifyContent="flex-start" display="flex" alignItems="center">
            {item.userName}
          </Grid>
          <Grid item xs={12} md={9}>
            <FormControl fullWidth>
              <Controller
                name={`${collectionName}.${index}.completed`}
                control={control}
                render={({
                  field: {
                    onChange, value, name, onBlur,
                  },
                  fieldState: {
                    error,
                  },
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={booleanValuesOptions}
                    optionsMapKeys={['id', 'label']}
                    error={error}
                    disabled={item.userId !== loggedUserId || isReadonly}
                    textFieldProps={{
                      label: `Praca na formularzu oceny została zakończona - ${item.userName}`,
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
}
