import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import { useApplicationToPdfContext } from '../../../../Context';
import { mapPkdCodes } from '../../../../_helpers';

/**
 * ReduceTurnover Pdf element.
 *
 * @returns {ReduceTurnoverPdf}
 */
function ReduceTurnoverPdf() {
  const { getDictionary } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="reduce_turnover_reduce_applicant_turnover" isSingleRow />
        <RowField
          name="reduce_turnover_calculation_types"
          dictionaryName={DICTIONARIES.reduceTurnoverCalculationType.name}
        />
        <RowField name="reduce_turnover_turnover_value_in_the_year_preceding_reduce_turnover" />
        <RowField name="reduce_turnover_turnover_value_in_the_year_reduce_turnover" />
        <RowField name="reduce_turnover_percent_of_reduce_turnover" />
        <RowField name="reduce_turnover_economic_activity" isSingleRow />
        <RowField name="reduce_turnover_necessary_documents_to_realization_sme" isSingleRow />
        <RowField name="reduce_turnover_pkd_code" externalDictionary={mapPkdCodes(getDictionary)} />
        <RowField name="reduce_turnover_decision" isSingleRow />
        <RowField name="reduce_turnover_suspension_economic_activity" isSingleRow />
        <RowField name="reduce_turnover_activity_description" isSingleRow />
        <RowField name="reduce_turnover_applicant_ran_business" />
        <RowField name="reduce_turnover_estimation_method" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ReduceTurnoverPdf;
