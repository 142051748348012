import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { useEffect } from 'react';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
  getImplementers,
} from '../../../_helpers';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { MonetaryField } from '../../MonetaryField';

/**
 * ConsortiumOrganization element renderer.
 *
 * @returns {ConsortiumOrganization}
 */
export function ConsortiumOrganization() {
  const {
    isReadonly, control, fieldsConfig,
  } = useElementContext();
  const {
    setValue, getValues,
  } = useFormContext();

  const collectionName = 'consortium_organization_partners_available_resources';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });

  const availableResourcesConfig = fieldsConfig[collectionName];
  const subfields = availableResourcesConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = availableResourcesConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <FieldSorter>
      { /* Nazwa Konsorcjum */}
      <FormField name="consortium_organization_consortium_name">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Data akredytacji */}
      <FormField name="consortium_organization_accreditation_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            onChange={onChange}
            value={value}
            name={name}
            disabled={isReadonly}
            ariaLabel={label}
            onBlur={onBlur}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zakres akredytacji */}
      <FormField name="consortium_organization_accreditation_scope">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            renderSelectAllButton={false}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Posiadane zasoby techniczne i kadrowe do realizacji projektu */}
      <FormField name="consortium_organization_technical_resources">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Potrzeby rozwojowe Konsorcjum */}
      <FormField name="consortium_organization_development_needs">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Liczba zatrudnionych osób realizujących projekt */}
      <FormField name="consortium_organization_employed_persons_number">
        {({
          name, onChange, value, error, onBlur,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{ inputComponent: MonetaryField }}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Liczba zatrudnionych kobiet realizujących projekt */}
      <FormField name="consortium_organization_employed_women_number">
        {({
          name, onChange, value, error, onBlur,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{ inputComponent: MonetaryField }}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Źródła i sposób finansowania projektu */}
      <FormField name="consortium_organization_financing_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Kluczowe ryzyka związane z realizacją projektu oraz działania ograniczające zidentyfikowane ryzyka */}
      <FormField name="consortium_organization_key_risks">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Posiadane zasoby techniczne i kadrowe do realizacji projektu Wnioskodawcy – Lidera Konsorcjum */}
      <FormField name="consortium_organization_leader_available_resources">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela "Zadania i posiadane zasoby techniczne i kadrowe do realizacji projektu Partnerów Konsorcjum" */}
      <LabeledFieldRow fieldConfig={availableResourcesConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionName,
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Nazwa partnera */}
            <FormField name={`${collectionName}.${index}.partner_name`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={getImplementers(getValues)}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Zadanie Partnera w ramach Konsorcjum */}
            <FormField name={`${collectionName}.${index}.partner_task`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Posiadane zasoby techniczne i kadrowe do realizacji projektu Partnera Konsorcjum */}
            <FormField name={`${collectionName}.${index}.partner_resources_available`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="OpPtK9MPz6SFuGu"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
