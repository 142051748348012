import { ImageRun } from 'docx';

/**
 * Printout to docx image.
 *
 * @param {string} imageBase64 - image base64 data
 * @param {number} width - image width
 * @param {number} height - image height
 * @returns {printoutToDocxImage}
 */
export const printoutToDocxImage = (imageBase64, width, height) => new ImageRun({
  data: Uint8Array.from(atob(imageBase64), (c) => c.charCodeAt(0)),
  transformation: {
    width,
    height,
  },
});
