import { TableRow } from 'docx';

/**
 * Printout to docx table row
 *
 * @param {object} props - root props
 * @param {Array} props.children - table cells
 * @returns {printoutToDocxTableRow}
 */
export const printoutToDocxTableRow = ({ children }) => new TableRow({ children });
