/**
 * Evaluation types.
 *
 * @type {object}
 */
export const EVALUATION_TYPES = {
  evaluation_type_experts_panel: 'evaluation_type_experts_panel',
  evaluation_type_merit: 'evaluation_type_merit',
};

/**
 * Evaluation types translations.
 *
 * @type {object}
 */
export const EVALUATION_TYPES_TRANSLATIONS = {
  [EVALUATION_TYPES.evaluation_type_experts_panel]: 'Panel ekspertów',
  [EVALUATION_TYPES.evaluation_type_merit]: 'Ocena merytoryczna',
};

export const EVALUATION_JUSTIFICATION_FIELD_TYPE = {
  commonJustification: 'common_justification',
  separateJustification: 'separate_justification',
};

export const EVALUATION_JUSTIFICATION_FIELD_TRANSLATIONS = {
  [EVALUATION_JUSTIFICATION_FIELD_TYPE.commonJustification]: 'Wspólne pole "Propozycja uzasadnienia"',
  [EVALUATION_JUSTIFICATION_FIELD_TYPE.separateJustification]: 'Osobne pola "Propozycja uzasadnienia"',
};

export const EVALUATION_JUSTIFICATION_FIELD_OPTIONS = [
  {
    id: EVALUATION_JUSTIFICATION_FIELD_TYPE.commonJustification,
    label: EVALUATION_JUSTIFICATION_FIELD_TRANSLATIONS[EVALUATION_JUSTIFICATION_FIELD_TYPE.commonJustification],
  },
  {
    id: EVALUATION_JUSTIFICATION_FIELD_TYPE.separateJustification,
    label: EVALUATION_JUSTIFICATION_FIELD_TRANSLATIONS[EVALUATION_JUSTIFICATION_FIELD_TYPE.separateJustification],
  },
];
