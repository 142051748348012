import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import {
  DICTIONARIES,
  API_ROUTE,
} from '../../../_constants';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import {
  formatPkdCodesFieldValue,
  iriToId,
} from '../../../_helpers';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';

/**
 * Product and work description
 *
 * @returns {ProductAndWorkDescription}
 */
export function ProductAndWorkDescription() {
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const { get } = useDictionaryLoader(
    DICTIONARIES.productAndWorkDescriptionProductDevelopmentStage,
    DICTIONARIES.productAndWorkDescriptionProductInnovationScale,
    DICTIONARIES.productAndWorkDescriptionProductInnovationLevel,
    DICTIONARIES.productAndWorkDescriptionProductRight,
  );

  const pkdCodesChoices = fieldsConfig?.pkd?.choices || [];

  return (
    <FieldSorter>
      {/* Opis produktu  */}
      <FormField name="product_and_work_description_product_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* PKD produktu */}
      <FormField name="product_and_work_description_product_pkd">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <ApiAutocomplete
            id={name}
            filterBy="nameOrCode"
            initialValue={iriToId(value)}
            initialValueFilterBy="id"
            changeHandler={({ id }) => onChange(id)}
            optionParser={formatPkdCodesFieldValue}
            textFieldProps={{ onBlur }}
            baseUrl={API_ROUTE.elementDictionaries.pkdCodes}
            disabled={isReadonly}
            customFilter={pkdCodesChoices.length === 0
              ? () => true
              : (payloadObject) => pkdCodesChoices.includes(payloadObject.id)}
            error={error}
          />
        )}
      </FormField>
      {/* Etap zaawansowania prac nad produktem */}
      <FormField name="product_and_work_description_product_development_stage">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={filterOptions(get(DICTIONARIES.productAndWorkDescriptionProductDevelopmentStage.name))}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {/* Przebieg prac badawczych i rozwojowych  */}
      <FormField name="product_and_work_description_research_and_development_work_progress">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Przebieg prac wdrożeniowych  */}
      <FormField name="product_and_work_description_implementation_work_progress">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Źródła finansowania prac badawczych, rozwojowych oraz wdrożeniowych produktu   */}
      <FormField name="product_and_work_description_works_financing_sources">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Skala innowacyjności produktu */}
      <FormField name="product_and_work_description_product_innovation_scale">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={filterOptions(get(DICTIONARIES.productAndWorkDescriptionProductInnovationScale.name))}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {/*  Poziom innowacyjności produktu */}
      <FormField name="product_and_work_description_product_innovation_level">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={filterOptions(get(DICTIONARIES.productAndWorkDescriptionProductInnovationLevel.name))}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {/* Uzasadnienie dot. wybranego poziomu innowacyjności produktu */}
      <FormField name="product_and_work_description_justification_of_selected_level_of_product_innovation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Istniejące rozwiązania/ produkty konkurencyjne oraz porównanie cech przez nie oferowanych względem
       zgłaszanego produktu  */}
      <FormField name="product_and_work_description_existing_competitive_products">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Korzyści dla odbiorcy produktu   */}
      <FormField name="product_and_work_description_product_recipient_benefits">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis potencjału rynkowego produktu    */}
      <FormField name="product_and_work_description_product_market_potential_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Szacunkowa wielkość rynku docelowego i jego tendencje rozwojowe   */}
      <FormField name="product_and_work_description_estimated_target_market_size">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Strategia wprowadzenia produktu na rynek    */}
      <FormField name="product_and_work_description_product_launch_strategy">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis podejmowanych działań zmierzających do wprowadzenia produktu na rynki międzynarodowe */}
      <FormField name="product_and_work_description_product_launch_on_international_markets_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 16.Deklaracja udziału w EXPO 2025 w Osace */}
      <FormField name="product_and_work_description_participation_in_expo_2025">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* 17.Opis strategii zmierzającej do wprowadzenia produktu na rynek japoński (jeśli dotyczy) */}
      <FormField name="product_and_work_description_product_launch_on_japanese_markets_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 18.Opis modelu biznesowego */}
      <FormField name="product_and_work_description_business_model_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 19.Opis wpływu produktu na rozwój branży */}
      <FormField name="product_and_work_description_product_impact_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 20.Opis problemów społecznych jakie rozwiązuje produkt */}
      <FormField name="product_and_work_description_social_problems_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 21.Opis wpływu produktu lub sposobu jego wytwarzania na środowisko */}
      <FormField name="product_and_work_description_product_impact_on_the_environment_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 22.Patenty, zgłoszenia patentowe związane z produktem */}
      <FormField name="product_and_work_description_patents_given_to_product">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 23.Nagrody i wyróżnienia przyznane produktowi */}
      <FormField name="product_and_work_description_awards_given_to_product">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 24. Zdjęcia i/lub filmy prezentujące produkt */}
      <FormField name="product_and_work_description_photos_presenting_product">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 25.Autorzy produktu */}
      <FormField name="product_and_work_description_product_authors">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Numer konta, na który zostanie wypłacona nagroda lub wyróżnienie */}
      <FormField name="product_and_work_description_bank_account_number">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            disabled={isReadonly}
            InputProps={{
              inputComponent: ImprovedMaskedInput,
              endAdornment: <CharsCounter
                valueLength={value ? value.toString().replace(/\s/g, '').length : 0}
                maxLength={maxLength}
              />,
            }}
            inputProps={{
              mask: '00 0000 0000 0000 0000 0000 0000',
              maxLength: 32,
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Przysługujące prawa do produktu */}
      <FormField name="product_and_work_description_product_rights">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={filterOptions(get(DICTIONARIES.productAndWorkDescriptionProductRight.name))}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            renderSelectAllButton={false}
            error={error}
          />
        )}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name="product_and_work_description_product_rights_justification">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
