import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import UeHorizontalPoliciesProjectComplianceProductsPdf
  from './UeHorizontalPoliciesProjectCompliance/UeHorizontalPoliciesProjectComplianceProducts.pdf';
import UeHorizontalPoliciesProjectComplianceEnvironmentalProtectionPdf
  from './UeHorizontalPoliciesProjectCompliance/UeHorizontalPoliciesProjectComplianceEnvironmentalProtection.pdf';
import { DICTIONARIES } from '../../../../_constants';
import UeHorizontalPoliciesProjectCompliance6RRuleCompatibilityPdf
  from './UeHorizontalPoliciesProjectCompliance/UeHorizontalPoliciesProjectCompliance6rRuleCompatibility.pdf';
import { useApplicationToPdfContext } from '../../../../Context';
import UeHorizontalPoliciesProjectComplianceOtherPdf
  from './UeHorizontalPoliciesProjectCompliance/UeHorizontalPoliciesProjectComplianceOther.pdf';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import { ELEMENTS_DICTIONARIES_MAP } from '../../../../_constants/elementsDictionariesMap';

/**
 * UeHorizontalPoliciesProjectCompliancePdf element.
 *
 * @returns {UeHorizontalPoliciesProjectCompliancePdf}
 */
function UeHorizontalPoliciesProjectCompliancePdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();
  const {
    ueHorizontalPoliciesProjectComplianceIsR6Rules: {
      project6R,
      projectPositiveInfluence,
      atLeastOneRuleFrom6RAndPositiveImpactOnOtherEnvironmentalAspects,
      withAtLeastOneRuleFrom6R,
      withAtLeastTwoRulesFrom6R,
      positiveImpactOnOtherEnvironmentalAspectsOfProject,
    },
  } = ELEMENTS_DICTIONARIES_MAP;

  const showTableUeHorizontalPoliciesProjectCompliance6rRuleCompatibilityById = [
    project6R.id,
    atLeastOneRuleFrom6RAndPositiveImpactOnOtherEnvironmentalAspects.id,
    withAtLeastTwoRulesFrom6R.id,
    withAtLeastOneRuleFrom6R.id,
  ];

  const showTableUeHorizontalPoliciesProjectComplianceOtherById = [
    projectPositiveInfluence.id,
    positiveImpactOnOtherEnvironmentalAspectsOfProject.id,
    atLeastOneRuleFrom6RAndPositiveImpactOnOtherEnvironmentalAspects.id,
  ];

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="ue_horizontal_policies_project_compliance_rules" isSingleRow />
        <RowField name="ue_horizontal_policies_project_compliance_equality_rule" isSingleRow />
        <CollectionWrapper name="ue_horizontal_policies_project_compliance_products">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <UeHorizontalPoliciesProjectComplianceProductsPdf
                key={item.set_uuid}
                collectionName={collectionName}
                item={item}
                index={index}
                collectionData={collectionData}
                previousRowData={previousRowData}
              />
            );
          })}
        </CollectionWrapper>
        <RowField name="ue_horizontal_policies_project_compliance_sex_equality" isSingleRow />
        <RowField name="ue_horizontal_policies_project_compliance_development_principles" isSingleRow />
        <CollectionWrapper name="ue_horizontal_policies_project_compliance_environment_protection">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <UeHorizontalPoliciesProjectComplianceEnvironmentalProtectionPdf
                collectionName={collectionName}
                item={item}
                index={index}
                collectionData={collectionData}
                previousRowData={previousRowData}
              />
            );
          })}
        </CollectionWrapper>
        <RowField
          name="ue_horizontal_policies_project_compliance_is_6r_rule"
          dictionaryName={DICTIONARIES.ueHorizontalPoliciesProjectComplianceIsR6Rules.name}
        />
        {initialFormData?.ue_horizontal_policies_project_compliance_is_6r_rule
          ?.some((value) => showTableUeHorizontalPoliciesProjectCompliance6rRuleCompatibilityById.includes(value))
        && (
          <CollectionWrapper name="ue_horizontal_policies_project_compliance_6r_rule_compatibility">
            {({
              collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <UeHorizontalPoliciesProjectCompliance6RRuleCompatibilityPdf
                  collectionName={collectionName}
                  item={item}
                  index={index}
                  collectionData={collectionData}
                  previousRowData={previousRowData}
                />
              );
            })}
          </CollectionWrapper>
        )}
        {initialFormData?.ue_horizontal_policies_project_compliance_is_6r_rule
          ?.some((value) => showTableUeHorizontalPoliciesProjectComplianceOtherById.includes(value))
        && (
          <CollectionWrapper name="ue_horizontal_policies_project_compliance_other">
            {({
              collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <UeHorizontalPoliciesProjectComplianceOtherPdf
                  collectionName={collectionName}
                  item={item}
                  index={index}
                  collectionData={collectionData}
                  previousRowData={previousRowData}
                />
              );
            })}
          </CollectionWrapper>
        )}
        <RowField name="ue_horizontal_policies_project_compliance_project_impact" isSingleRow />
        <RowField name="ue_horizontal_policies_project_compliance_local_government" isSingleRow />
        <RowField name="ue_horizontal_policies_project_compliance_statement_1" />
        <RowField
          name="ue_horizontal_policies_project_compliance_entity_controlled_by_local_government_unit"
          isSingleRow
        />
        <RowField name="ue_horizontal_policies_project_compliance_statement_2" />
        <RowField name="ue_horizontal_policies_project_project_compliance_with_fundamental_rights_charter" />
        <RowField name="ue_horizontal_policies_project_impact" dictionaryName={DICTIONARIES.impact.name} />
        <RowField name="ue_horizontal_policies_project_environmental_principles" isSingleRow />
        <RowField
          name="ue_horizontal_policies_project_project_compatibility"
          dictionaryName={DICTIONARIES.projectCompatibility.name}
        />
        <RowField name="ue_horizontal_policies_project_justifications" isSingleRow />
        <RowField
          name="ue_horizontal_policies_project_takes_into_account_promotion"
          dictionaryName={DICTIONARIES.greenSustainableEconomies.name}
        />
        <RowField
          name="ue_horizontal_policies_project_project_takes_into_account_promotion_justifications"
          isSingleRow
        />
        <RowField name="ue_horizontal_policies_project_compliance_fundamental_rights_charter" isSingleRow />
        <RowField
          name="ue_horizontal_policies_project_compliance_convention_on_rights_persons_with_disabilities"
          isSingleRow
        />
        {/* Pozytywny wpływ projektu na realizację zasady równości szans i niedyskryminacji, w tym dostępności dla osób
         z niepełnosprawnościami */}
        <RowField name="ue_horizontal_policies_project_project_positive_influence" isSingleRow />
        {/* Wpływ realizacji projektu na promocję zielonej i zrównoważonej gospodarki */}
        <RowField name="ue_horizontal_policies_project_project_implementation_impact" isSingleRow />
        {/* Projekt uwzględnia co najmniej jedno z rozwiązań dla promocji zielonej i zrównoważonej gospodarki */}
        <RowField
          name="ue_horizontal_policies_project_project_solutions"
          dictionaryName={DICTIONARIES.ueHorizontalPoliciesProjectSolutions.name}
        />
        {/* Projekt uwzględnia co najmniej jedno z rozwiązań dla promocji zielonej i zrównoważonej gospodarki -
        uzasadniene */}
        <RowField name="ue_horizontal_policies_project_project_solutions_justifications" isSingleRow />

        {/* Etykieta:Cele środowiskowe UE */}
        <RowField name="ue_horizontal_policies_project_compliance_environmental_goals" isSingleRow />
        {/* Etykieta:Łagodzenie zmian klimatu */}
        <RowField name="ue_horizontal_policies_project_compliance_mitigating_climate_change" isSingleRow />
        {/* Uzasadnienie */}
        <RowField
          name="ue_horizontal_policies_project_compliance_mitigating_climate_change_justifications"
          isSingleRow
        />
        {/* Etykieta:Adaptacja do zmian klimatu */}
        <RowField name="ue_horizontal_policies_project_compliance_climate_change_adaptation" isSingleRow />
        {/* Uzasadnienie */}
        <RowField
          name="ue_horizontal_policies_project_compliance_climate_change_adaptation_justifications"
          isSingleRow
        />
        {/* Etykieta:Odpowiednie użytkowanie i ochrona zasobów wodnych i morskich */}
        <RowField name="ue_horizontal_policies_project_compliance_water_resources_protection" isSingleRow />
        {/* Uzasadnienie */}
        <RowField
          name="ue_horizontal_policies_project_compliance_water_resources_protection_justifications"
          isSingleRow
        />
        {/* Etykieta:Gospodarka o obiegu zamkniętym, w tym zapobieganie powstawaniu odpadów i recykling */}
        <RowField name="ue_horizontal_policies_project_compliance_circular_economy" isSingleRow />
        {/* Uzasadnienie */}
        <RowField
          name="ue_horizontal_policies_project_compliance_circular_economy_justifications"
          isSingleRow
        />
        {/* Etykieta:Zapobieganie i kontrola zanieczyszczeń powietrza, wody lub ziemi */}
        <RowField name="ue_horizontal_policies_project_compliance_air_pollution_control" isSingleRow />
        {/* Uzasadnienie */}
        <RowField
          name="ue_horizontal_policies_project_compliance_air_pollution_control_justifications"
          isSingleRow
        />
        {/* Etykieta:Ochrona i odtwarzanie bioróżnorodności i ekosystemów */}
        <RowField name="ue_horizontal_policies_project_compliance_ecosystem_protection" isSingleRow />
        {/* Uzasadnienie */}
        <RowField
          name="ue_horizontal_policies_project_compliance_ecosystem_protection_justifications"
          isSingleRow
        />

        {/* Etykieta: Podejmowane działania związane z zasadą zrównoważonego rozwoju */}
        <RowField
          name="ue_horizontal_policies_project_compliance_actions_taken_related_principle_of_sustainable_development"
          isSingleRow
        />
        {/* Etykieta:Obszary */}
        <RowField
          name="ue_horizontal_policies_project_compliance_areas"
          isSingleRow
        />
        {/* Racjonalne gospodarowanie zasobami */}
        <RowField
          name="ue_horizontal_policies_project_compliance_rational_management_of_resources"
          isSingleRow
        />
        {/* Ograniczenie presji na środowisko */}
        <RowField
          name="ue_horizontal_policies_project_compliance_reducing_pressure_on_environment"
          isSingleRow
        />
        {/* Uwzględnianie efektów środowiskowych w zarządzaniu */}
        <RowField
          name="ue_horizontal_policies_project_compliance_taking_into_account_environmental_effects_in_management"
          isSingleRow
        />
        {/* Podnoszenie świadomości ekologicznej społeczeństwa */}
        <RowField
          name="ue_horizontal_policies_project_compliance_raising_ecological_awareness_of_society"
          isSingleRow
        />
        {/* Etykieta:Zgodność przedsięwzięcia z zasadą „niewyrządzania znaczącej szkody środowisku ( DNSH)” */}
        <RowField
          name="ue_horizontal_policies_project_compliance_compliance_of_project_with_principle_dnsh"
          isSingleRow
        />
        {/* Uzasadnienie pozytywnego wpływu na zasadę DNSH */}
        <RowField
          name="ue_horizontal_policies_project_compliance_justification_for_positive_impact_on_dnsh_rule"
          isSingleRow
        />
        {/* etykieta: "Zgodność z zasadą długotrwałego wpływu przedsięwzięcia na wydajność i odporność
      gospodarki polskiej" */}
        <RowField
          name="ue_horizontal_policies_project_compliance_compliance_with_principles"
          isSingleRow
        />
        {/* Uzasadnienie zapewnienia efektów długoterminowych */}
        <RowField
          name="ue_horizontal_policies_project_compliance_justification_for_ensuring_long_term_effects"
          isSingleRow
        />
        {/* Projekt uwzględnia działania w obszarze zrównoważonego rozwoju */}
        <RowField
          name="ue_horizontal_policies_project_includes_activities"
          dictionaryName={DICTIONARIES.ueHorizontalPoliciesProjectIncludesActivity.name}
        />
      </FieldSorter>
    </View>
  );
}

export default UeHorizontalPoliciesProjectCompliancePdf;
