import PropTypes from 'prop-types';
import {
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import {
  red,
  green,
} from '@mui/material/colors';
import { theme } from '../../../../styles/theme';
import { handleNewlines } from '../../../../_helpers';

/**
 * Pdf table cell
 *
 * @param {object} props - root props
 * @param {Node | string} props.children - children component
 * @param {string} props.bgColor - bg color
 * @param {boolean} props.rowSpan - number of spans in a row
 * @param {boolean} props.isTextBold - is text bold
 * @param {string} props.customWidth - custom width
 * @param {boolean} props.isTextValue - is text value
 * @returns {PrintoutTableCell}
 */
function PrintoutTableCell({
  children, bgColor, rowSpan, isTextBold, customWidth, isTextValue,
}) {
  const BG_COLORS = {
    white: theme.palette.background.default,
    gray: theme.palette.brandGray20.main,
    dkGray: theme.palette.brandGray40.main,
    added: green[200],
    removed: red[200],
  };

  const tableCellStyle = StyleSheet.create({
    cell: {
      boxSizing: 'border-box',
      paddingVertical: 7,
      paddingHorizontal: 5,
      width: customWidth || `${100 / rowSpan}%`,
      backgroundColor: bgColor in BG_COLORS ? BG_COLORS[bgColor] : BG_COLORS.white,
      borderStyle: 'solid',
      borderColor: theme.palette.brandGray10.contrastText,
      borderWidth: 1,
      margin: -1,
    },
    text: {
      color: theme.palette.brandGray10.contrastText,
      fontFamily: isTextBold ? 'Montserrat-Regular' : 'Montserrat-Light',
    },
  });

  if (!isTextValue) {
    return (
      <View style={tableCellStyle.cell}>
        {children ? handleNewlines(children) : null}
      </View>
    );
  }

  return (
    <View style={tableCellStyle.cell}>
      <Text style={tableCellStyle.text}>
        {children ? handleNewlines(children) : null}
      </Text>
    </View>
  );
}

export default PrintoutTableCell;

PrintoutTableCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  bgColor: PropTypes.oneOf(['white', 'gray', 'dkGray', 'added', 'removed']),
  rowSpan: PropTypes.number,
  isTextBold: PropTypes.bool,
  customWidth: PropTypes.string,
  isTextValue: PropTypes.bool,
};

PrintoutTableCell.defaultProps = {
  bgColor: 'white',
  rowSpan: 2,
  children: null,
  isTextBold: false,
  customWidth: null,
  isTextValue: true,
};
