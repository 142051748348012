import { Button } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ConfirmDialog } from '../../../../../Dialogs';
import {
  useEvaluationSheetsActionContext,
  useEvaluationSheetsContext,
  useEvaluationSheetsUserSessionContext,
} from '../../../../../Context';
import { request } from '../../../../../_services';
import {
  API_ROUTE,
  EVALUATION_SHEET_STATUS,
  URL,
} from '../../../../../_constants';
import { useCustomSnackbar } from '../../../../../_hooks';

/**
 * Evaluation sheet send to PARP button.
 *
 * @returns {EvaluationSheetSendToParpButton}
 */
export function EvaluationSheetSendToParpButton() {
  const { evaluationSheetId } = useParams();
  const { handleShowFilteredModulesByExpert } = useEvaluationSheetsActionContext();
  const {
    reloadData, transformedData: {
      isApplicationLeadingExpert, expertList,
    },
  } = useEvaluationSheetsContext();
  const { handleCloseUserSession } = useEvaluationSheetsUserSessionContext();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const {
    handleSubmit, formState: {
      isValid, isSubmitting, isDirty,
    },
  } = useFormContext();
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const navigate = useNavigate();

  const onSubmit = async () => {
    const {
      statusSuccess, violations,
    } = await request.put(`${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}`, {
      status: EVALUATION_SHEET_STATUS.sentToParp,
    });

    if (statusSuccess) {
      successNotification('Arkusz został wysłany do PARP.');
      reloadData();
      handleCloseUserSession(false, null);
      navigate(URL.expert.expertPanels);

      return;
    }

    if (!violations) {
      errorNotification('Nie można odesłać formularza oceny PARP, gdyż nie wszyscy eksperci uzupełnili '
        + 'pole Uzasadnienie oceny we wszystkich kryteriach oceny wniosku.');

      return;
    }

    violations.length > 0 ? violations.map(({ message }) => errorNotification(message))
      : errorNotification('Nie udało się wysłać arkusza. Spróbuj ponownie.');
  };

  const onSendToParp = () => {
    handleShowFilteredModulesByExpert(false);
    /**
     * A timeout has been used here because the application lead expert can send the sheet
     * if all the required fields are completed.
     * For this reason, we need to change the filtering of the modules
     * and display all of them for the validation to work.
     */
    setTimeout(() => {
      handleSubmit(onSubmit)();
    }, [200]);
    setConfirmDialogOpen(false);
  };

  const isAllModulesRejectedOrAccepted = expertList
    .filter(({ refused }) => !refused)
    .filter(({ accepted }) => !accepted).length === 0;

  if (!isApplicationLeadingExpert) {
    return null;
  }

  return (
    <>
      <Button
        type="button"
        color="success"
        variant="contained"
        id="55h0DBa5t4rEzQd"
        disabled={isSubmitting || !isAllModulesRejectedOrAccepted || !isValid}
        onClick={() => (isDirty ? setConfirmDialogOpen(true) : onSendToParp())}
      >
        Wyślij do PARP
      </Button>
      <ConfirmDialog
        closeHandler={() => setConfirmDialogOpen(false)}
        open={confirmDialogOpen}
        confirmButtonHandler={onSendToParp}
        cancelButtonColor="primary"
        confirmButtonLabel="TAK"
        cancelButtonLabel="NIE"
      >
        Formularz zawiera niezapisane dane. Czy chcesz wysłać arkusz do PARP bez ich zapisania?
      </ConfirmDialog>
    </>
  );
}
