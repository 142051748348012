import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { useElementContext } from '../../../Context';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import {
  getCollectionFieldObject,
  getImplementers,
  addItem,
  removeItem,
} from '../../../_helpers';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import {
  MonetaryField,
  parsePastedText,
} from '../../MonetaryField';

/**
 * RegionalInvestmentAidGreening
 *
 * @returns {RegionalInvestmentAidGreening}
 */
export default function RegionalInvestmentAidGreening() {
  const {
    control, watch, getValues, setValue, resetField, reset,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const isRegionalValue = watch('regional_investment_aid_greening_is_regional');
  const isEnvironmentalProtectionValue = watch('regional_investment_aid_greening_is_environmental_protection');

  const {
    fields: investmentAidFields,
    append: investmentAidAppend,
    remove: investmentAidRemove,
  } = useFieldArray({
    control,
    name: 'regional_investment_aid_greening_investment_aid',
  });
  const investmentAidConfig = fieldsConfig.regional_investment_aid_greening_investment_aid;
  const investmentAidSubfields = investmentAidConfig?.fields || {};
  const investmentAidFieldObject = getCollectionFieldObject(investmentAidSubfields, investmentAidFields);

  const {
    maxRowNumber: investmentAidMaxRowNumber,
    minRowNumber: investmentAidMinRowNumber,
  } = investmentAidConfig || {};

  useEffect(() => {
    if (investmentAidFields.length === 0 && investmentAidMinRowNumber > 0 && isRegionalValue === BOOLEAN_VALUES.TRUE) {
      for (let i = 1; i <= investmentAidMinRowNumber; i++) {
        investmentAidAppend(getCollectionFieldObject(investmentAidSubfields, investmentAidFields, i));
      }
    }
  }, [investmentAidMaxRowNumber, investmentAidMinRowNumber, isRegionalValue]);

  const collectionFieldName = 'regional_investment_aid_greening_environmental_protection_aid_type';

  const {
    fields: environmentalProtectionAidTypeFields,
    append: environmentalProtectionAidTypeAppend,
    remove: environmentalProtectionAidTypeRemove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });

  const environmentalProtectionAidTypeConfig = fieldsConfig[collectionFieldName];
  const environmentalProtectionAidTypeSubfields = environmentalProtectionAidTypeConfig?.fields || {};
  const environmentalProtectionAidTypeFieldObject = getCollectionFieldObject(
    environmentalProtectionAidTypeSubfields,
    environmentalProtectionAidTypeFields,
  );

  const {
    maxRowNumber: environmentalProtectionAidTypeMaxRowNumber,
    minRowNumber: environmentalProtectionAidTypeMinRowNumber,
  } = environmentalProtectionAidTypeConfig || {};

  useEffect(() => {
    if (environmentalProtectionAidTypeFields.length === 0
      && environmentalProtectionAidTypeMinRowNumber > 0 && isEnvironmentalProtectionValue === BOOLEAN_VALUES.TRUE) {
      for (let i = 1; i <= environmentalProtectionAidTypeMinRowNumber; i++) {
        environmentalProtectionAidTypeAppend(getCollectionFieldObject(
          environmentalProtectionAidTypeSubfields,
          environmentalProtectionAidTypeFields,
          i,
        ));
      }
    }
  }, [
    environmentalProtectionAidTypeMaxRowNumber,
    environmentalProtectionAidTypeMinRowNumber,
    isEnvironmentalProtectionValue,
  ]);

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.initialInvestmentTypes,
    DICTIONARIES.investmentTypeGreenings,
  );

  const { initialInvestmentTypes } = ELEMENTS_DICTIONARIES_MAP;

  const additionalFieldsByIds = [
    initialInvestmentTypes.investmentInTangibleFixedAssets.id,
    initialInvestmentTypes.investmentInTangibleFixedAssetsAndValues.id,
  ];

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Czy moduł dotyczy regionalnej pomocy inwestycyjnej (art. 14 rozporządzenia Komisji (UE) nr 651/2014) */}
      <FormField name="regional_investment_aid_greening_is_regional">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                reset((formValues) => ({
                  ...formValues,
                  regional_investment_aid_greening_investment_aid: FIELD_TYPES_MAP.collection,
                  regional_investment_aid_greening__innovation_implementation_is_regional__description: FIELD_TYPES_MAP
                    .text,
                }));
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {(watch('regional_investment_aid_greening_is_regional') === BOOLEAN_VALUES.TRUE) && (
        <FormField name="regional_investment_aid_greening__innovation_implementation_is_regional__description">
          {({
            onChange, value, name, maxLength, onBlur, error,
          }) => (
            <TextField
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              defaultValue={value}
              variant="outlined"
              InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
              inputProps={{ maxLength }}
              disabled={isReadonly}
              multiline
              error={!!error}
              helperText={error?.message}
            />
          )}
        </FormField>
      )}
      <LabeledFieldRow
        hide={isRegionalValue === BOOLEAN_VALUES.FALSE}
        fieldConfig={investmentAidConfig}
        contextHelpLabel
        fullWidth
      >
        {investmentAidFields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              investmentAidFields,
              investmentAidMinRowNumber,
              investmentAidRemove,
              setValue,
              'regional_investment_aid_greening_investment_aid',
              index,
            )}
            buttonRemoveVisible={investmentAidFields.length > investmentAidMinRowNumber}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(investmentAidSubfields)}
            key={field.id}
            isIndexed
          >
            {/* Nazwa wnioskodawcy/ konsorcjanta */}
            <FormField name={`regional_investment_aid_greening_investment_aid.${index}.applicant_name`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={getImplementers(getValues)}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Rodzaj inwestycji początkowej */}
            <FormField name={`regional_investment_aid_greening_investment_aid.${index}.initial_investment_type`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={(event) => {
                    onChange(event);
                    if (event.target.value !== initialInvestmentTypes.investmentInTangibleFixedAssets.id) {
                      reset((formValues) => ({
                        ...formValues,
                        [`regional_investment_aid_greening_investment_aid.${index}.reused_assets`]: FIELD_TYPES_MAP.text,
                        [`regional_investment_aid_greening_investment_aid.${index}.reused_assets_book_value`]: FIELD_TYPES_MAP.text,
                      }));
                    }
                  }}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.initialInvestmentTypes.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Uzasadnienie */}
            <FormField name={`regional_investment_aid_greening_investment_aid.${index}.reason`}>
              {({
                name, onChange, value, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {additionalFieldsByIds.includes(watch(
              `regional_investment_aid_greening_investment_aid.${index}.initial_investment_type`
            )) && (
              <>
                {/* Ponownie wykorzystane aktywa */}
                <FormField name={`regional_investment_aid_greening_investment_aid.${index}.reused_assets`}>
                  {({
                    onChange, value, name, maxLength, onBlur, error,
                  }) => (
                    <TextField
                      multiline
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={value}
                      variant="outlined"
                      InputProps={{
                        endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} />,
                      }}
                      inputProps={{ maxLength }}
                      disabled={isReadonly}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </FormField>
                {/* Wartość księgowa ponownie wykorzystywanych aktywów, odnotowana w roku obrotowym */}
                <FormField name={`regional_investment_aid_greening_investment_aid.${index}.reused_assets_book_value`}>
                  {({
                    onChange, value, name, onBlur, error,
                  }) => (
                    <TextField
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={value}
                      variant="outlined"
                      disabled={isReadonly}
                      InputProps={{
                        inputComponent: MonetaryField,
                      }}
                      inputProps={{
                        allowNegative: false,
                        allowedDecimalSeparators: ['.', ','],
                      }}
                      onPaste={(event) => parsePastedText(event, onChange)}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </FormField>
              </>
            )}
          </CollectionFieldContainer>
        ))}
        {!isReadonly && investmentAidFields.length < investmentAidMaxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="czsyRPeaaWeotGy"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                investmentAidFields,
                investmentAidMaxRowNumber,
                investmentAidAppend,
                investmentAidFieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Czy moduł dotyczy pomocy na ochronę środowiska (sekcja 7 rozporządzenia Komisji (UE) nr 651/2014) */}
      <FormField name="regional_investment_aid_greening_is_environmental_protection">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(collectionFieldName, { defaultValue: FIELD_TYPES_MAP.collection });
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      <LabeledFieldRow
        hide={isEnvironmentalProtectionValue === BOOLEAN_VALUES.FALSE}
        fieldConfig={environmentalProtectionAidTypeConfig}
        contextHelpLabel
        fullWidth
      >
        {environmentalProtectionAidTypeFields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              environmentalProtectionAidTypeFields,
              environmentalProtectionAidTypeMinRowNumber,
              environmentalProtectionAidTypeRemove,
              setValue,
              collectionFieldName,
              index,
            )}
            buttonRemoveVisible={
              environmentalProtectionAidTypeFields.length > environmentalProtectionAidTypeMinRowNumber
            }
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(environmentalProtectionAidTypeSubfields)}
            key={field.id}
            isIndexed
          >
            {/* Nazwa wnioskodawcy/ konsorcjanta */}
            <FormField name={`${collectionFieldName}.${index}.applicant_name`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={getImplementers(getValues)}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Rodzaj inwestycji */}
            <FormField name={`${collectionFieldName}.${index}.investment_type_greening`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.investmentTypeGreenings.name))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Opis spełnienia warunków koniecznych do uzyskania wybranego rodzaju pomocy na ochronę środowiska */}
            <FormField name={`${collectionFieldName}.${index}.conditions_description`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}
        {!isReadonly && environmentalProtectionAidTypeFields.length < environmentalProtectionAidTypeMaxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="aMfjmVcSJob6Wzh"
              variant="outlined"
              color="secondary"
              onClick={() => addItem(
                environmentalProtectionAidTypeFields,
                environmentalProtectionAidTypeMaxRowNumber,
                environmentalProtectionAidTypeAppend,
                environmentalProtectionAidTypeFieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
