import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
} from '../../../_helpers';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';

/**
 * StartupApplicantInformation
 *
 * @returns {StartupApplicantInformation}
 */
export function StartupApplicantInformation() {
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const {
    watch, control, setValue, resetField,
  } = useFormContext();

  const {
    isLoaded, get,
  } = useDictionaryLoader(
    DICTIONARIES.startupApplicantInformationActivityFiscalYearsNumber,
    DICTIONARIES.startupApplicantInformationCompetitionLevel,
    DICTIONARIES.startupApplicantInformationHonorableMentionObject,
  );
  const collectionName = 'startup_applicant_information_acceleration_program';
  const startupCompetitionParticipation = 'startup_applicant_information_startup_competition_participation';

  const [
    accelerationProgramValue,
    accelerationStartupCompetitionHonorableMentionValue,
  ] = watch([
    'startup_applicant_information_acceleration_program_participation',
    'startup_applicant_information_acceleration_startup_competition_honorable_mention',
  ]);

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });

  const {
    fields: fieldsCompetitionParticipation,
    append: appendCompetitionParticipation,
    remove: removeCompetitionParticipation,
  } = useFieldArray({
    control,
    name: startupCompetitionParticipation,
  });

  const accelerationProgramConfig = fieldsConfig[collectionName];
  const subfields = accelerationProgramConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const competitionParticipationConfig = fieldsConfig[startupCompetitionParticipation];
  const competitionPartSubfields = competitionParticipationConfig?.fieldsCompetitionParticipation || {};
  const competitionPartFieldObject = getCollectionFieldObject(competitionPartSubfields, fieldsCompetitionParticipation);

  const {
    maxRowNumber,
    minRowNumber,
  } = accelerationProgramConfig || {};

  const {
    maxRowNumber: maxRowNumberCompetitionParticipation,
    minRowNumber: minRowNumberCompetitionParticipation,
  } = competitionParticipationConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0 && accelerationProgramValue === BOOLEAN_VALUES.TRUE) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber, accelerationProgramValue]);

  useEffect(() => {
    if (fieldsCompetitionParticipation.length === 0
      && minRowNumberCompetitionParticipation > 0
      && accelerationStartupCompetitionHonorableMentionValue !== BOOLEAN_VALUES.TRUE) {
      for (let i = 1; i <= minRowNumberCompetitionParticipation; i++) {
        appendCompetitionParticipation(
          getCollectionFieldObject(competitionPartSubfields, fieldsCompetitionParticipation, i)
        );
      }
    }
  }, [
    maxRowNumberCompetitionParticipation,
    minRowNumberCompetitionParticipation,
    accelerationStartupCompetitionHonorableMentionValue,
  ]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      {/* Liczba lat obrachunkowych (obrotowych) działalności */}
      <FormField name="startup_applicant_information_activity_fiscal_years_number">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            multiple
            options={filterOptions(get(DICTIONARIES.startupApplicantInformationActivityFiscalYearsNumber.name))}
            renderSelectAllButton={false}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {/* Udział Wnioskodawcy w programie akceleracyjnym  */}
      <FormField name="startup_applicant_information_acceleration_program_participation">
        {({
          onChange, value, name, onBlur, error, allChoices,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'startup_applicant_information_acceleration_program',
                  { defaultValue: FIELD_TYPES_MAP.collection }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {/* Tabela: Program Akceleracyjny */}
      <LabeledFieldRow
        hide={accelerationProgramValue === BOOLEAN_VALUES.FALSE}
        fieldConfig={accelerationProgramConfig}
        contextHelpLabel
        fullWidth
      >
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionName,
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Nazwa programu akceleracyjnego */}
            <FormField name={`${collectionName}.${index}.acceleration_program_name`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Podmiot prowadzący program akceleracyjny */}
            <FormField name={`${collectionName}.${index}.running_entity`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Daty udziału Wnioskodawcy w programie akceleracyjnym (data od) */}
            <FormField name={`${collectionName}.${index}.participation_start_date`}>
              {({
                onChange, value, name, label, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  ariaLabel={label}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Daty udziału Wnioskodawcy w programie akceleracyjnym (data do) */}
            <FormField name={`${collectionName}.${index}.participation_end_date`}>
              {({
                onChange, value, name, label, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  ariaLabel={label}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="c1JndXI9SYCjRT5"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>

      {/* Wyróżnienie w konkursie dla startupów  */}
      <FormField name="startup_applicant_information_acceleration_startup_competition_honorable_mention">
        {({
          onChange, value, name, onBlur, error, allChoices,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField(
                  'startup_applicant_information_startup_competition_participation',
                  { defaultValue: FIELD_TYPES_MAP.collection }
                );
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {/* Tabela: Udział w konkursie dla startupów */}
      <LabeledFieldRow
        hide={accelerationStartupCompetitionHonorableMentionValue === BOOLEAN_VALUES.FALSE}
        fieldConfig={competitionParticipationConfig}
        contextHelpLabel
        fullWidth
      >
        {fieldsCompetitionParticipation.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fieldsCompetitionParticipation,
              minRowNumber,
              removeCompetitionParticipation,
              setValue,
              startupCompetitionParticipation,
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(competitionPartSubfields)}
            buttonRemoveVisible={fieldsCompetitionParticipation.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Nazwa konkursu */}
            <FormField name={`${startupCompetitionParticipation}.${index}.competition_name`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Podmiot organizujący konkurs */}
            <FormField name={`${startupCompetitionParticipation}.${index}.competition_organizing_entity`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Poziom konkursu */}
            <FormField name={`${startupCompetitionParticipation}.${index}.competition_level`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.startupApplicantInformationCompetitionLevel.name))}
                  renderSelectAllButton={false}
                  error={error}
                  disabled={isReadonly}
                />
              )}
            </FormField>
            {/* Rodzaj (nazwa) wyróżnienia */}
            <FormField name={`${startupCompetitionParticipation}.${index}.honorable_mention_type`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Data otrzymania wyróżnienia */}
            <FormField name={`${startupCompetitionParticipation}.${index}.honorable_mention_received_date`}>
              {({
                onChange, value, name, label, onBlur, error,
              }) => (
                <CustomDesktopDatePicker
                  ariaLabel={label}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Co było przedmiotem wyróżnienia? */}
            <FormField name={`${startupCompetitionParticipation}.${index}.honorable_mention_object`}>
              {({
                onChange, value, name, filterOptions, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.startupApplicantInformationHonorableMentionObject.name))}
                  renderSelectAllButton={false}
                  error={error}
                  disabled={isReadonly}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fieldsCompetitionParticipation.length < maxRowNumberCompetitionParticipation && (
          <Grid item xs={12}>
            <Button
              id="EcTBqZlGg0LlypI"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fieldsCompetitionParticipation,
                maxRowNumberCompetitionParticipation,
                appendCompetitionParticipation,
                competitionPartFieldObject
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Czy Wnioskodawca jest startupem prowadzącym działalność gospodarczą na dzień składania Wniosku nie krócej
       niż przez jeden pełen rok obrachunkowy (obrotowy) i nie dłużej niż przez pięć pełnych lat obrachunkowych
       (obrotowych)? */}
      <FormField name="startup_applicant_information_is_applicant_business_activity_conducted_at_least_financial_year">
        {({
          onChange, value, name, onBlur, error, allChoices,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>

    </FieldSorter>
  );
}
