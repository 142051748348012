import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import { DICTIONARIES } from '../../../../_constants';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Project detailed information pdf element renderer.
 *
 * @returns {ProjectDetailedInformationPdf}
 */
function ProjectDetailedInformationPdf() {
  const fieldNamePrefix = 'project_detailed_information';

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Kategoria drogi */}
        <RowField name="road_category" isSingleRow />
        {/* Zakres wsparcia */}
        <RowField
          name="support_scope"
          dictionaryName={DICTIONARIES.supportScopes.name}
        />
        {/* Zachowanie jednolitych parametrów technicznych */}
        <RowField name="uniform_technical_parameters_maintenance" isSingleRow />
        {/* Podniesienie parametrów infrastruktury drogowej  */}
        <RowField name="road_infrastructure_upgrade" isSingleRow />
        {/* Podniesienie parametrów infrastruktury drogowej  */}
        <RowField name="urban_areas_impact" isSingleRow />
        {/* Wpływ na obszary miejskie  */}
        <RowField name="cyclist_safety_in_traffic_improving_elements" isSingleRow />
        {/* Elementy poprawiające bezpieczeństwo rowerzystów w ruchu drogowym  */}
        <RowField name="non_motorized_participants_safety_in_traffic_improving_elements" isSingleRow />
        {/* Wprowadzenie ruchu drogowego poza teren zabudowany  */}
        <RowField name="intorduction_of_traffic_outside_built_up_area" isSingleRow />
        {/* Dostosowanie drogi do wykonywania codziennych przewozów publicznego transportu zbiorowego  */}
        <RowField name="road_to_public_transport_adaptation" isSingleRow />

        {/* Kompleksowość projektu */}
        <RowField
          name={`${fieldNamePrefix}_project_complexity`}
          dictionaryName={DICTIONARIES.projectComplexities.name}
        />
        {/* Zgodność projektu z ideą inicjatywy Nowy Europejski Bauhaus (z ang. NEB)  */}
        <RowField
          name={`${fieldNamePrefix}_compatibility_with_idea_neb_initiative`}
          dictionaryName={DICTIONARIES.compatibilityWithIdeaNebInitiative.name}
        />
        {/* Uzasadnienie  */}
        <RowField name={`${fieldNamePrefix}_compatibility_with_idea_neb_initiative_justification`} isSingleRow />
        {/* Uwzględnienie infrastruktury odnawialnych źródeł energii (OZE) w projekcie  */}
        <RowField name={`${fieldNamePrefix}_take_into_account_renewable_energy_infrastructure`} />
        {/* Uzasadnienie  */}
        <RowField name={`${fieldNamePrefix}_take_into_account_renewable_energy_infrastructure_justification`} isSingleRow />
        {/* Tabela "Uwzględnienie rozwiązań dotyczących integracji usług przewozowych na obszarze MOF w projekcie" */}
        <CollectionWrapper name="project_detailed_information_taking_into_account_transport_services_in_mof_area">
          {({
            collectionData, collectionName,
          }) => collectionData.map((item, index) => (
            <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
              <View style={printStyle.table}>
                <RowField
                  name={`${collectionName}.${index}.taking_into_account_transport_services_in_mof_area`}
                  value={item.taking_into_account_transport_services_in_mof_area}
                  dictionaryName={DICTIONARIES.takeIntoAccountDoesNotTakeIntoAccount.name}
                />
                <RowField
                  name={`${collectionName}.${index}.taking_into_account_transport_services_in_mof_area_justification`}
                  value={item.taking_into_account_transport_services_in_mof_area_justification}
                  isSingleRow
                />
              </View>
            </SingleCollectionWrapper>
          ))}
        </CollectionWrapper>

        {/* Uwzględnienie koncepcji „Mobilność jako usługa” (z ang. MaaS) w projekcie */}
        <RowField name={`${fieldNamePrefix}_taking_into_account_concept_maas`} />
        {/* Uzasadnienie  */}
        <RowField name={`${fieldNamePrefix}_taking_into_account_concept_maas_justification`} isSingleRow />
        {/* Objęcie świadczoną usługą przewozów pasażerskich obszarów dotychczas nieobsługiwanych  */}
        <RowField name={`${fieldNamePrefix}_coverage_provided_passenger_service`} />
        {/* Uzasadnienie  */}
        <RowField name={`${fieldNamePrefix}_coverage_provided_passenger_service_justification`} isSingleRow />
        {/* Realizacja projektu na obszarze, na którym istnieje wydzielona strefa płatnego parkowania lub w
      ramach projektu planowane jest utworzenie strefy płatnego parkowania  */}
        <RowField
          name={`${fieldNamePrefix}_project_implementation_in_area_with_separated_paid_parking_zones`}
          dictionaryName={DICTIONARIES.projectImplementationInAreaWithSeparatedPaidParkingZone.name}
        />
        {/* Uzasadnienie  */}
        <RowField name={`${fieldNamePrefix}_project_implementation_in_area_with_separated_paid_parking_zones_justification`} isSingleRow />
        {/* Konsultacje społeczne w celu zwiększenia dostępności transportu publicznego i mobilności */}
        <RowField name={`${fieldNamePrefix}_public_consultations`} />
        {/* Uzasadnienie  */}
        <RowField name={`${fieldNamePrefix}_public_consultations_justification`} isSingleRow />

        {/* Potencjał do realizacji projektu  */}
        <RowField name={`${fieldNamePrefix}_project_implement_potential`} isSingleRow />
        {/* Zaangażowanie społeczne w projekt  */}
        <RowField name={`${fieldNamePrefix}_social_involvement_in_the_project`} isSingleRow />
        {/* Koncepcja produktu turystycznego  */}
        <RowField name={`${fieldNamePrefix}_tourism_product_concept`} isSingleRow />
        {/* Trwałość produktu turystycznego  */}
        <RowField name={`${fieldNamePrefix}_tourism_product_concept_durability`} isSingleRow />
        {/* Zgodność projektu z ideą inicjatywy Nowy Europejski Bauhaus (FEPW.05.01)  */}
        <RowField name={`${fieldNamePrefix}_project_conformity_with_the_idea`} isSingleRow />
        {/* Dostępność transportem zbiorowym  */}
        <RowField name={`${fieldNamePrefix}_public_transport_accessibility`} isSingleRow />
        {/* Zdolność do funkcjonowania oferty turystycznej w ciągu roku  */}
        <RowField name={`${fieldNamePrefix}_the_ability_of_the_tourist_offer_to_function`} isSingleRow />
        {/* Wpływ produktu turystycznego na gospodarkę lokalną (jako element turystyki zrównoważonej)  */}
        <RowField name={`${fieldNamePrefix}_tourism_product_impact_on_the_local_economy`} isSingleRow />
        {/* Korzyści dla społeczności lokalnej (jako element turystyki zrównoważonej) */}
        <RowField name={`${fieldNamePrefix}_local_community_benefits`} isSingleRow />
        {/* Potencjał rynkowy produktu turystycznego */}
        <RowField name={`${fieldNamePrefix}_tourism_product_market_potential`} isSingleRow />
        {/* Unikalność produktu turystycznego */}
        <RowField name={`${fieldNamePrefix}_tourism_product_uniqueness`} isSingleRow />
        {/* Poziom digitalizacji produktu turystycznego */}
        <RowField name={`${fieldNamePrefix}_tourism_product_digitalization_level`} isSingleRow />
        {/* Plan marketingowy */}
        <RowField name={`${fieldNamePrefix}_marketing_plan`} isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ProjectDetailedInformationPdf;
