import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { DICTIONARIES } from '../../../_constants';
import { FormField } from '../../Application/Application/FormField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';

/**
 * GrantProjectImplementationTeam element.
 *
 * @returns {GrantProjectImplementationTeam}
 */
export function GrantProjectImplementationTeam() {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const {
    control, setValue,
  } = useFormContext();

  const applicantExperience = 'grant_project_implementation_team_grant_project_operator_applicant_experience';
  const collectionName = 'grant_project_implementation_team_grant_project_2_key_members_implementation_team_experience';

  const {
    fields: applicantExperienceFields, append: applicantExperienceAppend, remove: applicantExperienceRemove,
  } = useFieldArray({
    control,
    name: applicantExperience,
  });

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.responsibilityAreas,
  );

  const applicantExperienceConfig = fieldsConfig[applicantExperience];
  const applicantExperienceSubfields = applicantExperienceConfig?.fields || {};
  const applicantExperienceFieldObject = getCollectionFieldObject(
    applicantExperienceSubfields,
    applicantExperienceFields,
  );

  const membersTeamExperienceConfig = fieldsConfig[collectionName];
  const subfields = membersTeamExperienceConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber: applicantExperienceMaxRowNumber,
    minRowNumber: applicantExperienceMinRowNumber,
  } = applicantExperienceConfig || {};

  const {
    maxRowNumber,
    minRowNumber,
  } = membersTeamExperienceConfig || {};

  useEffect(() => {
    if (applicantExperienceFields.length === 0 && applicantExperienceMinRowNumber > 0) {
      for (let i = 1; i <= applicantExperienceMinRowNumber; i++) {
        applicantExperienceAppend(getCollectionFieldObject(applicantExperienceSubfields, applicantExperienceFields, i));
      }
    }
  }, [applicantExperienceMaxRowNumber, applicantExperienceMinRowNumber]);

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <>
      {/* Tabela: Doświadczenie Wnioskodawcy w roli operatora projektu grantowego lub
      tożsamego z projektem grantowym */}
      <LabeledFieldRow fieldConfig={applicantExperienceConfig} contextHelpLabel fullWidth>
        {applicantExperienceFields.map((item, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              applicantExperienceFields,
              applicantExperienceMinRowNumber,
              applicantExperienceRemove,
              setValue,
              applicantExperience,
              index,
            )}
            buttonRemoveVisible={applicantExperienceFields.length > applicantExperienceMinRowNumber}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(applicantExperienceSubfields)}
            key={item.id}
            isIndexed
          >
            {/* Doświadczenie w realizacji projektu grantowego */}
            <FormField name={`${applicantExperience}.${index}.implementing_grant_project_experience`}>
              {({
                onChange, value, name, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  renderSelectAllButton={false}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Doświadczenie w realizacji projektu tożsamego z projektem grantowym   */}
            <FormField name={`${applicantExperience}.${index}.implementing_project_identical_to_grant_project_experience`}>
              {({
                onChange, value, name, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  renderSelectAllButton={false}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Nazwa programu */}
            <FormField name={`${applicantExperience}.${index}.program_name`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* Tytuł projektu */}
            <FormField name={`${applicantExperience}.${index}.project_title`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* etykieta: Okres realizacji projektu   */}
            <FormField name={`${applicantExperience}.${index}.project_implementation_period`} contextHelpLabel>
              {() => null}
            </FormField>
            {/* Od */}
            <FormField name={`${applicantExperience}.${index}.start_date`}>
              {({
                name, onChange, value, error, onBlur, label,
              }) => (
                <CustomDesktopDatePicker
                  ariaLabel={label}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Do */}
            <FormField name={`${applicantExperience}.${index}.finish_date`}>
              {({
                name, onChange, value, error, onBlur, label,
              }) => (
                <CustomDesktopDatePicker
                  ariaLabel={label}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Budżet projektu (PLN) i źródło finansowania */}
            <FormField name={`${applicantExperience}.${index}.project_budget`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* Podmioty zaangażowane w realizację projektu */}
            <FormField name={`${applicantExperience}.${index}.project_implementation_entities_involved`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* Szczegółowy opis doświadczenia Wnioskodawcy w roli operatora (lub lidera) projektu grantowego
             lub tożsamego z projektem grantowym */}
            <FormField name={`${applicantExperience}.${index}.grant_project_operator_applicant_experience_detailed_description`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && applicantExperienceFields.length < applicantExperienceMaxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="2EKipFuMzoaAgjP"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                applicantExperienceFields,
                applicantExperienceMaxRowNumber,
                applicantExperienceAppend,
                applicantExperienceFieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Struktura organizacyjna zespołu wdrażającego projekt grantowy */}
      <FormField name="grant_project_implementation_team_implementing_grant_project_organizational_structure">
        {({
          name, onChange, value, maxLength, error, onBlur,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            variant="outlined"
            multiline
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
            onBlur={onBlur}
          />
        )}
      </FormField>
      {/* Tabela: Doświadczenie i kompetencje 2 kluczowych członków zespołu wdrażającego projekt grantowy */}
      <LabeledFieldRow
        fieldConfig={membersTeamExperienceConfig}
        contextHelpLabel
        fullWidth
      >
        {fields.map((item, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionName,
              index,
            )}
            buttonRemoveVisible={fields.length > minRowNumber}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            key={item.id}
            isIndexed
          >
            {/* Imię i nazwisko */}
            <FormField name={`${collectionName}.${index}.first_and_last_name`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* Wykształcenie */}
            <FormField name={`${collectionName}.${index}.education`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* Nazwa stanowiska/funkcji */}
            <FormField name={`${collectionName}.${index}.position_name`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* Obszar odpowiedzialności */}
            <FormField name={`${collectionName}.${index}.responsibility_area`}>
              {({
                name, onChange, value, error, onBlur, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.responsibilityAreas.name))}
                  renderSelectAllButton={false}
                  multiple
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Zakres obowiązków w projekcie */}
            <FormField name={`${collectionName}.${index}.responsibilities_scope_in_the_project`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            {/* Doświadczenie członka zespołu  w projektach grantowych lub tożsamych z grantowym */}
            <FormField name={`${collectionName}.${index}.grant_projects_team_member_experience`}>
              {({
                onChange, value, name, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  renderSelectAllButton={false}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Opis doświadczenia zawodowego związanego z pełnioną funkcją w projekcie */}
            <FormField name={`${collectionName}.${index}.grant_projects_team_member_experience_description`}>
              {({
                name, onChange, value, maxLength, error, onBlur,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  variant="outlined"
                  multiline
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={onBlur}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="sLonoDSCAJigt21"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </>
  );
}
