// eslint-disable-next-line import/no-cycle
import {
  NotBeforeSubmissionDate,
  Required,
  DateRange,
  MaxLengthAuto,
  StringLength,
  NumberValue,
  CollectionRows,
  inEasternPoland,
  RegExp,
  ProjectMonthsNumber,
} from '../Features/ApplicationTemplate/Edit/Validations';

export const RULE_COMPONENTS = {
  not_before_submission_date: NotBeforeSubmissionDate,
  required: Required,
  date_after: DateRange,
  date_before: DateRange,
  max_length_auto: MaxLengthAuto,
  min_length: StringLength,
  max_length: StringLength,
  min_number: NumberValue,
  max_number: NumberValue,
  min_row_number: CollectionRows,
  max_row_number: CollectionRows,
  district_in_eastern_poland: inEasternPoland,
  regexp_match: RegExp,
  no_regexp_match: RegExp,
  min_project_months_number: ProjectMonthsNumber,
  max_project_months_number: ProjectMonthsNumber,
};
