import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import {
  DICTIONARIES,
  BOOLEAN_VALUES,
} from '../../../../_constants';
import { useApplicationToPdfContext } from '../../../../Context';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import { mapPkdCodes } from '../../../../_helpers';

/**
 * EcosystemPartners Pdf element.
 *
 * @returns {EcosystemPartnersPdf}
 */
function EcosystemPartnersPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    }, getDictionary,
  } = useApplicationToPdfContext();

  const ecosystemPartnersIsEcosystemPartners = initialFormData?.ecosystem_partners_is_ecosystem_partners
  === BOOLEAN_VALUES.TRUE;

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="ecosystem_partners_is_ecosystem_partners" />

        {ecosystemPartnersIsEcosystemPartners && (
          <CollectionWrapper name="ecosystem_partners_ecosystem_partners">
            {({
              collectionName, collectionData, collectionLabel, previousCollectionData, getIndexForPreviousRowData,
            }) => collectionData.map((item, index) => {
              const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

              return (
                <SingleCollectionWrapper
                  key={item.set_uuid}
                  label={collectionLabel}
                  index={index}
                  rowStatus={item?.status}
                >
                  <View style={printStyle.table}>
                    <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                      <RowField
                        name={`${collectionName}.${index}.partner_type`}
                        value={item.partner_type}
                        oldValue={previousRowData?.partner_type}
                        dictionaryName={DICTIONARIES.ecosystemPartnerType.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.name`}
                        value={item.name}
                        oldValue={previousRowData?.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.start_date`}
                        value={item.start_date}
                        oldValue={previousRowData?.start_date}
                      />
                      <RowField
                        name={`${collectionName}.${index}.legal_form`}
                        value={item.legal_form}
                        oldValue={previousRowData?.legal_form}
                        dictionaryName={DICTIONARIES.legalEntityTypes.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.property_form`}
                        value={item.property_form}
                        oldValue={previousRowData?.property_form}
                        dictionaryName={DICTIONARIES.propertyForms.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.company_size`}
                        value={item.company_size}
                        oldValue={previousRowData?.company_size}
                        dictionaryName={DICTIONARIES.companySize.name}
                      />
                      <RowField
                        name={`${collectionName}.${index}.nip`}
                        value={item.nip}
                        oldValue={previousRowData?.nip}
                      />
                      <RowField
                        name={`${collectionName}.${index}.regon`}
                        value={item.regon}
                        oldValue={previousRowData?.regon}
                      />
                      <RowField
                        name={`${collectionName}.${index}.krs`}
                        value={item.krs}
                        oldValue={previousRowData?.krs}
                      />
                      <RowField
                        name={`${collectionName}.${index}.pkd`}
                        value={item.pkd}
                        oldValue={previousRowData?.pkd}
                        externalDictionary={mapPkdCodes(getDictionary)}
                      />
                      <RowField
                        name={`${collectionName}.${index}.activities_description`}
                        value={item.activities_description}
                        oldValue={previousRowData?.activities_description}
                      />
                      <RowField
                        name={`${collectionName}.${index}.offer_scope`}
                        value={item.offer_scope}
                        oldValue={previousRowData?.offer_scope}
                      />
                      <RowField
                        name={`${collectionName}.${index}.implemented_project_description`}
                        value={item.implemented_project_description}
                        oldValue={previousRowData?.implemented_project_description}
                      />
                      <RowField
                        name={`${collectionName}.${index}.partner_experience`}
                        value={item.partner_experience}
                        oldValue={previousRowData?.partner_experience}
                      />
                    </FieldSorter>
                  </View>
                </SingleCollectionWrapper>
              );
            })}
          </CollectionWrapper>
        )}
      </FieldSorter>
    </View>
  );
}

export default EcosystemPartnersPdf;
