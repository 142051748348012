import { Table } from 'docx';
import { printoutToDocxHelper } from '../../../_helpers';

/**
 * Printout to docx table
 *
 * @param {object} props - root props
 * @param {number} props.columns - number of columns
 * @param {Array} props.rows - rows
 * @returns {printoutToDocxTable}
 */
export const printoutToDocxTable = ({
  columns, rows,
}) => new Table({
  columnWidths: Array(columns).fill(printoutToDocxHelper.calculateColumnSize(columns)),
  rows,
});
