import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ClusterData pdf element.
 *
 * @returns {ClusterDataPdf}
 */
function ClusterDataPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        <RowField name="cluster_data_name" />
        <RowField name="cluster_data_type" dictionaryName={DICTIONARIES.clusterTypes.name} />
        <RowField name="cluster_data_www" />
        <RowField name="cluster_data_members_number" />
        <RowField name="cluster_data_enterprises_number" />
        <RowField name="cluster_data_enterprises_number_with_sme_status" />
        <RowField name="cluster_data_cluster_members_percentage_with_sme_status" />
        <RowField name="cluster_data_current_cluster_situation_description" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ClusterDataPdf;
