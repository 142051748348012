import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import uniqid from 'uniqid';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useCustomSnackbar } from '../../../../../_hooks';
import { useEvaluationItemContext } from '../../../../../Context';
import { request } from '../../../../../_services';
import { API_ROUTE } from '../../../../../_constants';
import { iriToId } from '../../../../../_helpers';

/**
 * Evaluation sheet remove question.
 *
 * @param {object} props - root props
 * @param {object} props.question - question data
 * @param {number} props.questionIndex - question index
 * @returns {EvaluationSheetRemoveQuestion}
 */
export function EvaluationSheetRemoveQuestion({
  question, questionIndex,
}) {
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const { evaluationItem } = useEvaluationItemContext();
  const evaluationItemId = evaluationItem.id;
  const collectionName = `${evaluationItemId}.questions`;
  const { control } = useFormContext();

  const { remove } = useFieldArray({
    control,
    name: collectionName,
  });
  const removeQuestion = async (questionId) => {
    const { statusSuccess } = await request.delete(`${API_ROUTE.evaluationQuestions}/${questionId}`);

    statusSuccess
      ? successNotification('Pytanie zostało skasowane.')
      : errorNotification('Wystąpił problem podczas usuwania pytania. Spróbuj ponownie.');

    return statusSuccess;
  };

  const removeItem = async (questionIri, index) => {
    if (questionIri) {
      const isQuestionRemoved = await removeQuestion(iriToId(questionIri));

      if (isQuestionRemoved) {
        remove(index);
      }

      return;
    }

    remove(index);
  };

  return (
    <Button
      type="button"
      id={uniqid()}
      color="primary"
      variant="contained"
      onClick={() => removeItem(question?.['@id'], questionIndex)}
    >
      Usuń
    </Button>
  );
}

EvaluationSheetRemoveQuestion.propTypes = {
  question: PropTypes.instanceOf(Object).isRequired,
  questionIndex: PropTypes.number.isRequired,
};
