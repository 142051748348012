import {
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import CharsCounter from '../../../../CharsCounter';
import { CustomAutocomplete } from '../../../../CustomAutocomplete/CustomAutocomplete';
import { yesNoValues } from '../../../../../_optionsCustomAutocomplete';
import {
  useEvaluationItemContext,
  useEvaluationSheetsActionContext,
  useEvaluationSheetsContext,
} from '../../../../../Context';
import { EvaluationSheetRemoveQuestion } from './EvaluationSheetRemoveQuestion';

/**
 * Evaluation sheet single question form.
 *
 * @param {object} props - root props
 * @param {boolean} props.isQuestionAuthor - is question author
 * @param {object} props.question - question data
 * @param {number} props.questionIndex - question index
 * @returns {EvaluationSheetSingleQuestionForm}
 */
export function EvaluationSheetSingleQuestionForm({
  isQuestionAuthor, question, questionIndex,
}) {
  const { showFilteredModulesByExpert } = useEvaluationSheetsActionContext();
  const {
    transformedData: { isEmployeeOrAdmin }, isReadonly,
  } = useEvaluationSheetsContext();
  const {
    userPermissions, evaluationItem,
  } = useEvaluationItemContext();
  const evaluationItemId = evaluationItem.id;
  const collectionName = `${evaluationItemId}.questions`;
  const {
    control, getValues,
  } = useFormContext();

  return (
    <>
      <Grid item xs={isEmployeeOrAdmin ? 7 : 10}>
        <FormControl fullWidth>
          <Controller
            rules={{
              required: {
                value: (!showFilteredModulesByExpert || userPermissions.questions),
                message: 'Proszę uzupełnić pole "Pytanie do wnioskodawcy w ramach uzupełnień wniosku"',
              },
            }}
            name={`${collectionName}.${questionIndex}.question`}
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                error={!!error}
                helperText={error?.message || `Pytanie dodane przez: ${getValues(`${collectionName}.${questionIndex}.createdBy.fullName`) || '-'}`}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                label="Pytanie do wnioskodawcy w ramach uzupełnień wniosku"
                onBlur={onBlur}
                required
                InputProps={{
                  endAdornment: (
                    <CharsCounter valueLength={value.length} maxLength={5000} />
                  ),
                }}
                inputProps={{ maxLength: 5000 }}
                disabled={!isQuestionAuthor || isReadonly}
                multiline
              />
            )}
          />
        </FormControl>
      </Grid>
      {(isEmployeeOrAdmin && !isReadonly) && (
        <Grid item xs={3}>
          <FormControl fullWidth>
            <Controller
              name={`${collectionName}.${questionIndex}.sendToApplicant`}
              control={control}
              render={({
                field: {
                  onChange, value, name, onBlur,
                },
                fieldState: {
                  error,
                },
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  textFieldProps={{
                    label: 'Czy wysłać do wnioskodawcy?',
                  }}
                  options={yesNoValues}
                  optionsMapKeys={['id', 'label']}
                  error={error}
                />
              )}
            />
          </FormControl>
        </Grid>
      )}
      {!isReadonly && isQuestionAuthor && (
        <Grid item xs={2}>
          <EvaluationSheetRemoveQuestion questionIndex={questionIndex} question={question} />
        </Grid>
      )}
    </>
  );
}

EvaluationSheetSingleQuestionForm.propTypes = {
  isQuestionAuthor: PropTypes.bool.isRequired,
  question: PropTypes.instanceOf(Object).isRequired,
  questionIndex: PropTypes.number.isRequired,
};
