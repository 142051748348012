import {
  AlignmentType,
  TableCell,
  WidthType,
} from 'docx';
import {
  green,
  red,
} from '@mui/material/colors';
import { theme } from '../../../styles/theme';
import { printoutToDocxText } from './printoutToDocxText';
import { printoutToDocxHelper } from '../../../_helpers';

/**
 * Printout to docx text.
 *
 * @param {object} props - root props
 * @param {string} props.text - text
 * @param {string} props.bgColor - background color
 * @param {number} props.numberOfColumns - number of columns
 * @param {number} props.colSpan - column merge
 * @param {boolean} props.bold - bold
 * @returns {printoutToDocxTableCell}
 */
export const printoutToDocxTableCell = ({
  text,
  bgColor = 'white',
  numberOfColumns,
  colSpan,
  bold = false,
}) => {
  const BG_COLORS = {
    white: theme.palette.background.default,
    gray: theme.palette.brandGray20.main,
    dkGray: theme.palette.brandGray40.main,
    added: green[200],
    removed: red[200],
  };

  return new TableCell({
    shading: { fill: BG_COLORS[bgColor] },
    children: [printoutToDocxText({
      text,
      size: 12,
      bold,
    })],
    columnSpan: colSpan,
    margins: {
      marginUnitType: WidthType.DXA,
      top: 150,
      right: 100,
      bottom: 150,
      left: 100,
    },
    verticalAlign: AlignmentType.CENTER,
    width: {
      size: printoutToDocxHelper.calculateColumnSize(numberOfColumns),
      type: WidthType.DXA,
    },
  });
};
