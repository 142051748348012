import {
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import CharsCounter from '../../../CharsCounter';
import {
  useEvaluationItemContext,
  useEvaluationSheetsActionContext,
} from '../../../../Context';
import { EvaluationSheetEvaluationTypeField } from './EvaluationSheetEvaluationTypeField';

/**
 * Evaluation sheet general fields.
 *
 * @returns {EvaluationSheetGeneralFields}
 */
export function EvaluationSheetGeneralFields() {
  const { control } = useFormContext();
  const { showFilteredModulesByExpert } = useEvaluationSheetsActionContext();
  const {
    evaluationItem, userPermissions,
  } = useEvaluationItemContext();
  const evaluationItemId = evaluationItem.id;

  return (
    <>
      <Grid item xs={12} md={4} lg={2}>
        <EvaluationSheetEvaluationTypeField />
      </Grid>
      <Grid item xs={12} md={8} lg={10}>
        <FormControl fullWidth>
          <Controller
            rules={{
              required: {
                value: (!showFilteredModulesByExpert || userPermissions.finalJustification),
                message: 'Proszę uzupełnić pole "Ostateczne uzasadnienie oceny"',
              },
            }}
            name={`${evaluationItemId}.finalJustification`}
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                error={!!error}
                helperText={error?.message}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                label="Ostateczne uzasadnienie oceny"
                onBlur={onBlur}
                disabled={!userPermissions.finalJustification}
                multiline
                minRows={1}
                required={userPermissions.finalJustification}
                InputProps={{
                  endAdornment: (
                    <CharsCounter valueLength={value?.length || 0} maxLength={20000} />
                  ),
                }}
                inputProps={{ maxLength: 20000 }}
              />
            )}
          />
        </FormControl>
      </Grid>
    </>
  );
}
