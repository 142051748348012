import { BlobProvider } from '@react-pdf/renderer';
import { useState } from 'react';
import {
  MenuItem,
  Button,
} from '@mui/material';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import PrintoutOpener from '../../../PrintToPdf/PrintApplication/PrintoutOpener';
import { useCustomSnackbar } from '../../../../_hooks';
import { request } from '../../../../_services';
import { API_ROUTE } from '../../../../_constants';
import { getTransformedSupplementCall } from '../../../../_helpers';
import PrintoutSupplementCallTemplate from './PrintoutSupplementCallTemplate';
import { PrintingInProgressBackdrop } from '../../../PrintingInProgressBackdrop/PrintingInProgressBackdrop';

/**
 * Printout supplement call.
 *
 * @param {object} props - root props
 * @param {string} props.supplementCallId - supplement call id
 * @param {string} props.applicationNumber - application number
 * @param {string} props.sentToApplicantDate - sent to applicant date
 * @param {string} props.variant - varian item from menu
 * @returns {PrintoutSupplementCall}
 */
function PrintoutSupplementCall({
  supplementCallId, applicationNumber, sentToApplicantDate, variant,
}) {
  const [isLoading, setLoading] = useState(false);
  const [supplementCallData, setSupplementCallData] = useState(null);
  const { errorNotification } = useCustomSnackbar();

  const getSupplementCall = async (modules) => {
    const {
      payload, statusSuccess,
    } = await request.get(`${API_ROUTE.supplementCalls}/${supplementCallId}`);

    if (!statusSuccess) {
      errorNotification('Nie udało się wczytań danych wezwania. Spróbuj ponownie.');

      return;
    }

    setSupplementCallData(getTransformedSupplementCall(modules, payload));
    setLoading(true);
  };

  const getModules = async () => {
    const {
      payload, statusSuccess,
    } = await request.get(API_ROUTE.modules);

    if (statusSuccess) {
      getSupplementCall(payload);

      return;
    }

    errorNotification('Nie udało się wczytań danych wezwania. Spróbuj ponownie.');
  };
  const getBackdropComponent = () => (
    <PrintingInProgressBackdrop
      isLoading={isLoading}
      content="Proszę czekać... Trwa generowanie wydruku wezwania do uzupełnień."
    />
  );

  const getItem = () => {
    if (variant === 'button') {
      return (
        <Button
          type="button"
          color="secondary"
          variant="outlined"
          id={uniqid()}
          onClick={getModules}
        >
          {isLoading ? 'Generowanie PDF...' : 'Drukuj wezwanie'}
        </Button>
      );
    }

    return (
      <MenuItem
        key={uniqid()}
        variant="right-border"
        id={uniqid()}
        onClick={getModules}
      >
        {isLoading ? 'Generowanie PDF...' : 'Drukuj wezwanie'}
      </MenuItem>
    );
  };

  if (!supplementCallData) {
    return (
      <>
        {getItem()}
        {isLoading && getBackdropComponent()}
      </>
    );
  }

  return (
    <BlobProvider document={(
      <PrintoutSupplementCallTemplate
        supplementCallData={supplementCallData}
        applicationNumber={applicationNumber}
        sentToApplicantDate={sentToApplicantDate}
      />
    )}
    >
      {({
        url, loading, error,
      }) => {
        if (url && !loading && !error) {
          setLoading(loading);
          setSupplementCallData(null);
        }

        if (!url && loading) {
          getBackdropComponent();
        }

        return (
          <PrintoutOpener
            loading={loading}
            error={error}
            url={url}
            backdrop={getBackdropComponent}
          >
            {getItem()}
          </PrintoutOpener>
        );
      }}
    </BlobProvider>
  );
}

export default PrintoutSupplementCall;

PrintoutSupplementCall.propTypes = {
  supplementCallId: PropTypes.string.isRequired,
  applicationNumber: PropTypes.string.isRequired,
  sentToApplicantDate: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['button ', 'menuItem ']),
};

PrintoutSupplementCall.defaultProps = {
  variant: 'menuItem',
};
