import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  useEffect,
  useMemo,
} from 'react';
import {
  useEvaluationSheetsContext,
  useEvaluationSheetsUserSessionContext,
} from '../../../../../Context';
import { useCustomSnackbar } from '../../../../../_hooks';
import { request } from '../../../../../_services';
import {
  API_ROUTE,
  EVALUATION_SHEET_STATUS,
} from '../../../../../_constants';
import { iriToId } from '../../../../../_helpers';
import { useAuth } from '../../../../../_security';

/**
 * Evaluation sheet approval button.
 *
 * @returns {EvaluationSheetApprovalButton}
 */
export function EvaluationSheetApprovalButton() {
  const { evaluationSheetId } = useParams();
  const {
    reloadData, transformedData: {
      expertList, isEmployeeOrAdmin,
    }, isReadonly, evaluationSheet: { status: evaluationSheetStatus },
  } = useEvaluationSheetsContext();
  const { handleCloseUserSession } = useEvaluationSheetsUserSessionContext();
  const { id: loggedUserId } = useAuth();
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const {
    handleSubmit, formState: {
      isSubmitting, isValid, errors,
    },
  } = useFormContext();

  const isEvaluationSheetApprovedByExpert = useMemo(() => {
    let expertApprovedEvaluationSheet = false;

    expertList.forEach((expert) => {
      const isExpertACurrentUser = iriToId(expert.externalExpert?.user['@id']) === loggedUserId;
      expertApprovedEvaluationSheet = expertApprovedEvaluationSheet
        || (isExpertACurrentUser && expert.evaluationSheetApprovalDate !== null);
    });

    return expertApprovedEvaluationSheet;
  }, [expertList]);

  const onSubmit = async () => {
    const {
      statusSuccess,
    } = await request.get(
      `${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}/approved`
    );

    if (statusSuccess) {
      successNotification('Arkusz został zatwierdzony.');
      reloadData();
      handleCloseUserSession(true, null);

      return;
    }

    errorNotification('Nie udało się zatwierdzić arkusza. Spróbuj ponownie.');
  };

  useEffect(() => {
    if (!isValid && Object.values(errors).length !== 0) {
      errorNotification('Arkusz zawiera nieuzupełnione dane. Prosimy uzupełnić wymagane pola.');
    }
  }, [isValid, errors]);

  if (isEmployeeOrAdmin || (!isReadonly && isEvaluationSheetApprovedByExpert)) {
    return null;
  }

  return (
    <Button
      type="button"
      color="success"
      variant="contained"
      id="R6AzWtMVL57Q8Gk"
      disabled={isSubmitting || !isValid || evaluationSheetStatus !== EVALUATION_SHEET_STATUS.sentToApprove}
      onClick={handleSubmit(onSubmit)}
    >
      Zatwierdź ocenę
    </Button>
  );
}
