import {
  Text,
  View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { printStyle } from '../print.style';
import PageWrapper from '../Components/PageWrapper/PageWrapper';
import SingleElement from '../Components/SingleElement/SingleElement';
import PrintoutTableRow from '../Components/Table/PrintoutTableRow';
import PrintoutTableCell from '../Components/Table/PrintoutTableCell';

/**
 * PrintoutAssessmentSheetWithoutModules element.
 *
 * @param {object} props - root props
 * @param {object} props.application - application data
 * @param {Array} props.criteriaItems - criteria items
 * @returns {PrintoutAssessmentSheetWithoutModules}
 */
function PrintoutAssessmentSheetWithoutModules({
  application, criteriaItems,
}) {
  return (
    <PageWrapper title="Arkusz oceny projektu" hideWatermark>
      <View style={{
        marginBottom: 10,
        flexDirection: 'row',
      }}
      >
        <Text style={{
          ...printStyle.sectionTitle,
          marginTop: 0,
          marginBottom: 0,
          marginRight: 5,
        }}
        >
          Numer wniosku o dofinansowanie:
        </Text>
        <Text style={{ fontSize: 14 }}>{application.applicationNumber}</Text>
      </View>
      <View style={{
        marginBottom: 15,
        flexDirection: 'row',
      }}
      >
        <Text style={{
          ...printStyle.sectionTitle,
          marginTop: 0,
          marginBottom: 0,
          marginRight: 5,
          maxWidth: '35%',
        }}
        >
          Nazwa wnioskodawcy:
        </Text>
        <Text style={{
          fontSize: 14,
          maxWidth: '65%',
        }}
        >
          {application.applicantName}
        </Text>
      </View>
      <SingleElement>
        <View style={printStyle.table}>
          <PrintoutTableRow>
            <PrintoutTableCell bgColor="dkGray" isTextBold customWidth="80%">
              Kryteria wyboru projektu
            </PrintoutTableCell>
            <PrintoutTableCell bgColor="dkGray" isTextBold customWidth="20%">
              Przyznana liczba punktów
            </PrintoutTableCell>
          </PrintoutTableRow>
          {criteriaItems.map(({
            criteriaItem, points, finalJustification,
          }) => (
            <>
              <PrintoutTableRow>
                <PrintoutTableCell bgColor="gray" rowSpan={1.25}>
                  {criteriaItem.name}
                </PrintoutTableCell>
                <PrintoutTableCell bgColor="gray" rowSpan={5}>
                  {points?.toString()}
                </PrintoutTableCell>
              </PrintoutTableRow>
              <PrintoutTableRow>
                <PrintoutTableCell customWidth="99.6%">
                  {finalJustification}
                </PrintoutTableCell>
              </PrintoutTableRow>
            </>
          ))}
        </View>
      </SingleElement>
    </PageWrapper>
  );
}

export default PrintoutAssessmentSheetWithoutModules;

PrintoutAssessmentSheetWithoutModules.propTypes = {
  application: PropTypes.instanceOf(Object).isRequired,
  criteriaItems: PropTypes.arrayOf(Object).isRequired,
};
