import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';

/**
 * AccelerationForeignMarkets pdf element.
 *
 * @returns {AccelerationForeignMarketsPdf}
 */
function AccelerationForeignMarketsPdf() {
  return (
    <CollectionWrapper name="acceleration_foreign_markets">
      {({
        collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
      }) => collectionData.map((item, index) => {
        const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

        return (
          <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
            <View style={printStyle.table}>
              <RowField
                name={`${collectionName}.${index}.foreign_market_name`}
                value={item.foreign_market_name}
                dictionaryName={DICTIONARIES.countries.name}
                oldValue={previousRowData?.foreign_market_name}
              />
              <RowField
                name={`${collectionName}.${index}.justification`}
                value={item.justification}
                oldValue={previousRowData?.justification}
                isSingleRow
              />
            </View>
          </SingleCollectionWrapper>
        );
      })}
    </CollectionWrapper>
  );
}

export default AccelerationForeignMarketsPdf;
