import PropTypes from 'prop-types';
import ExpandableText from './ExpandableText';

/**
 * Onclick expandable span text element with uuid.
 *
 * @param {object} props - props
 * @param {string} props.uuid - uuid string
 * @returns {ExpandableUuid} - component
 */
export default function ExpandableUuid({ uuid }) {
  return <ExpandableText shortText={uuid.slice(0, 8)} fullText={uuid} />;
}

ExpandableUuid.propTypes = {
  uuid: PropTypes.string.isRequired,
};
