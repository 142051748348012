import { printoutToDocxTableCell } from './printoutToDocxTableCell';

/**
 * Printout to docx label cell
 *
 * @param {object} props - root props
 * @param {Array} props.templateFields - template fields
 * @param {string} props.fieldName - field name
 * @param {string} props.bgColor - background color
 * @param {number} props.colSpan - column merge
 * @param {number} props.numberOfColumns - number of columns
 * @returns {printoutToDocxLabelCell}
 */
export const printoutToDocxLabelCell = ({
  templateFields, fieldName, numberOfColumns, bgColor, colSpan,
}) => {
  const fieldConfig = templateFields?.find(({ name }) => name === fieldName);

  return printoutToDocxTableCell({
    text: fieldConfig?.label || 'Brak etykiety',
    bgColor,
    numberOfColumns,
    colSpan,
  });
};
