import { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { DICTIONARIES } from '../../../_constants';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { FieldSorter } from './FieldSorter';

/**
 * ClusterCoordinatorServiceOffer element renderer.
 *
 * @returns {ClusterCoordinatorServiceOffer}
 */
export function ClusterCoordinatorServiceOffer() {
  const {
    control, setValue,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const collectionName = 'cluster_coordinator_service_offer';
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.projectInnovations,
    DICTIONARIES.strategicAreas,
    DICTIONARIES.serviceImplementationBenefits,
  );

  const config = fieldsConfig[collectionName];
  const subfields = config?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = config || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);
  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      <LabeledFieldRow fieldConfig={config} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionName,
              index,
            )}
            isReadonly={isReadonly}
            itemIndex={index}
            fieldsOrder={Object.keys(subfields)}
            key={field.id}
            buttonRemoveVisible={fields.length > minRowNumber}
            isIndexed
          >
            {/* Nazwa usługi */}
            <FormField name={`${collectionName}.${index}.service_name`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  disabled={isReadonly}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  multiline
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Innowacje w projekcie */}
            <FormField name={`${collectionName}.${index}.project_innovations`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  renderSelectAllButton={false}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.projectInnovations.name))}
                  error={error}
                  multiple
                />
              )}
            </FormField>
            {/* Opis przedmiotu usługi, sposobu jej świadczenia i uzasadnienie innowacji (jeśli dotyczy) */}
            <FormField name={`${collectionName}.${index}.service_description`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  disabled={isReadonly}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  multiline
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Obszary strategiczne */}
            <FormField name={`${collectionName}.${index}.strategic_areas`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  renderSelectAllButton={false}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.strategicAreas.name))}
                  error={error}
                  multiple
                />
              )}
            </FormField>
            {/* Uzasadnienie wyboru obszarów strategicznych */}
            <FormField name={`${collectionName}.${index}.selection_justification`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  disabled={isReadonly}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  multiline
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Korzyści z wdrożenia usługi */}
            <FormField name={`${collectionName}.${index}.service_implementation_benefits`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  renderSelectAllButton={false}
                  onBlur={onBlur}
                  options={filterOptions(get(DICTIONARIES.serviceImplementationBenefits.name))}
                  error={error}
                  multiple
                />
              )}
            </FormField>
            {/* Uzasadnienie korzyści z wdrożenia usługi (jeśli dotyczy) */}
            <FormField name={`${collectionName}.${index}.benefits_justification`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  disabled={isReadonly}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  multiline
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="PgY0aU7qHdxzhwi"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Dotychczasowa oferta usługowa koordynatora klastra */}
      <FormField name="cluster_coordinator_service_offer_previous_service_offer">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
