import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import uniqid from 'uniqid';
import {
  useEvaluationItemContext,
  useEvaluationSheetsContext,
  useGlobalDialog,
} from '../../../../../Context';
import { EvaluationSheetAddChairmanRemarksForm } from './EvaluationSheetAddChairmanRemarks.form';
import { useCustomSnackbar } from '../../../../../_hooks';
import { API_ROUTE } from '../../../../../_constants';
import { request } from '../../../../../_services';

const classes = {
  button: {
    animation: 'pulse 0.75s infinite cubic-bezier(0.30, 0.15, 0, 1)',
    '@keyframes pulse': {
      to: {
        boxShadow: '0 0 0 3px transparent, 0 0 0 6px rgba(211, 47, 47, 0.5)',
      },
    },
  },
};

/**
 * Evaluation sheet add chairman remarks button.
 *
 * @returns {EvaluationSheetAddChairmanRemarksButton}
 */
export function EvaluationSheetAddChairmanRemarksButton() {
  const {
    render, closeAll,
  } = useGlobalDialog();
  const {
    isReadonly, transformedData: { isEmployeeOrAdmin },
  } = useEvaluationSheetsContext();

  const { evaluationItem } = useEvaluationItemContext();
  const remark = evaluationItem.chairmanRemarks.find(({ active }) => active) || {};
  const evaluationItemId = evaluationItem.id;
  const { reloadData } = useEvaluationSheetsContext();
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();

  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: {
      remark: remark?.remark || '',
      evaluationItem: `${API_ROUTE.expertEvaluationItems}/${evaluationItemId}`,
    },
    mode: 'all',
  });

  const onSubmit = async (values) => {
    const { statusSuccess } = remark?.id
      ? await request.put(`${API_ROUTE.chairmanRemarks}/${remark.id}`, values)
      : await request.post(API_ROUTE.chairmanRemarks, values);

    statusSuccess
      ? successNotification('Uwaga przewodniczącego została dodana.')
      : errorNotification('Nie udało się dodać uwagi przewodniczącego. Spróbuj ponownie.');

    closeAll();
    reloadData();
  };

  return (
    <Button
      type="button"
      id={uniqid()}
      color={remark?.active ? 'error' : 'secondary'}
      variant="outlined"
      onClick={() => render(
        <EvaluationSheetAddChairmanRemarksForm
          onSubmit={handleSubmit(onSubmit)}
          control={control}
          isReadonly={isReadonly}
          isEmployeeOrAdmin={isEmployeeOrAdmin}
        />,
        'Uwagi przewodniczącego',
      )}
      sx={remark?.active ? classes.button : {}}
    >
      {(isEmployeeOrAdmin && !isReadonly) ? 'Dodaj uwagi przewodniczącego' : 'Uwagi przewodniczącego'}
    </Button>
  );
}
