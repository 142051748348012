import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import uniqid from 'uniqid';
import {
  Fragment,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import {
  MonetaryField,
  parsePastedText,
} from '../../MonetaryField';
import { Alert } from '../../Alert';
import InfoPopover from '../../InfoPopover/InfoPopover';
import CharsCounter from '../../CharsCounter';

const classes = {
  cell: {
    borderBottom: 'none',
  },
};

/**
 * Table custom row
 *
 * @param {object} props - root props
 * @param {Node} props.children - provider children elements
 * @param {boolean} props.withTableRow - should render element with table row wrapper or not
 * @returns {Row}
 */
function Row({
  children, withTableRow,
}) {
  if (withTableRow) {
    return <TableRow>{children}</TableRow>;
  }

  return <>{children}</>;
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
  withTableRow: PropTypes.bool,
};

Row.defaultProps = {
  withTableRow: true,
};

/**
 * Funding Sources element.
 *
 * @returns {FundingSources}
 * */
export function FundingSources() {
  const {
    fieldsConfig,
    isReadonly,
    currentElementFieldsConfig,
  } = useElementContext();

  const {
    control,
    getValues,
    setValue,
    watch,
  } = useFormContext();
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'funding_sources_others',
  });

  const FIELD_TYPES = {
    TOTAL: 'total',
    ELIGIBLE: 'eligible',
  };

  const [fixedDecimalScale, setFixedDecimalScale] = useState({});

  const setDefaultFixedDecimalScale = () => {
    const elements = {};

    currentElementFieldsConfig.forEach((currentElementField) => {
      if (currentElementField.type === 'collection') {
        fields.forEach((_, index) => {
          elements[`${currentElementField.name}.${index}.${FIELD_TYPES.TOTAL}`] = true;
          elements[`${currentElementField.name}.${index}.${FIELD_TYPES.ELIGIBLE}`] = true;
        });
      }
      if (currentElementField.type === 'money') {
        elements[currentElementField.name] = true;
      }
    });

    setFixedDecimalScale(elements);
  };

  const handleFixedDecimalScale = (fieldName, value) => {
    setFixedDecimalScale((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const fundingSourcesOthersConfig = fieldsConfig.funding_sources_others;
  const subfields = fundingSourcesOthersConfig?.fields || {};
  const fieldObject = subfields ? Object.keys(subfields).reduce((prev, key) => ({
    ...prev,
    [key]: subfields[key].defaultValue,
  }), {}) : {};

  const {
    maxRowNumber,
    minRowNumber,
  } = fundingSourcesOthersConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(fieldObject);
      }
    }
  }, [maxRowNumber, minRowNumber]);

  const totalExpenses = fieldsConfig.funding_sources_total_expenses;
  const eligibleExpenses = fieldsConfig.funding_sources_eligible_expenses;

  const renderRow = (category, label, customBlur, externalValues = null, disabled = false, withTableRow = true) => {
    const transformedCategory = category.substring(0, category.indexOf('.'));
    const fieldTotal = category.indexOf('.') !== -1
      ? fieldsConfig[transformedCategory].fields.total : fieldsConfig[`${category}${FIELD_TYPES.TOTAL}`];
    const fieldEligible = category.indexOf('.') !== -1
      ? fieldsConfig[transformedCategory].fields.eligible : fieldsConfig[`${category}${FIELD_TYPES.ELIGIBLE}`];

    if ((fieldTotal === undefined && fieldEligible === undefined)) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return (
      <Row withTableRow={withTableRow}>
        {label && (
          <TableCell
            sx={{
              ...classes.cell,
              fontWeight: 'bold',
            }}
            scope="row"
          >
            {label}
          </TableCell>
        )}
        {totalExpenses && !disabled && !isReadonly && (
          <TableCell sx={classes.cell}>
            <Box display="flex">
              <FormField name={`${category}${FIELD_TYPES.TOTAL}`} labelled={false}>
                {({
                  name, onChange, value, onBlur, defaultValue, error,
                }) => (
                  <TextField
                    value={externalValues ? externalValues.total : value || defaultValue}
                    type="text"
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={(event) => {
                      if (customBlur) {
                        customBlur(FIELD_TYPES.TOTAL);
                      }
                      handleFixedDecimalScale(name, true);
                      onBlur(event);
                    }}
                    variant="outlined"
                    disabled={disabled || isReadonly}
                    onFocus={() => handleFixedDecimalScale(name, false)}
                    InputProps={{
                      inputComponent: MonetaryField,
                    }}
                    inputProps={{
                      allowNegative: false,
                      fixedDecimalScale: fixedDecimalScale[name],
                      allowedDecimalSeparators: ['.', ','],
                    }}
                    onPaste={(event) => parsePastedText(event, onChange)}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {(fieldsConfig[`${category}${FIELD_TYPES.TOTAL}`]?.help
                || fundingSourcesOthersConfig?.fields[FIELD_TYPES.TOTAL]?.help)
                && (
                  <InfoPopover>
                    {fieldsConfig[`${category}${FIELD_TYPES.TOTAL}`]?.help
                      || fundingSourcesOthersConfig?.fields[FIELD_TYPES.TOTAL]?.help}
                  </InfoPopover>
                )}
            </Box>
          </TableCell>
        )}

        {totalExpenses && (disabled || isReadonly) && (
          <TableCell sx={classes.cell}>
            <Box display="flex">
              <FormField name={`${category}${FIELD_TYPES.TOTAL}`} labelled={false}>
                {({ value }) => (
                  <Alert severity="status" sx={{ margin: 0 }}>
                    {externalValues ? externalValues?.total : value}
                  </Alert>
                )}
              </FormField>
              {fieldsConfig[`${category}${FIELD_TYPES.TOTAL}`]?.help && (
                <InfoPopover>
                  {fieldsConfig[`${category}${FIELD_TYPES.TOTAL}`].help}
                </InfoPopover>
              )}
            </Box>
          </TableCell>
        )}

        {eligibleExpenses && !disabled && !isReadonly && (
          <TableCell sx={classes.cell}>
            <Box display="flex">
              <FormField name={`${category}${FIELD_TYPES.ELIGIBLE}`} labelled={false}>
                {({
                  name, onChange, value, onBlur, defaultValue, error,
                }) => (
                  <TextField
                    value={externalValues ? externalValues.eligible : value || defaultValue}
                    type="text"
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={(event) => {
                      if (customBlur) {
                        customBlur(FIELD_TYPES.ELIGIBLE);
                      }
                      handleFixedDecimalScale(name, true);
                      onBlur(event);
                    }}
                    variant="outlined"
                    disabled={disabled || isReadonly}
                    onFocus={() => handleFixedDecimalScale(name, false)}
                    InputProps={{
                      inputComponent: MonetaryField,
                    }}
                    inputProps={{
                      allowNegative: false,
                      fixedDecimalScale: fixedDecimalScale[name],
                      allowedDecimalSeparators: ['.', ','],
                    }}
                    onPaste={(event) => parsePastedText(event, onChange)}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {(fieldsConfig[`${category}${FIELD_TYPES.ELIGIBLE}`]?.help
                || fundingSourcesOthersConfig?.fields[FIELD_TYPES.ELIGIBLE]?.help) && (
                <InfoPopover>
                  {fieldsConfig[`${category}${FIELD_TYPES.ELIGIBLE}`]?.help
                      || fundingSourcesOthersConfig?.fields[FIELD_TYPES.ELIGIBLE]?.help}
                </InfoPopover>
              )}
            </Box>
          </TableCell>
        )}

        {eligibleExpenses && (disabled || isReadonly) && (
          <TableCell sx={classes.cell}>
            <Box display="flex">
              <FormField name={`${category}${FIELD_TYPES.ELIGIBLE}`} labelled={false}>
                {({ value }) => (
                  <Alert severity="status" sx={{ margin: 0 }}>
                    {externalValues ? externalValues?.total : value}
                  </Alert>
                )}
              </FormField>
              {fieldsConfig[`${category}${FIELD_TYPES.ELIGIBLE}`]?.help && (
                <InfoPopover>
                  {fieldsConfig[`${category}${FIELD_TYPES.ELIGIBLE}`].help}
                </InfoPopover>
              )}
            </Box>
          </TableCell>
        )}
      </Row>
    );
  };

  const hrfAllTasks = Object
    .keys(getValues())
    .filter((filterKey) => filterKey.includes('tasks-'))
    ?.map((key) => getValues(key)?.map((task) => task.task_id))
    ?.flat();

  const hrfTotalCoFinancing = Object.entries(getValues())
    .filter(([key]) => (key.substring(0, 15) === 'actual_expenses' || key.substring(0, 17) === 'lump_sum_expenses'))
    ?.reduce(
      (prev, acc) => (prev
        + (acc?.[1]
          ?.reduce((itemPrev, accPrev) => {
            if (accPrev?.task_id === '' || !hrfAllTasks.includes(accPrev?.task_id)) {
              return itemPrev;
            }

            return itemPrev + Number(accPrev?.co_financing || 0);
          }, 0) || 0)),
      0
    ) || 0;

  const calculateSum = (type) => {
    const ownContribution = parseFloat(getValues(`funding_sources_own_contribution_${type}`)) || 0;

    setValue(`funding_sources_sum_${type}`, parseFloat(ownContribution + parseFloat(hrfTotalCoFinancing)).toFixed(2));
  };

  const calculateFundingSourcesOwnContribution = (type) => {
    const publicBudget = parseFloat(getValues(`funding_sources_public_budget_${type}`)) || 0;
    const localGovernmentBudget = parseFloat(getValues(`funding_sources_local_government_budget_${type}`)) || 0;
    const publicOther = parseFloat(getValues(`funding_sources_public_other_${type}`)) || 0;
    const privateMoney = parseFloat(getValues(`funding_sources_private_${type}`)) || 0;

    setValue(
      `funding_sources_own_contribution_${type}`,
      parseFloat(publicBudget + localGovernmentBudget + publicOther + privateMoney).toFixed(2)
    );

    calculateSum(type);
  };

  const calculatePrivate = (type) => {
    const credit = parseFloat(getValues(`funding_sources_credit_${type}`)) || 0;
    const loan = parseFloat(getValues(`funding_sources_loan_${type}`)) || 0;
    const ownFounds = parseFloat(getValues(`funding_sources_own_funds_${type}`)) || 0;
    let others = 0;

    // eslint-disable-next-line no-return-assign
    getValues('funding_sources_others').forEach((other) => others += parseFloat(other[type]));

    setValue(
      `funding_sources_private_${type}`,
      parseFloat(credit + loan + ownFounds + others).toFixed(2)
    );

    calculateSum(type);
  };

  useEffect(() => {
    calculateFundingSourcesOwnContribution(FIELD_TYPES.TOTAL);
  }, [watch('funding_sources_private_total')]);

  useEffect(() => {
    calculateFundingSourcesOwnContribution(FIELD_TYPES.ELIGIBLE);
  }, [watch('funding_sources_private_eligible')]);

  useEffect(() => {
    setDefaultFixedDecimalScale();
  }, [fields]);

  useEffect(() => {
    setValue('funding_sources_co_financing_total', Number(hrfTotalCoFinancing).toFixed(2));
    setValue('funding_sources_co_financing_eligible', Number(hrfTotalCoFinancing).toFixed(2));
  }, []);

  return (
    <>
      <TableContainer>
        <Table
          aria-label="Lista konfiguracji"
        >
          <TableHead>
            <TableRow>
              <TableCell width="30%" scope="column" />
              {totalExpenses && (
                <TableCell width="30%" scope="column">
                  <Box display="flex">
                    <Typography fontWeight="bold">{totalExpenses.label}</Typography>
                    {totalExpenses?.help && (
                      <InfoPopover noBorder>
                        {totalExpenses.help}
                      </InfoPopover>
                    )}
                  </Box>
                </TableCell>
              )}
              {eligibleExpenses && (
                <TableCell width="30%" scope="column">
                  <Box display="flex">
                    <Typography fontWeight="bold">{eligibleExpenses.label}</Typography>
                    {eligibleExpenses?.help && (
                      <InfoPopover noBorder>
                        {eligibleExpenses.help}
                      </InfoPopover>
                    )}
                  </Box>
                </TableCell>
              )}
              <TableCell width="10%" scope="column" />
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow('funding_sources_co_financing_', 'Dofinansowanie', null, {
              total: Number(hrfTotalCoFinancing).toFixed(2),
              eligible: Number(hrfTotalCoFinancing).toFixed(2),
            }, true)}

            {renderRow('funding_sources_own_contribution_', 'Razem wkład własny', null, null, true)}

            {renderRow('funding_sources_public_budget_', 'Budżet państwa', calculateFundingSourcesOwnContribution)}

            {renderRow(
              'funding_sources_local_government_budget_',
              'Budżet jednostek samorządu terytorialnego',
              calculateFundingSourcesOwnContribution
            )}

            {renderRow(
              'funding_sources_public_other_',
              'Inne publiczne',
              calculateFundingSourcesOwnContribution
            )}

            {renderRow('funding_sources_private_', 'Prywatne, w tym', null, null, true)}

            {renderRow('funding_sources_own_funds_', 'Środki własne', calculatePrivate)}

            {renderRow('funding_sources_credit_', 'Kredyt', calculatePrivate)}

            {renderRow('funding_sources_loan_', 'Pożyczka', calculatePrivate)}

            {(Object.values(subfields).length !== 0 && fields.length !== 0) && (
              <>
                <TableRow>
                  <TableCell sx={classes.cell} scope="row" colSpan={4}>
                    <Box display="flex">
                      <Typography fontWeight="bold">{fundingSourcesOthersConfig.label}</Typography>
                      {fundingSourcesOthersConfig?.help && (
                        <InfoPopover noBorder>
                          {fundingSourcesOthersConfig.help}
                        </InfoPopover>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                {fields.map((item, index) => (
                  <Fragment key={`row-total-${item.id}`}>
                    <TableRow>
                      <TableCell sx={classes.cell}>
                        <Box display="flex">
                          <FormField name={`funding_sources_others.${index}.name`} labelled={false}>
                            {({
                              name, onChange, value, label, onBlur, error,
                            }) => (
                              <TextField
                                value={value}
                                id={name}
                                name={name}
                                onChange={onChange}
                                onBlur={onBlur}
                                variant="outlined"
                                inputProps={{ maxLength: 15 }}
                                disabled={isReadonly}
                                label={label}
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          </FormField>
                          {fundingSourcesOthersConfig.fields.name?.help && (
                            <InfoPopover>{fundingSourcesOthersConfig.fields.name.help}</InfoPopover>
                          )}
                        </Box>
                      </TableCell>
                      {renderRow(`funding_sources_others.${index}.`, null, calculatePrivate, null, false, false)}
                      {fields.length > minRowNumber && (
                        <TableCell sx={classes.cell}>
                          <Button
                            id={uniqid()}
                            onClick={() => {
                              remove(index);
                              calculatePrivate(FIELD_TYPES.TOTAL);
                              calculatePrivate(FIELD_TYPES.ELIGIBLE);
                            }}
                            variant="contained"
                            color="primary"
                          >
                            Usuń
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  </Fragment>
                ))}

              </>
            )}
            {!isReadonly && fields.length < maxRowNumber && (
              <TableRow>
                <TableCell scope="row" colSpan={4} sx={classes.cell}>
                  <Button
                    id="YffDKmvKV2Xm96N"
                    variant="contained"
                    color="secondary"
                    onClick={() => append(fieldObject)}
                  >
                    Dodaj źródło
                  </Button>
                </TableCell>
              </TableRow>
            )}

            {renderRow('funding_sources_sum_', 'Suma', null, null, true)}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Wkład własny */}
      <FormField name="funding_sources_own_contribution" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Uzasadnienie zapewnienia na rzecz projektu wkładu własnego wnioskodawcy */}
      <FormField name="funding_sources_applicant_contribution_provision_justification">
        {({
          name, onChange, value, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Źródło wkładu własnego */}
      <FormField name="funding_sources_own_contribution_source">
        {({
          name, onChange, value, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </>
  );
}
