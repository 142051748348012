import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { request } from '../../_services';
import {
  ACTION_TYPES,
  API_ROUTE,
} from '../../_constants';
import { useEvaluationSheetsContext } from './EvaluationSheets.context';
import { useAuth } from '../../_security';
import { useGlobalContext } from '../Global.context';
import SessionInfoBar from '../../Features/SessionInfoBar/SessionInfoBar';

/**
 * Evaluation sheets user session context wrapper.
 *
 * @type {React.Context}
 */
export const EvaluationSheetsUserSessionContext = createContext({});

export const useEvaluationSheetsUserSessionContext = () => useContext(EvaluationSheetsUserSessionContext);

/**
 * Evaluation sheets with modules provider.
 *
 * @param {object} props - root props
 * @param {Node} props.children - children elements
 * @returns {EvaluationSheetsUserSessionProvider}
 */
export function EvaluationSheetsUserSessionProvider({ children }) {
  const { evaluationSheetId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { id: loggedUserId } = useAuth();
  const {
    notify, handleAction,
  } = useGlobalContext();
  const {
    isReadonly, transformedData: { isEmployeeOrAdmin },
  } = useEvaluationSheetsContext();
  const [sessionData, setSessionData] = useState({});

  /**
   * Close expert session
   *
   * @param {boolean} goToPreviousPage - go to previous page
   * @param {Function|null} handleCloseModal - handle
   * @returns {Promise<void>}
   */
  const handleCloseUserSession = async (goToPreviousPage = true, handleCloseModal = null) => {
    if (!isEmployeeOrAdmin && !isReadonly) {
      await request.put(`${API_ROUTE.evaluationSessions}/${sessionData.id}`, {
        evaluationSheet: `/lsi/auth/api/evaluation-sheets/${evaluationSheetId}`,
        closed: true,
      });
    }

    if (goToPreviousPage) {
      navigate(-1);
    }

    if (handleCloseModal) {
      handleCloseModal(false);
    }
  };

  const setEvaluationSession = async () => {
    const {
      statusSuccess, payload,
    } = await request.post(API_ROUTE.evaluationSessions, {
      evaluationSheet: `/lsi/auth/api/evaluation-sheets/${evaluationSheetId}`,
      user: `/lsi/auth/api/users/${loggedUserId}`,
    });

    if (!statusSuccess) {
      navigate(-1);
      setTimeout(() => {
        notify(payload?.detail || 'Arkusz oceny jest obecnie edytowany przez innego eksperta.', 'error');
      }, 100);

      return;
    }

    setSessionData(payload);
  };

  useEffect(() => {
    if (!isEmployeeOrAdmin && !isReadonly && Object.values(sessionData).length === 0) {
      setEvaluationSession();
    }
  }, [isEmployeeOrAdmin]);

  useEffect(() => () => {
    if (!isEmployeeOrAdmin && Object.values(sessionData).length !== 0) {
      handleCloseUserSession(false);
    }
    handleAction({ type: ACTION_TYPES.SET_PAGE_UNLOCK });
  }, [location, sessionData]);

  const userSessionStore = useMemo(() => ({
    ...sessionData,
    handleCloseUserSession,
  }), [sessionData]);

  return (
    <EvaluationSheetsUserSessionContext.Provider value={userSessionStore}>
      {sessionData?.startDate && (
        <SessionInfoBar sessionStartDate={sessionData.startDate} />
      )}
      {children}
    </EvaluationSheetsUserSessionContext.Provider>
  );
}
EvaluationSheetsUserSessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
