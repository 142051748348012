import { AlignmentType } from 'docx';
import {
  printoutToDocxCellValueByType,
  printoutToDocxLabelCell,
  printoutToDocxTable,
  printoutToDocxTableRow,
  printoutToDocxText,
} from '../PrintoutToDocxComponents';
import { printoutToDocxHelper } from '../../../_helpers';

/**
 * Lump sum expenses element
 *
 * @param {object} props - root props
 * @param {object} props.subElementData - sub element data
 * @param {Array} props.dictionaries - dictionaries
 * @param {string} props.taskId - task id
 * @returns {lumpSumExpenses}
 */
export const lumpSumExpenses = ({
  subElementData, dictionaries, taskId,
}) => {
  if (!subElementData) {
    return [];
  }

  const subfields = subElementData.fields;
  const fieldsConfig = printoutToDocxHelper.fieldsConfig(subfields);
  const rowsCount = subfields.map(({ name }) => [
    'lump_sum_type',
    'cost_name_in_words',
    'cost_name_from_dictionary',
    'cost_category',
    'total_expenses',
    'eligible_expenses',
    'co_financing',
    'co_financing_percent',
    'confirmation_documents',
  ].includes(name)).filter((field) => field).length;

  const filteredElementData = subElementData.elementData.filter((elementData) => taskId === elementData.task_id);

  if (filteredElementData.length === 0) {
    return [];
  }

  return [
    printoutToDocxText({
      text: subElementData.label,
      size: 16,
      align: AlignmentType.LEFT,
      bold: true,
      marginTop: 500,
    }),
    ...filteredElementData.flatMap((elementData) => [
      printoutToDocxText({ marginBottom: 500 }),
      printoutToDocxTable({
        columns: rowsCount,
        rows: [
          printoutToDocxTableRow({
            children: [
              ...printoutToDocxHelper.fieldConfig('lump_sum_type', fieldsConfig, [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'lump_sum_type',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('cost_name_in_words', fieldsConfig, [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'cost_name_in_words',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('cost_name_from_dictionary', fieldsConfig, [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'cost_name_from_dictionary',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('cost_category', fieldsConfig, [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'cost_category',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('total_expenses', fieldsConfig, [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'total_expenses',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('eligible_expenses', fieldsConfig, [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'eligible_expenses',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('co_financing', fieldsConfig, [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'co_financing',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('co_financing_percent', fieldsConfig, [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'co_financing_percent',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('confirmation_documents', fieldsConfig, [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'confirmation_documents',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ]),
            ],
          }),
          printoutToDocxTableRow({
            children: [
              ...printoutToDocxHelper.fieldConfig('lump_sum_type', fieldsConfig, [
                printoutToDocxCellValueByType({
                  templateFields: subElementData.fields,
                  fieldName: 'lump_sum_type',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  value: elementData.lump_sum_type,
                  dictionaries,
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('cost_name_in_words', fieldsConfig, [
                printoutToDocxCellValueByType({
                  templateFields: subElementData.fields,
                  fieldName: 'cost_name_in_words',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  value: elementData.cost_name_in_words,
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('cost_name_from_dictionary', fieldsConfig, [
                printoutToDocxCellValueByType({
                  templateFields: subElementData.fields,
                  fieldName: 'cost_name_from_dictionary',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  value: elementData.cost_name_from_dictionary,
                  dictionaries,
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('cost_category', fieldsConfig, [
                printoutToDocxCellValueByType({
                  templateFields: subElementData.fields,
                  fieldName: 'cost_category',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  value: elementData.cost_category,
                  dictionaries,
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('total_expenses', fieldsConfig, [
                printoutToDocxCellValueByType({
                  templateFields: subElementData.fields,
                  fieldName: 'total_expenses',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  value: elementData.total_expenses,
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('eligible_expenses', fieldsConfig, [
                printoutToDocxCellValueByType({
                  templateFields: subElementData.fields,
                  fieldName: 'eligible_expenses',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  value: elementData.eligible_expenses,
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('co_financing', fieldsConfig, [
                printoutToDocxCellValueByType({
                  templateFields: subElementData.fields,
                  fieldName: 'co_financing',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  value: elementData.co_financing,
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('co_financing_percent', fieldsConfig, [
                printoutToDocxCellValueByType({
                  templateFields: subElementData.fields,
                  fieldName: 'co_financing_percent',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  value: elementData.co_financing_percent,
                }),
              ]),
              ...printoutToDocxHelper.fieldConfig('confirmation_documents', fieldsConfig, [
                printoutToDocxCellValueByType({
                  templateFields: subElementData.fields,
                  fieldName: 'confirmation_documents',
                  colSpan: 1,
                  numberOfColumns: rowsCount,
                  value: elementData.confirmation_documents,
                  dictionaries,
                }),
              ]),
            ],
          }),
          ...printoutToDocxHelper.fieldConfig('estimation_methods', fieldsConfig, [
            printoutToDocxTableRow({
              children: [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'estimation_methods',
                  colSpan: rowsCount,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ],
            }),
            printoutToDocxTableRow({
              children: [
                printoutToDocxCellValueByType({
                  value: elementData.estimation_methods,
                  templateFields: subElementData.fields,
                  fieldName: 'estimation_methods',
                  colSpan: rowsCount,
                  numberOfColumns: rowsCount,
                }),
              ],
            }),
          ]),
          ...printoutToDocxHelper.fieldConfig('expense_justification', fieldsConfig, [
            printoutToDocxTableRow({
              children: [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'expense_justification',
                  colSpan: rowsCount,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ],
            }),
            printoutToDocxTableRow({
              children: [
                printoutToDocxCellValueByType({
                  value: elementData.expense_justification,
                  templateFields: subElementData.fields,
                  fieldName: 'expense_justification',
                  colSpan: rowsCount,
                  numberOfColumns: rowsCount,
                }),
              ],
            }),
          ]),
          ...printoutToDocxHelper.fieldConfig('description_and_justification', fieldsConfig, [
            printoutToDocxTableRow({
              children: [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'description_and_justification',
                  colSpan: rowsCount,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ],
            }),
            printoutToDocxTableRow({
              children: [
                printoutToDocxCellValueByType({
                  value: elementData.description_and_justification,
                  templateFields: subElementData.fields,
                  fieldName: 'description_and_justification',
                  colSpan: rowsCount,
                  numberOfColumns: rowsCount,
                }),
              ],
            }),
          ]),
          ...printoutToDocxHelper.fieldConfig('spending_on_accessibility', fieldsConfig, [
            printoutToDocxTableRow({
              children: [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'spending_on_accessibility',
                  colSpan: rowsCount,
                  numberOfColumns: rowsCount,
                  bgColor: 'gray',
                }),
              ],
            }),
            printoutToDocxTableRow({
              children: [
                printoutToDocxCellValueByType({
                  value: elementData.spending_on_accessibility,
                  templateFields: subElementData.fields,
                  fieldName: 'spending_on_accessibility',
                  colSpan: rowsCount,
                  numberOfColumns: rowsCount,
                  dictionaries,
                }),
              ],
            }),
          ]),
        ],
      }),
    ]),
  ];
};
