import { DICTIONARIES } from '../_constants';
/**
 * Function to format pkd codes field value.
 *
 * @param {object} value - current value
 * @param {string} value.'@id' - iri
 * @param {string} value.code - code
 * @param {string} value.name - name
 * @returns {object}
 */
export const formatPkdCodesFieldValue = ({
  '@id': id, code, name: pkdCodeName,
}) => ({
  id,
  label: `${code}: ${pkdCodeName}`,
});

export const mapPkdCodes = (getDictionary) => getDictionary(DICTIONARIES.pkdCodes.name).map((pkdCode) => ({
  '@id': pkdCode['@id'],
  name: `${pkdCode.code} ${pkdCode.name}`,
}));
