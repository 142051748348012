import {
  Box,
  TextField,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { useElementContext } from '../../../Context';
import InternationalizationModuleEssenceProductInternationalization
  from './InternationalizationModuleEssence/InternationalizationModuleEssenceProductInternationalization';
import InternationalizationModuleEssencePropertyProtection
  from './InternationalizationModuleEssence/InternationalizationModuleEssencePropertyProtection';
import InternationalizationModuleEssencePropertyDefence
  from './InternationalizationModuleEssence/InternationalizationModuleEssencePropertyDefence';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import {
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import InternationalizationModuleEssencePromotion
  from './InternationalizationModuleEssence/InternationalizationModuleEssencePromotion';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';

/**
 * Internationalization module essence component
 *
 * @returns {InternationalizationModuleEssence}
 */
function InternationalizationModuleEssence() {
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();
  const {
    watch, resetField, reset,
  } = useFormContext();
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.internationalizationModuleEssenceScopes,
    DICTIONARIES.internationalizationModuleEssenceEventTypes,
    DICTIONARIES.countries,
  );

  const { internationalizationModuleEssenceScopes } = ELEMENTS_DICTIONARIES_MAP;

  const internationalizationModuleEssenceScope = watch('internationalization_module_essence_scope') || [];

  const internationalizationModuleEssenceProductInternationalizationConfig = fieldsConfig
    .internationalization_module_essence_product_internationalization;
  const internationalizationModuleEssencePropertyProtectionConfig = fieldsConfig
    .internationalization_module_essence_property_protection;
  const internationalizationModuleEssencePropertyDefenceConfig = fieldsConfig
    .internationalization_module_essence_property_defence;
  const internationalizationModuleEssencePromotionEventsConfig = fieldsConfig
    .internationalization_module_essence_promotion_events;

  return (
    <>
      {!isLoaded && <ContainerLoader />}
      <Box display={isLoaded ? 'block' : 'none'} width="100%">
        <FieldSorter>
          {/* Tabela "Produkt będący przedmiotem umiędzynarodowienia (promocji zagranicznej)"" */}
          {internationalizationModuleEssenceScope.includes(
            internationalizationModuleEssenceScopes.productsInternationalization.id
          ) && (
            <LabeledFieldRow
              fieldConfig={internationalizationModuleEssenceProductInternationalizationConfig}
              contextHelpLabel
              fullWidth
            >
              <InternationalizationModuleEssenceProductInternationalization />
            </LabeledFieldRow>
          )}
          {/* Tabela "Wydarzenia promujące, w których będzie uczestniczył wnioskodawca" */}
          {internationalizationModuleEssenceScope.includes(
            internationalizationModuleEssenceScopes.productsInternationalization.id
          ) && (
            <LabeledFieldRow
              fieldConfig={internationalizationModuleEssencePromotionEventsConfig}
              contextHelpLabel
              fullWidth
            >
              <InternationalizationModuleEssencePromotion />
            </LabeledFieldRow>
          )}
          {/* Tabela "Uzyskanie ochrony własności przemysłowej poza Polską" */}
          {internationalizationModuleEssenceScope.includes(
            internationalizationModuleEssenceScopes.propertyProtection.id
          ) && (
            <LabeledFieldRow
              fieldConfig={internationalizationModuleEssencePropertyProtectionConfig}
              contextHelpLabel
              fullWidth
            >
              <InternationalizationModuleEssencePropertyProtection />
            </LabeledFieldRow>
          )}
          {internationalizationModuleEssenceScope.includes(
            internationalizationModuleEssenceScopes.propertyDefence.id
          ) && (
            <LabeledFieldRow
              fieldConfig={internationalizationModuleEssencePropertyDefenceConfig}
              contextHelpLabel
              fullWidth
            >
              <InternationalizationModuleEssencePropertyDefence />
            </LabeledFieldRow>
          )}
          {/* Usługi doradcze związane z internacjonalizacją produktów */}
          <FormField name="internationalization_module_essence_consulting_services">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Zakres realizacji modułu */}
          <FormField name="internationalization_module_essence_scope">
            {({
              onChange, value, name, filterOptions, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={(event) => {
                  onChange(event);
                  if (!internationalizationModuleEssenceScope.includes(
                    internationalizationModuleEssenceScopes.productsInternationalization.id
                  )) {
                    reset((formValues) => ({
                      ...formValues,
                      internationalization_module_essence_product_internationalization: FIELD_TYPES_MAP.collection,
                      internationalization_module_essence_promotion_events: FIELD_TYPES_MAP.collection,
                    }));
                  }
                  if (!internationalizationModuleEssenceScope.includes(
                    internationalizationModuleEssenceScopes.propertyProtection.id
                  )) {
                    resetField(
                      'internationalization_module_essence_property_protection',
                      { defaultValue: FIELD_TYPES_MAP.collection }
                    );
                  }
                  if (!internationalizationModuleEssenceScope.includes(
                    internationalizationModuleEssenceScopes.propertyDefence.id
                  )) {
                    resetField(
                      'internationalization_module_essence_property_defence',
                      { defaultValue: FIELD_TYPES_MAP.collection }
                    );
                  }
                }}
                onBlur={onBlur}
                options={filterOptions(get(DICTIONARIES.internationalizationModuleEssenceScopes.name))}
                disabled={isReadonly}
                multiple
                renderSelectAllButton={false}
                error={error}
              />
            )}
          </FormField>
          {/* Cel modułu */}
          <FormField name="internationalization_module_essence_module_purpose">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
        </FieldSorter>
      </Box>
    </>
  );
}

export default InternationalizationModuleEssence;
