import {
  Box,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import {
  ADDITIONAL_MODULE_IDS,
  DATE,
  EVALUATION_SHEET_STATUS,
} from '../../../../_constants';
import { useEvaluationSheetsContext } from '../../../../Context';
import { formatDateString } from '../../../../_helpers';

/**
 * EvaluationSheetExpertApprovalList element.
 *
 * @param {object} props - root props
 * @param {string} props.moduleId - module id
 * @param {Array} props.experts - list of experts
 * @param {Array} props.modules - list of modules
 * @returns {EvaluationSheetExpertApprovalList}
 */
export function EvaluationSheetExpertApprovalList({
  moduleId, experts, modules,
}) {
  const { evaluationSheet: { status: evaluationSheetStatus } } = useEvaluationSheetsContext();

  const excludedModules = [
    ADDITIONAL_MODULE_IDS.kryteriaOgolem,
    ADDITIONAL_MODULE_IDS.modulowaFinansowa,
    ADDITIONAL_MODULE_IDS.ocenaFinansowa,
  ];

  const excludedEvaluationSheetStatus = [
    EVALUATION_SHEET_STATUS.sentToApprove,
    EVALUATION_SHEET_STATUS.approved,
  ];

  const expertList = useMemo(() => {
    if (moduleId === ADDITIONAL_MODULE_IDS.modulowaFinansowa) {
      const financialModule = modules.find((module) => module.moduleId === ADDITIONAL_MODULE_IDS.ocenaFinansowa);

      return financialModule?.experts || [];
    }

    if (moduleId === ADDITIONAL_MODULE_IDS.kryteriaOgolem) {
      const modularExperts = modules.filter((module) => !excludedModules.includes(module.moduleId))
        .map((module) => module.experts).flat()
        .map((expert) => ({
          ...expert,
          expertId: expert.externalExpert.id,
        }));

      return uniqBy(modularExperts, 'expertId');
    }

    return uniqBy(experts, 'externalExpert.id');
  }, [modules, experts]).filter(({ refused }) => !refused);

  if (!excludedEvaluationSheetStatus.includes(evaluationSheetStatus)) {
    return null;
  }

  return (
    <Box
      mt={3}
      direction="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      textAlign="left"
    >
      <Typography mb={1} fontWeight="bold">
        Zatwierdzenie oceny:
      </Typography>
      {expertList.map(({
        externalExpert, parpExpert, evaluationSheetApprovalDate, '@id': id,
      }) => {
        const expert = externalExpert ?? parpExpert;

        return (
          <Typography key={id} width="100%">
            {`${expert.user.fullName || ''}: ${formatDateString(
              evaluationSheetApprovalDate,
              DATE.defaultFormat,
              'Brak zatwierdzenia'
            )}`}
          </Typography>
        );
      })}
    </Box>
  );
}

EvaluationSheetExpertApprovalList.propTypes = {
  moduleId: PropTypes.string.isRequired,
  experts: PropTypes.arrayOf(Object).isRequired,
  modules: PropTypes.arrayOf(Object).isRequired,
};
