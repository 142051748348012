import { Box } from '@mui/material';
import { useEvaluationSheetsContext } from '../../../../../Context';
import { EvaluationSheetSaveButton } from './EvaluationSheetSaveButton';
import { EvaluationSheetCloseButton } from './EvaluationSheetCloseButton';
import { EvaluationSheetSendToParpButton } from './EvaluationSheetSendToParpButton';
import { EvaluationSheetUnlockForExpertsButton } from './EvaluationSheetUnlockForExpertsButton';
import { EvaluationSheetApprovalButton } from './EvaluationSheetApprovalButton';
import { EvaluationSheetSendToApproveButton } from './EvaluationSheetSendToApproveButton';

/**
 * Evaluation sheet action buttons.
 *
 * @returns {EvaluationSheetActionButtons}
 */
export function EvaluationSheetActionButtons() {
  const {
    transformedData: {
      isEmployeeOrAdmin, hasAcceptedAtLeastOneModule,
    }, isReadonly,
  } = useEvaluationSheetsContext();

  return (
    <Box mt={5} display="flex" justifyContent="space-between" width="100%">
      <Box />
      <Box gap={3} display="flex" justifyContent="center">
        {(!isReadonly && (hasAcceptedAtLeastOneModule || isEmployeeOrAdmin)) && (
          <>
            <EvaluationSheetSaveButton />
            <EvaluationSheetSendToParpButton />
            <EvaluationSheetUnlockForExpertsButton />
          </>
        )}
        {isReadonly && (
          <EvaluationSheetApprovalButton />
        )}
        <EvaluationSheetCloseButton />
      </Box>
      <Box mr={3}>
        <EvaluationSheetSendToApproveButton />
      </Box>
    </Box>
  );
}
