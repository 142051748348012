import { ApplicantGeneralInformation } from './ApplicantGeneralInformation';
import { Attachments } from './Attachments';
import { ContactPersons } from './ContactPersons';
import { Implementers } from './Implementers';
import { ProjectGeneralInformation } from './ProjectGeneralInformation';
import { RegionalInvestmentAidInnovationImplementation } from './RegionalInvestmentAidInnovationImplementation';
import { ProjectImplementationArea } from './ProjectImplementationArea';
import { RdSubimplementers } from './RdSubimplementers';
import { Statements } from './Statements';
import { UnhandledElement } from './UnhandledElement';
import { MailingAddress } from './MailingAddress';
import MaterialAndFinancialSchedule from './MaterialAndFinancialSchedule';
import Indicators from './Indicators';
import Risks from './Risks';
import TechnicalResourcesAndIntangibleAssets from './TechnicalResourcesAndIntangibleAssets';
import { GeneralProgramInformation } from './GeneralProgramInformation';
import { ModuleClassification } from './ModuleClassification';
import { MarketDemandAndRdProfitability } from './MarketDemandAndRdProfitability';
import { DigitizationModuleEssence } from './DigitizationModuleEssence';
import CompetencesModuleEssence from './CompetencesModuleEssence';
import ModuleImplementationPotential from './ModuleImplementationPotential';
import BrImplementationPotential from './BrImplementationPotential';
import IntensityOfPublicAidForRd from './IntensityOfPublicAidForRd';
import { RdInfrastructureModuleEssence } from './RdInfrastructureModuleEssence';
import { FundingSources } from './FundingSources';
import RegionalInvestmentAidDigitization from './RegionalInvestmentAidDigitization';
import GreeningEnvironmentalProtectionAid from './GreeningEnvironmentalProtectionAid';
import RdModuleEssence from './RdModuleEssence';
import GreeningModuleEssence from './GreeningModuleEssence';
import RegionalInvestmentAidGreening from './RegionalInvestmentAidGreening';
import InternationalizationModuleEssence from './InternationalizationModuleEssence';
import RegionalInvestmentAidInfrastructure from './RegionalInvestmentAidInfrastructure';
import ResearchAgendaRdInfrastructure from './ResearchAgendaRdInfrastructure';
import HumanResourcesPotentialRdInfrastructure from './HumanResourcesPotentialRdInfrastructure';
import { RdProjectTeam } from './RdProjectTeam';
import InnovationImplementationModuleEssence from './InnovationImplementationModuleEssence';
import LinkBetweenModules from './LinkBetweenModules';
import { UeHorizontalPoliciesProjectCompliance } from './UeHorizontalPoliciesProjectCompliance';
import ApplicantOtherApplications from './ApplicantOtherApplications';
import { ApplicantPotentialCompetition } from './ApplicantPotentialCompetition';
import { MarketTransformation } from './MarketTransformation';
import { HumanResourcesInnovationImplementation } from './HumanResourcesInnovationImplementation';
import { HumanResourcesDigitization } from './HumanResourcesDigitization';
import InnovationSocialSignificance from './InnovationSocialSignificance';
import { ProjectCollaboration } from './ProjectCollaboration';
import { HumanResourcesGreening } from './HumanResourcesGreening';
import { HumanResourcesInternationalization } from './HumanResourcesInternationalization';
import { ProjectClassification } from './ProjectClassification';
import { NationalKeyClusterParticipation } from './NationalKeyClusterParticipation';
import DigitalInnovations from './DigitalInnovations';
import EcoInnovations from './EcoInnovations';
import ProjectBudget from './ProjectBudget';
import { EdihOrganization } from './EdihOrganization';
import { EdihServices } from './EdihServices';
import { KpiOtherThanProductOrResultIndicators } from './KpiOtherThanProductOrResultIndicators';
import LaunchPadIncubationProgramConceptDescription from './LaunchPadIncubationProgramConceptDescription';
import EcosystemPartners from './EcosystemPartners';
import IncubationTakingIntoAccountIndustrySpecialization from './IncubationTakingIntoAccountIndustrySpecialization';
import { ServicesImplementation } from './ServicesImplementation';
import ApplicantExperienceInImplementationOfGrantProjects from './ApplicantExperienceInImplementationOfGrantProjects';
import { ApplicantAndProjectPartnersResources } from './ApplicantAndProjectPartnersResources';
import { ApplicantBranchAdressInEasternPoland } from './ApplicantBranchAdressInEasternPoland';
import { ApplicantPotential } from './ApplicantPotential';
import { ProjectImplementationJustification } from './ProjectImplementationJustification';
import ApplicantIncome from './ApplicantIncome';
import PkwEligibility from './PkwEligibility';
import { ClusterData } from './ClusterData';
import { ClusterInternationalization } from './ClusterInternationalization';
import { ClusterCoordinatorServiceOffer } from './ClusterCoordinatorServiceOffer';
import { ClusterCoordinatorPotential } from './ClusterCoordinatorPotential';
import { ProjectDetailedInformation } from './ProjectDetailedInformation';
import { ForeignPartners } from './ForeignPartners';
import {
  ApplicantExperienceInImplementationAccelerationPrograms,
} from './ApplicantExperienceInImplementationAccelerationPrograms';
import AccelerationProgramConcept from './AccelerationProgramConcept';
import { AccelerationProgramImplementationTeam } from './AccelerationProgramImplementationTeam';
import { GrantProjectManagementTeam } from './GrantProjectManagementTeam';
import { ResultOfCostsAndBenefitsAnalysis } from './ResultOfCostsAndBenefitsAnalysis';
import { ProjectImplementationConcept } from './ProjectImplementationConcept';
import { ApplicantExperience } from './ApplicantExperience';
import { InnovationDescription } from './InnovationDescription';
import { BusinessModelDescription } from './BusinessModelDescription';
import { BusinessPartners } from './BusinessPartners';
import { ExportPotential } from './ExportPotential';
import { ApplicantInnovation } from './ApplicantInnovation';
import { ApplicantExportActivities } from './ApplicantExportActivities';
import { ApplicantProductBrand } from './ApplicantProductBrand';
import { SupportSoFarReceived } from './SupportSoFarReceived';
import { PromotionalActivities } from './PromotionalActivities';
import { TeamImplementingProgramConcept } from './TeamImplementingProgramConcept';
import {
  ApplicantExperienceInInnovativeBusinessIdeasDevelopment,
} from './ApplicantExperienceInInnovativeBusinessIdeasDevelopment';
import InnovatorLabProgramConcept from './InnovatorLabProgramConcept';
import { ProjectSmeAssumptions } from './ProjectSmeAssumptions';
import { ReduceTurnover } from './ReduceTurnover';
import { BusinessIdeaInformation } from './BusinessIdeaInformation';
import { Originator } from './Originator';
import { VentureImplementationJustification } from './VentureImplementationJustification';
import { UeProgramSubjectEurogrant } from './UeProgramSubjectEurogrant';
import { VentureFeasibility } from './VentureFeasibility';
import { ApplicantPreparedSolution } from './ApplicantPreparedSolution';
import { StartupApplicantInformation } from './StartupApplicantInformation';
import { BusinessModelElements } from './BusinessModelElements';
import { GozTransformationImplementation } from './GozTransformationImplementation';
import { ProjectFinancialResources } from './ProjectFinancialResources';
import { ApplicantRepresentative } from './ApplicantRepresentative';
import { PersonRepresentingCompetitionParticipant } from './PersonRepresentingCompetitionParticipant';
import { ProductAndWorkDescription } from './ProductAndWorkDescription';
import { ConsortiumOrganization } from './ConsortiumOrganization';
import { ProjectEligibility } from './ProjectEligibility';
import { InnovationCentrePotential } from './InnovationCentrePotential';
import { OtherAccreditedOiEdihKkkCooperation } from './OtherAccreditedOiEdihKkkCooperation';
import { AccelerationForeignMarkets } from './AccelerationForeignMarkets';
import { GrantProjectImplementationTeam } from './GrantProjectImplementationTeam';

/**
 * Contains all available elements mapped.
 * Remember that when you add a new element, it must be registered in this constant.
 * This map tells the application what item to render based on data from the API.
 */
export const MAPPING = {
  applicant_general_information: ApplicantGeneralInformation,
  attachments: Attachments,
  contact_persons: ContactPersons,
  implementers: Implementers,
  project_general_information: ProjectGeneralInformation,
  informacje_ogolne_o_projekcie: ProjectGeneralInformation,
  project_implementation_area: ProjectImplementationArea,
  statements: Statements,
  mailing_address: MailingAddress,
  hrf: MaterialAndFinancialSchedule,
  indicators: Indicators,
  risks: Risks,
  technical_resources_and_intangible_assets: TechnicalResourcesAndIntangibleAssets,
  general_program_information: GeneralProgramInformation,
  br_subcontractors: RdSubimplementers,
  regional_investment_aid_innovation_implementation: RegionalInvestmentAidInnovationImplementation,
  rd_module_classification: ModuleClassification,
  rd_infrastructure_module_classification: ModuleClassification,
  innovation_implementation_module: ModuleClassification,
  internationalization_module_classification: ModuleClassification,
  competences_module_classification: ModuleClassification,
  digitization_module_classification: ModuleClassification,
  greening_module_classification: ModuleClassification,
  market_demand_and_rd_profitability: MarketDemandAndRdProfitability,
  digitization_module_essence: DigitizationModuleEssence,
  competences_module_essence: CompetencesModuleEssence,
  br_implementation_potential: BrImplementationPotential,
  module_implementation_potential: ModuleImplementationPotential,
  intensity_of_public_aid_for_rd: IntensityOfPublicAidForRd,
  rd_infrastructure_module_essence: RdInfrastructureModuleEssence,
  human_resources_potential_rd_infrastructure: HumanResourcesPotentialRdInfrastructure,
  funding_sources: FundingSources,
  regional_investment_aid_digitization: RegionalInvestmentAidDigitization,
  greening_environmental_protection_aid: GreeningEnvironmentalProtectionAid,
  rd_module_essence: RdModuleEssence,
  greening_module_essence: GreeningModuleEssence,
  regional_investment_aid_greening: RegionalInvestmentAidGreening,
  internationalization_module_essence: InternationalizationModuleEssence,
  regional_investment_aid_rd_infrastructure: RegionalInvestmentAidInfrastructure,
  research_agenda_rd_infrastructure: ResearchAgendaRdInfrastructure,
  rd_project_team: RdProjectTeam,
  innovation_implementation_module_essence: InnovationImplementationModuleEssence,
  link_between_modules: LinkBetweenModules,
  ue_horizontal_policies_project_compliance: UeHorizontalPoliciesProjectCompliance,
  applicant_other_applications: ApplicantOtherApplications,
  applicant_potential_competition: ApplicantPotentialCompetition,
  human_resources_innovation_implementation: HumanResourcesInnovationImplementation,
  human_resources_digitization: HumanResourcesDigitization,
  innovation_social_significance: InnovationSocialSignificance,
  market_transformation: MarketTransformation,
  project_collaboration: ProjectCollaboration,
  human_resources_greening: HumanResourcesGreening,
  human_resources_internationalization: HumanResourcesInternationalization,
  project_classification: ProjectClassification,
  national_key_cluster_participation: NationalKeyClusterParticipation,
  digital_innovation: DigitalInnovations,
  ecoinnovations: EcoInnovations,
  project_budget: ProjectBudget,
  edih_organization: EdihOrganization,
  edih_services: EdihServices,
  kpi_other_than_product_or_result_indicators: KpiOtherThanProductOrResultIndicators,
  launch_pad_incubation_program_concept_description: LaunchPadIncubationProgramConceptDescription,
  ecosystem_partners: EcosystemPartners,
  incubation_taking_into_account_industry_specialization: IncubationTakingIntoAccountIndustrySpecialization,
  applicant_experience_in_implementation_of_grant_projects:
  ApplicantExperienceInImplementationOfGrantProjects,
  services_implementation: ServicesImplementation,
  applicant_and_project_partners_resources: ApplicantAndProjectPartnersResources,
  applicant_branch_adress_in_eastern_poland: ApplicantBranchAdressInEasternPoland,
  applicant_potential: ApplicantPotential,
  project_implementation_justification: ProjectImplementationJustification,
  applicant_income: ApplicantIncome,
  pkw_eligibility: PkwEligibility,
  cluster_data: ClusterData,
  cluster_internationalization: ClusterInternationalization,
  cluster_coordinator_service_offer: ClusterCoordinatorServiceOffer,
  cluster_coordinator_potential: ClusterCoordinatorPotential,
  project_detailed_information: ProjectDetailedInformation,
  foreign_partners: ForeignPartners,
  applicant_experience_in_implementation_acceleration_programs: ApplicantExperienceInImplementationAccelerationPrograms,
  acceleration_program_concept: AccelerationProgramConcept,
  acceleration_program_implementation_team: AccelerationProgramImplementationTeam,
  grant_project_management_team: GrantProjectManagementTeam,
  result_of_costs_and_benefits_analysis: ResultOfCostsAndBenefitsAnalysis,
  project_implementation_concept: ProjectImplementationConcept,
  applicant_experience: ApplicantExperience,
  innovation_description: InnovationDescription,
  business_model_description: BusinessModelDescription,
  business_partners: BusinessPartners,
  export_potential: ExportPotential,
  applicant_innovation: ApplicantInnovation,
  applicant_export_activities: ApplicantExportActivities,
  applicant_product_brand: ApplicantProductBrand,
  support_so_far_received: SupportSoFarReceived,
  promotional_activities: PromotionalActivities,
  team_implementing_program_concept: TeamImplementingProgramConcept,
  applicant_experience_in_innovative_business_ideas_development:
  ApplicantExperienceInInnovativeBusinessIdeasDevelopment,
  innovator_lab_program_concept: InnovatorLabProgramConcept,
  project_sme_assumptions: ProjectSmeAssumptions,
  reduce_turnover: ReduceTurnover,
  business_idea_information: BusinessIdeaInformation,
  originator: Originator,
  venture_implementation_justification: VentureImplementationJustification,
  ue_program_subject_eurogrant: UeProgramSubjectEurogrant,
  venture_feasibility: VentureFeasibility,
  applicant_prepared_solution: ApplicantPreparedSolution,
  startup_applicant_information: StartupApplicantInformation,
  business_model_elements: BusinessModelElements,
  goz_transformation_implementation: GozTransformationImplementation,
  project_financial_resources: ProjectFinancialResources,
  applicant_representative: ApplicantRepresentative,
  person_representing_competition_participant: PersonRepresentingCompetitionParticipant,
  product_and_work_description: ProductAndWorkDescription,
  consortium_organization: ConsortiumOrganization,
  project_eligibility: ProjectEligibility,
  innovation_centre_potential: InnovationCentrePotential,
  other_accredited_oi_edih_kkk_cooperation: OtherAccreditedOiEdihKkkCooperation,
  acceleration_foreign_markets: AccelerationForeignMarkets,
  grant_project_implementation_team: GrantProjectImplementationTeam,
};

/**
 * Gets element from mapping or if not exists, returns UnhandledElement.
 *
 * @param {string} name - element name
 * @returns {Node} - element from mapping or UnhandledElement
 */
export const getElement = (name) => MAPPING[name] ?? UnhandledElement;
