import {
  ADDITIONAL_MODULE_IDS,
  ADDITIONAL_MODULE_LABELS,
  CRITERIA_BUSINESS_GROUP,
  EXPERT_PANEL_ROLES,
  MODULE_STATUSES,
} from '../_constants';
import { iriToId } from './apiRouteHelper';

/**
 * Checks if provided user id is expert
 *
 * @param {string} userId - user id
 * @param {object} expert - expert data
 * @returns {boolean}
 */
const isExpert = (userId, expert) => {
  if (expert.externalExpert) {
    return iriToId(expert.externalExpert.user['@id']) === userId;
  }

  return iriToId(expert.parpExpert.user['@id']) === userId;
};

/**
 * Get external module ids to evaluation items
 *
 * @param {string} businessGroup - evaluation item business group
 * @param {string} moduleId - module id
 * @param {boolean} isConclusive - evaluation item is conclusive
 * @returns {string}
 */
const getExternalIdsToEvaluationItem = (businessGroup, moduleId, isConclusive = false) => {
  if (isConclusive) {
    return ADDITIONAL_MODULE_IDS.kryteriaRankingujace;
  }

  if (businessGroup === CRITERIA_BUSINESS_GROUP.ogolnaFinansowa && !moduleId) {
    return ADDITIONAL_MODULE_IDS.ocenaFinansowa;
  }

  if ((businessGroup === CRITERIA_BUSINESS_GROUP.ogolna || businessGroup === CRITERIA_BUSINESS_GROUP.ogolnaModulowa)
    && !moduleId) {
    return ADDITIONAL_MODULE_IDS.kryteriaOgolem;
  }

  if (businessGroup === CRITERIA_BUSINESS_GROUP.modulowaFinansowa) {
    return ADDITIONAL_MODULE_IDS.modulowaFinansowa;
  }

  return moduleId;
};

/**
 * Transform evaluation items data, add external module ids to evaluation item
 *
 * @param {Array} evaluationItems - list of evaluation items
 * @returns {Array}
 */
const mappedEvaluationItems = (evaluationItems) => evaluationItems.map((evaluationItem) => ({
  ...evaluationItem,
  moduleId: getExternalIdsToEvaluationItem(
    evaluationItem.criteriaItem.businessGroup,
    evaluationItem.criteriaItem.moduleId,
    evaluationItem.criteriaItem.conclusive,
  ),
  justifications: [],
}));

/**
 * Get expert data by provided user id
 *
 * @param {Array} experts - list of experts
 * @param {string} userId - user id
 * @returns {object}
 */
const getUserExpertData = (experts, userId) => experts.find((expert) => isExpert(userId, expert)) || {};

/**
 * Get list of experts from all modules
 *
 * @param {Array} modules - list of modules
 * @returns {Array}
 */
const getExpertListFromModules = (modules) => modules.map(({ experts }) => experts).flat();

/**
 * Checks if module is financial
 *
 * @param {Array} modules - list of modules
 * @returns {boolean}
 */
const isFinancialModule = (modules) => modules.some(({ moduleId }) => moduleId === null) || false;

/**
 * Get financial module data if exist
 *
 * @param {Array} modules - list of modules
 * @returns {object}
 */
const getFinancialModule = (modules) => modules.find(({ moduleId }) => moduleId === null) || {};

/**
 * Checks if provided user id is a financial expert
 *
 * @param {Array} modules - list of modules
 * @param {string} userId - user id
 * @returns {boolean}
 */
const isFinancialExpert = (modules, userId) => {
  if (!isFinancialModule(modules)) {
    return false;
  }
  const financialModule = getFinancialModule(modules);

  return financialModule.experts.some((expert) => isExpert(userId, expert));
};

/**
 * Get module status by expert
 *
 * @param {object} expert - single expert data
 * @returns {string}
 */
const getModuleStatusByExpert = (expert) => {
  if (expert.accepted) {
    return MODULE_STATUSES.accepted;
  }
  if (expert.refused) {
    return MODULE_STATUSES.refused;
  }

  return MODULE_STATUSES.waiting;
};

/**
 * Get module status by user
 *
 * @param {object} module - single module data
 * @param {string} userId - user id
 * @param {boolean} isEmployeeOrAdmin - current user is employee ( chairman or viceChairman ) or admin
 * @returns {string}
 */
const getModuleStatus = (module, userId, isEmployeeOrAdmin) => {
  if (isEmployeeOrAdmin) {
    return MODULE_STATUSES.accepted;
  }

  if (module.experts.some((expert) => isExpert(userId, expert))) {
    const expert = getUserExpertData(module.experts, userId);

    return getModuleStatusByExpert(expert);
  }

  return MODULE_STATUSES.readonly;
};

/**
 * Get user role
 *
 * @param {object} expert - expert data
 * @param {boolean} isFinancial - current user is financial expert
 * @param {boolean} isEmployeeOrAdmin - current user is employee ( chairman or viceChairman ) or admin
 * @returns {string}
 */
const getExpertRole = (expert, isFinancial, isEmployeeOrAdmin) => {
  if (isEmployeeOrAdmin) {
    return EXPERT_PANEL_ROLES.employeeOrAdmin;
  }

  if (isFinancial && expert) {
    if (expert.role === EXPERT_PANEL_ROLES.expert) {
      return EXPERT_PANEL_ROLES.financial;
    }

    return EXPERT_PANEL_ROLES.financialLeadingExpert;
  }
  if (expert?.role === EXPERT_PANEL_ROLES.expert) {
    return EXPERT_PANEL_ROLES.expert;
  }

  if (expert?.role === EXPERT_PANEL_ROLES.leading_expert) {
    return EXPERT_PANEL_ROLES.leading_expert;
  }

  if (expert?.role === EXPERT_PANEL_ROLES.financial_expert) {
    return EXPERT_PANEL_ROLES.financial_expert;
  }

  return EXPERT_PANEL_ROLES.readonly;
};

/**
 * Verifies that expert has accepted at least one module
 *
 * @param {Array} modules - list of modules
 * @param {string} userId - user id
 * @returns {boolean}
 */
const hasAcceptedMinOneModule = (modules, userId) => getExpertListFromModules(modules)
  .filter((expert) => isExpert(userId, expert))
  .filter(({ accepted }) => accepted).length !== 0;

/**
 * Add additional modules
 *
 * @param {string} userId - user id
 * @param {boolean} isLeadingExpert - current user is leading expert for application
 * @param {boolean} expertHasAcceptedMinOneModule - current user has accepted at least one module
 * @param {boolean} withConclusiveCriteria - should add conclusive criteria module to list of modules
 * @returns {Array}
 */
const addGeneralModules = (userId, isLeadingExpert, expertHasAcceptedMinOneModule, withConclusiveCriteria) => {
  const experts = [{
    externalExpert: {
      user: {
        '@id': `/lsi/auth/api/users/${userId}`,
      },
    },
    role: isLeadingExpert ? EXPERT_PANEL_ROLES.leading_expert : EXPERT_PANEL_ROLES.expert,
    accepted: expertHasAcceptedMinOneModule,
  }];

  const generalModules = [
    {
      moduleId: ADDITIONAL_MODULE_IDS.kryteriaOgolem,
      moduleName: ADDITIONAL_MODULE_LABELS.kryteriaOgolem,
      experts,
    },
  ];

  if (withConclusiveCriteria) {
    generalModules.push({
      moduleId: ADDITIONAL_MODULE_IDS.kryteriaRankingujace,
      moduleName: ADDITIONAL_MODULE_LABELS.kryteriaRankingujace,
      experts,
    });
  }

  return generalModules;
};

/**
 * Transform modules data, add additional modules
 *
 * @param {Array} modules - list of modules
 * @param {string} userId - user id
 * @param {boolean} isEmployeeOrAdmin - current user is employee ( chairman or viceChairman ) or admin
 * @param {boolean} isLeadingExpert - current user is leading expert for application
 * @param {boolean} withConclusiveCriteria - should add conclusive criteria module to list of modules
 * @returns {Array}
 */
const mappedModules = (modules, userId, isEmployeeOrAdmin, isLeadingExpert, withConclusiveCriteria = false) => [
  ...modules,
  ...addGeneralModules(
    userId,
    isLeadingExpert,
    hasAcceptedMinOneModule(modules, userId),
    withConclusiveCriteria
  ),
].map((module) => ({
  ...module,
  moduleStatus: getModuleStatus(module, userId, isEmployeeOrAdmin),
  expertRole: getExpertRole(
    getUserExpertData(module.experts, userId),
    isFinancialExpert(modules, userId),
    isEmployeeOrAdmin,
  ),
  expertData: getUserExpertData(module.experts, userId),
}));

/**
 * Filter evaluation items by access
 *
 * @param {Array} evaluationItems - list of evaluation items
 * @param {Array} modules - list of modules
 * @returns {Array}
 */
const filterEvaluationItemsByAccess = (evaluationItems, modules) => evaluationItems.map((evaluationItem) => {
  const currentModule = modules.find((module) => module.moduleId === evaluationItem.moduleId);

  if (currentModule?.currentExpertRole !== EXPERT_PANEL_ROLES.readonly
    && currentModule?.moduleStatus === MODULE_STATUSES.accepted) {
    return evaluationItem.id;
  }

  return null;
}).filter((evaluationItem) => evaluationItem !== null);

/**
 * Extract expert full name by provided data
 *
 * @param {object} expert - single expert data
 * @returns {string}
 */
const extractFullNameFromExpert = (expert) => {
  if (expert.externalExpert) {
    return expert.externalExpert.user.fullName;
  }

  return expert.parpExpert.user.fullName;
};

/**
 * Extract user id by provided expert data
 *
 * @param {object} expert - expert data
 * @returns {object}
 */
const extractIdFromExpert = (expert) => {
  if (expert.externalExpert) {
    return expert.externalExpert.user.id;
  }

  return expert.parpExpert.user.id;
};

export const evaluationSheetHelper = {
  getExternalIdsToEvaluationItem,
  getUserExpertData,
  getExpertListFromModules,
  mappedEvaluationItems,
  isExpert,
  isFinancialModule,
  isFinancialExpert,
  getModuleStatus,
  getExpertRole,
  hasAcceptedMinOneModule,
  addGeneralModules,
  mappedModules,
  filterEvaluationItemsByAccess,
  extractFullNameFromExpert,
  extractIdFromExpert,
};
