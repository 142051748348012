import { Controller } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useGlobalDialog } from '../../../../../Context';

/**
 * Evaluation sheet add chairman remarks form.
 *
 * @param {object} props - root props
 * @param {Function} props.control - control from react-hook-form's useFormContext
 * @param {Function} props.onSubmit - handle submit form
 * @param {boolean} props.isReadonly - evaluation sheet is read-only
 * @param {boolean} props.isEmployeeOrAdmin - is current user has role employee or admin
 * @returns {EvaluationSheetAddChairmanRemarksForm}
 */
export function EvaluationSheetAddChairmanRemarksForm({
  control, onSubmit, isReadonly, isEmployeeOrAdmin,
}) {
  const { closeAll } = useGlobalDialog();

  return (
    <>
      <InputLabel htmlFor="remark">
        Uwagi przewodniczącego
      </InputLabel>
      <FormControl
        variant="outlined"
        fullWidth
        margin="dense"
      >
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Proszę uzupełnić pole "Uwagi przewodniczącego"',
            },
          }}
          name="remark"
          control={control}
          render={({
            field: {
              onChange,
              value,
              name,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              multiline
              id={name}
              name={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              required={isEmployeeOrAdmin || !isReadonly}
              disabled={!isEmployeeOrAdmin || isReadonly}
            />
          )}
        />
      </FormControl>
      <Box mt={5} display="flex" gap={2}>
        {(isEmployeeOrAdmin && !isReadonly) && (
          <Button
            type="button"
            id="oKN2Ubr76sy60qT"
            variant="contained"
            color="secondary"
            onClick={onSubmit}
          >
            Zapisz
          </Button>
        )}
        <Button
          type="button"
          id="u271MmRJPCWYFP3"
          variant="outlined"
          color="primary"
          onClick={closeAll}
        >
          Zamknij
        </Button>
      </Box>
    </>
  );
}

EvaluationSheetAddChairmanRemarksForm.propTypes = {
  control: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  isEmployeeOrAdmin: PropTypes.bool.isRequired,
};
