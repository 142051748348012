import PropTypes from 'prop-types';
import { Fragment } from 'react';
import {
  View,
  Svg,
  Path,
} from '@react-pdf/renderer';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { printStyle } from '../../print.style';
import PrintoutTableCell from '../../Components/Table/PrintoutTableCell';
import PrintoutTableRow from '../../Components/Table/PrintoutTableRow';
import { useApplicationToPdfContext } from '../../../../Context';

/**
 * Statements Pdf element.
 *
 * @param {object} props - root props
 * @param {object} props.elementData - elementData
 * @returns {StatementsPdf}
 */
function StatementsPdf({ elementData }) {
  const { elementDefinition: { fields } } = elementData;

  const checkBoxOutlinedIconSvgPath = CheckBoxOutlinedIcon.type.render().props.children.props.d;

  const {
    managedApplicationTemplate: {
      initialFormData,
    },
  } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      {fields.map((field) => (
        <Fragment key={field?.uuid}>
          <PrintoutTableRow>
            <PrintoutTableCell bgColor="gray" rowSpan={1}>
              {`${field.ordinalNumber}. ${field.label}`}
            </PrintoutTableCell>
          </PrintoutTableRow>
          {field.hasAdditionalOptions && field.options?.map(({
            name, label,
          }) => (
            <PrintoutTableRow>
              {(Object.keys(initialFormData[field.name]).includes(name)
              && initialFormData[field.name][name])
                ? (
                  <>
                    <PrintoutTableCell customWidth="90%">
                      {label}
                    </PrintoutTableCell>
                    <PrintoutTableCell customWidth="10%" isTextValue={false}>
                      <Svg width={20} height={20} fill="black">
                        <Path d={checkBoxOutlinedIconSvgPath} fill="black" />
                      </Svg>
                    </PrintoutTableCell>
                  </>
                )
                : <PrintoutTableCell rowSpan={1}>{label}</PrintoutTableCell>}

            </PrintoutTableRow>
          ))}
        </Fragment>
      ))}
    </View>
  );
}

export default StatementsPdf;

StatementsPdf.propTypes = {
  elementData: PropTypes.objectOf(Object).isRequired,
};
