import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';
import { getImplementersAndApplicantNameForPdf } from '../../../../_helpers/getImplementersAndApplicantName';
import { useApplicationToPdfContext } from '../../../../Context';

/**
 * ConsortiumOrganization Pdf element.
 *
 * @returns {ConsortiumOrganizationPdf}
 */
function ConsortiumOrganizationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    },
    id,
    previousInitialFormData,
  } = useApplicationToPdfContext();

  const applicantName = initialFormData?.applicant_name || '';
  const implementers = initialFormData?.implementers || [];
  const previousApplicantName = previousInitialFormData?.applicant_name || '';
  const previousImplementers = previousInitialFormData?.implementers || [];
  const partnerName = [
    ...getImplementersAndApplicantNameForPdf(applicantName, implementers, id),
    ...getImplementersAndApplicantNameForPdf(previousApplicantName, previousImplementers, id),
  ];

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Nazwa Konsorcjum */}
        <RowField name="consortium_organization_consortium_name" isSingleRow />
        {/* Doświadczenie i kompetencje 2 kluczowych ekspertów oceniających startupy biorących udział w naborze */}
        <RowField name="consortium_organization_accreditation_date" />
        {/* Zakres akredytacji */}
        <RowField name="consortium_organization_accreditation_scope" />
        {/* Posiadane zasoby techniczne i kadrowe do realizacji projektu */}
        <RowField name="consortium_organization_technical_resources" isSingleRow />
        {/* Potrzeby rozwojowe Konsorcjum */}
        <RowField name="consortium_organization_development_needs" isSingleRow />
        {/* Liczba zatrudnionych osób realizujących projekt */}
        <RowField name="consortium_organization_employed_persons_number" />
        {/* Liczba zatrudnionych kobiet realizujących projekt */}
        <RowField name="consortium_organization_employed_women_number" />
        {/* Źródła i sposób finansowania projektu */}
        <RowField name="consortium_organization_financing_method" isSingleRow />
        {/* Kluczowe ryzyka związane z realizacją projektu oraz działania ograniczające zidentyfikowane ryzyka */}
        <RowField name="consortium_organization_key_risks" isSingleRow />
        {/* Posiadane zasoby techniczne i kadrowe do realizacji projektu Wnioskodawcy – Lidera Konsorcjum */}
        <RowField name="consortium_organization_leader_available_resources" isSingleRow />
        {/* Tabela "Zadania i posiadane zasoby techniczne i kadrowe do realizacji projektu Partnerów Konsorcjum" */}
        <CollectionWrapper name="consortium_organization_partners_available_resources">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index}>
                <View style={printStyle.table}>
                  <FieldSorter fieldsOrder={Object.keys(collectionData[index])}>
                    <RowField
                      name={`${collectionName}.${index}.partner_name`}
                      value={item.partner_name}
                      oldValue={previousRowData?.partner_name}
                      externalDictionary={partnerName}
                    />
                    <RowField
                      name={`${collectionName}.${index}.partner_task`}
                      value={item.partner_task}
                      oldValue={previousRowData?.partner_task}
                      isSingleRow
                    />
                    <RowField
                      name={`${collectionName}.${index}.partner_resources_available`}
                      value={item.partner_resources_available}
                      oldValue={previousRowData?.partner_resources_available}
                      isSingleRow
                    />
                  </FieldSorter>
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
      </FieldSorter>
    </View>
  );
}

export default ConsortiumOrganizationPdf;
