import {
  printoutToDocxMaterialAndFinancialScheduleTemplate,
// eslint-disable-next-line max-len
} from '../Features/PrintToDocx/PrintoutToDocxMaterialAndFinancialSchedule/printoutToDocxMaterialAndFinancialSchedule.template';
import {
  printoutToDocxIndicatorsTemplate,
} from '../Features/PrintToDocx/PrintoutToDocxIndicators/printoutToDocxIndicators.template';

export const PRINT_TO_DOCX_TEMPLATE_TYPES = {
  hrf: 'hrf',
  indicators: 'indicators',
};

export const PRINT_TO_DOCX_TEMPLATE_BY_TYPES = {
  [PRINT_TO_DOCX_TEMPLATE_TYPES.hrf]: printoutToDocxMaterialAndFinancialScheduleTemplate,
  [PRINT_TO_DOCX_TEMPLATE_TYPES.indicators]: printoutToDocxIndicatorsTemplate,
};
