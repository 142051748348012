import {
  Box,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEvaluationSheetsContext } from '../../../../../Context';
import EvaluationSheetAcceptButton from './EvaluationSheetAcceptButton';
import { iriToId } from '../../../../../_helpers';
import EvaluationSheetRejectButton from './EvaluationSheetRejectButton';

/**
 * EvaluationSheetModuleActionButtons element.
 *
 * @param {object} props - root props
 * @param {object} props.module - module data
 * @returns {EvaluationSheetModuleActionButtons}
 */
export function EvaluationSheetModuleActionButtons({ module }) {
  const { expertPanel } = useEvaluationSheetsContext();

  const requestPayload = {
    moduleId: module.moduleId,
    moduleName: module.moduleName,
    expertPanel: expertPanel['@id'],
    expert: module.expertData.externalExpert['@id'],
    role: module.expertData.role,
    evaluationFee: module.expertData.evaluationFee,
  };

  return (
    <>
      <Typography variant="h4" textAlign="center">
        Musisz zaakceptować lub odrzucić moduł aby kontynuować.
      </Typography>
      <Box mt={2} display="flex" flexWrap="wrap" width="100%" gap={2} justifyContent="center">
        <EvaluationSheetAcceptButton
          requestPayload={requestPayload}
          expertByUsersId={iriToId(module.expertData['@id'])}
        />
        <EvaluationSheetRejectButton
          requestPayload={requestPayload}
          expertByUsersId={iriToId(module.expertData['@id'])}
        />
      </Box>
    </>
  );
}

EvaluationSheetModuleActionButtons.propTypes = {
  module: PropTypes.instanceOf(Object).isRequired,
};
