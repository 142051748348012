import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useEffect } from 'react';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
} from '../../../_helpers';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { FieldSorter } from './FieldSorter';
import CharsCounter from '../../CharsCounter';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { MonetaryField } from '../../MonetaryField';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { BOOLEAN_VALUES } from '../../../_constants';

/**
 * ProjectEligibility element renderer.
 *
 * @returns {ProjectEligibility}
 */
export function ProjectEligibility() {
  const {
    isReadonly, control, fieldsConfig,
  } = useElementContext();
  const collectionName = 'project_eligibility_developed_services';
  const { watch } = useFormContext();
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });

  const developedServicesConfig = fieldsConfig[collectionName];
  const subfields = developedServicesConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = developedServicesConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <FieldSorter>
      {/* Data akredytacji Ośrodka Innowacji  */}
      <FormField name="project_eligibility_accreditation_date">
        {({
          onChange, value, name, label, onBlur, error,
        }) => (
          <CustomDesktopDatePicker
            ariaLabel={label}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zakres akredytacji Ośrodka Innowacji */}
      <FormField name="project_eligibility_accreditation_scope">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
            multiple
            renderSelectAllButton={false}
          />
        )}
      </FormField>
      {/* Dotychczasowa oferta usługowa  */}
      <FormField name="project_eligibility_service_offering">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela "Usługi rozwijane w ramach projektu" */}
      <LabeledFieldRow fieldConfig={developedServicesConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              collectionName,
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Nazwa usługi */}
            <FormField name={`${collectionName}.${index}.service_name`}>
              {({
                onChange, value, name, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                  multiple
                  renderSelectAllButton={false}
                />
              )}
            </FormField>
            {/* Średnia wartość pomocy de minimis dla przedsiębiorcy w związku ze świadczeniem usługi */}
            <FormField name={`${collectionName}.${index}.average_de_minimis_aid`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  variant="outlined"
                  defaultValue={value}
                  error={!!error}
                  helperText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  disabled={isReadonly}
                />
              )}
            </FormField>
            {/* Planowana liczba zrealizowanych usług */}
            <FormField name={`${collectionName}.${index}.services_provided_number`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <TextField
                  id={name}
                  name={name}
                  variant="outlined"
                  defaultValue={value}
                  error={!!error}
                  helperText={error?.message}
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                  disabled={isReadonly}
                />
              )}
            </FormField>
            {/* Opis usługi, sposobu jej świadczenia, zasobów, mierzalnych celów i sposobu wyceny */}
            <FormField name={`${collectionName}.${index}.service_description`}>
              {({
                value, onChange, onBlur, error, name, maxLength,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Czy sposób świadczenia usługi opiera się o rozwiązanie cyfrowe */}
            <FormField name={`${collectionName}.${index}.is_service_delivery_based_on_digital_solutions`}>
              {({
                onChange, value, name, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Charakterystyka wykorzystywanych narzędzi informatycznych (inne niż poczta elektroniczna) */}
            <FormField name={`${collectionName}.${index}.tools_characteristics`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Uzasadnienie rozwoju usługi z perspektywy potrzeb rynku, rozwoju potencjału kadrowego lub
            kompetencyjnego lub organizacyjnego lub infrastrukturalnego OI */}
            <FormField name={`${collectionName}.${index}.service_development_justification`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Akredytacja usługi */}
            <FormField name={`${collectionName}.${index}.service_accreditation`}>
              {({
                onChange, value, name, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Czy usługi, których celem jest rozwój kompetencji kadr przedsiębiorstw w branżach, w których funkcjonuje
            Sektorowa Rada ds.kompetencji,lub Grupa robocza KIS Wnioskodawca wykorzystuje dostępne rekomendacje Rady */}
            <FormField name={`${collectionName}.${index}.services_available_from_group_recommendations`}>
              {({
                onChange, value, name, allChoices, onBlur, error,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={allChoices}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {(watch(`${collectionName}.${index}.services_available_from_group_recommendations`) === BOOLEAN_VALUES.TRUE) && (
              <FormField name={`${collectionName}.${index}.group_used_description`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
            )}
          </CollectionFieldContainer>
        ))}
        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="Fsp3gXHMqnoFzfZ"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
