import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import { useEvaluationItemContext } from '../../../../../Context';
import { iriToId } from '../../../../../_helpers';
import { useAuth } from '../../../../../_security';
import { EvaluationSheetSingleQuestionForm } from './EvaluationSheetSingleQuestion.form';

/**
 * Evaluation sheet questions form.
 *
 * @returns {EvaluationSheetQuestionsForm}
 */
export function EvaluationSheetQuestionsForm() {
  const { evaluationItem } = useEvaluationItemContext();
  const evaluationItemId = evaluationItem.id;
  const collectionName = `${evaluationItemId}.questions`;
  const { watch } = useFormContext();
  const { id: loggedUserId } = useAuth();
  const questions = watch(collectionName);

  return (
    <Grid container spacing={3} mt={0}>
      {questions.filter(({ active }) => active).map((question) => {
        const questionIndex = questions.findIndex(({ id }) => id === question.id);
        const isQuestionAuthor = iriToId(watch(`${collectionName}.${questionIndex}.createdBy.@id`)) === loggedUserId;

        return (
          <EvaluationSheetSingleQuestionForm
            key={question.id}
            questionIndex={questionIndex}
            question={question}
            isQuestionAuthor={isQuestionAuthor}
          />
        );
      })}
    </Grid>
  );
}
