import { Button } from '@mui/material';
import uniqid from 'uniqid';
import {
  useEvaluationItemContext,
  useGlobalDialog,
} from '../../../../../Context';
import { EvaluationSheetHistoryOfChairmanRemarksList } from './EvaluationSheetHistoryOfChairmanRemarksList';

/**
 * Evaluation sheet history of chairman remarks button.
 *
 * @returns {EvaluationSheetHistoryOfChairmanRemarksButton}
 */
export function EvaluationSheetHistoryOfChairmanRemarksButton() {
  const { render } = useGlobalDialog();
  const { evaluationItem: { chairmanRemarks } } = useEvaluationItemContext();
  const oldChairmanRemarks = chairmanRemarks.filter(({ active }) => !active);

  if (oldChairmanRemarks.length === 0) {
    return null;
  }

  return (
    <Button
      id={uniqid()}
      onClick={() => render(
        <EvaluationSheetHistoryOfChairmanRemarksList remarksList={oldChairmanRemarks} />,
        'Historia uwag przewodniczącego'
      )}
      variant="outlined"
      color="secondary"
    >
      Pokaż historię uwag przewodniczącego
    </Button>
  );
}
