import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  useEffect,
  useRef,
} from 'react';
import { useElementContext } from '../../../Context';
import {
  API_ROUTE,
  CHARS_LIMIT,
  DICTIONARIES,
  BOOLEAN_VALUES,
  ELEMENTS_DICTIONARIES_MAP,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { FormField } from '../../Application/Application/FormField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { FieldSorter } from './FieldSorter';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';
import {
  getCollectionFieldObject,
  addItem,
  removeItem,
  setValuesForOtherGeographicalFields,
  townParser,
  getPropertyFormWhetherLegalForm,
  formatPkdCodesFieldValue,
  iriToId,
} from '../../../_helpers';
import { ImprovedMaskedInput } from '../../ImprovedMaskedInput';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CharsCounter from '../../CharsCounter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import CustomDesktopDatePicker from '../../CustomDesktopDatePicker';
import { PrivatePartnershipPartners } from './PrivatePartnershipPartners';

/**
 * Implementers application element.
 *
 * @returns {Implementers}
 */
export function Implementers() {
  const streetsSelectRef = useRef({});
  const {
    fieldsConfig,
    isReadonly,
  } = useElementContext();
  const {
    control,
    watch,
    setValue,
    resetField,
  } = useFormContext();

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.implementerTypes,
    DICTIONARIES.companySize,
    DICTIONARIES.propertyForms,
    DICTIONARIES.vatRecoveryPossibilities,
    DICTIONARIES.geoDictionariesCountries,
    DICTIONARIES.legalEntityTypes,
    DICTIONARIES.innovationCenterType,
    DICTIONARIES.projectFinancingSources,
  );

  const {
    REGON, KRS, PESEL, NIP,
  } = CHARS_LIMIT;

  const { vatRecoveryPossibility } = ELEMENTS_DICTIONARIES_MAP;

  const propertyForms = get(DICTIONARIES.propertyForms.name);
  const hasImplementersValue = watch('has_implementers');

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'implementers',
  });

  const implementersConfig = fieldsConfig.implementers;
  const subfields = implementersConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber, minRowNumber,
  } = implementersConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0 && hasImplementersValue === BOOLEAN_VALUES.TRUE) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber, hasImplementersValue]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  if (!implementersConfig) {
    return <span>Brak konfiguracji elementu Realizatorzy</span>;
  }

  return (
    <FieldSorter>
      {/* Czy wnioskodawca przewiduje udział innych podmiotów w realizacji projektu */}
      <FormField name="has_implementers">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.TRUE) {
                resetField('implementers', { defaultValue: FIELD_TYPES_MAP.collection });
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>

      <LabeledFieldRow
        hide={hasImplementersValue === BOOLEAN_VALUES.FALSE}
        fieldConfig={implementersConfig}
        contextHelpLabel
        fullWidth
      >
        {fields.map((item, index) => {
          const mapKeys = [
            `implementers.${index}.country`,
            `implementers.${index}.voivodeship`,
            `implementers.${index}.district`,
            `implementers.${index}.commune`,
            `implementers.${index}.street`,
          ];

          const selectedCity = watch(`implementers.${index}.city`);
          const partnersConfig = implementersConfig.fields.implementers_partners_of_a_civil_partnership;
          const vatRecoveryPossibilityValue = watch(`implementers.${index}.vat_recovery_possibility`);
          const isJustificationRecoveryVatVisible = vatRecoveryPossibilityValue
            === vatRecoveryPossibility.partially.id || vatRecoveryPossibilityValue === vatRecoveryPossibility.no.id;
          const pkdCodesChoices = fieldsConfig?.[`implementers.${index}.pkd`]?.choices || [];

          return (
            <CollectionFieldContainer
              onRemove={() => removeItem(
                fields,
                minRowNumber,
                remove,
                setValue,
                'implementers',
                index,
              )}
              itemIndex={index}
              isReadonly={isReadonly}
              fieldsOrder={Object.keys(subfields)}
              buttonRemoveVisible={fields.length > minRowNumber}
              key={item.id}
              isIndexed
            >
              {/* Nazwa */}
              <FormField name={`implementers.${index}.name`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Forma prawna */}
              <FormField name={`implementers.${index}.legal_form`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.legalEntityTypes.name))}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Typ realizatora */}
              <FormField name={`implementers.${index}.implementer_type`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.implementerTypes.name))}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Email */}
              <FormField name={`implementers.${index}.email`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Telefon */}
              <FormField name={`implementers.${index}.phone_number`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Miejscowość */}
              <FormField name={`implementers.${index}.city`}>
                {({
                  onChange, value, name, onBlur, error,
                }) => (
                  <ApiAutocomplete
                    id={name}
                    filterBy="name"
                    initialValue={value?.value}
                    initialValueFilterBy="id"
                    optionParser={townParser}
                    changeHandler={(selectedValue) => {
                      streetsSelectRef.current[index].clear();
                      onChange(selectedValue);
                      setValuesForOtherGeographicalFields(selectedValue, setValue, mapKeys);
                    }}
                    textFieldProps={{
                      onBlur,
                    }}
                    charLimitExclusionWords={['oś']}
                    baseUrl={API_ROUTE.geoDictionaries.towns}
                    disabled={isReadonly}
                    error={error}
                    staticFilters={{ itemsPerPage: '500' }}
                  />
                )}
              </FormField>
              {/* Wielkość przedsiębiorstwa */}
              <FormField name={`implementers.${index}.company_size`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.companySize.name))}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Forma własności */}
              <FormField name={`implementers.${index}.property_form`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={getPropertyFormWhetherLegalForm(
                      filterOptions,
                      watch(`implementers.${index}.legal_form`),
                      propertyForms,
                    )}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Możliwość odzyskania VAT */}
              <FormField name={`implementers.${index}.vat_recovery_possibility`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={(event) => {
                      onChange(event);
                      if (event.target.value !== vatRecoveryPossibility.partially.id
                        && event.target.value !== vatRecoveryPossibility.no.id) {
                        resetField(
                          `implementers.${index}.implementers_justification_of_the_inability_to_recover_vat`,
                          { defaultValue: FIELD_TYPES_MAP.text },
                        );
                      }
                    }}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.vatRecoveryPossibilities.name))}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Uzasadnienie braku możliwości odzyskania VAT */}
              {isJustificationRecoveryVatVisible && (
                <FormField name={`implementers.${index}.implementers_justification_of_the_inability_to_recover_vat`}>
                  {({
                    onChange, value, name, maxLength, onBlur, error,
                  }) => (
                    <TextField
                      defaultValue={value}
                      multiline
                      id={name}
                      name={name}
                      onChange={onChange}
                      onBlur={onBlur}
                      variant="outlined"
                      InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                      inputProps={{ maxLength }}
                      disabled={isReadonly}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                </FormField>
              )}
              {/* Kraj */}
              <FormField name={`implementers.${index}.country`}>
                {({ value }) => (
                  <TextField
                    value={value?.label || ''}
                    disabled
                  />
                )}
              </FormField>
              {/* Województwo */}
              <FormField name={`implementers.${index}.voivodeship`}>
                {({ value }) => (
                  <TextField
                    value={value?.label || ''}
                    disabled
                  />
                )}
              </FormField>
              {/* Powiat */}
              <FormField name={`implementers.${index}.district`}>
                {({ value }) => (
                  <TextField
                    value={value?.label || ''}
                    disabled
                  />
                )}
              </FormField>
              {/* Gmina */}
              <FormField name={`implementers.${index}.commune`}>
                {({ value }) => (
                  <TextField
                    value={value?.label || ''}
                    disabled
                  />
                )}
              </FormField>
              {/* Ulica */}
              <FormField name={`implementers.${index}.street`}>
                {({
                  onChange, value, name, onBlur, error,
                }) => (
                  <ApiAutocomplete
                    id={name}
                    disabled={!selectedCity?.value || isReadonly}
                    /* eslint-disable-next-line no-return-assign */
                    ref={(el) => (streetsSelectRef.current = {
                      ...streetsSelectRef.current,
                      [index]: el,
                    })}
                    filterBy="name"
                    staticFilters={{
                      'town.id': selectedCity?.value,
                    }}
                    textFieldProps={{
                      onBlur,
                    }}
                    initialValue={value?.value}
                    initialValueFilterBy="id"
                    optionParser={({
                      id, name: label,
                    }) => ({
                      value: id,
                      label,
                    })}
                    changeHandler={onChange}
                    baseUrl={API_ROUTE.geoDictionaries.streets}
                    error={error}
                    minCharsToRequest={2}
                  />
                )}
              </FormField>
              {/* Nr budynku */}
              <FormField name={`implementers.${index}.house_number`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Nr lokalu */}
              <FormField name={`implementers.${index}.apartment_number`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Kod pocztowy */}
              <FormField name={`implementers.${index}.postal_code`}>
                {({
                  onChange, value, name, onBlur, error,
                }) => (
                  <TextField
                    value={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    placeholder="__-___"
                    InputProps={{
                      inputComponent: ImprovedMaskedInput,
                    }}
                    inputProps={{
                      mask: '00-000',
                    }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Strona www */}
              <FormField name={`implementers.${index}.web_page`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* NIP */}
              <FormField name={`implementers.${index}.nip`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    value={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    InputProps={{
                      inputComponent: ImprovedMaskedInput,
                      endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? NIP} />,
                    }}
                    inputProps={{
                      mask: '0000000000',
                      maxLength: maxLength ?? NIP,
                    }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* REGON */}
              <FormField name={`implementers.${index}.regon`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    value={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    disabled={isReadonly}
                    InputProps={{
                      inputComponent: ImprovedMaskedInput,
                      endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? REGON} />,
                    }}
                    inputProps={{
                      mask: '000000000',
                      maxLength: maxLength ?? REGON,
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Numer w Krajowym Rejestrze Sądowym  */}
              <FormField name={`implementers.${index}.krs_number`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    value={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    disabled={isReadonly}
                    InputProps={{
                      inputComponent: ImprovedMaskedInput,
                      endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? KRS} />,
                    }}
                    inputProps={{
                      mask: '0000000000',
                      maxLength: maxLength ?? KRS,
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* PESEL */}
              <FormField name={`implementers.${index}.pesel`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{
                      endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength ?? PESEL} />,
                    }}
                    inputProps={{ maxLength: maxLength ?? PESEL }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Numer zagraniczny */}
              <FormField name={`implementers.${index}.foreign_number`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Data podpisania porozumienia/ umowy o partnerstwie */}
              <FormField name={`implementers.${index}.implementers_date_of_signing_the_agreement`}>
                {({
                  onChange, value, name, label, onBlur, error,
                }) => (
                  <CustomDesktopDatePicker
                    onChange={onChange}
                    value={value}
                    name={name}
                    disabled={isReadonly}
                    ariaLabel={label}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Rola podmiotu w realizacji usług EDIH na rzecz MŚP */}
              <FormField
                name={`implementers.${index}.implementers_role_of_the entity_in_the_implementation_of_edih_services`}
              >
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Tabela: Wspólnicy spółki cywilnej – partnerzy */}
              <LabeledFieldRow fieldConfig={partnersConfig} contextHelpLabel>
                <PrivatePartnershipPartners
                  collectionName={`implementers.${index}.implementers_partners_of_a_civil_partnership`}
                />
              </LabeledFieldRow>

              {/* Etykieta: Charakterystyka partnera projektowego */}
              <FormField name={`implementers.${index}.project_partner_characteristics`} contextHelpLabel>
                {() => null}
              </FormField>
              {/* Rodzaj ośrodka innowacji */}
              <FormField name={`implementers.${index}.innovation_center_type`}>
                {({
                  onChange, value, name, onBlur, error, filterOptions,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    multiple
                    renderSelectAllButton={false}
                    options={filterOptions(get(DICTIONARIES.innovationCenterType.name))}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Uzasadnienie spełniania definicji ośrodka innowacji */}
              <FormField name={`implementers.${index}.justification_for_meeting_definition`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Sposób wyboru partnera */}
              <FormField name={`implementers.${index}.partner_selection_method`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Profil działalności partnera */}
              <FormField name={`implementers.${index}.partner_business_profile`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Data rozpoczęcia działalności zgodnie z dokumentem rejestrowym */}
              <FormField name={`implementers.${index}.start_date`}>
                {({
                  onChange, value, name, label, onBlur, error,
                }) => (
                  <CustomDesktopDatePicker
                    onChange={onChange}
                    value={value}
                    name={name}
                    disabled={isReadonly}
                    ariaLabel={label}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Numer kodu PKD przeważającej działalności */}
              <FormField name={`implementers.${index}.pkd`}>
                {({
                  onChange, value, name, onBlur, error,
                }) => (
                  <ApiAutocomplete
                    id={name}
                    filterBy="nameOrCode"
                    initialValue={iriToId(value)}
                    initialValueFilterBy="id"
                    changeHandler={({ id }) => onChange(id)}
                    optionParser={formatPkdCodesFieldValue}
                    textFieldProps={{ onBlur }}
                    baseUrl={API_ROUTE.elementDictionaries.pkdCodes}
                    disabled={isReadonly}
                    customFilter={pkdCodesChoices.length === 0
                      ? () => true
                      : (payloadObject) => pkdCodesChoices.includes(payloadObject.id)}
                    error={error}
                  />
                )}
              </FormField>

              {/* Opis wcześniej nawiązanej relacji krajowego partnera z Wnioskodawcą */}
              <FormField name={`implementers.${index}.description_relationship_national_partner_with_applicant`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Źródła finansowania przedsięwzięcia zrealizowanego przez krajowego partnera z
               wspólnie z Wnioskodawcą */}
              <FormField name={`implementers.${index}.project_financing_source`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.projectFinancingSources.name))}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Opis działalności krajowego partnera w kontekście planowanej współpracy w ramach programu
              akceleracji */}
              <FormField name={`implementers.${index}.description_national_partner_activities`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Informacje na temat przewidywanej formy i zakresu zaangażowania krajowego partnera w program
              akceleracyjny w konkretnych działaniach */}
              <FormField name={`implementers.${index}.information_on_involvement_national_partner`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Czy do wniosku o dofinansowanie dołączono pisemną deklarację od partnera zawierającą
              oświadczenie o gotowości współfinansowania kosztów operacyjnych akceleratora nie podlegających
              dofinansowaniu w projekcie? */}
              <FormField name={`implementers.${index}.statement_on_willingness_co_finance_operating_costs`}>
                {({
                  onChange, value, name, allChoices, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={allChoices}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Czy do wniosku o dofinansowanie dołączono pisemną deklarację krajowego partnera o zaangażowaniu
              w projekt i możliwości walidacji rozwiązań startupów (dotyczy partnera, który został wskazany jako
              Odbiorca Technologii w ścieżce Akceleracja branżowa startupów z partnerem biznesowym lub publicznym
              (B2B/B2A)? */}
              <FormField name={`implementers.${index}.commitment_to_project_and_ability_to_validate_startup_solutions`}>
                {({
                  onChange, value, name, allChoices, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={allChoices}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Czy do wniosku o dofinansowanie dołączono pisemną deklarację krajowego partnera o zaangażowaniu w
              projekt i możliwości weryfikacji gotowości inwestycyjnej startupów (dotyczy partnera, który został
              wskazany jako Inwestor w ścieżce Akceleracja startupów z funduszem VC (inwestorska)? */}
              <FormField name={`implementers.${index}.commitment_to_project_and_opportunities_to_verify_investment_readiness_startups`}>
                {({
                  onChange, value, name, allChoices, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={allChoices}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Oznaczenie innego rejestru (jeśli dotyczy) */}
              <FormField name={`implementers.${index}.another_register_designation`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    multiline
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
            </CollectionFieldContainer>
          );
        })}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="7t175cOcPei13u4"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
