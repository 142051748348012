import { Button } from '@mui/material';
import {
  useEvaluationSheetsContext,
  useGlobalDialog,
} from '../../../../../Context';
import { EvaluationSheetChairmanRemarksList } from './EvaluationSheetChairmanRemarksList';

/**
 * Evaluation sheet chairman remarks button.
 *
 * @returns {EvaluationSheetChairmanRemarksButton}
 */
export function EvaluationSheetChairmanRemarksButton() {
  const { render } = useGlobalDialog();
  const {
    evaluationSheet: { items }, expertPanel: { modules },
  } = useEvaluationSheetsContext();

  return (
    <Button
      id="czW5o0n03JqW342"
      type="button"
      color="secondary"
      variant="contained"
      onClick={() => render(
        <EvaluationSheetChairmanRemarksList evaluationItems={items} modulesList={modules} />,
        'Uwagi przewodniczącego'
      )}
    >
      Lista Uwag przewodniczącego
    </Button>
  );
}
