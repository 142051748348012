import { MenuItem } from '@mui/material';
import uniqid from 'uniqid';
import {
  generatePath,
  useNavigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  API_ROUTE,
  SUPPLEMENT_CALL_STATUSES,
  SUPPLEMENT_CALL_STATUSES_TRANSLATIONS,
  URL,
  PROFILES,
} from '../../../../_constants';
import { CustomDataGrid } from '../../../DataGrid/CustomDataGrid';
import ExpandableUuid from '../../../ExpandableUuid';
import { formatDateString } from '../../../../_helpers';
import { ButtonMenu } from '../../../ButtonMenu';
import PrintoutSupplementCallDialog
  from '../../../ProjectCard/SupplementCallsAndExplanations/PrintoutSupplementCall.dialog';
import PrintoutSupplementCall from '../../../Applicant/SupplementCall/PrintoutSupplementCall/PrintoutSupplementCall';
import { useGlobalDialog } from '../../../../Context';
import { useAuth } from '../../../../_security';

/**
 * Supplement calls list.
 *
 * @param {object} props - root props
 * @param {string} props.applicationId - application id
 * @param {string} props.applicationNumber - application number
 * @param {string} props.evaluationSheetId - evaluation sheet id
 * @returns {SupplementCallsList}
 */
function SupplementCallsList({
  applicationId, applicationNumber, evaluationSheetId,
}) {
  const navigate = useNavigate();
  const { closeAll } = useGlobalDialog();
  const { profile } = useAuth();

  const isEmployee = profile === PROFILES.employee;
  const apiUrl = isEmployee
    ? `${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}/supplement-calls-by-employees`
    : `${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}/supplement-calls-by-experts`;

  /**
   * Returns row actions.
   *
   * @param {object} row - table record data
   * @returns {Element[]}
   */
  const getRowActions = (row) => [
    <ButtonMenu
      openButtonId={uniqid()}
      buttonTitle="Szczegóły"
      buttonProps={{ color: 'secondary' }}
    >
      <MenuItem
        key={uniqid()}
        role="menuitem"
        variant="right-border"
        id={uniqid()}
        onClick={() => {
          navigate(generatePath(
            URL.application.supplementCall,
            { id: row.id }
          ), {
            state: {
              isReadonly: true,
              sentToApplicantDate: row.sentToApplicantDate,
            },
          });
          closeAll();
        }}
      >
        Podgląd
      </MenuItem>
      {(row.status === SUPPLEMENT_CALL_STATUSES.sentByCron
        || row.status === SUPPLEMENT_CALL_STATUSES.sentByApplicant) && (
        <PrintoutSupplementCallDialog supplementCallId={row.id} externalApplicationId={applicationId} />
      )}
      <PrintoutSupplementCall
        applicationNumber={applicationNumber}
        supplementCallId={row.id}
        sentToApplicantDate={row.sentToApplicantDate}
      />
    </ButtonMenu>,
  ];

  const columns = [
    {
      headerName: 'ID Wezwania',
      field: 'id',
      renderCell: (({ value }) => <ExpandableUuid uuid={value} wrap />),
      flex: 0.5,
    },
    {
      headerName: 'Data wezwania',
      field: 'sentToApplicantDate',
      valueFormatter: ({ value }) => formatDateString(value),
      flex: 0.5,
      type: 'date',
    },
    {
      headerName: 'Status',
      field: 'status',
      valueFormatter: ({ value }) => SUPPLEMENT_CALL_STATUSES_TRANSLATIONS[value] || 'Nieznany status',
      flex: 0.5,
    },
    {
      headerName: 'Data przesłania',
      field: 'sentByApplicantDate',
      valueFormatter: ({ value }) => formatDateString(value),
      flex: 0.5,
      type: 'date',
    },
    {
      headerName: 'Akcja',
      field: 'actions',
      type: 'actions',
      filterable: false,
      getActions: ({ row }) => getRowActions(row),
      width: 170,
    },
  ];

  return (
    <CustomDataGrid
      columns={columns}
      apiSource={apiUrl}
      name="expert-supplement-calls-grid"
    />
  );
}

export default SupplementCallsList;

SupplementCallsList.propTypes = {
  applicationId: PropTypes.string.isRequired,
  applicationNumber: PropTypes.string.isRequired,
  evaluationSheetId: PropTypes.string.isRequired,
};
