import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
} from 'react';
/**
 * Evaluation item context wrapper.
 *
 * @type {React.Context}
 */
export const EvaluationItemContext = createContext({});

export const useEvaluationItemContext = () => useContext(EvaluationItemContext);

/**
 * Evaluation sheets action provider.
 *
 * @param {object} props - root props
 * @param {Node} props.children - children elements
 * @param {object} props.value - evaluation item and module data
 * @returns {EvaluationItemProvider}
 */
export function EvaluationItemProvider({
  children, value,
}) {
  return (
    <EvaluationItemContext.Provider value={value}>
      {children}
    </EvaluationItemContext.Provider>
  );
}
EvaluationItemProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};
