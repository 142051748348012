import {
  Button,
  MenuItem,
} from '@mui/material';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import { useCustomSnackbar } from '../../../../../_hooks';
import { request } from '../../../../../_services';
import {
  API_ROUTE,
  EXPERT_PANEL_TYPES,
} from '../../../../../_constants';
import { useEvaluationSheetsContext } from '../../../../../Context';

/**
 * EvaluationSheetAcceptButton element.
 *
 * @param {object} props - root props
 * @param {object} props.requestPayload - request payload
 * @param {string} props.expertByUsersId - expert by users id
 * @returns {EvaluationSheetAcceptButton}
 */
function EvaluationSheetAcceptButton({
  requestPayload, expertByUsersId,
}) {
  const {
    isReadonly, expertPanel: { criteriaSet: { panelType } }, reloadData,
  } = useEvaluationSheetsContext();
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();

  const handleSubmit = async () => {
    const {
      statusSuccess,
    } = await request.put(`${API_ROUTE.expertPanelExpertByUsers}/${expertByUsersId}`, {
      ...requestPayload,
      accepted: true,
    });

    reloadData();

    statusSuccess
      ? successNotification('Wniosek został zaakceptowany.')
      : errorNotification('Nie udało się zaakceptować wniosku. Spróbuj ponownie.');
  };

  if (panelType === EXPERT_PANEL_TYPES.withExpert) {
    return (
      <MenuItem
        key={uniqid()}
        variant="right-border"
        id={uniqid()}
        onClick={handleSubmit}
      >
        Przyjmij wniosek
      </MenuItem>
    );
  }

  return (
    <Button
      type="button"
      id="LvAOMYT25Wi9m4e"
      variant="contained"
      color="secondary"
      onClick={handleSubmit}
      disabled={isReadonly}
    >
      Przyjmij wniosek
    </Button>
  );
}

export default EvaluationSheetAcceptButton;

EvaluationSheetAcceptButton.propTypes = {
  requestPayload: PropTypes.instanceOf(Object).isRequired,
  expertByUsersId: PropTypes.string.isRequired,
};
