import { View } from '@react-pdf/renderer';
import RowField from '../../Components/RowField/RowField';
import { printStyle } from '../../print.style';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * InnovationCentrePotential pdf element.
 *
 * @returns {InnovationCentrePotentialPdf}
 */
function InnovationCentrePotentialPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Posiadane zasoby techniczne i kadrowe do realizacji projektu */}
        <RowField name="innovation_centre_potential_technical_resources" isSingleRow />
        {/* Potrzeby rozwojowe Ośrodka Innowacji */}
        <RowField name="innovation_centre_potential_development_needs" isSingleRow />
        {/* Liczba zatrudnionych osób realizujących projekt */}
        <RowField name="innovation_centre_potential_persons_employed_number" isSingleRow />
        {/* Liczba zatrudnionych kobiet realizujących projekt */}
        <RowField name="innovation_centre_potential_women_employed_number" isSingleRow />
        {/* Źródła i sposób finansowania projektu */}
        <RowField name="innovation_centre_potential_financing_method" isSingleRow />
        {/* Kluczowe ryzyka związane z realizacją projektu oraz działania ograniczające zidentyfikowane ryzyka */}
        <RowField name="innovation_centre_potential_key_risks" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default InnovationCentrePotentialPdf;
