import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';

/**
 * Statements element renderer.
 *
 * @returns {Statements} - component
 */
export function Statements() {
  const {
    currentElementFieldsConfig,
    isReadonly,
  } = useElementContext();

  const renderStatementField = (field) => {
    const result = [];

    result.push(
      <Grid
        item
        xs={12}
        key={`statement-label-${field.uuid}`}
        textAlign="left"
      >
        {`${field.ordinalNumber}. ${field.label}`}
      </Grid>
    );

    if (field.options.length > 0 && field.hasAdditionalOptions) {
      result.push(renderFieldOptions(field));
    }

    if (field.note) {
      result.push(
        <Grid
          item
          xs={12}
          key={`statement-note-${field.uuid}`}
          sx={{
            fontSize: 'small',
            fontStyle: 'italic',
          }}
        >
          {field.note}
        </Grid>
      );
    }

    return result;
  };

  const renderFieldOptions = (field) => (
    field.options.map(
      (option) => (
        <FormField
          name={`${field.name}.${option.name}`}
          labelled={false}
          key={`statement-option-${option.name}`}
        >
          {({
            onChange,
            value,
          }) => renderOneOption(field, option, onChange, value)}
        </FormField>
      )
    )
  );

  const renderOneOption = (field, option, onChange, value) => (
    <Grid item xs={12} key={`statement-option-${option.name}`}>
      <FormControl fullWidth>
        <FormControlLabel
          control={(
            <Checkbox
              color="secondary"
              name={`${field.name}.${option.name}`}
              onChange={onChange}
              checked={Boolean(value)}
              disabled={isReadonly}
            />
          )}
          label={option.label}
        />
      </FormControl>
    </Grid>
  );

  return (
    <>
      {currentElementFieldsConfig
        .filter(({ type }) => type === 'statement').map((field) => renderStatementField(field))}
    </>
  );
}
