import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  useEvaluationSheetsContext,
  useEvaluationSheetsUserSessionContext,
} from '../../../../../Context';
import { useCustomSnackbar } from '../../../../../_hooks';
import { request } from '../../../../../_services';
import {
  API_ROUTE,
  EVALUATION_SHEET_STATUS,
} from '../../../../../_constants';

/**
 * Evaluation sheet send to approve button.
 *
 * @returns {EvaluationSheetSendToApproveButton}
 */
export function EvaluationSheetSendToApproveButton() {
  const { evaluationSheetId } = useParams();
  const {
    reloadData, evaluationSheet: { status: evaluationSheetStatus }, transformedData: { isEmployeeOrAdmin }, isReadonly,
  } = useEvaluationSheetsContext();
  const { handleCloseUserSession } = useEvaluationSheetsUserSessionContext();
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const { formState: { isSubmitting } } = useFormContext();

  const onSendToApprove = async () => {
    const {
      statusSuccess,
    } = await request.get(
      `${API_ROUTE.expertEvaluationSheets}/${evaluationSheetId}/sent-to-approve`
    );

    if (statusSuccess) {
      successNotification('Arkusz został przesłany do zatwierdzenia.');
      reloadData();
      handleCloseUserSession(true, null);

      return;
    }

    errorNotification('Nie udało się przesłać arkusza do zatwierdzenia. Spróbuj ponownie.');
  };

  if (!isEmployeeOrAdmin || isReadonly) {
    return null;
  }

  return (
    <Button
      type="button"
      color="success"
      variant="outlined"
      id="hcsQhdPh3lTnE56"
      disabled={isSubmitting || evaluationSheetStatus !== EVALUATION_SHEET_STATUS.sentToParp}
      onClick={onSendToApprove}
    >
      Wyślij do zatwierdzenia
    </Button>
  );
}
