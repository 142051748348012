import {
  FormControl,
  TextField,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import {
  BOOLEAN_VALUES,
  EVALUATION_METHODS,
} from '../../../../_constants';
import { Alert } from '../../../Alert';
import {
  MonetaryField,
  parsePastedText,
} from '../../../MonetaryField';
import { CustomAutocomplete } from '../../../CustomAutocomplete/CustomAutocomplete';
import {
  useEvaluationItemContext,
  useEvaluationSheetsActionContext,
} from '../../../../Context';

/**
 * Evaluation sheet evaluation type field.
 *
 * @returns {EvaluationSheetEvaluationTypeField}
 */
export function EvaluationSheetEvaluationTypeField() {
  const {
    evaluationItem, userPermissions,
  } = useEvaluationItemContext();
  const { showFilteredModulesByExpert } = useEvaluationSheetsActionContext();
  const { control } = useFormContext();
  const {
    criteriaItem, id: evaluationItemId,
  } = evaluationItem;
  const evaluationMethod = criteriaItem?.evaluationMethod || EVALUATION_METHODS.point_criterion;
  const fixedDecimalScale = (criteriaItem?.isMaxPointsLimitInteger || criteriaItem?.isMinPointsLimitInteger);
  const FIELD_CONFIG_BY_EVALUATION_METHOD = {
    [EVALUATION_METHODS.yes_no_criterion]: {
      name: 'yesNo',
      label: 'Tak/nie',
      options: [
        {
          '@id': BOOLEAN_VALUES.TRUE,
          name: BOOLEAN_VALUES.TRUE,
        },
        {
          '@id': BOOLEAN_VALUES.FALSE,
          name: BOOLEAN_VALUES.FALSE,
        },
      ],
    },
    [EVALUATION_METHODS.yes_no_not_applicable_criterion]: {
      name: 'yesNoNotApplicable',
      label: 'Tak/nie/nie dotyczy',
      options: [
        {
          '@id': BOOLEAN_VALUES.TRUE,
          name: BOOLEAN_VALUES.TRUE,
        },
        {
          '@id': BOOLEAN_VALUES.FALSE,
          name: BOOLEAN_VALUES.FALSE,
        },
        {
          '@id': 'NIE DOTYCZY',
          name: 'NIE DOTYCZY',
        },
      ],
    },
  };

  const fieldConfig = FIELD_CONFIG_BY_EVALUATION_METHOD?.[evaluationMethod] || null;

  if (!evaluationMethod) {
    return <Alert severity="warn">Brak wybranego &quot;Sposobu oceny&quot;</Alert>;
  }

  return (
    <>
      {evaluationMethod === EVALUATION_METHODS.point_criterion && (
        <FormControl fullWidth>
          <Controller
            rules={{
              required: {
                value: (!showFilteredModulesByExpert || userPermissions.points),
                message: 'Proszę uzupełnić pole "Liczba punktów"',
              },
              min: {
                value: criteriaItem?.minPointsLimit,
                message: `Wartość w polu "Liczba punktów" nie może być mniejsza niż ${criteriaItem?.minPointsLimit}`,
              },
              max: {
                value: criteriaItem?.maxPointsLimit,
                message: `Wartość w polu "Liczba punktów" nie może być większa niż ${criteriaItem?.maxPointsLimit}`,
              },
            }}
            name={`${evaluationItemId}.points`}
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                error={!!error}
                helperText={error?.message}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                label="Liczba punktów"
                onBlur={onBlur}
                disabled={!userPermissions.points}
                required={userPermissions.points}
                InputProps={{
                  inputComponent: MonetaryField,
                }}
                inputProps={{
                  allowNegative: false,
                  fixedDecimalScale,
                  decimalScale: fixedDecimalScale ? 2 : 0,
                }}
                onPaste={(event) => parsePastedText(event, onChange)}
              />
            )}
          />
        </FormControl>
      )}
      {fieldConfig && (
        <FormControl fullWidth>
          <Controller
            rules={{
              required: {
                value: (!showFilteredModulesByExpert || userPermissions.points),
                message: `Proszę uzupełnić pole "${fieldConfig.label}"`,
              },
            }}
            name={`${evaluationItemId}.${fieldConfig.name}`}
            control={control}
            render={({
              field: {
                onChange, value, name,
              },
              fieldState: {
                error,
              },
            }) => (
              <CustomAutocomplete
                id={name}
                options={fieldConfig.options}
                initialValue={value}
                renderSelectAllButton={false}
                onChange={onChange}
                textFieldProps={{
                  error: !!error,
                  name,
                  label: fieldConfig.label,
                }}
                error={!!error}
              />
            )}
          />
        </FormControl>
      )}
    </>
  );
}
