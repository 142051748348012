import {
  max,
  parseISO,
} from 'date-fns';
import uniqid from 'uniqid';
import {
  MODULE_STATUSES,
  EXPERT_PANEL_TYPES,
  EXPERT_PANEL_ROLES,
  CRITERIA_BUSINESS_GROUP,
  ADDITIONAL_MODULE_IDS,
  ACCESS_TO_FIELDS_BY_ROLE,
} from '../_constants';
import { iriToId } from './apiRouteHelper';

export const getModuleStatus = (currentExpert) => {
  if (currentExpert.accepted) {
    return MODULE_STATUSES.accepted;
  }
  if (currentExpert.refused) {
    return MODULE_STATUSES.refused;
  }

  return MODULE_STATUSES.waiting;
};

export const getExpertRole = (expert, isFinancialExpert) => {
  if (isFinancialExpert && expert) {
    if (expert.role === EXPERT_PANEL_ROLES.expert) {
      return EXPERT_PANEL_ROLES.financial;
    }

    return EXPERT_PANEL_ROLES.financialLeadingExpert;
  }
  if (expert?.role === EXPERT_PANEL_ROLES.expert) {
    return EXPERT_PANEL_ROLES.expert;
  }

  if (expert?.role === EXPERT_PANEL_ROLES.leading_expert) {
    return EXPERT_PANEL_ROLES.leading_expert;
  }

  if (expert?.role === EXPERT_PANEL_ROLES.financial_expert) {
    return EXPERT_PANEL_ROLES.financial_expert;
  }

  return EXPERT_PANEL_ROLES.readonly;
};

const getLatestDate = (dateKey, data) => {
  const dates = data.map((item) => (item[dateKey] ? parseISO(item[dateKey]) : item[dateKey])).filter((item) => item);

  return dates.length === 0 ? null : max(dates);
};

const getApplicationObject = (expertPanel, evaluationSheet, applicationData, expertsList) => ({
  id: uniqid(),
  expertPanelId: expertPanel.id,
  applicationId: applicationData.applicationId,
  recruitmentId: expertPanel.recruitmentId,
  applicationNumber: applicationData.applicationNumber,
  applicationStatus: applicationData.status,
  expertPanelApplication: applicationData['@id'],
  preliminaryRemark: applicationData.preliminaryRemark,
  leadingExpertId: expertPanel.leadingExpert?.id,
  finalDecisionDate: getLatestDate('finalDecisionDate', expertsList),
  acceptanceDate: getLatestDate('acceptanceDate', expertsList),
  finalEvaluationDate: expertPanel.criteriaSet.panelType === EXPERT_PANEL_TYPES.moduleFirstStage
    ? expertPanel.finalDate
    : getLatestDate('finalEvaluationDate', expertsList),
  refusalDate: getLatestDate('refusalDate', expertsList),
  evaluationSheetId: evaluationSheet ? evaluationSheet.id : null,
  evaluationSheetStatus: evaluationSheet ? evaluationSheet.status : null,
  status: evaluationSheet?.status || null,
  date: expertPanel.date,
  panelType: expertPanel.criteriaSet.panelType,
  sentToParpDate: evaluationSheet?.sentToParpDate || null,
  chairmanId: expertPanel.chairman?.user?.id,
  viceChairmanId: expertPanel.viceChairman?.user?.id,
  secretaryId: expertPanel.secretary?.user?.id,
  viceSecretaryId: expertPanel.viceSecretary?.user?.id,
});

export const getAssessmentSheetList = (data, loggedUserId, panelAssessmentSheet = false) => {
  const applicationList = [];

  data.filter((expertPanel) => expertPanel.started).forEach((expertPanel) => {
    if (expertPanel.applications.length !== 0 && (
      expertPanel.criteriaSet.panelType === EXPERT_PANEL_TYPES.module
      || expertPanel.criteriaSet.panelType === EXPERT_PANEL_TYPES.moduleFirstStage
    )) {
      const expertsList = expertPanel?.modules?.map((module) => module.experts).flat()
        ?.filter((expert) => (loggedUserId ? iriToId(expert.externalExpert?.user['@id']) === loggedUserId : true))
        || [];
      const applicationData = expertPanel.applications[0];

      const panelAssessmentSheetData = expertPanel.evaluationSheets?.find(({ origin }) => origin === 'panel');

      const currentEvaluationSheet = panelAssessmentSheet ? panelAssessmentSheetData : expertPanel.evaluationSheets[0];

      const evaluationSheet = expertPanel.evaluationSheets.length !== 0 ? currentEvaluationSheet : null;

      applicationList.push({
        ...getApplicationObject(expertPanel, evaluationSheet, applicationData, expertsList),
        refusedAssessmentSheet: expertsList.filter(({ refused }) => !refused).length === 0,
        isLoggedUserLeadingExpert: expertPanel.leadingExpert?.externalExpert?.user?.id === loggedUserId,
      });
    }

    if (expertPanel.applications.length !== 0 && expertPanel.criteriaSet.panelType === EXPERT_PANEL_TYPES.withExpert) {
      expertPanel.applications.forEach((application) => {
        const currentExpert = application.experts
          ?.find((expert) => {
            if (expert.externalExpert && iriToId(expert.externalExpert?.user['@id']) === loggedUserId) {
              return true;
            }

            if (expert.parpExpert && iriToId(expert.parpExpert?.user['@id']) === loggedUserId) {
              return true;
            }

            return false;
          }) || {};

        const evaluationSheetData = expertPanel.evaluationSheets
          .find((evaluationSheet) => application?.applicationId
            === evaluationSheet?.application?.applicationId && evaluationSheet?.origin === 'panel');

        applicationList.push({
          ...getApplicationObject(expertPanel, evaluationSheetData, application, [currentExpert]),
          currentExpert,
          refusedAssessmentSheet: currentExpert.refused,
          isLoggedUserLeadingExpert: currentExpert.role === EXPERT_PANEL_ROLES.leading_expert,
        });
      });
    }
  });

  return applicationList;
};

export const getExternalIdsToCriteriaItem = (businessGroup, moduleId, isConclusive = false) => {
  if (isConclusive) {
    return ADDITIONAL_MODULE_IDS.kryteriaRankingujace;
  }

  if (businessGroup === CRITERIA_BUSINESS_GROUP.ogolnaFinansowa && !moduleId) {
    return ADDITIONAL_MODULE_IDS.ocenaFinansowa;
  }

  if ((businessGroup === CRITERIA_BUSINESS_GROUP.ogolna || businessGroup === CRITERIA_BUSINESS_GROUP.ogolnaModulowa)
    && !moduleId) {
    return ADDITIONAL_MODULE_IDS.kryteriaOgolem;
  }

  if (businessGroup === CRITERIA_BUSINESS_GROUP.modulowaFinansowa) {
    return ADDITIONAL_MODULE_IDS.modulowaFinansowa;
  }

  return moduleId;
};

export const getAccessToCriteriaFields = (
  role,
  criteriaItem,
  panelType,
  moduleStatus,
  isReadonly,
  isLeadingExpert,
  isFinancialExpert,
  isModuleExpert
) => {
  if (panelType === EXPERT_PANEL_TYPES.module) {
    if (isReadonly
      || (moduleStatus === MODULE_STATUSES.readonly || moduleStatus === MODULE_STATUSES.readonly)) {
      return ACCESS_TO_FIELDS_BY_ROLE.readonly;
    }
    if (role === EXPERT_PANEL_ROLES.employeeOrAdmin) {
      return ACCESS_TO_FIELDS_BY_ROLE.employeeOrAdmin;
    }
    if (isLeadingExpert && (criteriaItem.businessGroup === CRITERIA_BUSINESS_GROUP.ogolnaModulowa
      || criteriaItem.businessGroup === CRITERIA_BUSINESS_GROUP.ogolna)) {
      return ACCESS_TO_FIELDS_BY_ROLE.leading_expert;
    }
    if (!isLeadingExpert && criteriaItem.businessGroup === CRITERIA_BUSINESS_GROUP.ogolna) {
      return ACCESS_TO_FIELDS_BY_ROLE.expert;
    }

    if (criteriaItem.businessGroup === CRITERIA_BUSINESS_GROUP.ogolnaModulowa && isFinancialExpert && !isModuleExpert) {
      return ACCESS_TO_FIELDS_BY_ROLE.readonly;
    }

    if (!isFinancialExpert && (criteriaItem.businessGroup === CRITERIA_BUSINESS_GROUP.ogolnaFinansowa
      || criteriaItem.businessGroup === CRITERIA_BUSINESS_GROUP.modulowaFinansowa)) {
      return ACCESS_TO_FIELDS_BY_ROLE.readonly;
    }
  }

  if (panelType === EXPERT_PANEL_TYPES.withExpert) {
    if (isReadonly) {
      return ACCESS_TO_FIELDS_BY_ROLE.readonly;
    }
    if (criteriaItem.businessGroup === CRITERIA_BUSINESS_GROUP.ogolnaFinansowa
    && role === EXPERT_PANEL_ROLES.financial_expert) {
      return ACCESS_TO_FIELDS_BY_ROLE.financial_expert;
    }

    if (criteriaItem.businessGroup !== CRITERIA_BUSINESS_GROUP.ogolnaFinansowa
      && role === EXPERT_PANEL_ROLES.financial_expert) {
      return ACCESS_TO_FIELDS_BY_ROLE.readonly;
    }

    if (criteriaItem.businessGroup === CRITERIA_BUSINESS_GROUP.ogolnaFinansowa
      && role !== EXPERT_PANEL_ROLES.financial_expert) {
      return ACCESS_TO_FIELDS_BY_ROLE.readonly;
    }
  }

  return ACCESS_TO_FIELDS_BY_ROLE[role] || ACCESS_TO_FIELDS_BY_ROLE.readonly;
};
