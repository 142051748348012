import { AlignmentType } from 'docx';
import {
  printoutToDocxCellValueByType,
  printoutToDocxLabelCell,
  printoutToDocxTable,
  printoutToDocxTableRow,
  printoutToDocxText,
} from '../PrintoutToDocxComponents';
import { actualExpenses } from './actualExpenses';
import { lumpSumExpenses } from './lumpSumExpenses';
import { printoutToDocxHelper } from '../../../_helpers';

/**
 * Material and financial schedule tasks
 *
 * @param {object} props - root props
 * @param {object} props.subElementData - sub element data
 * @param {Array} props.dictionaries - dictionaries
 * @param {object} props.parentElementData - parent element data
 * @returns {materialAndFinancialScheduleTasks}
 */
export const materialAndFinancialScheduleTasks = ({
  subElementData,
  dictionaries,
  parentElementData,
}) => {
  const actualExpensesTemplate = parentElementData.fields.find(({ name }) => name === 'actual_expenses');
  const lumpSumExpensesTemplate = parentElementData.fields.find(({ name }) => name === 'lump_sum_expenses');
  const fieldsConfig = printoutToDocxHelper.fieldsConfig(subElementData.fields);

  return [
    ...subElementData.elementData.flatMap((elementData) => [
      printoutToDocxText({
        text: subElementData.label,
        size: 16,
        align: AlignmentType.LEFT,
        bold: true,
        marginTop: 500,
        marginBottom: 500,
      }),
      printoutToDocxTable({
        columns: 3,
        rows: [
          printoutToDocxTableRow({
            children: [
              printoutToDocxLabelCell({
                templateFields: subElementData.fields,
                fieldName: 'name',
                numberOfColumns: 3,
                bgColor: 'gray',
              }),
              printoutToDocxLabelCell({
                templateFields: subElementData.fields,
                fieldName: 'data_rozpoczecia_zadania',
                numberOfColumns: 3,
                bgColor: 'gray',
              }),
              printoutToDocxLabelCell({
                templateFields: subElementData.fields,
                fieldName: 'data_zakonczenia_zadania',
                numberOfColumns: 3,
                bgColor: 'gray',
              }),
            ],
          }),
          printoutToDocxTableRow({
            children: [
              printoutToDocxCellValueByType({
                value: elementData.name,
                templateFields: subElementData.fields,
                fieldName: 'name',
                numberOfColumns: 3,
              }),
              printoutToDocxCellValueByType({
                value: elementData.data_rozpoczecia_zadania,
                templateFields: subElementData.fields,
                fieldName: 'data_rozpoczecia_zadania',
                numberOfColumns: 3,
              }),
              printoutToDocxCellValueByType({
                value: elementData.data_zakonczenia_zadania,
                templateFields: subElementData.fields,
                fieldName: 'data_zakonczenia_zadania',
                numberOfColumns: 3,
              }),
            ],
          }),
          ...printoutToDocxHelper.fieldConfig('task_type', fieldsConfig, [
            printoutToDocxTableRow({
              children: [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'task_type',
                  colSpan: 1,
                  numberOfColumns: 3,
                  bgColor: 'gray',
                }),
                printoutToDocxCellValueByType({
                  value: elementData.task_type,
                  templateFields: subElementData.fields,
                  fieldName: 'task_type',
                  colSpan: 2,
                  numberOfColumns: 3,
                  dictionaries,
                }),
              ],
            }),

          ]),
          ...printoutToDocxHelper.fieldConfig('opis_i_uzasadnienie', fieldsConfig, [
            printoutToDocxTableRow({
              children: [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'opis_i_uzasadnienie',
                  colSpan: 3,
                  numberOfColumns: 3,
                  bgColor: 'gray',
                }),
              ],
            }),
            printoutToDocxTableRow({
              children: [
                printoutToDocxCellValueByType({
                  value: elementData.opis_i_uzasadnienie,
                  templateFields: subElementData.fields,
                  fieldName: 'opis_i_uzasadnienie',
                  colSpan: 3,
                  numberOfColumns: 3,
                }),
              ],
            }),
          ]),
          ...printoutToDocxHelper.fieldConfig('planned_works_description', fieldsConfig, [
            printoutToDocxTableRow({
              children: [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'planned_works_description',
                  colSpan: 3,
                  numberOfColumns: 3,
                  bgColor: 'gray',
                }),
              ],
            }),
            printoutToDocxTableRow({
              children: [
                printoutToDocxCellValueByType({
                  value: elementData.planned_works_description,
                  templateFields: subElementData.fields,
                  fieldName: 'planned_works_description',
                  colSpan: 3,
                  numberOfColumns: 3,
                }),
              ],
            }),
          ]),
          ...printoutToDocxHelper.fieldConfig('milestone_name', fieldsConfig, [
            printoutToDocxTableRow({
              children: [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'milestone_name',
                  colSpan: 3,
                  numberOfColumns: 3,
                  bgColor: 'gray',
                }),
              ],
            }),
            printoutToDocxTableRow({
              children: [
                printoutToDocxCellValueByType({
                  value: elementData.milestone_name,
                  templateFields: subElementData.fields,
                  fieldName: 'milestone_name',
                  colSpan: 3,
                  numberOfColumns: 3,
                }),
              ],
            }),
          ]),
          ...printoutToDocxHelper.fieldConfig('milestone_params', fieldsConfig, [
            printoutToDocxTableRow({
              children: [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'milestone_params',
                  colSpan: 3,
                  numberOfColumns: 3,
                  bgColor: 'gray',
                }),
              ],
            }),
            printoutToDocxTableRow({
              children: [
                printoutToDocxCellValueByType({
                  value: elementData.milestone_params,
                  templateFields: subElementData.fields,
                  fieldName: 'milestone_params',
                  colSpan: 3,
                  numberOfColumns: 3,
                }),
              ],
            }),
          ]),
          ...printoutToDocxHelper.fieldConfig('milestone_achievement_verification', fieldsConfig, [
            printoutToDocxTableRow({
              children: [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'milestone_achievement_verification',
                  colSpan: 3,
                  numberOfColumns: 3,
                  bgColor: 'gray',
                }),
              ],
            }),
            printoutToDocxTableRow({
              children: [
                printoutToDocxCellValueByType({
                  value: elementData.milestone_achievement_verification,
                  templateFields: subElementData.fields,
                  fieldName: 'milestone_achievement_verification',
                  colSpan: 3,
                  numberOfColumns: 3,
                }),
              ],
            }),
          ]),
          ...printoutToDocxHelper.fieldConfig('milestone_fail_impact', fieldsConfig, [
            printoutToDocxTableRow({
              children: [
                printoutToDocxLabelCell({
                  templateFields: subElementData.fields,
                  fieldName: 'milestone_fail_impact',
                  colSpan: 3,
                  numberOfColumns: 3,
                  bgColor: 'gray',
                }),
              ],
            }),
            printoutToDocxTableRow({
              children: [
                printoutToDocxCellValueByType({
                  value: elementData.milestone_fail_impact,
                  templateFields: subElementData.fields,
                  fieldName: 'milestone_fail_impact',
                  colSpan: 3,
                  numberOfColumns: 3,
                }),
              ],
            }),
          ]),
        ],
      }),
      ...actualExpenses({
        subElementData: actualExpensesTemplate,
        dictionaries,
        taskId: elementData.task_id,
      }),
      ...lumpSumExpenses({
        subElementData: lumpSumExpensesTemplate,
        dictionaries,
        taskId: elementData.task_id,
      }),
    ]),
  ];
};
