import {
  printoutToDocxCellValueByType,
  printoutToDocxLabelCell,
  printoutToDocxTable,
  printoutToDocxTableCell,
  printoutToDocxTableRow,
} from '../PrintoutToDocxComponents';
import { printoutToDocxHelper } from '../../../_helpers';

/**
 * Single indicator row
 *
 * @param {object} props - root props
 * @param {object} props.indicator - single indicator data
 * @param {object} props.fieldsConfig - fields config
 * @param {Array} props.fields - list of fields
 * @returns {indicatorRow}
 */
export const indicatorRow = ({
  indicator, fieldsConfig, fields,
}) => printoutToDocxTable({
  columns: 2,
  rows: [
    printoutToDocxTableRow({
      children: [
        printoutToDocxTableCell({
          text: indicator.name,
          bgColor: 'gray',
          numberOfColumns: 2,
          colSpan: 2,
          bold: true,
        }),
      ],
    }),
    ...printoutToDocxHelper.fieldConfig('unit', fieldsConfig, [
      printoutToDocxTableRow({
        children: [
          printoutToDocxLabelCell({
            templateFields: fields,
            fieldName: 'unit',
            colSpan: 1,
            bgColor: 'gray',
            numberOfColumns: 2,
          }),
          printoutToDocxCellValueByType({
            templateFields: fields,
            fieldName: 'unit',
            colSpan: 1,
            numberOfColumns: 2,
            value: indicator.unit,
          }),
        ],
      }),
    ]),
    ...printoutToDocxHelper.fieldConfig('breakdown_by_gender', fieldsConfig, [
      printoutToDocxTableRow({
        children: [
          printoutToDocxLabelCell({
            templateFields: fields,
            fieldName: 'breakdown_by_gender',
            colSpan: 1,
            bgColor: 'gray',
            numberOfColumns: 2,
          }),
          printoutToDocxCellValueByType({
            templateFields: fields,
            fieldName: 'breakdown_by_gender',
            colSpan: 1,
            numberOfColumns: 2,
            value: indicator.breakdown_by_gender,
          }),
        ],
      }),
    ]),
    ...printoutToDocxHelper.fieldConfig('base_year', fieldsConfig, [
      printoutToDocxTableRow({
        children: [
          printoutToDocxLabelCell({
            templateFields: fields,
            fieldName: 'base_year',
            colSpan: 1,
            bgColor: 'gray',
            numberOfColumns: 2,
          }),
          printoutToDocxCellValueByType({
            templateFields: fields,
            fieldName: 'base_year',
            colSpan: 1,
            numberOfColumns: 2,
            value: indicator.base_year.toString(),
            dictionaries: indicator.baseYearDictionaries,
          }),
        ],
      }),
    ]),
    ...printoutToDocxHelper.fieldConfig('base_value_o', fieldsConfig, [
      printoutToDocxTableRow({
        children: [
          printoutToDocxLabelCell({
            templateFields: fields,
            fieldName: 'base_value_o',
            colSpan: 1,
            bgColor: 'gray',
            numberOfColumns: 2,
          }),
          printoutToDocxCellValueByType({
            templateFields: fields,
            fieldName: 'base_value_o',
            colSpan: 1,
            numberOfColumns: 2,
            value: indicator.base_value_o,
          }),
        ],
      }),
    ]),
    ...printoutToDocxHelper.fieldConfig('target_year', fieldsConfig, [
      printoutToDocxTableRow({
        children: [
          printoutToDocxLabelCell({
            templateFields: fields,
            fieldName: 'target_year',
            colSpan: 1,
            bgColor: 'gray',
            numberOfColumns: 2,
          }),
          printoutToDocxCellValueByType({
            templateFields: fields,
            fieldName: 'target_year',
            colSpan: 1,
            numberOfColumns: 2,
            value: indicator.target_year.toString(),
            dictionaries: indicator.targetYearDictionaries,
          }),
        ],
      }),
    ]),
    ...printoutToDocxHelper.fieldConfig('target_value_o', fieldsConfig, [
      printoutToDocxTableRow({
        children: [
          printoutToDocxLabelCell({
            templateFields: fields,
            fieldName: 'target_value_o',
            colSpan: 1,
            bgColor: 'gray',
            numberOfColumns: 2,
          }),
          printoutToDocxCellValueByType({
            templateFields: fields,
            fieldName: 'target_value_o',
            colSpan: 1,
            numberOfColumns: 2,
            value: indicator.target_value_o,
          }),
        ],
      }),
    ]),
    ...[indicator.breakdown_by_gender ? [
      ...printoutToDocxHelper.fieldConfig('target_value_k', fieldsConfig, [
        printoutToDocxTableRow({
          children: [
            printoutToDocxLabelCell({
              templateFields: fields,
              fieldName: 'target_value_k',
              colSpan: 1,
              bgColor: 'gray',
              numberOfColumns: 2,
            }),
            printoutToDocxCellValueByType({
              templateFields: fields,
              fieldName: 'target_value_k',
              colSpan: 1,
              numberOfColumns: 2,
              value: indicator.target_value_k,
            }),
          ],
        }),
      ]),
      ...printoutToDocxHelper.fieldConfig('target_value_m', fieldsConfig, [
        printoutToDocxTableRow({
          children: [
            printoutToDocxLabelCell({
              templateFields: fields,
              fieldName: 'target_value_m',
              colSpan: 1,
              bgColor: 'gray',
              numberOfColumns: 2,
            }),
            printoutToDocxCellValueByType({
              templateFields: fields,
              fieldName: 'target_value_m',
              colSpan: 1,
              numberOfColumns: 2,
              value: indicator.target_value_m,
            }),
          ],
        }),
      ]),
    ] : []].flat(),
    ...printoutToDocxHelper.fieldConfig('measuring_method', fieldsConfig, [
      printoutToDocxTableRow({
        children: [
          printoutToDocxLabelCell({
            templateFields: fields,
            fieldName: 'measuring_method',
            colSpan: 2,
            bgColor: 'gray',
            numberOfColumns: 2,
          }),
        ],
      }),
    ]),
    ...printoutToDocxHelper.fieldConfig('measuring_method', fieldsConfig, [
      printoutToDocxTableRow({
        children: [
          printoutToDocxCellValueByType({
            templateFields: fields,
            fieldName: 'measuring_method',
            colSpan: 2,
            numberOfColumns: 2,
            value: indicator.measuring_method,
          }),
        ],
      }),
    ]),
  ],
});
