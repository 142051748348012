import {
  Backdrop,
  Paper,
  Portal,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { usePrintoutStyles } from '../../styles/printout';
import { ContainerLoader } from '../Application/Application/ContainerLoader';

/**
 * Printing in progress backdrop
 *
 * @param {object} props - root props
 * @param {boolean} props.isLoading - is loading
 * @param {string} props.content - content
 * @returns {PrintingInProgressBackdrop}
 */
export function PrintingInProgressBackdrop({
  isLoading, content,
}) {
  return (
    <Portal>
      <Backdrop open={isLoading} sx={usePrintoutStyles.backdrop}>
        <Paper elevation={3} sx={usePrintoutStyles.paper}>
          <Typography mb={3}>
            {content}
          </Typography>
          <ContainerLoader />
        </Paper>
      </Backdrop>
    </Portal>
  );
}

PrintingInProgressBackdrop.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
};
