import uniqid from 'uniqid';
import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useGlobalDialog } from '../../../Context';
import PrintoutSupplementCallContent from './PrintoutSupplementCallContent';

/**
 * PrintoutSupplementCallDialog element.
 *
 * @param {object} props - root props
 * @param {string} props.supplementCallId - supplement call id
 * @param {string} props.externalApplicationId - external application id
 * @returns {PrintoutSupplementCallDialog}
 */
function PrintoutSupplementCallDialog({
  supplementCallId, externalApplicationId,
}) {
  const { render } = useGlobalDialog();

  return (
    <MenuItem
      key={uniqid()}
      variant="right-border"
      id={uniqid()}
      onClick={() => render(
        <PrintoutSupplementCallContent
          externalApplicationId={externalApplicationId}
          supplementCallId={supplementCallId}
        />,
        'Wydruk zmian',
        { sx: { zIndex: 9999 } }
      )}
    >
      Porównaj PDF
    </MenuItem>
  );
}

export default PrintoutSupplementCallDialog;

PrintoutSupplementCallDialog.propTypes = {
  supplementCallId: PropTypes.string.isRequired,
  externalApplicationId: PropTypes.string,
};

PrintoutSupplementCallDialog.defaultProps = {
  externalApplicationId: null,
};
