import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import {
  useEffect,
  useCallback,
} from 'react';
import { findIndex } from 'lodash';
import { DICTIONARIES } from '../../../_constants';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
} from '../../../_helpers';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import CharsCounter from '../../CharsCounter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { MonetaryField } from '../../MonetaryField';
import { FieldSorter } from './FieldSorter';

/**
 * ResultOfCostsAndBenefitsAnalysis element renderer.
 *
 * @returns {ResultOfCostsAndBenefitsAnalysis}
 */
export function ResultOfCostsAndBenefitsAnalysis() {
  const {
    isReadonly, control, fieldsConfig,
  } = useElementContext();
  const {
    watch, setValue,
  } = useFormContext();
  const {
    isLoaded, get,
  } = useDictionaryLoader(
    DICTIONARIES.mainParametersAndIndicators,
  );
  const collectionName = 'costs_and_benefits_analysis_result_akk_main_indicators_economic_analysis';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });
  const jobTitle = watch(collectionName);
  const filterConsumed = useCallback(
    (fieldIndex, options) => {
      const consumed = jobTitle
        .map(({ main_parameters_and_indicators: mainParametersAndIndicators }, index) => ({
          iri: mainParametersAndIndicators,
          index,
        }));

      const filterFn = ({ '@id': optionIri }) => {
        const consumedIndex = findIndex(consumed, { iri: optionIri });
        if (consumedIndex === -1) {
          return true;
        }

        return consumedIndex === fieldIndex;
      };

      return options.filter(filterFn);
    },
    [jobTitle],
  );

  const mainIndicatorsConfig = fieldsConfig[collectionName];
  const subfields = mainIndicatorsConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = mainIndicatorsConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }

  return (
    <FieldSorter>
      <LabeledFieldRow fieldConfig={mainIndicatorsConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionName,
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* Główne parametry i wskaźniki */}
            <FormField name={`${collectionName}.${index}.main_parameters_and_indicators`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={() => {
                    onBlur();
                  }}
                  options={filterConsumed(index, filterOptions(get(DICTIONARIES.mainParametersAndIndicators.name)))}
                  disabled={isReadonly}
                  error={error}
                />
              )}
            </FormField>
            {/* Wartość */}
            <FormField name={`${collectionName}.${index}.result_of_costs_and_benefits_analysis_value`}>
              {({
                onChange, value, name, onBlur, error,
              }) => (
                <TextField
                  defaultValue={value}
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  variant="outlined"
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                  InputProps={{
                    inputComponent: MonetaryField,
                  }}
                />
              )}
            </FormField>
            {/* Odniesienie */}
            <FormField name={`${collectionName}.${index}.result_of_costs_and_benefits_analysis_reference`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
            {/* Trwałość projektu */}
            <FormField name={`${collectionName}.${index}.project_durability`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="LFpi11ewFpPLKhn"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Poprawność analizy finansowej i ekonomicznej */}
      <FormField name="result_of_costs_and_benefits_analysis_correctness_of_analysis">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Trwałość */}
      <FormField name="result_of_costs_and_benefits_analysis_durability">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>

  );
}
