import { Paragraph } from 'docx';
import {
  printoutToDocxTable,
  printoutToDocxTableCell,
  printoutToDocxTableRow,
} from '../PrintoutToDocxComponents';
import { printoutToDocxHelper } from '../../../_helpers';
import { indicatorRow } from './indicatorRow';
import { ownIndicatorRow } from './ownIndicatorRow';

/**
 * Product indicators element
 *
 * @param {object} props - root props
 * @param {object} props.subElementData - sub element data
 * @returns {indicators}
 */
export const indicators = ({ subElementData }) => {
  const {
    label, elementData, fields,
  } = subElementData;

  const fieldsConfig = printoutToDocxHelper.fieldsConfig(fields);

  return [
    printoutToDocxTable({
      columns: 1,
      rows: [
        printoutToDocxTableRow({
          children: [
            printoutToDocxTableCell({
              text: label,
              bgColor: 'gray',
              numberOfColumns: 1,
              bold: true,
            }),
          ],
        }),
      ],
    }),
    new Paragraph({
      spacing: {
        after: 300,
      },
    }),
    ...elementData.flatMap((indicator) => [
      indicator.own ? ownIndicatorRow({
        indicator,
        fields,
        fieldsConfig,
      }) : indicatorRow({
        indicator,
        fields,
        fieldsConfig,
      }),
      new Paragraph({
        spacing: {
          after: 300,
        },
      }),
    ]),
  ];
};
