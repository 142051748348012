import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import {
  DICTIONARIES,
  API_ROUTE,
} from '../../../_constants';
import {
  formatPkdCodesFieldValue,
  iriToId,
} from '../../../_helpers';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import { MonetaryField } from '../../MonetaryField';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';

/**
 * ReduceTurnover
 *
 * @returns {ReduceTurnover}
 */
export function ReduceTurnover() {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const { get } = useDictionaryLoader(DICTIONARIES.reduceTurnoverCalculationType);
  const {
    getValues, setValue,
  } = useFormContext();
  const pkdCodesChoices = fieldsConfig?.reduce_turnover_pkd_code?.choices || [];

  const calculatePercentOfReduceTurnoverFieldValue = () => {
    const [precedingReduceTurnover, reduceTurnover] = getValues([
      'reduce_turnover_turnover_value_in_the_year_preceding_reduce_turnover',
      'reduce_turnover_turnover_value_in_the_year_reduce_turnover',
    ]);
    const calculateValue = (((Number(precedingReduceTurnover) - Number(reduceTurnover))
    / precedingReduceTurnover) * 100).toFixed(2).toString();

    const reduceTurnoverValue = calculateValue > 0 ? calculateValue : 0;
    setValue(
      'reduce_turnover_percent_of_reduce_turnover',
      precedingReduceTurnover && reduceTurnover
        ? reduceTurnoverValue
        : '',
    );
  };

  return (
    <FieldSorter>
      {/* Etykieta: Spadek obrotów Wnioskodawcy */}
      <FormField name="reduce_turnover_reduce_applicant_turnover" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Forma liczenia spadku obrotów  */}
      <FormField name="reduce_turnover_calculation_types">
        {({
          onChange, value, name, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={filterOptions(get(DICTIONARIES.reduceTurnoverCalculationType.name))}
            renderSelectAllButton={false}
            error={error}
            disabled={isReadonly}
          />
        )}
      </FormField>
      {/* Wartość obrotów w roku poprzedzającym spadek obrotów [zł] */}
      <FormField name="reduce_turnover_turnover_value_in_the_year_preceding_reduce_turnover">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={(event) => {
              calculatePercentOfReduceTurnoverFieldValue();
              onBlur(event);
            }}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{ inputComponent: MonetaryField }}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Wartość obrotów w roku spadku obrotów [zł] */}
      <FormField name="reduce_turnover_turnover_value_in_the_year_reduce_turnover">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={(event) => {
              calculatePercentOfReduceTurnoverFieldValue();
              onBlur(event);
            }}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{ inputComponent: MonetaryField }}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Spadek obrotów  [%] */}
      <FormField name="reduce_turnover_percent_of_reduce_turnover">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            variant="outlined"
            disabled
            error={!!error}
            fullWidth
            InputProps={{ inputComponent: MonetaryField }}
            inputProps={{
              allowNegative: false,
            }}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Etykieta: Prowadzenie działalności gospodarczej zgodnej z Inwestycją A1.2.1 */}
      <FormField name="reduce_turnover_economic_activity" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Etykieta: Dokumenty niezbędne do realizacji przedsięwzięcia MŚP */}
      <FormField name="reduce_turnover_necessary_documents_to_realization_sme" contextHelpLabel>
        {() => null}
      </FormField>
      {/* Numer kodu PKD (z sektora HoReCa, turystyka, kultura) */}
      <FormField name="reduce_turnover_pkd_code">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <ApiAutocomplete
            id={name}
            filterBy="nameOrCode"
            initialValue={value.map((iri) => iriToId(iri))}
            multiple
            initialValueFilterBy="id"
            changeHandler={(newValue) => onChange(newValue.map(({ id }) => id))}
            optionParser={formatPkdCodesFieldValue}
            textFieldProps={{ onBlur }}
            baseUrl={API_ROUTE.elementDictionaries.pkdCodes}
            disabled={isReadonly}
            customFilter={pkdCodesChoices.length === 0
              ? () => true
              : (payloadObject) => pkdCodesChoices.includes(payloadObject.id)}
            error={error}
          />
        )}
      </FormField>
      {/* Decyzje/prawa/pozwolenia */}
      <FormField name="reduce_turnover_decision">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zawieszenie działalności gospodarczej (jeśli dotyczy) */}
      <FormField name="reduce_turnover_suspension_economic_activity">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis działalności */}
      <FormField name="reduce_turnover_activity_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Czy w okresie od 20 marca 2020 r. do 15 maja 2022 r. wnioskodawca prowadził działalność
      gospodarczą we wskazanych kodach PKD nieprzerwanie przez 6 następujących po sobie miesięcy.  */}
      <FormField name="reduce_turnover_applicant_ran_business">
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {/* Metoda szacowania obrotów i sposób udokumentowania */}
      <FormField name="reduce_turnover_estimation_method">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
