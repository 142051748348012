/**
 * Application statuses.
 *
 * @type {object}
 */
export const APPLICATION_STATUSES = {
  edition: 'edition',
  submitted: 'submitted',
  withdrawn: 'withdrawn',
  evaluation: 'evaluation',
  supplementation: 'supplementation',
  autocorrect: 'autocorrect',
  approved: 'approved',
  rejected: 'rejected',
  invalidated: 'invalidated',
  rejectedFirstStage: 'rejected_first_stage',
  preEvaluation: 'pre_evaluation',
};

/**
 * Application statuses translations.
 *
 * @type {object}
 */
export const APPLICATION_STATUSES_TRANSLATIONS = {
  edition: 'W edycji',
  submitted: 'Złożony',
  withdrawn: 'Wycofany',
  evaluation: 'W trakcie oceny',
  supplementation: 'W uzupełnieniu',
  autocorrect: 'Korekta PARP',
  approved: 'Wybrany',
  rejected: 'Niewybrany',
  invalidated: 'Unieważniony',
};
