import {
  Controller,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import {
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import CharsCounter from '../../../CharsCounter';
import { useAuth } from '../../../../_security';
import {
  useEvaluationItemContext,
  useEvaluationSheetsActionContext,
  useEvaluationSheetsContext,
} from '../../../../Context';
import {
  evaluationSheetHelper,
  iriToId,
} from '../../../../_helpers';

/**
 * Evaluation sheet justification field.
 *
 * @returns {EvaluationSheetJustificationField}
 */
export function EvaluationSheetJustificationField() {
  const { showFilteredModulesByExpert } = useEvaluationSheetsActionContext();
  const { transformedData: {
    isApplicationLeadingExpert, expertList,
  } } = useEvaluationSheetsContext();
  const {
    evaluationItem, userPermissions,
  } = useEvaluationItemContext();
  const evaluationItemId = evaluationItem.id;
  const { id: loggedUserId } = useAuth();
  const {
    control,
  } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: `${evaluationItemId}.justifications`,
    keyName: 'key',
  });

  return fields.map((field, index) => {
    const ownerId = iriToId(field.createdBy);
    const isLoggedUserOwner = ownerId === loggedUserId;
    const expertName = evaluationSheetHelper.extractFullNameFromExpert(
      evaluationSheetHelper.getUserExpertData(expertList, ownerId)
    );

    return (
      <Grid key={field.id} item xs={12}>
        <FormControl fullWidth>
          <Controller
            rules={{
              required: {
                value: (!showFilteredModulesByExpert && isApplicationLeadingExpert),
                message: `Proszę uzupełnić pole "Propozycja uzasadnienia - ${expertName}"`,
              },
            }}
            name={`${evaluationItemId}.justifications.${index}.justification`}
            control={control}
            render={({
              field: {
                onChange, value, name, onBlur,
              },
              fieldState: {
                error,
              },
            }) => (
              <TextField
                error={!!error}
                helperText={error?.message}
                name={name}
                onChange={onChange}
                defaultValue={value}
                variant="outlined"
                label={`Propozycja uzasadnienia - ${expertName}`}
                onBlur={onBlur}
                InputProps={{
                  endAdornment: (
                    <CharsCounter valueLength={value?.length || 0} maxLength={30000} />
                  ),
                }}
                inputProps={{ maxLength: 30000 }}
                multiline
                minRows={3}
                disabled={!userPermissions.justification || !isLoggedUserOwner}
              />
            )}
          />
        </FormControl>
      </Grid>
    );
  });
}
