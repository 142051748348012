import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * OtherAccreditedOiEdihKkkCooperation Pdf element.
 *
 * @returns {OtherAccreditedOiEdihKkkCooperationPdf}
 */
function OtherAccreditedOiEdihKkkCooperationPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* OI współpracuje z co najmniej 1 innym akredytowanym OI, EDIH lub KKK  */}
        <RowField name="other_accredited_oi_edih_kkk_cooperation_io_cooperating" />
        {/* Tabela "Dane podmiotu" */}
        <CollectionWrapper name="other_accredited_oi_edih_kkk_cooperation_entity_data">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index}>
                <View style={printStyle.table}>
                  {/* Nazwa podmiotu */}
                  <RowField
                    name={`${collectionName}.${index}.entity_name`}
                    value={item.entity_name}
                    oldValue={previousRowData?.entity_name}
                    isSingleRow
                  />
                  {/* Data nawiązania współpracy */}
                  <RowField
                    name={`${collectionName}.${index}.cooperation_date`}
                    value={item.cooperation_date}
                    oldValue={previousRowData?.cooperation_date}
                  />
                  {/* Zakres współpracy   */}
                  <RowField
                    name={`${collectionName}.${index}.cooperation_scope`}
                    value={item.cooperation_scope}
                    oldValue={previousRowData?.cooperation_scope}
                    isSingleRow
                  />
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
      </FieldSorter>
    </View>
  );
}

export default OtherAccreditedOiEdihKkkCooperationPdf;
