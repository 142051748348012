import {
  FormControl,
  TextField,
} from '@mui/material';
import {
  Controller,
  useFormContext,
} from 'react-hook-form';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import FieldWrapper from '../FieldWrapper';

/**
 * Project months number.
 *
 * @param {object} props - root props
 * @param {number} props.index - validation index
 * @param {object} props.rule - rule data
 * @returns {ProjectMonthsNumber}
 */
function ProjectMonthsNumber({
  index, rule,
}) {
  const {
    control, watch, getValues, setValue,
  } = useFormContext();

  const PROJECT_MONTHS_NUMBER_LABELS = {
    min_project_months_number: 'Minimalna liczba miesięcy projektu',
    max_project_months_number: 'Maksymalna liczba miesięcy projektu',
  };

  const isEnabled = watch(`rules.${index}.enabled`);

  useEffect(() => {
    if (isEnabled && Array.isArray(getValues(`rules.${index}.options`))) {
      setValue(`rules.${index}.options`, {
        limit: null,
      });
    }
  }, []);

  return (
    <FieldWrapper name={`rules.${index}.options.limit`} label={PROJECT_MONTHS_NUMBER_LABELS[rule.name]}>
      <FormControl
        variant="outlined"
        fullWidth
      >
        <Controller
          name={`rules.${index}.options.limit`}
          control={control}
          render={({
            field: {
              onChange, value, name, onBlur,
            },
          }) => (
            <TextField
              type="number"
              id={name}
              name={name}
              onChange={onChange}
              defaultValue={value}
              variant="outlined"
              disabled={!isEnabled}
              onBlur={onBlur}
            />
          )}
        />
      </FormControl>
    </FieldWrapper>
  );
}

export default ProjectMonthsNumber;

ProjectMonthsNumber.propTypes = {
  index: PropTypes.number.isRequired,
  rule: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};
