import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { API_ROUTE } from '../../../_constants';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
  formatPkdCodesFieldValue,
  iriToId,
} from '../../../_helpers';

/**
 * ApplicantProductBrand program concept
 *
 * @returns {ApplicantProductBrand}
 */
export function ApplicantProductBrand() {
  const {
    isReadonly, control, fieldsConfig,
  } = useElementContext();

  const { setValue } = useFormContext();

  const collectionName = 'applicant_product_brand_products_subject_promotion';

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });

  const promotionSubjectConfig = fieldsConfig[collectionName];
  const subfields = promotionSubjectConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = promotionSubjectConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  return (
    <FieldSorter>
      {/* Nazwa marki produktowej, której dotyczy projekt  */}
      <FormField name="applicant_product_brand_name">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Od kiedy marka produktowa znajduje się w ofercie wnioskodawcy  */}
      <FormField name="applicant_product_brand_since_when_is_in_portfolio">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Informacje potwierdzające prawo wnioskodawcy do dysponowania marką produktową, której dotyczy projekt  */}
      <FormField name="applicant_product_brand_information_confirming_applicant_right">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela: Produkty będące przedmiotem promocji */}
      <LabeledFieldRow fieldConfig={promotionSubjectConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => {
          const pkdCodesChoices = fieldsConfig?.[`${collectionName}.${index}.pkd`]?.choices || [];

          return (
            <CollectionFieldContainer
              onRemove={() => removeItem(
                fields,
                minRowNumber,
                remove,
                setValue,
                collectionName,
                index,
              )}
              itemIndex={index}
              isReadonly={isReadonly}
              fieldsOrder={Object.keys(subfields)}
              buttonRemoveVisible={fields.length > minRowNumber}
              key={field.id}
              isIndexed
            >
              {/* Nazwa produktu będącego przedmiotem promocji */}
              <FormField name={`${collectionName}.${index}.product_name`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Numer kodu PKD działalności, której dotyczy produkt będący przedmiotem promocji */}
              <FormField name={`${collectionName}.${index}.pkd`}>
                {({
                  name, onChange, value, onBlur, error,
                }) => (
                  <ApiAutocomplete
                    id={name}
                    filterBy="nameOrCode"
                    initialValue={iriToId(value)}
                    initialValueFilterBy="id"
                    changeHandler={({ id }) => onChange(id)}
                    optionParser={formatPkdCodesFieldValue}
                    textFieldProps={{ onBlur }}
                    baseUrl={API_ROUTE.elementDictionaries.pkdCodes}
                    disabled={isReadonly}
                    customFilter={pkdCodesChoices.length === 0
                      ? () => true
                      : (payloadObject) => pkdCodesChoices.includes(payloadObject.id)}
                    error={error}
                  />
                )}
              </FormField>
              {/* Opis produktu będącego przedmiotem promocji */}
              <FormField name={`${collectionName}.${index}.product_description`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Opis założeń w zakresie wprowadzenia produktu pod wskazaną marką produktową na wybranych rynkach
             docelowych */}
              <FormField name={`${collectionName}.${index}.assumptions_description`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Potencjał eksportowy wnioskodawcy - potencjalne zainteresowanie produktem na rynkach zagranicznych,
            w tym rynkach docelowych */}
              <FormField name={`${collectionName}.${index}.applicant_export_potential`}>
                {({
                  onChange, value, name, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
            </CollectionFieldContainer>
          );
        })}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="WpAPcAG0oQMCKnO"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
    </FieldSorter>
  );
}
