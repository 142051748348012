import {
  Box,
  Typography,
} from '@mui/material';
import { ContainerLoader } from '../../../Application/Application/ContainerLoader';

/**
 * Evaluation sheet loader.
 *
 * @returns {EvaluationSheetLoader}
 */
export function EvaluationSheetLoader() {
  return (
    <Box display="flex" justifyContent="center" flexWrap="wrap">
      <ContainerLoader />
      <Typography mt={3} width="100%" textAlign="center">
        Proszę poczekać. Trwa wczytywanie danych.
      </Typography>
    </Box>
  );
}
