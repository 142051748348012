import {
  AlignmentType,
  Paragraph,
  TextRun,
} from 'docx';

/**
 * Printout to docx text.
 *
 * @param {object} props - root props
 * @param {string} props.text - text
 * @param {number} props.size - font size
 * @param {string} props.align - text align
 * @param {boolean} props.bold - font weight
 * @param {number} props.marginBottom - margin bottom
 * @param {number} props.marginTop - margin top
 * @returns {printoutToDocxText}
 */
export const printoutToDocxText = ({
  text = '',
  size = 20,
  align = AlignmentType.START,
  bold = false,
  marginBottom = 0,
  marginTop = 0,
}) => new Paragraph({
  alignment: align,
  spacing: {
    before: marginTop,
    after: marginBottom,
  },
  children: [
    new TextRun({
      text,
      size: size * 2,
      bold,
      font: 'Arial',
    }),
  ],
});
