import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import RowField from '../../Components/RowField/RowField';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * GrantProjectImplementationTeam pdf element.
 *
 * @returns {GrantProjectImplementationTeamPdf}
 */
function GrantProjectImplementationTeamPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Tabela: Członkowie klastra biorący udział w procesie internacjonalizacji */}
        <CollectionWrapper name="grant_project_implementation_team_grant_project_operator_applicant_experience">
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  {/* Doświadczenie w realizacji projektu grantowego */}
                  <RowField
                    name={`${collectionName}.${index}.implementing_grant_project_experience`}
                    value={item.implementing_grant_project_experience}
                    oldValue={previousRowData?.implementing_grant_project_experience}
                  />
                  {/* Doświadczenie w realizacji projektu tożsamego z projektem grantowym */}
                  <RowField
                    name={`${collectionName}.${index}.implementing_project_identical_to_grant_project_experience`}
                    value={item.implementing_project_identical_to_grant_project_experience}
                    oldValue={previousRowData?.implementing_project_identical_to_grant_project_experience}
                  />
                  {/* Nazwa programu */}
                  <RowField
                    name={`${collectionName}.${index}.program_name`}
                    value={item.program_name}
                    oldValue={previousRowData?.program_name}
                    isSingleRow
                  />
                  {/* Tytuł projektu */}
                  <RowField
                    name={`${collectionName}.${index}.project_title`}
                    value={item.project_title}
                    oldValue={previousRowData?.project_title}
                    isSingleRow
                  />
                  {/* etykieta: Okres realizacji projektu */}
                  <RowField
                    name={`${collectionName}.${index}.project_implementation_period`}
                    value={item.project_implementation_period}
                    oldValue={previousRowData?.project_implementation_period}
                    isSingleRow
                  />
                  {/* Od */}
                  <RowField
                    name={`${collectionName}.${index}.start_date`}
                    value={item.start_date}
                    oldValue={previousRowData?.start_date}
                  />
                  {/* Do */}
                  <RowField
                    name={`${collectionName}.${index}.finish_date`}
                    value={item.finish_date}
                    oldValue={previousRowData?.finish_date}
                  />
                  {/* Budżet projektu (PLN) i źródło finansowania */}
                  <RowField
                    name={`${collectionName}.${index}.project_budget`}
                    value={item.project_budget}
                    oldValue={previousRowData?.project_budget}
                    isSingleRow
                  />
                  {/* Podmioty zaangażowane w realizację projektu */}
                  <RowField
                    name={`${collectionName}.${index}.project_implementation_entities_involved`}
                    value={item.project_implementation_entities_involved}
                    oldValue={previousRowData?.project_implementation_entities_involved}
                    isSingleRow
                  />
                  {/* Szczegółowy opis doświadczenia Wnioskodawcy w roli operatora (lub lidera) projektu grantowego
             lub tożsamego z projektem grantowym */}
                  <RowField
                    name={`${collectionName}.${index}.grant_project_operator_applicant_experience_detailed_description`}
                    value={item.grant_project_operator_applicant_experience_detailed_description}
                    oldValue={previousRowData?.grant_project_operator_applicant_experience_detailed_description}
                    isSingleRow
                  />
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        {/* Struktura organizacyjna zespołu wdrażającego projekt grantowy */}
        <RowField
          name="grant_project_implementation_team_implementing_grant_project_organizational_structure"
          isSingleRow
        />
        {/* Tabela: Doświadczenie i kompetencje 2 kluczowych członków zespołu wdrażającego projekt grantowy */}
        <CollectionWrapper
          name="grant_project_implementation_team_grant_project_2_key_members_implementation_team_experience"
        >
          {({
            collectionName, collectionData, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                {/* Imię i nazwisko */}
                <View style={printStyle.table}>
                  <RowField
                    name={`${collectionName}.${index}.first_and_last_name`}
                    value={item.first_and_last_name}
                    oldValue={previousRowData?.first_and_last_name}
                    isSingleRow
                  />
                  {/* Wykształcenie */}
                  <RowField
                    name={`${collectionName}.${index}.education`}
                    value={item.education}
                    oldValue={previousRowData?.education}
                    isSingleRow
                  />
                  {/* Nazwa stanowiska/funkcji */}
                  <RowField
                    name={`${collectionName}.${index}.position_name`}
                    value={item.position_name}
                    oldValue={previousRowData?.position_name}
                    isSingleRow
                  />
                  {/* Obszar odpowiedzialności */}
                  <RowField
                    name={`${collectionName}.${index}.responsibility_area`}
                    value={item.responsibility_area}
                    oldValue={previousRowData?.responsibility_area}
                    dictionaryName={DICTIONARIES.responsibilityAreas.name}
                  />
                  {/* Zakres obowiązków w projekcie */}
                  <RowField
                    name={`${collectionName}.${index}.responsibilities_scope_in_the_project`}
                    value={item.responsibilities_scope_in_the_project}
                    oldValue={previousRowData?.responsibilities_scope_in_the_project}
                    isSingleRow
                  />
                  {/* Doświadczenie członka zespołu  w projektach grantowych lub tożsamych z grantowym */}
                  <RowField
                    name={`${collectionName}.${index}.grant_projects_team_member_experience`}
                    value={item.grant_projects_team_member_experience}
                    oldValue={previousRowData?.grant_projects_team_member_experience}
                  />
                  {/* Opis doświadczenia zawodowego związanego z pełnioną funkcją w projekcie */}
                  <RowField
                    name={`${collectionName}.${index}.responsibilities_scope_in_the_project`}
                    value={item.responsibilities_scope_in_the_project}
                    oldValue={previousRowData?.responsibilities_scope_in_the_project}
                    isSingleRow
                  />
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
      </FieldSorter>
    </View>
  );
}

export default GrantProjectImplementationTeamPdf;
