import {
  Box,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

/**
 * EvaluationSheetSingleChairmanRemark element.
 *
 * @param {object} props - root props
 * @param {string} props.remark - chairman remark
 * @param {string} props.label - combined label from module name and evaluation item name
 * @returns {EvaluationSheetSingleChairmanRemark}
 */
export function EvaluationSheetSingleChairmanRemark({
  remark, label,
}) {
  return (
    <Box mb={2}>
      <Typography
        fontFamily={'"Montserrat-Light", sans-serif'}
        fontSize="1.2rem"
        fontWeight="bold"
        textAlign="left"
        mb={1}
      >
        {label}
      </Typography>
      <Typography
        fontSize="1rem"
        textAlign="left"
      >
        {remark}
      </Typography>
    </Box>
  );
}

EvaluationSheetSingleChairmanRemark.propTypes = {
  label: PropTypes.string.isRequired,
  remark: PropTypes.string.isRequired,
};
