import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import CollectionWrapper from '../../Components/CollectionWrapper/CollectionWrapper';
import SingleCollectionWrapper from '../../Components/SingleCollectionWrapper/SingleCollectionWrapper';
import { DICTIONARIES } from '../../../../_constants';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * ResultOfCostsAndBenefitsAnalysis Pdf element.
 *
 * @returns {ResultOfCostsAndBenefitsAnalysisPdf}
 */
function ResultOfCostsAndBenefitsAnalysisPdf() {
  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Tabela "Główne wskaźniki analizy ekonomicznej zgodnie z dokumentem dotyczącym AKK" */}
        <CollectionWrapper name="costs_and_benefits_analysis_result_akk_main_indicators_economic_analysis">
          {({
            collectionData, collectionName, previousCollectionData, getIndexForPreviousRowData,
          }) => collectionData.map((item, index) => {
            const previousRowData = previousCollectionData[getIndexForPreviousRowData(item.set_uuid)];

            return (
              <SingleCollectionWrapper key={item.set_uuid} index={index} rowStatus={item?.status}>
                <View style={printStyle.table}>
                  {/* Główne parametry i wskaźniki */}
                  <RowField
                    name={`${collectionName}.${index}.main_parameters_and_indicators`}
                    value={item.main_parameters_and_indicators}
                    oldValue={previousRowData?.main_parameters_and_indicators}
                    dictionaryName={DICTIONARIES.mainParametersAndIndicators.name}
                  />
                  {/* Wartość */}
                  <RowField
                    name={`${collectionName}.${index}.result_of_costs_and_benefits_analysis_value`}
                    value={item.result_of_costs_and_benefits_analysis_value}
                    oldValue={previousRowData?.result_of_costs_and_benefits_analysis_value}
                  />
                  {/* Odniesienie */}
                  <RowField
                    name={`${collectionName}.${index}.result_of_costs_and_benefits_analysis_reference`}
                    value={item.result_of_costs_and_benefits_analysis_reference}
                    oldValue={previousRowData?.result_of_costs_and_benefits_analysis_reference}
                  />
                  {/* Trwałość projektu */}
                  <RowField
                    name={`${collectionName}.${index}.project_durability`}
                    value={item.project_durability}
                    oldValue={previousRowData?.project_durability}
                    isSingleRow
                  />
                </View>
              </SingleCollectionWrapper>
            );
          })}
        </CollectionWrapper>
        {/* Poprawność analizy finansowej i ekonomicznej */}
        <RowField name="result_of_costs_and_benefits_analysis_correctness_of_analysis" isSingleRow />
        {/* Trwałość */}
        <RowField name="result_of_costs_and_benefits_analysis_durability" isSingleRow />
      </FieldSorter>
    </View>
  );
}

export default ResultOfCostsAndBenefitsAnalysisPdf;
