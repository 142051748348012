import {
  Box,
  Button,
} from '@mui/material';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import { theme } from '../../../../styles/theme';
import { ADDITIONAL_MODULE_IDS } from '../../../../_constants';
import { useEvaluationSheetsActionContext } from '../../../../Context';

/**
 * EvaluationSheetModulesBar element.
 *
 * @param {object} props - root props
 * @param {Array} props.modules - modules
 * @param {boolean} props.hasAcceptedAtLeastOneModule - expert has accepted min one module
 * @returns {EvaluationSheetModulesBar}
 */
export function EvaluationSheetModulesBar({
  modules, hasAcceptedAtLeastOneModule,
}) {
  const {
    handleShowFilteredModulesByExpert, activeModule, handleActiveModule, showFilteredModulesByExpert,
  } = useEvaluationSheetsActionContext();

  if (modules.length === 0) {
    return '';
  }

  return (
    <Box
      p={2}
      pt={5}
      bgcolor={theme.palette.brandGray10.main}
      justifyContent="flex-start"
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      gap={1}
      width="100%"
    >
      {modules
        .filter(({ moduleId }) => ((moduleId === ADDITIONAL_MODULE_IDS.kryteriaOgolem && !hasAcceptedAtLeastOneModule)
          ? hasAcceptedAtLeastOneModule
          : true))
        .filter(({ moduleId }) => moduleId !== ADDITIONAL_MODULE_IDS.kryteriaRankingujace)
        .map(({
          moduleName, moduleId,
        }) => (
          <Button
            key={moduleId}
            type="button"
            id={uniqid()}
            color="secondary"
            variant={activeModule === moduleId ? 'contained' : 'outlined'}
            onClick={() => {
              if (activeModule === moduleId) {
                handleActiveModule(null);
                handleShowFilteredModulesByExpert(true);

                return;
              }
              handleActiveModule(moduleId);
              handleShowFilteredModulesByExpert(false);
            }}
          >
            {moduleName}
          </Button>
        ))}
      <Button
        type="button"
        id="AU1kQLFO0if8vvl"
        color="secondary"
        variant={(!activeModule && !showFilteredModulesByExpert) ? 'contained' : 'outlined'}
        onClick={() => {
          handleActiveModule(null);
          handleShowFilteredModulesByExpert(false);
        }}
      >
        Cały arkusz oceny
      </Button>
    </Box>
  );
}

EvaluationSheetModulesBar.propTypes = {
  modules: PropTypes.arrayOf(Object),
  hasAcceptedAtLeastOneModule: PropTypes.bool.isRequired,
};

EvaluationSheetModulesBar.defaultProps = {
  modules: [],
};
