import {
  TextField,
  Button,
  Grid,
} from '@mui/material';
import {
  useFormContext,
  useFieldArray,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useElementContext } from '../../../Context';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import { ContainerLoader } from '../../Application/Application/ContainerLoader';
import { FormField } from '../../Application/Application/FormField';
import { DICTIONARIES } from '../../../_constants';
import { FieldSorter } from './FieldSorter';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import CharsCounter from '../../CharsCounter';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import {
  addItem,
  removeItem,
  getCollectionFieldObject,
} from '../../../_helpers';

/**
 * ProjectDetailedInformation element renderer.
 *
 * @returns {ProjectDetailedInformation}
 */
export function ProjectDetailedInformation() {
  const {
    isReadonly, control, fieldsConfig,
  } = useElementContext();
  const { setValue } = useFormContext();

  const collectionName = 'project_detailed_information_taking_into_account_transport_services_in_mof_area';

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionName,
  });

  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.supportScopes,
    DICTIONARIES.projectComplexities,
    DICTIONARIES.compatibilityWithIdeaNebInitiative,
    DICTIONARIES.takeIntoAccountDoesNotTakeIntoAccount,
    DICTIONARIES.projectImplementationInAreaWithSeparatedPaidParkingZone,
  );

  const transportServicesSolutionConfig = fieldsConfig[collectionName];
  const subfields = transportServicesSolutionConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = transportServicesSolutionConfig || {};

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber]);

  if (!isLoaded) {
    return <ContainerLoader />;
  }
  const fieldNamePrefix = 'project_detailed_information';

  return (
    <FieldSorter>
      {/* Kategoria drogi */}
      <FormField name="road_category">
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zakres wsparcia */}
      <FormField name="support_scope">
        {({
          name, onChange, value, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={filterOptions(get(DICTIONARIES.supportScopes.name))}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            error={error}
            multiple
            renderSelectAllButton={false}
          />
        )}
      </FormField>
      {/* Zachowanie jednolitych parametrów technicznych */}
      <FormField name="uniform_technical_parameters_maintenance">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Podniesienie parametrów infrastruktury drogowej */}
      <FormField name="road_infrastructure_upgrade">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Wpływ na obszary miejskie */}
      <FormField name="urban_areas_impact">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Elementy poprawiające bezpieczeństwo rowerzystów w ruchu drogowym */}
      <FormField name="cyclist_safety_in_traffic_improving_elements">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Elementy poprawiające bezpieczeństwo niezmotoryzowanych uczestników ruchu drogowego na
      terenach zabudowanych */}
      <FormField name="non_motorized_participants_safety_in_traffic_improving_elements">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Wprowadzenie ruchu drogowego poza teren zabudowany */}
      <FormField name="intorduction_of_traffic_outside_built_up_area">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Dostosowanie drogi do wykonywania codziennych przewozów publicznego transportu zbiorowego */}
      <FormField name="road_to_public_transport_adaptation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Kompleksowość projektu */}
      <FormField name={`${fieldNamePrefix}_project_complexity`}>
        {({
          name, onChange, value, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={filterOptions(get(DICTIONARIES.projectComplexities.name))}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            error={error}
            multiple
            renderSelectAllButton={false}
          />
        )}
      </FormField>
      {/* Zgodność projektu z ideą inicjatywy Nowy Europejski Bauhaus (z ang. NEB) */}
      <FormField name={`${fieldNamePrefix}_compatibility_with_idea_neb_initiative`}>
        {({
          name, onChange, value, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={filterOptions(get(DICTIONARIES.compatibilityWithIdeaNebInitiative.name))}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            error={error}
            renderSelectAllButton={false}
          />
        )}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name={`${fieldNamePrefix}_compatibility_with_idea_neb_initiative_justification`}>
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Uwzględnienie infrastruktury odnawialnych źródeł energii (OZE) w projekcie */}
      <FormField name={`${fieldNamePrefix}_take_into_account_renewable_energy_infrastructure`}>
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={allChoices}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            error={error}
            renderSelectAllButton={false}
          />
        )}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name={`${fieldNamePrefix}_take_into_account_renewable_energy_infrastructure_justification`}>
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Tabela "Uwzględnienie rozwiązań dotyczących integracji usług przewozowych na obszarze MOF w projekcie" */}
      <LabeledFieldRow fieldConfig={transportServicesSolutionConfig} contextHelpLabel fullWidth>
        {fields.map((field, index) => (
          <CollectionFieldContainer
            onRemove={() => removeItem(
              fields,
              minRowNumber,
              remove,
              setValue,
              collectionName,
              index,
            )}
            itemIndex={index}
            isReadonly={isReadonly}
            fieldsOrder={Object.keys(subfields)}
            buttonRemoveVisible={fields.length > minRowNumber}
            key={field.id}
            isIndexed
          >
            {/* a) Uwzględnienie rozwiązań dotyczących integracji usług przewozowych na obszarze MOF w projekcie */}
            <FormField name={`${collectionName}.${index}.taking_into_account_transport_services_in_mof_area`}>
              {({
                onChange, value, name, onBlur, error, filterOptions,
              }) => (
                <CustomAutocomplete
                  id={name}
                  options={filterOptions(get(DICTIONARIES.takeIntoAccountDoesNotTakeIntoAccount.name))}
                  initialValue={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={isReadonly}
                  error={error}
                  multiple
                  renderSelectAllButton={false}
                />
              )}
            </FormField>
            {/* b) Uzasadnienie */}
            <FormField name={`${collectionName}.${index}.taking_into_account_transport_services_in_mof_area_justification`}>
              {({
                onChange, value, name, maxLength, onBlur, error,
              }) => (
                <TextField
                  multiline
                  id={name}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  defaultValue={value}
                  variant="outlined"
                  InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                  inputProps={{ maxLength }}
                  disabled={isReadonly}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            </FormField>
          </CollectionFieldContainer>
        ))}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="pBHZxxl1hs0EDsk"
              variant="contained"
              color="secondary"
              onClick={() => addItem(fields, maxRowNumber, append, fieldObject)}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* 7.Uwzględnienie koncepcji „Mobilność jako usługa” (z ang. MaaS) w projekcie */}
      <FormField name={`${fieldNamePrefix}_taking_into_account_concept_maas`}>
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={allChoices}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            error={error}
            renderSelectAllButton={false}
          />
        )}
      </FormField>
      {/* 8.Uzasadnienie */}
      <FormField name={`${fieldNamePrefix}_taking_into_account_concept_maas_justification`}>
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 9.Objęcie świadczoną usługą przewozów pasażerskich obszarów dotychczas nieobsługiwanych */}
      <FormField name={`${fieldNamePrefix}_coverage_provided_passenger_service`}>
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={allChoices}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            error={error}
            renderSelectAllButton={false}
          />
        )}
      </FormField>
      {/* 10.Uzasadnienie */}
      <FormField name={`${fieldNamePrefix}_coverage_provided_passenger_service_justification`}>
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 11.Realizacja projektu na obszarze, na którym istnieje wydzielona strefa płatnego parkowania lub w
      ramach projektu planowane jest utworzenie strefy płatnego parkowania */}
      <FormField name={`${fieldNamePrefix}_project_implementation_in_area_with_separated_paid_parking_zones`}>
        {({
          name, onChange, value, filterOptions, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={filterOptions(get(DICTIONARIES.projectImplementationInAreaWithSeparatedPaidParkingZone.name))}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            error={error}
            renderSelectAllButton={false}
          />
        )}
      </FormField>
      {/* 12. Uzasadnienie */}
      <FormField
        name={`${fieldNamePrefix}_project_implementation_in_area_with_separated_paid_parking_zones_justification`}
      >
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* 13. Konsultacje społeczne w celu zwiększenia dostępności transportu publicznego i mobilności */}
      <FormField name={`${fieldNamePrefix}_public_consultations`}>
        {({
          name, onChange, value, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            options={allChoices}
            initialValue={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={isReadonly}
            error={error}
            renderSelectAllButton={false}
          />
        )}
      </FormField>
      {/* Uzasadnienie */}
      <FormField name={`${fieldNamePrefix}_public_consultations_justification`}>
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>

      {/* Potencjał do realizacji projektu */}
      <FormField name={`${fieldNamePrefix}_project_implement_potential`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zaangażowanie społeczne w projekt */}
      <FormField name={`${fieldNamePrefix}_social_involvement_in_the_project`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Koncepcja produktu turystycznego */}
      <FormField name={`${fieldNamePrefix}_tourism_product_concept`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Trwałość produktu turystycznego */}
      <FormField name={`${fieldNamePrefix}_tourism_product_concept_durability`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zgodność projektu z ideą inicjatywy Nowy Europejski Bauhaus (FEPW.05.01) */}
      <FormField name={`${fieldNamePrefix}_project_conformity_with_the_idea`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Dostępność transportem zbiorowym */}
      <FormField name={`${fieldNamePrefix}_public_transport_accessibility`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Zdolność do funkcjonowania oferty turystycznej w ciągu roku */}
      <FormField name={`${fieldNamePrefix}_the_ability_of_the_tourist_offer_to_function`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Wpływ produktu turystycznego na gospodarkę lokalną (jako element turystyki zrównoważonej) */}
      <FormField name={`${fieldNamePrefix}_tourism_product_impact_on_the_local_economy`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Korzyści dla społeczności lokalnej (jako element turystyki zrównoważonej) */}
      <FormField name={`${fieldNamePrefix}_local_community_benefits`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Potencjał rynkowy produktu turystycznego */}
      <FormField name={`${fieldNamePrefix}_tourism_product_market_potential`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Unikalność produktu turystycznego */}
      <FormField name={`${fieldNamePrefix}_tourism_product_uniqueness`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Poziom digitalizacji produktu turystycznego */}
      <FormField name={`${fieldNamePrefix}_tourism_product_digitalization_level`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Plan marketingowy */}
      <FormField name={`${fieldNamePrefix}_marketing_plan`}>
        {({
          name, onChange, value, onBlur, error, maxLength,
        }) => (
          <TextField
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            multiline
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
