import { TextField } from '@mui/material';
import { useElementContext } from '../../../Context';
import { FormField } from '../../Application/Application/FormField';
import CharsCounter from '../../CharsCounter';
import { FieldSorter } from './FieldSorter';
import { API_ROUTE } from '../../../_constants';
import {
  formatPkdCodesFieldValue,
  iriToId,
} from '../../../_helpers';
import { ApiAutocomplete } from '../../Autocomplete/ApiAutocomplete';

/**
 * Project Sme Assumptions
 *
 * @returns {ProjectSmeAssumptions}
 */
export function ProjectSmeAssumptions() {
  const {
    fieldsConfig, isReadonly,
  } = useElementContext();
  const pkdCodesChoices = fieldsConfig?.project_sme_assumptions_pkd_codes?.choices || [];

  return (
    <FieldSorter>
      {/* Opis i zakres rozszerzonej, unowocześnionej działalności przedsiębiorstwa lub
      zmienionego profilu działalności przedsiębiorstwa */}
      <FormField name="project_sme_assumptions_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Kod PKD rozszerzonej, unowocześnionej działalności przedsiębiorstwa lub
      zmienionego profilu działalności przedsiębiorstwa */}
      <FormField name="project_sme_assumptions_pkd_codes">
        {({
          onChange, value, name, onBlur, error,
        }) => (
          <ApiAutocomplete
            id={name}
            filterBy="nameOrCode"
            initialValue={value.map((iri) => iriToId(iri))}
            multiple
            initialValueFilterBy="id"
            changeHandler={(newValue) => onChange(newValue.map(({ id }) => id))}
            optionParser={formatPkdCodesFieldValue}
            textFieldProps={{ onBlur }}
            baseUrl={API_ROUTE.elementDictionaries.pkdCodes}
            disabled={isReadonly}
            customFilter={pkdCodesChoices.length === 0
              ? () => true
              : (payloadObject) => pkdCodesChoices.includes(payloadObject.id)}
            error={error}
          />
        )}
      </FormField>
      {/* Cel przedsięwzięcia MŚP */}
      <FormField name="project_sme_assumptions_project_sme_goal">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Komponent inwestycyjny  */}
      <FormField name="project_sme_assumptions_investment_component">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Komponent usług doradczych o charakterze rozwojowym  */}
      <FormField name="project_sme_assumptions_consulting_services_component">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Komponent szkoleniowy  */}
      <FormField name="project_sme_assumptions_training_component">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Nazwa produktu/usługi wprowadzonego do sprzedaży w wyniku rozszerzenia, unowocześnienia działalności
      przedsiębiorstwa lub zmiany profilu działalności przedsiębiorstwa */}
      <FormField name="project_sme_assumptions_product_name">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Transformacja cyfrowa */}
      <FormField name="project_sme_assumptions_digital_transformation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* w tym: zielona transformacja */}
      <FormField name="project_sme_assumptions_green_transformation">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
