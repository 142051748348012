import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from 'react-router-dom';
import {
  lazy,
  Suspense,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Link,
} from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Home,
  Login,
} from '../Pages';
import {
  PROFILES,
  URL,
  ROLES,
} from '../_constants';
import ContentWrapper from '../Features/ContentWrapper';
import { useAuth } from './AuthProvider';
import { GoBackNavigate } from './GoBackNavigate';
import { GlobalDialogProvider } from '../Context';
import { getBreadcrumbsByUrlAndRole } from '../_helpers';

/**
 * Loading progress visual indicator for Suspense fallback
 *
 * @returns {Progress} - component
 */
function Progress() { return <CircularProgress color="secondary" size={50} />; }

/**
 * Error fallback component
 *
 * @param {object} props - root props
 * @param {object} props.error - error object
 * @returns {ErrorFallback}
 */
function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <p>Coś poszło nie tak:</p>
      <pre>{error.message}</pre>
      <Link href="/home" color="primary">Wróć do strony startowej</Link>
    </div>
  );
}

/**
 * Suspense wrapped at error boundary
 *
 * @param {object} props - root props
 * @param {Function} props.children - function will be invoked as children
 * @returns {ErrorBoundarySuspense}
 */
function ErrorBoundarySuspense({ children }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<Progress />}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
}

/**
 * All defined routes privileges and matched components are stored in this component.
 *
 * @param {Array | Node | null} children - empty, child node or array of children
 * @returns {AuthBrowserRouter} - component
 */
export function AuthBrowserRouter({ children }) {
  const {
    authenticated, profile, sessionEnded, roles,
  } = useAuth();

  const isApplicant = profile === PROFILES.applicant;
  const isEmployee = profile === PROFILES.employee;
  const isExpert = profile === PROFILES.expert;
  const isOperator = profile === PROFILES.operator;
  const isManagementInstitution = profile === PROFILES.managementInstitution;

  const hasRoleAdmin = roles?.includes(ROLES.ROLE_ADMIN);
  const hasRoleExpertEdit = roles?.includes(ROLES.ROLE_EXPERT_EDIT);
  const hasRoleExpertPreview = roles?.includes(ROLES.ROLE_EXPERT_PREVIEW);
  const hasRoleSecretary = roles?.some((role) => role.includes(ROLES.ROLE_SECRETARY));

  const ProjectCard = lazy(() => import('../Pages/Application/ProjectCard/ProjectCardPage'));
  const AddApplicationTemplate = lazy(() => import('../Pages/AddApplicationTemplate'));
  const AddRecruitment = lazy(() => import('../Pages/AddRecruitment'));
  const MyApplications = lazy(() => import('../Pages/Applicant/MyApplications'));
  const ContactForm = lazy(() => import('../Pages/ContactForm'));
  const EditApplicationTemplate = lazy(() => import('../Pages/EditApplicationTemplate'));
  const EditRecruitment = lazy(() => import('../Pages/EditRecruitment'));
  const OngoingRecruitments = lazy(() => import('../Pages/OngoingRecruitments'));
  const PasswordChange = lazy(() => import('../Pages/PasswordChange'));
  const RecruitmentListPage = lazy(() => import('../Pages/RecruitmentList'));
  const NewsListPage = lazy(() => import('../Pages/NewsList.page'));
  const ExpertApplicationFormData = lazy(() => import('../Pages/Expert/ApplicationFormData'));
  const ExpertApplicationForm = lazy(() => import('../Pages/Expert/ExpertApplicationForm'));
  const UserIssues = lazy(() => import('../Pages/User/Issues'));
  const AccountSettingsPage = lazy(() => import('../Pages/User/AccountSettings'));
  const UsersListPage = lazy(() => import('../Pages/Employee/Users/UsersListPage'));
  const ExpertsListPage = lazy(() => import('../Pages/Employee/Experts/ExpertsListPage'));
  const ExpertCard = lazy(() => import('../Pages/ExpertCard'));
  const SessionEndedPage = lazy(() => import('../Pages/SessionEndedPage'));
  const ApplicationWithdrawal = lazy(() => import('../Pages/Application/ApplicationWithdrawal'));
  const ApplicationWithdrawalDecision = lazy(() => import('../Pages/Application/ApplicationWithdrawalDecision'));
  const ApplicationCorrection = lazy(() => import('../Pages/Application/ApplicationCorrection'));
  const UserDetails = lazy(() => import('../Pages/User/UserDetails'));
  const LsiDictionariesPage = lazy(() => import('../Pages/LsiDictionariesPage'));
  const OrganizationAddressPage = lazy(() => import('../Pages/Address/OrganizationAddressPage'));
  const HelpCenterPage = lazy(() => import('../Pages/User/HelpCenterPage'));
  const ApplicationForms = lazy(() => import('../Pages/Expert/ApplicationForms'));
  const ProjectAppraisalCommissionPage = lazy(() => import('../Pages/Recruitment/ProjectAppraisalCommissionPage'));
  const ExchangeDataWithCst = lazy(() => import('../Pages/ExchangeDataWithCst'));
  const ApplicationPrints = lazy(() => import('../Pages/ApplicationPrints'));
  const UserGuide = lazy(() => import('../Pages/UserGuide'));
  const CstDictionariesPage = lazy(() => import('../Pages/CstDictionariesPage'));
  const CostCategoryGroupPage = lazy(() => import('../Pages/ElementDictionaries/CostCategoryGroup.page'));
  const CostCategoryPage = lazy(() => import('../Pages/ElementDictionaries/CostCategory.page'));
  const ElementDictionaries = lazy(() => import('../Pages/ElementDictionaries/ElementDictionaries'));
  const ElementDictionariesTasks = lazy(() => import('../Pages/ElementDictionaries/Tasks.page'));
  const IndicatorsDictionary = lazy(() => import('../Pages/ElementDictionaries/IndicatorsDictionary'));
  const TaskTypesPage = lazy(() => import('../Pages/ElementDictionaries/TaskTypes.page'));
  const RedirectToExcludedEntitiesRegistry = lazy(() => import('../Pages/RedirectToExcludedEntitiesRegistry'));
  const StatementConfig = lazy(() => import('../Pages/StatementConfig'));
  const AllApplications = lazy(() => import('../Pages/Administration/AllApplications'));
  const SubmittedApplications = lazy(() => import('../Pages/Employee/SubmittedApplications'));
  const SubmittedApplicationsWithOperator = lazy(() => import('../Pages/Operator/SubmittedApplicationsWithOperator'));
  const ExpertPanelsPage = lazy(() => import('../Pages/Employee/ExpertPanels/ExpertPanelsPage'));
  const ApplicationsInExpertPanel = lazy(() => import('../Pages/Employee/ApplicationsInExpertPanel'));
  const ApplicationsListForExpert = lazy(() => import('../Pages/Employee/ApplicationsListForExpert'));
  const ApplicationFormCard = lazy(() => import('../Pages/Expert/ApplicationFormCard'));
  const AccountActivation = lazy(() => import('../Pages/User/AccountActivation'));
  const SystemMessages = lazy(() => import('../Pages/User/SystemMessages'));
  const SystemMessagesByProfile = lazy(() => import('../Pages/SystemMessagesByProfile'));
  const UserPermissions = lazy(() => import('../Pages/User/UserPermissions'));
  const ExpertContract = lazy(() => import('../Features/ExpertCard/ExpertContract/ExpertContract'));
  const OrderAcceptanceProtocols = lazy(
    () => import('../Features/ExpertCard/OrderAcceptanceProtocols/OrderAcceptanceProtocols')
  );
  const Opinions = lazy(() => import('../Pages/Expert/Opinions'));
  const Panels = lazy(() => import('../Pages/Expert/Panels'));
  const SubstantiveEvaluation = lazy(() => import('../Pages/Expert/SubstantiveEvaluation'));
  const ApplicationEdit = lazy(() => import('../Pages/Application/ApplicationEdit'));
  const Reports = lazy(() => import('../Pages/Employee/Reports'));
  const ReportDetail = lazy(() => import('../Features/Reports/ReportDetail'));
  const InformationClausePage = lazy(() => import(
    '../Pages/Administration/System/InformationClause/InformationClausePage'
  ));
  const KopStatementsPage = lazy(() => import('../Pages/Expert/KopStatements.page'));
  const ExpertAssessmentSheet = lazy(() => import('../Pages/Expert/AssessmentSheet'));
  const ExpertPreliminaryRemarksPage = lazy(() => import('../Pages/Expert/ExpertPreliminaryRemarksPage'));
  const SupplementCallPage = lazy(() => import('../Pages/Applicant/SupplementCallPage'));
  const TransferDetails = lazy(() => import('../Pages/Expert/TransferDetails'));
  const ApplicationStatusManagement = lazy(() => import(
    '../Pages/Application/ApplicationStatusManagement/ApplicationStatusManagement'
  ));
  const ReviewsPage = lazy(() => import('../Pages/Employee/ReviewsPage'));
  const ReviewSheetPage = lazy(() => import('../Pages/Employee/ReviewSheetPage'));
  const WorkEvaluationPage = lazy(() => import('../Pages/Employee/WorkEvaluationPage'));
  const WorkEvaluationList = lazy(() => import('../Pages/Expert/WorkEvaluationList'));
  const ExpertTaxReturnPage = lazy(() => import('../Pages/Expert/ExpertTaxReturnPage'));
  const AddKitConfiguratorPage = lazy(() => import(
    '../Pages/Recruitment/EvaluationConfigurator/AddKitConfiguratorPage'
  ));
  const EditKitConfiguratorPage = lazy(() => import(
    '../Pages/Recruitment/EvaluationConfigurator/EditKitConfiguratorPage'
  ));
  const ManageCriteriaPage = lazy(() => import(
    '../Pages/Recruitment/EvaluationConfigurator/ManageCriteriaPage'
  ));
  const AddInformationClausePage = lazy(() => import(
    '../Pages/Administration/System/InformationClause/AddInformationClausePage'
  ));
  const EditInformationClausePage = lazy(() => import(
    '../Pages/Administration/System/InformationClause/EditInformationClausePage'
  ));
  const ShareEvaluationSheetPage = lazy(() => import('../Pages/Employee/ShareEvaluationSheet.page'));
  const AddExpertPanelPage = lazy(() => import('../Pages/Employee/ExpertPanels/AddExpertPanelPage'));
  const EditExpertPanelPage = lazy(() => import('../Pages/Employee/ExpertPanels/EditExpertPanelPage'));
  const AddProtestPage = lazy(() => import('../Pages/Application/ProjectCard/Protests/AddProtestPage'));
  const EditProtestPage = lazy(() => import('../Pages/Application/ProjectCard/Protests/EditProtestPage'));
  const EvaluationSheetsList = lazy(() => import('../Pages/Applicant/EvaluationSheetsList.page'));
  const ExpertEvaluationSheet = lazy(() => import('../Pages/Expert/EvaluationSheetPage'));

  /**
   * Authenticated routes, available only for logged clients.
   * Notice that route suffixed with `/*` most probably contains sub-routes in given component.
   * Use `access` as extra variable to check if user has access to given route except that user is authenticated.
   */
  const authenticatedRoutes = [
    {
      path: URL.home,
      component: <Home />,
      access: true,
    },
    {
      path: URL.recruitment.add,
      component: (
        <ErrorBoundarySuspense>
          <AddRecruitment />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.recruitment.applicationTemplate.add,
      component: (
        <ErrorBoundarySuspense>
          <AddApplicationTemplate />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.recruitment.applicationTemplate.edit,
      component: (
        <ErrorBoundarySuspense>
          <EditApplicationTemplate />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.applicationsListExpert,
      component: (
        <ErrorBoundarySuspense>
          <ApplicationsListForExpert />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee
        && (hasRoleAdmin || hasRoleExpertEdit || hasRoleExpertPreview),
    },
    {
      path: URL.elementDictionaries.main,
      component: (
        <ErrorBoundarySuspense>
          <ElementDictionaries />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.elementDictionaries.tasks,
      component: (
        <ErrorBoundarySuspense>
          <ElementDictionariesTasks />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.elementDictionaries.costCategoryGroups,
      component: (
        <ErrorBoundarySuspense>
          <CostCategoryGroupPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.elementDictionaries.costCategories,
      component: (
        <ErrorBoundarySuspense>
          <CostCategoryPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.elementDictionaries.taskTypes,
      component: (
        <ErrorBoundarySuspense>
          <TaskTypesPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.elementDictionaries.indicators,
      component: (
        <ErrorBoundarySuspense>
          <IndicatorsDictionary />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.recruitment.edit,
      component: (
        <ErrorBoundarySuspense>
          <EditRecruitment />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.recruitmentEdit.evaluationConfigurator.addKit,
      component: (
        <ErrorBoundarySuspense>
          <AddKitConfiguratorPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.recruitmentEdit.evaluationConfigurator.editKit,
      component: (
        <ErrorBoundarySuspense>
          <EditKitConfiguratorPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.recruitmentEdit.evaluationConfigurator.manageCriteria,
      component: (
        <ErrorBoundarySuspense>
          <ManageCriteriaPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.recruitment.list,
      component: (
        <ErrorBoundarySuspense>
          <RecruitmentListPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee || isManagementInstitution,
    },
    {
      path: URL.expertsList,
      component: (
        <ErrorBoundarySuspense>
          <ExpertsListPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.expertCard,
      component: (
        <ErrorBoundarySuspense>
          <ExpertCard />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.applicationExpertCard,
      component: (
        <ErrorBoundarySuspense>
          <ApplicationFormCard />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: `${URL.config.statements}/*`,
      component: (
        <ErrorBoundarySuspense>
          <StatementConfig />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.newsList,
      component: (
        <ErrorBoundarySuspense>
          <NewsListPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.project.card,
      component: (
        <ErrorBoundarySuspense>
          <ProjectCard />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee || isOperator,
    },
    {
      path: URL.recruitment.ongoing,
      component: (
        <ErrorBoundarySuspense>
          <OngoingRecruitments />
        </ErrorBoundarySuspense>
      ),
      access: isApplicant || isOperator,
    },
    {
      path: URL.application.myApplications,
      component: (
        <ErrorBoundarySuspense>
          <MyApplications />
        </ErrorBoundarySuspense>
      ),
      access: isApplicant,
    },
    {
      path: URL.contactForm,
      component: (
        <ErrorBoundarySuspense>
          <ContactForm />
        </ErrorBoundarySuspense>
      ),
      access: true,
    },
    {
      path: URL.expertApplicationFormData,
      component: (
        <ErrorBoundarySuspense>
          <ExpertApplicationFormData />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.applicationForm.add,
      component: (
        <ErrorBoundarySuspense>
          <ExpertApplicationForm createMode />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.applicationForm.edit,
      component: (
        <ErrorBoundarySuspense>
          <ExpertApplicationForm />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.applicationForm.read,
      component: (
        <ErrorBoundarySuspense>
          <ExpertApplicationForm isReadonly />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.applicationForm.list,
      component: (
        <ErrorBoundarySuspense>
          <ApplicationForms />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.expertContract,
      component: (
        <ErrorBoundarySuspense>
          <ExpertContract />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.expert.transferDetails,
      component: (
        <ErrorBoundarySuspense>
          <TransferDetails />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.listOfOrderAcceptanceProtocols,
      component: (
        <ErrorBoundarySuspense>
          <OrderAcceptanceProtocols isReadonly isCurrentLoggedUserExpert />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.expert.substantiveEvaluation,
      component: (
        <ErrorBoundarySuspense>
          <SubstantiveEvaluation />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.expert.expertPanels,
      component: (
        <ErrorBoundarySuspense>
          <Panels />
        </ErrorBoundarySuspense>
      ),
      access: isExpert || isEmployee,
    },
    {
      path: URL.expert.opinionsProtests,
      component: (
        <ErrorBoundarySuspense>
          <Opinions />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.users.account.settings,
      component: (
        <ErrorBoundarySuspense>
          <AccountSettingsPage />
        </ErrorBoundarySuspense>
      ),
      access: true,
    },
    {
      path: URL.users.account.notifications,
      component: (
        <ErrorBoundarySuspense>
          <AccountSettingsPage />
        </ErrorBoundarySuspense>
      ),
      access: true,
    },
    {
      path: URL.applications.list,
      component: (
        <ErrorBoundarySuspense>
          <AllApplications />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.recruitment.submittedApplications,
      component: (
        <ErrorBoundarySuspense>
          <SubmittedApplications />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee || isManagementInstitution,
    },
    {
      path: URL.recruitment.submittedApplicationsByRecruitmentId,
      component: (
        <ErrorBoundarySuspense>
          <SubmittedApplications />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee || isManagementInstitution,
    },
    {
      path: URL.applications.submittedApplicationsWithOperator,
      component: (
        <ErrorBoundarySuspense>
          <SubmittedApplicationsWithOperator />
        </ErrorBoundarySuspense>
      ),
      access: isOperator,
    },
    {
      path: URL.applications.submittedApplicationsWithOperator,
      component: (
        <ErrorBoundarySuspense>
          <SubmittedApplicationsWithOperator />
        </ErrorBoundarySuspense>
      ),
      access: isManagementInstitution,
    },
    {
      path: URL.application.edit,
      component: (
        <ErrorBoundarySuspense>
          <ApplicationEdit />
        </ErrorBoundarySuspense>
      ),
      access: isApplicant || isEmployee,
    },
    {
      path: URL.recruitment.applicationStatusManagement,
      component: (
        <ErrorBoundarySuspense>
          <ApplicationStatusManagement />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.userIssues,
      component: (
        <ErrorBoundarySuspense>
          <UserIssues />
        </ErrorBoundarySuspense>
      ),
      access: true,
    },
    {
      path: URL.systemMessages,
      component: (
        <ErrorBoundarySuspense>
          <SystemMessages />
        </ErrorBoundarySuspense>
      ),
      access: hasRoleAdmin,
    },
    {
      path: URL.systemMessagesByProfile,
      component: (
        <ErrorBoundarySuspense>
          <SystemMessagesByProfile />
        </ErrorBoundarySuspense>
      ),
      access: true,
    },
    {
      path: URL.usersList,
      component: (
        <ErrorBoundarySuspense>
          <UsersListPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.applications.withdrawal,
      component: (
        <ErrorBoundarySuspense>
          <ApplicationWithdrawal />
        </ErrorBoundarySuspense>
      ),
      access: isApplicant,
    },
    {
      path: URL.application.withdrawalDecisionView,
      component: (
        <ErrorBoundarySuspense>
          <ApplicationWithdrawalDecision />
        </ErrorBoundarySuspense>
      ),
      access: isApplicant,
    },
    {
      path: URL.application.correction,
      component: (
        <ErrorBoundarySuspense>
          <ApplicationCorrection />
        </ErrorBoundarySuspense>
      ),
      access: isApplicant,
    },

    {
      path: URL.users.details,
      component: (
        <ErrorBoundarySuspense>
          <UserDetails />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.users.permissions,
      component: (
        <ErrorBoundarySuspense>
          <UserPermissions />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.excludedEntitiesList,
      component: (
        <ErrorBoundarySuspense>
          <RedirectToExcludedEntitiesRegistry page="excluded_entities_list" />
        </ErrorBoundarySuspense>
      ),
      access: true,
    },
    {
      path: URL.redFlagsList,
      component: (
        <ErrorBoundarySuspense>
          <RedirectToExcludedEntitiesRegistry page="red_flags_list" />
        </ErrorBoundarySuspense>
      ),
      access: true,
    },
    {
      path: URL.application.preview,
      component: (
        <ErrorBoundarySuspense>
          <ApplicationEdit readOnly />
        </ErrorBoundarySuspense>
      ),
      access: isApplicant || isEmployee || isExpert || isOperator || isManagementInstitution,
    },
    {
      path: URL.lsiDictionaries,
      component: (
        <ErrorBoundarySuspense>
          <LsiDictionariesPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.organizationAddress,
      component: (
        <ErrorBoundarySuspense>
          <OrganizationAddressPage />
        </ErrorBoundarySuspense>
      ),
      access: isApplicant,
    },
    {
      path: URL.helpCenter,
      component: (
        <ErrorBoundarySuspense>
          <HelpCenterPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.exchangeDataWithCst,
      component: (
        <ErrorBoundarySuspense>
          <ExchangeDataWithCst />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.recruitment.projectAppraisalCommission,
      component: (
        <ErrorBoundarySuspense>
          <ProjectAppraisalCommissionPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.recruitment.expertPanelsList,
      component: (
        <ErrorBoundarySuspense>
          <ExpertPanelsPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.recruitment.addExpertPanel,
      component: (
        <ErrorBoundarySuspense>
          <AddExpertPanelPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.recruitment.editExpertPanel,
      component: (
        <ErrorBoundarySuspense>
          <EditExpertPanelPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.recruitment.expertPanelApplicationsList,
      component: (
        <ErrorBoundarySuspense>
          <ApplicationsInExpertPanel />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.applicationPrints,
      component: (
        <ErrorBoundarySuspense>
          <ApplicationPrints />
        </ErrorBoundarySuspense>
      ),
      access: false,
    },
    {
      path: URL.userGuide,
      component: (
        <ErrorBoundarySuspense>
          <UserGuide />
        </ErrorBoundarySuspense>
      ),
      access: true,
    },
    {
      path: URL.cstDictionaries,
      component: (
        <ErrorBoundarySuspense>
          <CstDictionariesPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.reports.main,
      component: (
        <ErrorBoundarySuspense>
          <Reports />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.reports.reportDetail,
      component: (
        <ErrorBoundarySuspense>
          <ReportDetail />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.informationClause.list,
      component: (
        <ErrorBoundarySuspense>
          <InformationClausePage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.informationClause.add,
      component: (
        <ErrorBoundarySuspense>
          <AddInformationClausePage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.informationClause.edit,
      component: (
        <ErrorBoundarySuspense>
          <EditInformationClausePage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee && hasRoleAdmin,
    },
    {
      path: URL.expert.kopStatements,
      component: (
        <ErrorBoundarySuspense>
          <KopStatementsPage />
        </ErrorBoundarySuspense>
      ),
      access: isExpert || isEmployee,
    },
    {
      path: URL.expert.expertPanelsAssessmentSheet,
      component: (
        <ErrorBoundarySuspense>
          <ExpertAssessmentSheet />
        </ErrorBoundarySuspense>
      ),
      access: isExpert || isEmployee,
    },
    {
      path: URL.expert.expertPanelsPreliminaryRemark,
      component: (
        <ErrorBoundarySuspense>
          <ExpertPreliminaryRemarksPage />
        </ErrorBoundarySuspense>
      ),
      access: isExpert || isEmployee || isApplicant,
    },
    {
      path: URL.application.supplementCall,
      component: (
        <ErrorBoundarySuspense>
          <SupplementCallPage />
        </ErrorBoundarySuspense>
      ),
      access: isApplicant || isExpert || isEmployee,
    },
    {
      path: URL.reviews,
      component: (
        <ErrorBoundarySuspense>
          <ReviewsPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee || isExpert,
    },
    {
      path: URL.reviewsSheet,
      component: (
        <ErrorBoundarySuspense>
          <ReviewSheetPage />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.workEvaluation,
      component: (
        <ErrorBoundarySuspense>
          <WorkEvaluationPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee || isExpert,
    },
    {
      path: URL.expert.workEvaluation,
      component: (
        <ErrorBoundarySuspense>
          <WorkEvaluationList />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.expert.taxReturn,
      component: (
        <ErrorBoundarySuspense>
          <ExpertTaxReturnPage />
        </ErrorBoundarySuspense>
      ),
      access: isExpert,
    },
    {
      path: URL.recruitment.shareEvaluationSheet,
      component: (
        <ErrorBoundarySuspense>
          <ShareEvaluationSheetPage />
        </ErrorBoundarySuspense>
      ),
      access: hasRoleAdmin || hasRoleSecretary,
    },
    {
      path: URL.application.projectCard.protests.addProtest,
      component: (
        <ErrorBoundarySuspense>
          <AddProtestPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee || isOperator,
    },
    {
      path: URL.application.projectCard.protests.editProtest,
      component: (
        <ErrorBoundarySuspense>
          <EditProtestPage />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee || isOperator,
    },
    {
      path: URL.applicant.evaluationSheetsList,
      component: (
        <ErrorBoundarySuspense>
          <EvaluationSheetsList />
        </ErrorBoundarySuspense>
      ),
      access: isApplicant,
    },
    {
      path: URL.testRoutes.editApplicationTemplate,
      component: (
        <ErrorBoundarySuspense>
          <EditApplicationTemplate />
        </ErrorBoundarySuspense>
      ),
      access: isEmployee,
    },
    {
      path: URL.expert.evaluationSheet,
      component: (
        <ErrorBoundarySuspense>
          <ExpertEvaluationSheet />
        </ErrorBoundarySuspense>
      ),
      access: isExpert || isEmployee,
    },
  ];

  /**
   * Not-authenticated routes, available only for not logged clients.
   * Notice that route suffixed with `/*` most probably contains sub-routes in given component.
   */
  const notAuthenticatedRoutes = [
    {
      path: '/auth/*',
      component: <Login />,
    },
    {
      path: `${URL.users.passwordChange}:recoveryCode`,
      component: (
        <ErrorBoundarySuspense>
          <PasswordChange />
        </ErrorBoundarySuspense>
      ),
    },
    {
      path: `${URL.users.account.activation}/*`,
      component: (
        <ErrorBoundarySuspense>
          <Routes>
            <Route path=":activationCode" element={<AccountActivation />} />
            <Route path="" element={<AccountActivation />} />
          </Routes>
        </ErrorBoundarySuspense>
      ),
    },
    {
      path: URL.users.sessionEnded,
      component: (
        <ErrorBoundarySuspense>
          <SessionEndedPage />
        </ErrorBoundarySuspense>
      ),
      access: true,
    },
  ];

  /**
   * Fast way to generate <Route> element with expected path & element.
   *
   * @param {string} path - route path
   * @param {Element} component - route component element
   * @param {Array} breadcrumbs - breadcrumb list
   */
  const makeRouteComponent = useCallback((path, component, breadcrumbs) => (
    <Route
      key={`page-${path}`}
      path={path}
      element={(
        <ContentWrapper breadcrumbList={breadcrumbs}>
          {component}
        </ContentWrapper>
      )}
    />
  ), [profile, authenticated]);

  /**
   * Authenticated and not-authenticated routes combined.
   * It will be better if you do not modify this array.
   * Treat it as an IMMUTABLE thing.
   */
  const combinedRoutes = [
    ...authenticatedRoutes
      .map(({
        path, access, component,
      }) => {
        if (!authenticated) {
          if (!sessionEnded) {
            return makeRouteComponent(path, <GoBackNavigate to="/auth" />, getBreadcrumbsByUrlAndRole(path, profile));
          }

          return makeRouteComponent(
            path,
            <GoBackNavigate to={URL.users.sessionEnded} />,
            getBreadcrumbsByUrlAndRole(path, profile)
          );
        }

        return makeRouteComponent(
          path,
          access ? component : <Navigate to="/home" />,
          getBreadcrumbsByUrlAndRole(path, profile)
        );
      }),
    ...notAuthenticatedRoutes.map(({
      path, component, breadcrumbs,
    }) => (
      makeRouteComponent(path, authenticated ? <GoBackNavigate to="/home" /> : component, breadcrumbs)
    )),
  ];

  return (
    <BrowserRouter>
      <GlobalDialogProvider>
        <Routes>
          {[
            <Route
              key="wildcard"
              path="*"
              element={authenticated ? <Navigate replace to="/home" /> : <Navigate replace to="/auth" />}
            />,
            ...combinedRoutes,
          ]}
        </Routes>
        {children}
      </GlobalDialogProvider>
    </BrowserRouter>
  );
}

AuthBrowserRouter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
    PropTypes.instanceOf(null),
  ]),
};

AuthBrowserRouter.defaultProps = {
  children: null,
};

ErrorFallback.propTypes = {
  error: PropTypes.string,
};

ErrorFallback.defaultProps = {
  error: 'Default error message',
};

ErrorBoundarySuspense.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.instanceOf(null),
  ]),
};

ErrorBoundarySuspense.defaultProps = {
  children: null,
};
