import { Button } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useEvaluationSheetsUserSessionContext } from '../../../../../Context';
import { ConfirmDialog } from '../../../../../Dialogs';
import { usePageLock } from '../../../../../_hooks';

/**
 * Evaluation sheet close button.
 *
 * @returns {EvaluationSheetCloseButton}
 */
export function EvaluationSheetCloseButton() {
  const navigate = useNavigate();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const confirmMessage = 'Formularz zawiera niezapisane dane. Czy chcesz zamknąć formularz i utracić dane?';
  const { handleCloseUserSession } = useEvaluationSheetsUserSessionContext();
  const { formState: {
    isSubmitting, isDirty,
  } } = useFormContext();

  usePageLock(isDirty);

  return (
    <>
      <Button
        type="button"
        color="primary"
        variant="outlined"
        id="d8cWr3kJecbbGi4"
        onClick={() => (isDirty ? setConfirmDialogOpen(true) : navigate(-1))}
        disabled={isSubmitting}
      >
        Zamknij
      </Button>
      <ConfirmDialog
        closeHandler={() => setConfirmDialogOpen(false)}
        open={confirmDialogOpen}
        confirmButtonHandler={handleCloseUserSession}
        confirmButtonLabel="OK"
        cancelButtonColor="primary"
      >
        {confirmMessage}
      </ConfirmDialog>
    </>
  );
}
