import { View } from '@react-pdf/renderer';
import { printStyle } from '../../print.style';
import RowField from '../../Components/RowField/RowField';
import {
  DICTIONARIES,
  BOOLEAN_VALUES,
} from '../../../../_constants';
import { mapPkdCodes } from '../../../../_helpers';
import { useApplicationToPdfContext } from '../../../../Context';
import { FieldSorter } from '../../../ApplicationTemplate/Element/FieldSorter';

/**
 * Project classification pdf element renderer.
 *
 * @returns {ProjectClassificationPdf}
 */
function ProjectClassificationPdf() {
  const {
    managedApplicationTemplate: {
      initialFormData,
    }, getDictionary,
  } = useApplicationToPdfContext();

  return (
    <View style={printStyle.table}>
      <FieldSorter>
        {/* Zakres interwencji */}
        <RowField
          name="project_classification_intervention_scope"
          externalDictionary={getDictionary(DICTIONARIES.interventionScopes.name).map((interventionScope) => ({
            '@id': interventionScope['@id'],
            name: `${interventionScope.cstCode} - ${interventionScope.name}`,
          }))}
        />
        {/* Rodzaj działalności gospodarczej */}
        <RowField
          name="project_classification_activity_type"
          dictionaryName={DICTIONARIES.activityTypes.name}
        />
        {/* Kod i nazwa PKD działalności, której dotyczy projekt */}
        <RowField name="project_classification_pkd" externalDictionary={mapPkdCodes(getDictionary)} />
        {/* Opis rodzaju działalności */}
        <RowField name="project_classification_activity_type_description" isSingleRow />
        {/* Przedmiot projektu wpisuje się w zakres Regionalnych Inteligentnych Specjalizacji (RIS) wspólnych dla  */}
        <RowField name="project_classification_project_subject_falls_within_ris_scope" />
        {initialFormData?.project_classification_project_subject_falls_within_ris_scope === BOOLEAN_VALUES.TRUE && (
          <>
            {/* Obszar RIS (wspólny dla co najmniej dwóch województw Polski Wschodniej),
            w który wpisuje się projekt. */}
            <RowField
              name="project_classification_ris_area"
              dictionaryName={DICTIONARIES.risAreas.name}
            />
            {/* Uzasadnienie wybranego obszaru RIS, w który wpisuje się projekt */}
            <RowField name="project_classification_justification_selected_ris_area" />
          </>
        )}
        <RowField
          name="project_classification_kis_domain_area"
          dictionaryName={DICTIONARIES.kisDomainAreas.name}
        />
        <RowField name="project_classification_kis_domain" />
        <RowField name="project_classification_kis_explanation" />
        <RowField name="project_classification_project_is_included_in_regional_transportation_plan" />
        <RowField name="project_classification_project_is_consistent_with_urban_mobility_plans" />
        <RowField name="project_classification_project_is_included_in_territorial_instrument" />
        <RowField
          name="project_classification_sustainable_urban_mobility_plan_compatibility"
          dictionaryName={DICTIONARIES.sumpCompatibilities.name}
        />
        <RowField name="project_classification_urban_mobility_planning_documents_compatibility" isSingleRow />
        <RowField name="project_classification_relevant_air_protection_program_compliance" isSingleRow />
        <RowField name="project_classification_partner_project" isSingleRow />
        <RowField name="project_classification_project_concerns_public_aid" isSingleRow />
        <RowField
          name="project_classification_project_consistency_with_eastern_poland_voivodeships_development_strategies"
          isSingleRow
        />
      </FieldSorter>
    </View>
  );
}

export default ProjectClassificationPdf;
