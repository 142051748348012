import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  Box,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useCustomSnackbar } from '../../../../../_hooks';
import {
  useGlobalDialog,
  useReloadListener,
} from '../../../../../Context';
import { request } from '../../../../../_services';
import { API_ROUTE } from '../../../../../_constants';
import CharsCounter from '../../../../CharsCounter';
import { Alert } from '../../../../Alert';
import { formatDateString } from '../../../../../_helpers';
import { SaveCancelButtons } from '../../../../SaveCancelButtons';

/**
 * EvaluationSheetRejectDialog element.
 *
 * @param {object} props - root props
 * @param {object} props.requestPayload - request payload
 * @param {string} props.expertByUsersId - expert by users id
 * @param {boolean} props.isReadonly - is read-only
 * @returns {EvaluationSheetRejectDialog}
 */
function EvaluationSheetRejectDialog({
  requestPayload, expertByUsersId, isReadonly,
}) {
  const {
    successNotification, errorNotification,
  } = useCustomSnackbar();
  const { closeAll } = useGlobalDialog();
  const {
    control, handleSubmit, formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      refusalRemarks: '',
      refused: true,
    },
    mode: 'all',
  });
  const {
    reload,
  } = useReloadListener();
  const watcherName = 'evaluation-sheet';

  const onSubmit = async (values) => {
    const {
      statusSuccess,
    } = await request.put(`${API_ROUTE.expertPanelExpertByUsers}/${expertByUsersId}`, {
      ...requestPayload,
      ...values,
    });

    closeAll();
    reload(watcherName);

    statusSuccess
      ? successNotification('Wniosek został odrzucony pomyślnie.')
      : errorNotification('Nie udało się odrzucić wniosku. Spróbuj ponownie.');
  };

  return (
    <>
      <InputLabel htmlFor="refusalRemarks">Powód odrzucenia wniosku</InputLabel>
      <FormControl variant="outlined" fullWidth margin="normal">
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Proszę uzupełnić pole "Powów odrzucenia wniosku"',
            },
          }}
          name="refusalRemarks"
          control={control}
          render={({
            field: {
              onChange, value, name,
              onBlur,
            },
            fieldState: {
              error,
            },
          }) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              id={name}
              name={name}
              onChange={onChange}
              defaultValue={value}
              onBlur={onBlur}
              InputProps={{
                endAdornment: (<CharsCounter valueLength={value?.length} maxLength={1000} />),
              }}
              inputProps={{
                maxLength: 1000,
              }}
              multiline
            />
          )}
        />
      </FormControl>

      <InputLabel htmlFor="rejectionDate">Data odrzucenia</InputLabel>
      <Alert severity="status" sx={{ mt: (theme) => theme.spacing(1) }}>
        {formatDateString(new Date())}
      </Alert>
      <Box mt={3} display="flex" justifyContent="center">
        <SaveCancelButtons
          saveButtonId="8o0P869CrUjM8p3"
          cancelButtonId="44VpvzlE1PLgTrY"
          cancelHandler={closeAll}
          saveHandler={handleSubmit(onSubmit)}
          saveTitle="Odrzucam wniosek"
          saveDisabled={isSubmitting || isReadonly}
          cancelDisabled={isSubmitting || isReadonly}
        />
      </Box>
    </>
  );
}

export default EvaluationSheetRejectDialog;

EvaluationSheetRejectDialog.propTypes = {
  requestPayload: PropTypes.objectOf(Object).isRequired,
  expertByUsersId: PropTypes.string.isRequired,
  isReadonly: PropTypes.bool.isRequired,
};
