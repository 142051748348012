import { Packer } from 'docx';
import {
  FIELD_TYPES,
  PRINT_TO_DOCX_TEMPLATE_BY_TYPES,
} from '../../_constants';

/**
 * Calculate column size
 *
 * @param {number} columns - number of columns
 * @returns {number}
 */
const calculateColumnSize = (columns) => {
  if (!columns) {
    return 14010;
  }

  return Math.floor(Number(14010 / columns) * 100) / 100;
};

const fieldsConfig = (subfields) => Object.keys(subfields).reduce((prev, key) => ({
  ...prev,
  [subfields[key].name]: subfields[key],
}), {});

/**
 * Returns fields if defined in config
 *
 * @param {string} fieldName - field name
 * @param {object} fieldsObject - fields object
 * @param {Array} fields - fields to render
 * @returns {Array}
 */
const fieldConfig = (fieldName, fieldsObject, fields) => (fieldsObject?.[fieldName] ? fields : []);

/**
 * Map elements fields
 *
 * @param {Array} elementFields - element fields
 * @param {string} elementId - element id
 * @param {object} elementDataByKeys - element data by keys
 * @returns {Array}
 */
const map = (elementFields, elementId, elementDataByKeys) => elementFields.map(({
  uuid, label, title, type, fields, name,
}) => {
  const initialData = ({
    id: uuid,
    label: label || title,
    type,
    name,
    elementId,
    elementData: elementDataByKeys?.[`${name}-${elementId}`] || null,
  });

  if (type === FIELD_TYPES.collection) {
    return ({
      ...initialData,
      fields: map(fields, elementId),
    });
  }

  return initialData;
});

/**
 * Printout docx method
 *
 * @param {object} managedApplicationData - managed application data
 * @param {string} templateType - template type ( PRINT_TO_DOCX_TEMPLATE_TYPES )
 * @param {string} filenameToSet - filename to set
 * @returns {Promise<void>}
 */
const onPrintout = async (managedApplicationData, templateType, filenameToSet) => {
  const blob = await Packer.toBlob(PRINT_TO_DOCX_TEMPLATE_BY_TYPES[templateType](managedApplicationData));

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', filenameToSet);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const printoutToDocxHelper = {
  calculateColumnSize,
  fieldConfig,
  fieldsConfig,
  map,
  onPrintout,
};
