import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import CharsCounter from '../../CharsCounter';
import { FormField } from '../../Application/Application/FormField';
import { useElementContext } from '../../../Context';
import WorksType from './EssenceModuleImplementInnovations/WorksType';
import { FieldSorter } from './FieldSorter';
import InnovationImplementation from './EssenceModuleImplementInnovations/InnovationImplementation';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import RdWorkImplementers from './EssenceModuleImplementInnovations/RdWorkImplementers';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import {
  BOOLEAN_VALUES,
  FIELD_TYPES_MAP,
  DICTIONARIES,
} from '../../../_constants';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';

/**
 * Essence module implement innovations component
 *
 * @returns {InnovationImplementationModuleEssence}
 */
function InnovationImplementationModuleEssence() {
  const {
    watch, reset, resetField,
  } = useFormContext();
  const {
    isReadonly, fieldsConfig,
  } = useElementContext();

  const { get } = useDictionaryLoader(DICTIONARIES.innovationTypes);

  const resultsInRdModuleValue = watch('innovation_implementation_module_essence_results_in_rd_module');
  const isResultsInRdModule = resultsInRdModuleValue === BOOLEAN_VALUES.FALSE;
  const innovationsTypeValue = watch('innovation_implementation_module_essence_innovation_type');

  const innovationImplementationModuleEssenceRdResultNoveltyConfig = fieldsConfig
    .innovation_implementation_module_essence_rd_result_novelty;
  const rdWorkImplementersConfig = fieldsConfig
    ?.innovation_implementation_module_essence_rd_work_implementers;
  const workTypesConfig = fieldsConfig.innovation_implementation_module_essence_work_types;

  const fieldsNames = [
    'innovation_implementation_module_essence_work_types',
    'innovation_implementation_module_essence_rd_result_novelty',
    'innovation_implementation_module_essence_applicant_offer',
    'innovation_implementation_module_essence_competitors_offer',
    'innovation_implementation_module_essence_destination_market',
    'innovation_implementation_module_essence_innovation_implementation_plan',
    'innovation_implementation_module_essence_influence_on_production_cycle',
    'innovation_implementation_module_essence_innovation_impact',
    'innovation_implementation_module_essence_implementation_benefits',
    'innovation_implementation_module_essence_process_implementation_plan',
    'innovation_implementation_module_essence_innovation_type',
  ];

  const defaulFieldsConfig = useMemo(() => {
    const config = {};

    fieldsNames.forEach((fieldName) => {
      if (fieldsConfig[fieldName]) {
        config[fieldsConfig[fieldName].name] = FIELD_TYPES_MAP[fieldsConfig[fieldName].type];
      }
    });

    return config;
  }, [fieldsConfig]);

  return (
    <FieldSorter>
      {/* Cel modułu */}
      <FormField name="innovation_implementation_module_essence_purpose">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Prace będące wynikiem modułu B+R */}
      <FormField name="innovation_implementation_module_essence_results_in_rd_module">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(event) => {
              onChange(event);
              if (event.target.value !== BOOLEAN_VALUES.FALSE) {
                reset((formValues) => ({
                  ...formValues,
                  ...defaulFieldsConfig,
                }));
              }
            }}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>
      {isResultsInRdModule && (
        <>
          {/* Tabela Rodzaj prac */}
          <LabeledFieldRow fieldConfig={workTypesConfig} contextHelpLabel fullWidth>
            <WorksType />
          </LabeledFieldRow>
          {/* Tabela Nowość rezultatów prac B+R  */}
          {innovationsTypeValue?.length > 0 && (
            <LabeledFieldRow
              fieldConfig={innovationImplementationModuleEssenceRdResultNoveltyConfig}
              contextHelpLabel
              fullWidth
            >
              <InnovationImplementation />
            </LabeledFieldRow>
          )}
          {/* Konkurencyjność */}
          <FormField name="innovation_implementation_module_essence_competence" contextHelpLabel>
            {() => null}
          </FormField>
          {/* Innowacyjność produktowa */}
          <FormField name="innovation_implementation_module_essence_product_innovation" contextHelpLabel>
            {() => null}
          </FormField>
          {/* Oferta wnioskodawcy */}
          <FormField name="innovation_implementation_module_essence_applicant_offer">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Oferta konkurencji */}
          <FormField name="innovation_implementation_module_essence_competitors_offer">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Rynek docelowy dla innowacji produktowej */}
          <FormField name="innovation_implementation_module_essence_destination_market">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Plan wprowadzenia rezultatu modułu na rynek – innowacja produktowa */}
          <FormField name="innovation_implementation_module_essence_innovation_implementation_plan">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Innowacje w procesie biznesowym */}
          <FormField name="innovation_implementation_module_essence_process_innovation" contextHelpLabel>
            {() => null}
          </FormField>
          {/* Wpływ innowacji w procesie biznesowym na cykl produkcyjny */}
          <FormField name="innovation_implementation_module_essence_influence_on_production_cycle">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Analiza opłacalności wdrożenia */}
          <FormField name="innovation_implementation_module_essence_innovation_impact">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Korzyści z wprowadzenia rezultatu modułu - innowacji w procesie biznesowym */}
          <FormField name="innovation_implementation_module_essence_implementation_benefits">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Plan wdrożenia rezultatu modułu - innowacji w procesie biznesowym */}
          <FormField name="innovation_implementation_module_essence_process_implementation_plan">
            {({
              onChange, value, name, maxLength, onBlur, error,
            }) => (
              <TextField
                multiline
                id={name}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                defaultValue={value}
                variant="outlined"
                InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                inputProps={{ maxLength }}
                disabled={isReadonly}
                error={!!error}
                helperText={error?.message}
              />
            )}
          </FormField>
          {/* Innowacyjność przedmiotu wdrożenia */}
          <FormField name="innovation_implementation_module_essence_innovation_subject" contextHelpLabel>
            {() => null}
          </FormField>
          {/* Rodzaj innowacji */}
          <FormField name="innovation_implementation_module_essence_innovation_type">
            {({
              onChange, value, name, onBlur, error,
            }) => (
              <CustomAutocomplete
                id={name}
                initialValue={value}
                onChange={(event) => {
                  onChange(event);
                  if (event.target.value.length <= 0) {
                    resetField(
                      'innovation_implementation_module_essence_rd_result_novelty',
                      { defaultValue: FIELD_TYPES_MAP.collection },
                    );
                  }
                }}
                onBlur={onBlur}
                options={get(DICTIONARIES.innovationTypes.name)}
                disabled={isReadonly}
                multiple
                renderSelectAllButton={false}
                error={error}
              />
            )}
          </FormField>
          {/* Zapotrzebowanie rynkowe i opłacalność wdrożeni */}
          <FormField name="innovation_implementation_module_essence_market_needs" contextHelpLabel>
            {() => null}
          </FormField>
        </>
      )}
      <LabeledFieldRow
      /* Tabela Wykonawcy/sprzedawcy prac badawczo-rozwojowych zleconych / zakupionych przez wnioskodawcę */
        fieldConfig={rdWorkImplementersConfig}
        contextHelpLabel
        fullWidth
      >
        <RdWorkImplementers />
      </LabeledFieldRow>
      {/* Przedmiot ochrony i planowana ochrona praw własności intelektualnej */}
      <FormField name="innovation_implementation_module_essence_intellectual_property_right_protection_subject">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Opis sposobu uregulowania praw do wyników prac B+R, w tym wskazanie właściciela */}
      <FormField name="innovation_implementation_module_essence_regulating_rights_method_description">
        {({
          onChange, value, name, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Sposób uregulowania praw własności intelektualnej */}
      <FormField
        name="innovation_implementation_module_essence_intellectual_property_rights_regulation_method"
        contextHelpLabel
      >
        {() => null}
      </FormField>
      {/* Prawa własności intelektualnej */}
      <FormField name="innovation_implementation_module_essence_intellectual_property_rights" contextHelpLabel>
        {() => null}
      </FormField>
    </FieldSorter>
  );
}

export default InnovationImplementationModuleEssence;
