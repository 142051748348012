import {
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { useEffect } from 'react';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { FormField } from '../../Application/Application/FormField';
import { LabeledFieldRow } from '../../Application/Application/LabeledFieldRow';
import { useElementContext } from '../../../Context';
import CharsCounter from '../../CharsCounter';
import { useDictionaryLoader } from '../../DictionaryProvider/useDictionaryLoader';
import {
  BOOLEAN_VALUES,
  DICTIONARIES,
  ELEMENTS_DICTIONARIES_MAP,
  FIELD_TYPES_MAP,
} from '../../../_constants';
import { FieldSorter } from './FieldSorter';
import CollectionFieldContainer from '../../Application/Application/CollectionFieldContainer';
import { CustomAutocomplete } from '../../CustomAutocomplete/CustomAutocomplete';
import {
  getImplementers,
  getCollectionFieldObject,
  addItem,
  removeItem,
} from '../../../_helpers';
import { MonetaryField } from '../../MonetaryField';

/**
 * RegionalInvestmentAidInnovationImplementation application element.
 *
 * @returns {RegionalInvestmentAidInnovationImplementation}
 */
export function RegionalInvestmentAidInnovationImplementation() {
  const {
    fieldsConfig,
    isReadonly,
  } = useElementContext();
  const {
    control, watch, getValues, resetField, setValue, reset,
  } = useFormContext();

  const collectionFieldName = 'regional_investment_aid_innovation_implementation_investment_aid';
  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: collectionFieldName,
  });
  const {
    get, isLoaded,
  } = useDictionaryLoader(
    DICTIONARIES.initialInvestmentTypes,
  );

  const { initialInvestmentTypes } = ELEMENTS_DICTIONARIES_MAP;

  const isRegionalValue = watch('regional_investment_aid_innovation_implementation_is_regional');

  const onChangeRegional = (field, defaultOnChange) => {
    field?.id === 'true'
      ? append(fieldObject)
      : resetField(collectionFieldName, { defaultValue: FIELD_TYPES_MAP.collection });

    return defaultOnChange(field);
  };

  const investmentAidConfig = fieldsConfig[collectionFieldName];
  const subfields = investmentAidConfig?.fields || {};
  const fieldObject = getCollectionFieldObject(subfields, fields);

  const {
    maxRowNumber,
    minRowNumber,
  } = investmentAidConfig || {};

  const additionalFieldsByIds = [
    initialInvestmentTypes.investmentInTangibleFixedAssets.id,
    initialInvestmentTypes.investmentInTangibleFixedAssetsAndValues.id,
  ];

  useEffect(() => {
    if (fields.length === 0 && minRowNumber > 0 && isRegionalValue === BOOLEAN_VALUES.TRUE) {
      for (let i = 1; i <= minRowNumber; i++) {
        append(getCollectionFieldObject(subfields, fields, i));
      }
    }
  }, [maxRowNumber, minRowNumber, isRegionalValue]);

  return (
    <FieldSorter>
      {/* Czy Wnioskodawca ubiega się o regionalną pomoc inwestycyjną (art. 14 rozporządzenia Komisji (UE) nr 651 */}
      <FormField name="regional_investment_aid_innovation_implementation_is_regional">
        {({
          onChange, value, name, allChoices, onBlur, error,
        }) => (
          <CustomAutocomplete
            id={name}
            initialValue={value}
            onChange={(field) => onChangeRegional(field, onChange)}
            onBlur={onBlur}
            options={allChoices}
            disabled={isReadonly}
            error={error}
          />
        )}
      </FormField>

      {/* Tabela: Rodzaj inwestycji początkowej */}
      <LabeledFieldRow
        hide={isRegionalValue === BOOLEAN_VALUES.FALSE}
        fieldConfig={investmentAidConfig}
        contextHelpLabel
        fullWidth
      >
        {fields.map((item, index) => {
          const investmentTypeValue = watch(`${collectionFieldName}.${index}.investment_type`);

          return (
            <CollectionFieldContainer
              key={item.id}
              isReadonly={isReadonly}
              itemIndex={index}
              onRemove={() => removeItem(
                fields,
                minRowNumber,
                remove,
                setValue,
                collectionFieldName,
                index,
              )}
              fieldsOrder={Object.keys(subfields)}
              isIndexed
              buttonRemoveVisible={fields.length > minRowNumber}
            >
              {/* Nazwa wnioskodawcy/ konsorcjanta */}
              <FormField name={`${collectionFieldName}.${index}.name`}>
                {({
                  name, onChange, value, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={getImplementers(getValues)}
                    disabled={isReadonly}
                    error={error}
                  />
                )}
              </FormField>
              {/* Rodzaj inwestycji początkowej */}
              <FormField name={`${collectionFieldName}.${index}.investment_type`}>
                {({
                  onChange, value, name, filterOptions, onBlur, error,
                }) => (
                  <CustomAutocomplete
                    id={name}
                    initialValue={value}
                    onChange={(event) => {
                      onChange(event);
                      if (event.target.value !== initialInvestmentTypes.investmentInTangibleFixedAssets.id) {
                        reset((formValues) => ({
                          ...formValues,
                          [`${collectionFieldName}.${index}.reused_assets`]: FIELD_TYPES_MAP.text,
                          [`${collectionFieldName}.${index}.reused_assets_value`]: FIELD_TYPES_MAP.text,
                        }));
                      }
                    }}
                    onBlur={onBlur}
                    options={filterOptions(get(DICTIONARIES.initialInvestmentTypes.name))}
                    disabled={!isLoaded}
                    error={error}
                  />
                )}
              </FormField>
              {/* Uzasadnienie */}
              <FormField name={`${collectionFieldName}.${index}.reason`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    multiline
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={value}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>

              {additionalFieldsByIds.includes(investmentTypeValue) && (
                <>
                  {/* Ponownie wykorzystywane aktywa */}
                  <FormField name={`${collectionFieldName}.${index}.reused_assets`}>
                    {({
                      name, onChange, value, maxLength, onBlur, error,
                    }) => (
                      <TextField
                        defaultValue={value}
                        id={name}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        variant="outlined"
                        InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                        inputProps={{ maxLength }}
                        disabled={isReadonly}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  </FormField>
                  {/* Wartość księgowa ponownie wykorzystywanych aktywów, odnotowana w roku obrotowym poprzedzającym */}
                  <FormField name={`${collectionFieldName}.${index}.reused_assets_value`}>
                    {({
                      name, onChange, value, onBlur, error,
                    }) => (
                      <TextField
                        defaultValue={value}
                        type="number"
                        id={name}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        variant="outlined"
                        disabled={isReadonly}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  </FormField>
                </>
              )}
              {/* Aktywa podlegające modernizacji */}
              <FormField name={`${collectionFieldName}.${index}.modernization_assets`}>
                {({
                  name, onChange, value, maxLength, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
                    inputProps={{ maxLength }}
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
              {/* Wartość księgowa aktywów związanych z działalnością podlegającą modernizacji w ciągu trzech */}
              <FormField name={`${collectionFieldName}.${index}.modernization_assets_value`}>
                {({
                  name, onChange, value, onBlur, error,
                }) => (
                  <TextField
                    defaultValue={value}
                    type="number"
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    variant="outlined"
                    disabled={isReadonly}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              </FormField>
            </CollectionFieldContainer>
          );
        })}

        {!isReadonly && fields.length < maxRowNumber && (
          <Grid item xs={12}>
            <Button
              id="oUpJphYiGyw7vxp"
              variant="contained"
              color="secondary"
              onClick={() => addItem(
                fields,
                maxRowNumber,
                append,
                fieldObject,
              )}
            >
              Dodaj
            </Button>
          </Grid>
        )}
      </LabeledFieldRow>
      {/* Pomoc publiczna na podstawie art. 27 rozporządzenia Komisji (UE) nr 651/2014 */}
      <FormField name="regional_investment_aid_innovation_implementation_public_aid">
        {({
          name, onChange, value, maxLength, onBlur, error,
        }) => (
          <TextField
            multiline
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={value}
            variant="outlined"
            InputProps={{ endAdornment: <CharsCounter valueLength={value.length} maxLength={maxLength} /> }}
            inputProps={{ maxLength }}
            disabled={isReadonly}
            error={!!error}
            helperText={error?.message}
          />
        )}
      </FormField>
      {/* Pomoc na udział MŚP w targach w roku bieżącym [EUR] */}
      <FormField
        name="regional_investment_aid_innovation_implementation_aid_for_participation_in_trade_fairs_in_current_year"
      >
        {({
          name, onChange, value, onBlur, error,
        }) => (
          <TextField
            defaultValue={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            disabled={isReadonly}
            error={!!error}
            fullWidth
            InputProps={{
              inputComponent: MonetaryField,
            }}
            helperText={error?.message}
          />
        )}
      </FormField>
    </FieldSorter>
  );
}
