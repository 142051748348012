import {
  ButtonGroup,
  LinearProgress,
  MenuItem,
} from '@mui/material';
import uniqid from 'uniqid';
import {
  generatePath,
  useNavigate,
} from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { ButtonMenu } from '../../ButtonMenu';
import {
  EVALUATION_SHEET_STATUS,
  EVALUATION_SHEET_STATUS_TRANSLATIONS,
  EXPERT_PANEL_TYPES,
  URL,
  BOOLEAN_VALUES,
  PANEL_TYPES_TRANSLATIONS,
} from '../../../_constants';
import {
  formatDateString,
  iriToId,
} from '../../../_helpers';
import { GridFilterToolbar } from '../../DataGrid/GridFilterToolbar';
import PrintoutApplication from '../../PrintToPdf/PrintApplication/PrintoutApplication';
import ApplicationAcceptanceDialog from '../AssessmentSheet/ApplicationAcceptance.dialog';
import { ApplicationRejectionDialog } from '../AssessmentSheet/ApplicationRejection.dialog';
import PrintoutAssessmentSheet from '../../PrintToPdf/PrintoutAssessmentSheet/PrintoutAssessmentSheet';
import { useGlobalDialog } from '../../../Context';

/**
 * Data table of expert panel list.
 *
 * @param {object} props - root props
 * @param {Function} props.setFilterModel - set filter model
 * @param {Array} props.expertPanelList - expert panel list
 * @param {boolean} props.isLoaded - is loaded
 * @param {Function} props.reloadData - reload data
 * @returns {ExpertPanelList}
 */
export function ExpertPanelList({
  setFilterModel, expertPanelList, isLoaded, reloadData,
}) {
  const navigate = useNavigate();
  const { render } = useGlobalDialog();

  /**
   *  Button "Wstępne uwagi ekspertów" must be not visible
   * if application has current recruitment id
   *
   * @see https://redmine.parp.gov.pl/issues/299726
   */
  const notVisibleBtnOnRecruitmentId = '3abcc636-602e-40e1-8cec-48a605269767';

  const dataGridProps = {
    components: {
      Toolbar: GridFilterToolbar,
      LoadingOverlay: LinearProgress,
    },
    componentsProps: {
      toolbar: { setFilterModel },
      loadingOverlay: {
        color: 'secondary',
      },
    },
  };

  /**
   * Returns row actions.
   *
   * @param {object} props - root props
   * @param {object} props.row - single row api data
   * @returns {Element[]}
   */
  const getRowActions = ({ row }) => {
    const payload = {
      moduleId: null,
      moduleName: null,
      expertPanel: row.id,
      expert: row?.currentExpert?.externalExpert?.['@id'] || row?.currentExpert?.parpExpert?.['@id'],
      role: row?.currentExpert?.role,
      evaluationFee: row?.currentExpert?.evaluationFee,
    };

    return [
      <>
        {!row.refusedAssessmentSheet && (
          <ButtonGroup
            orientation="vertical"
            sx={{
              py: (theme) => theme.spacing(1),
              gap: (theme) => theme.spacing(1),
            }}
          >
            <PrintoutApplication isReadonly externalApplicationDataId={row.applicationId} />
            <ButtonMenu
              openButtonId={uniqid()}
              buttonTitle="Szczegóły"
              buttonProps={{
                color: 'secondary',
                fullWidth: true,
              }}
            >
              <PrintoutAssessmentSheet evaluationSheetId={row.evaluationSheetId} applicationId={row.applicationId} />
              <MenuItem
                key={uniqid()}
                variant="right-border"
                id={uniqid()}
                onClick={() => navigate(generatePath(URL.application.preview, { id: row.applicationId }))}
              >
                Podgląd wniosku
              </MenuItem>
              {(
                ((row.panelType === EXPERT_PANEL_TYPES.module || row.panelType === EXPERT_PANEL_TYPES.moduleFirstStage)
                  && row.evaluationSheetId)
                || (row.panelType === EXPERT_PANEL_TYPES.withExpert && row?.currentExpert?.accepted)
              ) && (
                <MenuItem
                  key={uniqid()}
                  variant="right-border"
                  id={uniqid()}
                  onClick={() => {
                    navigate(
                      row.panelType === EXPERT_PANEL_TYPES.moduleFirstStage
                        ? generatePath(
                          URL.expert.evaluationSheet,
                          {
                            expertPanelId: row.expertPanelId,
                            evaluationSheetId: row.evaluationSheetId,
                          },
                        )
                        : generatePath(URL.expert.expertPanelsAssessmentSheet, { id: row.evaluationSheetId }),
                    );
                  }}
                >
                  Podgląd arkusza
                </MenuItem>
              )}
              {(
                (((row.panelType === EXPERT_PANEL_TYPES.module || row.panelType === EXPERT_PANEL_TYPES.moduleFirstStage)
                    && row.evaluationSheetId)
                || (row.panelType === EXPERT_PANEL_TYPES.withExpert && row?.currentExpert?.accepted))
                && [EVALUATION_SHEET_STATUS.edition, EVALUATION_SHEET_STATUS.unblocked].includes(row.status)
              ) && (
                <MenuItem
                  key={uniqid()}
                  variant="right-border"
                  id={uniqid()}
                  onClick={() => {
                    navigate(
                      row.panelType === EXPERT_PANEL_TYPES.moduleFirstStage
                        ? generatePath(
                          URL.expert.evaluationSheet,
                          {
                            expertPanelId: row.expertPanelId,
                            evaluationSheetId: row.evaluationSheetId,
                          }
                        )
                        : generatePath(URL.expert.expertPanelsAssessmentSheet, { id: row.evaluationSheetId }),
                      { state: { isReadonly: false } }
                    );
                  }}
                >
                  Arkusz oceny
                </MenuItem>
              )}
              {(row.panelType === EXPERT_PANEL_TYPES.withExpert
                && !row?.currentExpert?.accepted
                && !row?.currentExpert?.refused) && (
                <ApplicationAcceptanceDialog
                  panelType={row.panelType}
                  payload={payload}
                  expertPanelExpertsId={iriToId(row?.currentExpert?.['@id'])}
                  reloadData={reloadData}
                  isReadonly={false}
                />
              )}
              {(row.panelType === EXPERT_PANEL_TYPES.withExpert
                && !row?.currentExpert?.accepted
                && !row?.currentExpert?.refused) && (
                <MenuItem
                  key={uniqid()}
                  variant="right-border"
                  id={uniqid()}
                  onClick={() => render(
                    <ApplicationRejectionDialog
                      expertPanelExpertsId={iriToId(row?.currentExpert?.['@id'])}
                      panelType={row.panelType}
                      payload={payload}
                      reloadData={reloadData}
                      isReadonly={false}
                    />,
                    'Odrzuenie wniosku',
                  )}
                >
                  Odrzuć wniosek
                </MenuItem>
              )}
              {row.panelType === EXPERT_PANEL_TYPES.module && row?.acceptanceDate
                && row.recruitmentId !== notVisibleBtnOnRecruitmentId && (
                <MenuItem
                  key={uniqid()}
                  variant="right-border"
                  id={uniqid()}
                  onClick={() => {
                    navigate(
                      URL.expert.expertPanelsPreliminaryRemark,
                      {
                        state: {
                          applicationNumber: row.applicationNumber,
                          expertPanelApplication: row.expertPanelApplication,
                          leadingExpertId: row.leadingExpertId,
                          preliminaryRemark: row.preliminaryRemark,
                          expertPanelId: row.expertPanelId,
                        },
                      }
                    );
                  }}
                >
                  Wstępne uwagi ekspertów
                </MenuItem>
              )}
            </ButtonMenu>
          </ButtonGroup>
        )}
      </>,
    ];
  };

  const columns = [
    {
      headerName: 'Numer wniosku',
      field: 'applicationNumber',
      flex: 0.5,
    },
    {
      headerName: 'Ostateczna data decyzji',
      field: 'finalDecisionDate',
      valueFormatter: ({ value }) => formatDateString(value),
      flex: 0.5,
      type: 'date',
    },
    {
      headerName: 'Data przyjęcia do oceny',
      field: 'acceptanceDate',
      valueFormatter: ({ value }) => formatDateString(value),
      flex: 0.5,
      type: 'date',
    },
    {
      headerName: 'Ostateczna data oceny',
      field: 'finalEvaluationDate',
      valueFormatter: ({ value }) => formatDateString(value),
      flex: 0.5,
      type: 'date',
    },
    {
      headerName: 'Data odrzucenia',
      field: 'refusalDate',
      valueFormatter: ({ value }) => formatDateString(value),
      flex: 0.5,
      type: 'date',
    },
    {
      headerName: 'Data posiedzenia panelu',
      field: 'date',
      valueFormatter: ({ value }) => formatDateString(value),
      filterable: false,
      flex: 0.5,
      type: 'date',
    },
    {
      headerName: 'Rodzaj panelu',
      field: 'panelType',
      filterable: false,
      valueFormatter: ({ value }) => (PANEL_TYPES_TRANSLATIONS[value] || ''),
      flex: 0.3,
    },
    {
      headerName: 'Ekspert wiodący',
      field: 'isLoggedUserLeadingExpert',
      filterable: false,
      valueFormatter: ({ value }) => (value ? BOOLEAN_VALUES.TRUE : BOOLEAN_VALUES.FALSE),
      flex: 0.3,
    },
    {
      headerName: 'Status arkusza',
      field: 'status',
      filterType: 'multipleSelect',
      valueFormatter: ({ value }) => EVALUATION_SHEET_STATUS_TRANSLATIONS[value] ?? 'Nieznany status',
      valueOptions: Object.entries(EVALUATION_SHEET_STATUS_TRANSLATIONS).map(([value, label]) => ({
        id: value,
        label,
      })),
      flex: 0.3,
    },
    {
      headerName: 'Akcja',
      field: 'actions',
      type: 'actions',
      filterable: false,
      getActions: getRowActions,
      width: 170,
    },
  ];

  return (
    <DataGrid
      disableVirtualization
      rows={expertPanelList}
      rowCount={expertPanelList.length || 0}
      columns={columns}
      autoHeight
      loading={!isLoaded}
      initialState={{
        sorting: {
          sortModel: [{
            field: 'date',
            sort: 'desc',
          }],
        },
      }}
      {...dataGridProps}
    />
  );
}

ExpertPanelList.propTypes = {
  setFilterModel: PropTypes.func.isRequired,
  expertPanelList: PropTypes.arrayOf(Object).isRequired,
  isLoaded: PropTypes.bool.isRequired,
  reloadData: PropTypes.func.isRequired,
};
