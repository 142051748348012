import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Button,
  Typography,
  Box,
} from '@mui/material';
import uniqid from 'uniqid';
import { sanitizeAndRenderHtml } from '../_helpers';

/**
 * Onclick expendable span text element.
 *
 * @param {object} props - root props
 * @param {string} props.shortText - short/partial text
 * @param {string} props.fullText - full text
 * @param {string} props.variant - type variant
 * @param {number} props.truncateLength - truncate length
 * @returns {ExpandableText}
 */
export default function ExpandableText({
  shortText, fullText, variant, truncateLength,
}) {
  const isTextShortenThanProvidedValue = fullText.length < truncateLength;
  const [expanded, setExpanded] = useState((variant === 'id' ? false : isTextShortenThanProvidedValue));

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  if (variant === 'text') {
    return (
      <Typography mb={3} component="p">
        {expanded ? fullText : fullText.slice(0, truncateLength || 200)}
        {!isTextShortenThanProvidedValue && (
          <Button
            type="button"
            variant="text"
            color="secondary"
            id={uniqid()}
            onClick={handleExpand}
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
            }}
          >
            {expanded ? 'Pokaż mniej' : '(...) Pokaż więcej'}
          </Button>
        )}
      </Typography>
    );
  }

  if (variant === 'html') {
    return <Box mb={3}>{expanded ? sanitizeAndRenderHtml(fullText) : sanitizeAndRenderHtml(`${fullText.slice(0, 200)}...`)}</Box>;
  }

  return (
    <span
      onClick={handleExpand}
      aria-hidden="true"
      style={{
        cursor: 'pointer',
        textAlign: 'left',
      }}
    >
      {expanded ? fullText : shortText}
    </span>
  );
}

ExpandableText.propTypes = {
  shortText: PropTypes.string,
  fullText: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['id', 'text', 'html']),
  truncateLength: PropTypes.number,
};

ExpandableText.defaultProps = {
  shortText: null,
  variant: 'id',
  truncateLength: 200,
};
