import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import uniqid from 'uniqid';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useFormContext } from 'react-hook-form';
import {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../../../styles/theme';
import { useEvaluationSheetsActionContext } from '../../../../Context';

/**
 * Evaluation sheet accordion.
 *
 * @param {object} props - root props
 * @param {string} props.evaluationItemId - evaluation item id
 * @param {string} props.evaluationItemName - evaluation item name
 * @param {Node} props.children - evaluation item form
 * @returns {EvaluationSheetAccordion}
 */
export function EvaluationSheetAccordion({
  evaluationItemId, evaluationItemName, children,
}) {
  const { formState: { errors } } = useFormContext();
  const hasErrors = evaluationItemId in errors;
  const { isExpandedAllEvaluationItems } = useEvaluationSheetsActionContext();
  const [isExpanded, setExpanded] = useState(isExpandedAllEvaluationItems);

  useEffect(() => {
    setExpanded(isExpandedAllEvaluationItems);
  }, [isExpandedAllEvaluationItems]);

  return (
    <Accordion
      sx={{ mb: (t) => t.spacing(1) }}
      expanded={isExpanded}
      onChange={() => setExpanded((prevState) => !prevState)}
      slotProps={{ transition: { unmountOnExit: false } }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={uniqid()}
        sx={{ backgroundColor: theme.palette.brandGray10.main }}
      >
        <Typography fontSize={15} fontWeight={theme.typography.fontWeightRegular} mr={2}>
          {evaluationItemName}
        </Typography>
        {hasErrors && (
          <ErrorOutlineIcon color="error" />
        )}
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

EvaluationSheetAccordion.propTypes = {
  evaluationItemId: PropTypes.string.isRequired,
  evaluationItemName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
